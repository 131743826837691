import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {Formulaire,Segment} from "@domain/admin/workflow/segment";
import {BehaviorSubject,Subscription} from "rxjs";
import {FloatingButtonAction} from "@share/component/floating-button/floating-button";
import {ListView} from "@domain/common/list-view";
import {AbstractObjetWorkflow} from "@domain/workflow/abstract-objet-workflow";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {AvanceListItemComponent} from "@components/avance/avance-list/avance-list-item/avance-list-item.component";
import {FactureListItemComponent} from "@components/facture/facture-list/facture-list-item/facture-list-item.component";
import {NdfListItemComponent} from "@components/ndf/ndf-list/ndf-list-item/ndf-list-item.component";
import {OdListItemComponent} from "@components/od/od-list/od-list-item/od-list-item.component";
import {OmpListItemComponent} from "@components/omp/omp-list/omp-list-item/omp-list-item.component";
import {ListeAvance} from "@domain/avance/liste-avance";
import {ListeFacture} from "@domain/facture/liste-facture";
import {ListeNdf} from "@domain/ndf/liste-ndf";
import {ListeOd} from "@domain/od/liste-od";
import {ListeOmp} from "@domain/omp/liste-omp";
import {Page} from "@domain/common/http/list-result";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";

/**
 * Onglet informations
 */
@Component({
	host: {'data-test-id': 'segment-informations'},
	selector: 'segment-informations',
	templateUrl: './segment-informations.component.html'
})
export class SegmentInformationsComponent<T extends AbstractObjetWorkflow & ListItem,S extends ListViewItem<T>> implements OnInit,OnDestroy {

	/** Segment visualisé */
	@Input() segment: Segment;

	/** Segment enregistré */
	private _segmentBuffer: Segment;
	@Input() set segmentBuffer(segmentBuffer: Segment) { this._segmentBuffer = segmentBuffer; this.setNewSegment(); };
	get segmentBuffer(): Segment { return this._segmentBuffer; }

	/** Liste des actions du floatingButton en bas à droite */
	@Input() listeFloatingButtonActions: BehaviorSubject<Array<FloatingButtonAction>>;

	/** Données */
	liste: ListView<T,S>;

	/** Indicateur d'affichage de la liste en fonction de la synchro WF */
	displayListe: boolean = false;

	/** Souscription du composant */
	souscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private statutWorkflowService: StatutWorkflowService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Abonnement aux changements de statut workflow
		this.souscription = this.statutWorkflowService.synchroWFStatut$.subscribe((statut) => this.displayListe = statut?.statut == KeyStatutWorkflow.HISTO_WF_NONE);
	}

	/**
	 * Paramétrages de la liste en fonction du segment sauvegardé
	 */
	private setNewSegment(): void {
		//Si le segment a déjà un ID
		if (this._segmentBuffer?.idSegment) {
			//Initialisation
			let component: any;
			let defaultOrder: string;

			//Données en fonction de la portée
			switch (this.segmentBuffer?.formulaireDepart?.idPortee) {
				case Formulaire.AV:
					component = AvanceListItemComponent;
					defaultOrder = '-idAvance';
					break;
				case Formulaire.FC:
					component = FactureListItemComponent;
					defaultOrder = '-idFacture';
					break;
				case Formulaire.NF:
					component = NdfListItemComponent;
					defaultOrder = '-idNDF';
					break;
				case Formulaire.OD:
				case Formulaire.DV:
					component = OdListItemComponent;
					defaultOrder = '-idOd';
					break;
				case Formulaire.OT:
					component = OmpListItemComponent;
					defaultOrder = '-idOMPermanent';
					break;
				default:
					throw new Error("Type non supporté.");
			}

			//Purge de la liste
			this.liste = undefined;

			//Définition de la liste
			setTimeout(() => {
				this.liste = new ListView<T,S>({
					uri: `/controller/Workflow/listeObjets/${this.segmentBuffer?.idSegment}`,
					title: this.getListeTitle(),
					component: component,
					defaultOrder: defaultOrder,
					isFilterVisible: false,
					isSearchBar: false,
					isLocal: true,
					mapResult: (result: any) => {
						//Extraction de l'objet concerné dans le résultat
						const page = result;

						//Typage des éléments de la liste
						if (page?.listeResultats) {
							page.listeResultats = page.listeResultats.map(r => {
								switch (this.segmentBuffer?.formulaireDepart?.idPortee) {
									case Formulaire.AV :
										return new ListeAvance(r);
									case Formulaire.FC :
										return new ListeFacture(r);
									case Formulaire.NF :
										return new ListeNdf(r);
									case Formulaire.OD :
									case Formulaire.DV :
										return new ListeOd(r);
									case Formulaire.OT :
										return new ListeOmp(r);
									default:
										throw new Error("Type non supporté.");
								}
							});
						}

						//Retour
						return page as Page<T>;
					}
				});

				//Forçage du nombre d'éléments listés à 25
				this.liste.nbObjetsParPage = 25;
			});
		}
	}

	/**
	 * Retourne le titre de la liste en fonction de la portée
	 */
	getListeTitle(): string {
		return this.translateService.instant('admin.workflow.construction.segment.onglets.informations.listeObjets.title' + (this.segmentBuffer?.formulaireDepart?.idPortee == Formulaire.DV ? 'OD' : this.segmentBuffer?.formulaireDepart?.idPortee));
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.souscription.unsubscribe();
	}
}
