import {CoreComponentsModule} from "../core-components.module";
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {RseIframeComponent} from "./rse-iframe.component";

@NgModule({
	imports: [CoreComponentsModule, TranslateModule],
	declarations: [RseIframeComponent],
	exports: [RseIframeComponent]
})
export class RseIframeModule {
}