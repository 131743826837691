import {Component,Inject} from '@angular/core';
import {Od} from "@domain/od/od";
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ODService} from "../../../../od.service";
import {OdFraisPrevisionnelAddComponent} from "./frais-previsionnel/od-frais-previsionnel-add.component";
import {SettingsODState} from "@domain/settings/settings";
import {FraisPrev} from "@domain/fraisprev/frais-prev";
import {NavigationExtras,Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypePortee} from "@domain/workflow/workflow";
import {OdTypeFrais} from "@domain/od/frais/od-type-frais";

/**
 * Composant d'affichage de la popin de sélection du type de frais à créer
 *
 * @author Laurent Convert
 * @date 09/12/2021
 */
@Component({
    templateUrl: './od-frais-add.component.html'
})
export class OdFraisAddComponent {
    /** Déclaration pour accès direct depuis le template */
    OdTypeFrais = OdTypeFrais;

    /** Portée de l'objet */
    readonly portee: TypePortee = TypePortee.OD;

    /** Ordre de mission */
    od: Od = null;

    /** Paramétrage de l'OD */
    settings: SettingsODState;

    /** Indicateur de modification */
    canModifier: boolean;

    /** Paramètres du type entité */
    typeEntiteParam: TypeEntiteParamOD;

    /** Liste des types disponibles à la création */
    listeTypes: Array<OdTypeFrais>;

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private pleaseWaitService: PleaseWaitService,
                private odService: ODService,
                private router: Router,
                private matDialog: MatDialog,
                private matDialogRef: MatDialogRef<any>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    refreshListe: () => void,
                    od: Od,
                    settings: SettingsODState,
                    listeTypes: Array<OdTypeFrais>
                }
    ) {
        //Récupération des données fournies à l'ouverture de la popin
        this.od = this.data.od;
        this.settings = this.data.settings;
        this.listeTypes = this.data.listeTypes;
    }

    /**
     * Création d'un frais prévisionnel
     */
    openNewFraisPrev(): void {
        let fraisPrev: FraisPrev;
        let refPopinAdd: MatDialogRef<OdFraisPrevisionnelAddComponent>;

        //Initialisation des valeurs par défaut du frais prévisionnel
        fraisPrev = {
            idFraisPrev: 0,
            idOd: this.od.idOd,
            ville: this.od.ville,
            pays: this.od.pays,
            devise: this.settings.deviseEntreprise,
            cours: 1
        };

        //Ouverture de la popup
        refPopinAdd = this.matDialog.open(OdFraisPrevisionnelAddComponent,{
            data: {
                fraisPrev: fraisPrev,
                od: this.od,
                settings: this.settings,
                canModifier: true //On est en création, on ne peut que modifier
            },
            hasBackdrop: true,
            disableClose: true
        });

        //Rafraichissement après la fermeture de la popin de création
        if (this.data.refreshListe) {
            refPopinAdd.afterClosed().subscribe((res: {refresh: boolean, message: string}) => {
                //Rechargement de la liste le cas échéant
                if (res?.refresh) {
                    //Rechargement de la liste des frais
                    this.data.refreshListe();
                }
            });
        }

        //Après l'ouverture de la popin de création...
        refPopinAdd.afterOpened().subscribe((data) => {
                //...Fermeture de la popin de choix du type de frais à créer
                this.matDialogRef.close();
            });

    }

    /**
     * Création d'une NDF
     */
    createNDF(): void {
        let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Handler sur une erreur lors de la création
        const errorHandler = (err) => {
            //Affichage d'un message d'erreur
            this.toastrService.error(this.translateService.instant("od.frais.frais.liste.errorCreation"));

            //Fermeture de la popup d'attente
            stopWaitingHandler.call(this);
        };

        //Handler de fermeture de la popup d'attente
        const stopWaitingHandler = () => {
            //Fermeture de la popup d'attente
            matDialogRef.close();
        }

        //Affichage de la popup d'attente
        matDialogRef = this.pleaseWaitService.show();

        //Création de la NDF
        this.odService.createNDF(this.od).subscribe({
            next: idNDF => {
                if (idNDF && idNDF > 0) {
                    this.odService.showNDF({idNDF: idNDF})
                        .then(() => { this.matDialogRef.close(); })
                        .finally(stopWaitingHandler);
                } else {
                    //Gestion de l'erreur
                    errorHandler.call(this);
                }
            },
            //Gestion de l'erreur
            error: errorHandler
        });
    }

    /**
     * Redirection vers la création d'une avance
     */
    createAvance(): void {
        //Définition d'un objet contenant l'od à passer au composant des avances
        const extras: NavigationExtras = {
            state: {
                od: this.od,
                collab: {
                    idUser: this.od.user.idUser,
                    nom: this.od.user.nom,
                    prenom: this.od.user.prenom
                }
            }
        };

        //Redirection vers l'écran de création de l'avance
        this.router.navigate(['Avance',0], extras).then(() => {
            //Fermeture de la popin de création
            this.matDialogRef.close();
        });
    }

    /**
     * Exécute l'action de création associée au type de frais
     *
     * @param typeFrais
     */
    doAction(typeFrais: OdTypeFrais): void {
        switch (typeFrais) {
            case OdTypeFrais.frais_prev:
                //Création d'une facture prévisionnelle
                this.openNewFraisPrev();
                break;
            case OdTypeFrais.ndf:
                //Création d'une NDF
                this.createNDF();
                break;
            case OdTypeFrais.avance:
                //Création d'une avance
                this.createAvance();
                break;
        }
    }
}
