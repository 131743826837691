import {Routes} from '@angular/router';
import {AdminComponent} from "@components/admin/admin.component";
import {adminRoutes} from "@components/admin/admin.module";
import {AvanceListComponent} from "@components/avance/avance-list/avance-list.component";
import {AvanceComponent} from "@components/avance/avance.component";
import {EnveloppeListComponent} from "@components/budget/liste/enveloppe-list.component";
import {LotListComponent} from "@components/comptabilite/list/lot-list.component";
import {LotComponent} from "@components/comptabilite/lot.component";
import {DashboardComponent} from '@components/dashboard/dashboard.component';
import {DelegationsListComponent} from "@components/delegations/liste/delegations-list.component";
import {FactureListComponent} from '@components/facture/facture-list/facture-list.component';
import {ReleveFactureComponent} from '@components/facture/releve-facture.component';
import {ReleveListComponent} from '@components/facture/releve-list/releve-list.component';
import {NdfListComponent} from '@components/ndf/ndf-list/ndf-list.component';
import {NDFComponent} from '@components/ndf/ndf.component';
import {ODComponent} from '@components/od/detail/od.component';
import {OdListComponent} from '@components/od/od-list/od-list.component';
import {OMPComponent} from "@components/omp/detail/omp.component";
import {OmpListComponent} from "@components/omp/omp-list/omp-list.component";
import {ProfilComponent} from "@components/profil/profil.component";
import {ProfilAppMobileComponent} from "@components/profil/user/app-mobile/profil-app-mobile.component";
import {DonneesConnexionComponent} from "@components/profil/user/donnees-connexion/donnees-connexion.component";
import {ProfilPreferencesAnalytiquesComponent} from "@components/profil/user/preferences-analytiques/profil-preferences-analytiques.component";
import {ReportingListComponent} from "@components/reporting/reporting-list.component";
import {VehiculeListComponent} from "@components/vehicule/list/vehicule-list.component";
import {VehiculeComponent} from "@components/vehicule/vehicule.component";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {LoggedGuardProvider} from '@core/security/logged-guard.provider';
import {TypePortee} from '@domain/workflow/workflow';
import {LoginComponent} from '@share/login/login.component';
import {FactureComponent} from "@components/facture/detail/facture.component";
import {ObjetsWFGuardProvider} from "@core/security/objetsWF-guard.provider";
import {SsoGuardProvider} from "@core/security/sso-guard.provider";
import {BlankComponent} from "@share/layout/blank.component";
import {ProfilGuardProvider} from "@core/security/profil-guard.provider";
import {ProfilPersonnalisationComponent} from "@components/profil/user/personnalisation/profil-personnalisation.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {DashboardGuardProvider} from "@core/security/dashboard-guard.provider";
import {TypeProfil} from "@domain/user/user";
import {FonctionGuardProvider} from "@core/security/fonction-guard-provider.service";

/**
 * Routes
 */
export const routes: Routes = [{
	path: 'Login',
	component: LoginComponent
},{
	path: 'Login/:typeAction',
	component: LoginComponent
},{
	path: 'SSO/:typeAction',
	component: LoginComponent,
	canActivate: [SsoGuardProvider]
},{
	path: 'Dashboard',
	component: DashboardComponent,
	canActivate: [LoggedGuardProvider,DashboardGuardProvider]
},{
	path: 'ListeNDF',
	component: NdfListComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.NF
	}
},{
	path: 'NDF/:id',
	component: NDFComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.NF,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_NDF]
	}
},{
	path: 'NDF/:idNDF/Avance/:id',
	component: AvanceComponent,
	canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.AV,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_AVANCES]
	}
},{
	path: 'ListeOMP',
	component: OmpListComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.OT
	}
},{
	path: 'OMP/:id',
	component: OMPComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.OT,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_MISSIONS]
	}
},{
	path: 'ListeOD',
	component: OdListComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.OD
	}
},{
	path: 'OD/:id',
	component: ODComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.OD,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_MISSIONS]
	}
},{
	path: 'OD/:idOd/Facture/:id',
	component: FactureComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.FC,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_RELEVES_FACTURES]
	}
},{
	path: 'ListeReleve',
	component: ReleveListComponent,
    canActivate: [ObjetsWFGuardProvider],
    data: {
        typePortee: TypePortee.FC
    }
}, {
    path: 'ReleveFacture/:id',
    component: ReleveFactureComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.FC,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_RELEVES_FACTURES]
	}
},{
	path: 'ListeFacture',
	component: FactureListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.FC
	}
},{
	path: 'Facture/:id',
	component: FactureComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.FC
	}
},{
	path: 'ReleveFacture/:idReleve/Facture/:id',
	component: FactureComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.FC,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_RELEVES_FACTURES]
	}
},{
	path: 'Budget',
	component: EnveloppeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.BGT
	}
},{
	path: 'ListeVehicule',
	component: VehiculeListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.VP
	}
},{
	path: 'Vehicule/:idPa',
	component: VehiculeComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.VP
	}
},{
	path: 'ListeReport',
	component: ReportingListComponent,
	canActivate: [LoggedGuardProvider]
},{
	path: 'ListeAvance',
	component: AvanceListComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.AV
	}
},{
	path: 'Avance/:id',
	component: AvanceComponent,
    canActivate: [ObjetsWFGuardProvider],
	data: {
		typePortee: TypePortee.AV,
		sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_AVANCES]
	}
},{
	path: 'Delegations',
	component: DelegationsListComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: null
	}
},{
	path: 'ListeLot',
	component: LotListComponent,
	canActivate: [LoggedGuardProvider,FonctionGuardProvider],
	data: {
		typePortee: TypePortee.CPT,
		fonctions: [TypeProfil.ADMINISTRATEUR,TypeProfil.COMPTABLE]
	}
},{
	path: 'Lot/:idLot/Facture/:id',
	component: FactureComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.FC
	}
},{
	path: 'Lot/:idLot/Avance/:id',
	component: AvanceComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.AV
	}
},{
	path: 'Lot/:idLot/NDF/:id',
	component: NDFComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.NF
	}
},{
	path: 'Lot/:idLot',
	component: LotComponent,
	canActivate: [LoggedGuardProvider],
	data: {
		typePortee: TypePortee.CPT
	}
},{
	path: 'Profil',
	component: ProfilComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
},{
	path: 'Profil/donneesConnexion',
	component: DonneesConnexionComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
},{
	path: 'Profil/mobile',
	component: ProfilAppMobileComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
},{
	path: 'Profil/prefAnalytique',
	component: ProfilPreferencesAnalytiquesComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
}, {
    path: 'Profil/personnalisation',
    component: ProfilPersonnalisationComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
},{
	path: 'Profil/Vehicule/:idPa',
	component: VehiculeComponent,
    canActivate: [LoggedGuardProvider,ProfilGuardProvider],
	data: { isAdminForbidden: true }
},{
    path: 'Blank',
    component: BlankComponent,
}, {
	path: 'Admin',
	component: AdminComponent,
	canActivate: [AdminGuardProvider],
	children: adminRoutes
},{
	path: '',
	redirectTo: 'Dashboard',
	pathMatch: 'full'
},{
	path: '**',
	redirectTo: 'Dashboard'
}];