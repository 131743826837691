import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Etape } from "@domain/od/etape";
import { Proposition, StatutProposition } from "@domain/od/proposition";
import { TypeAiguillage } from "@domain/voyage/travel/constants";
import { MapAction } from "@domain/workflow/mapAction";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import * as moment from 'moment';
import { of } from "rxjs";
import { filter } from "rxjs/operators";
import { WorkflowActionComponent } from "@components/workflow/workflow-action.component";
import { ODService } from "../../../od.service";
import { PopinMontant } from "./montant/od-proposition-montant-popin.component";

/**
 * Composant d'affichage d'une proposition
 *
 * @author Laurent SCIMIA
 * @date 04/01/2022
 */
@Component({
    host: {'data-test-id': 'proposition-item'},
    selector: 'proposition-item',
    templateUrl: './od-proposition-item.component.html',
    styleUrls: ['./od-proposition-item.component.scss']
})
export class ODPropositionItemComponent implements OnInit {
    /** Accès à l'enum dans la vue */
    StatutProposition = StatutProposition;
    TypeAiguillage = TypeAiguillage;

    /** Élément courant */
    @Input() data: Proposition;

    /** Index de la proposition dans la liste de toutes les propositions de l'OD */
    @Input() index: number;

    /** Nombre total de propositions de l'OD */
    @Input() nbPropositions: number;

    /** Devise entreprise */
    @Input() devise: string;

    /** Devise travel */
    @Input() deviseOrigine: string;

    /** true si une proposition a été choisie pour l'OD (pas forcément celle là) */
    @Input() hasPropositionChoisie: boolean;

    /** Identifiant de l'OD de la proposition */
    @Input() idOd: number;

    /** Actions possibles pour l'OD */
    @Input() odMapActions: MapAction;

    /** Liste des étapes de l'OD */
    @Input() listeEtapes: Etape[];

    /** Aiguillage de l'OD (Online/Offline) */
    @Input() aiguillageOd: TypeAiguillage;

    /** Actions possibles pour le DV */
    @Input() dvActions: MapAction;

    /** Evènement de demande de refresh */
    @Output() needRefresh = new EventEmitter<void>();

    /** Indique si le détail de prestation est ouvert */
    public isOuvert: boolean = false;

    constructor(
        private matDialog: MatDialog,
        private odService: ODService,
        private confirmService: ConfirmService,
        private translateService: TranslateService
    ) { }

    /** Initialisation du composant */
    ngOnInit() {
        //On ouvre la proposition choisie ou la proposition s'il n'y en a qu'une
        if (this.data.choisie || this.nbPropositions === 1) {
            this.isOuvert = true;
        }
    }

    /**
     * Sélection d'une proposition
     */
    choisir() {
        this.confirmService.showConfirm(this.translateService.instant('od.voyage.confirmChoisir')).pipe(filter(isConfirm => isConfirm)).subscribe(() => {
            this.odService.choisirProposition(this.data.idProposition).subscribe(() => {
                //On refresh les infos
                this.needRefresh.emit();
            });
        });
    }

    /**
     * Rejeter toutes les propositions
     */
    rejeterTout() {
        if (this.dvActions.canRejeter.possible && this.dvActions.canRejeter.motifRejet) {
            this.matDialog.open(WorkflowActionComponent, {
                data: {
                    libelle: 'rejetProposition',
                    getListeMotifs: () => of([]),
                    isMotif: true,
                    isMotifObligatoire: this.dvActions.canRejeter.motifRejetObligatoire,
                    listeItems: Array(this.nbPropositions)
                },
                position: {
                    top: '100px'
                },
                hasBackdrop: true
            }).afterClosed().subscribe((motif: { isConfirmed: boolean, idMotif?: number, motif?: string }) => {
                if (motif.isConfirmed) {
                    this.odService.rejeterTout(this.idOd, motif.motif).subscribe(() => {
                        this.needRefresh.emit();
                    });
                }
            })
        } else {
            this.odService.rejeterTout(this.idOd).subscribe(() => {
                this.needRefresh.emit();
            });
        }
    }


    /**
     * Envoyer une modification des étapes
     */
    modifier() {
        this.odService.modifierProposition(this.idOd).subscribe(() => {
            this.needRefresh.emit();
        });
    }

    /**
     * Inverse l'ouverture du détail de la proposition
     */
    switchIsOuvert() {
        this.isOuvert = !this.isOuvert;
    }

    /**
     * Affiche la popin de détail des montants de la proposition
     */
    showPopinMontant() {
        //Affichage de la popup des montants de la proposition
        this.matDialog.open(PopinMontant, {
            data: {
                proposition: this.data,
                devisePrimaire: this.devise,
                deviseSecondaire: this.deviseOrigine
            }
        });
    }

    /**
     * Indique si la date d'approbation est dépassée
     * @returns {boolean}
     */
    isApprobationPerimee(): boolean {
        return this.data.statut != StatutProposition.STATUT_RESERVE && moment().isAfter(this.data.dateLimiteEmission);
    }

    /**
     * Indique si la date d'approbation est sur le point d'être dépassée
     * @returns {boolean}
     */
    isApprobationPresquePerimee(): boolean {
        return !this.isApprobationPerimee() && this.data.statut != StatutProposition.STATUT_RESERVE && moment().isAfter(moment(this.data.dateLimiteEmission).subtract(1, 'days'));
    }

    /**
     * Annulation du choix d'une proposition
     */
    annulerChoix() {
        this.confirmService.showConfirm(this.translateService.instant('od.voyage.confirmAnnulerChoix')).pipe(filter(isConfirm => isConfirm)).subscribe(() => {
            this.odService.annulerChoixProposition(this.data.idProposition).subscribe(() => {
                //On refresh les infos
                this.needRefresh.emit();
            });
        });
    }
}