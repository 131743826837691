import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {Etat} from "@domain/workflow/etat";
import {EtatListeItemComponent} from "@components/admin/workflow/statuts/etats/liste/etat-liste-item.component";
import {Result} from "@domain/common/http/result";

/**
 * Liste des Etats
 *
 * @author Angeline Ha
 * @date 01/10/2024
 */
@Component({
  selector: 'etats-liste',
  templateUrl: './etats-liste.component.html'
})
export class EtatsListeComponent implements OnInit {
  /** Liste des états */
  listeEtats: ListView<Etat,EtatListeItemComponent>;

  /**
   * Constructeur
   *
   * @param translateService Service des traductions
   */
  constructor(private translateService: TranslateService) {
  }

  /**
   * Initialisation
   */
  ngOnInit() {
    //Initialisation de la liste des états
    this.listeEtats = new ListView<Etat,EtatListeItemComponent>({
      uri: '/controller/Statut/listeEtats',
      title: this.translateService.instant('workflow.etats.title'),
      component: EtatListeItemComponent,
      defaultOrder: 'idEtat',
      isSimple: true,
      mapResult: (result: Result) => {
        return result?.data?.listeEtats
      }
    });
  }
}
