import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {MailTo} from "@domain/admin/workflow/segment";
import {MatDialog} from "@angular/material/dialog";
import {MailToPopupComponent} from "@components/admin/workflow/construction/segment/mail-to-popup.component";

/**
 * Composant d'une notification
 *
 * @author Guillaume TRAVOT
 * @date 26/06/2024
 */
@Component({
    host: {'data-test-id': 'mail-to-item'},
    selector: 'mail-to-item',
    templateUrl: './mail-to-item.component.html'
})
export class MailToItemComponent extends ListViewItem<MailTo> {

    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog) {
        super();
    }

    /**
     * Ouverture de la popup
     */
    onMailToClick(): void {
        this.matDialog.open(MailToPopupComponent,{
            data: {
                mailTo: this.data,
                listeRoles: this.extraOptions.listeRoles,
                segmentId: this.liste.extraOptions.segmentId
            }
        }).afterClosed().subscribe((refresh) => { if (refresh) { this.extraOptions.refreshListeMailTo(); }});
    }
}
