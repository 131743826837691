<div class="lvi-content">
    <div class="avatar">
        <ng-container [ngSwitch]="data.idEtat">
            <i *ngSwitchCase=EnumEtat.EN_CREATION class="nio icon-modification icone"></i>
            <i *ngSwitchCase=EnumEtat.EN_VALIDATION class="material-icons-outlined">published_with_changes</i>
            <i *ngSwitchCase=EnumEtat.VALIDE class="material-icons-outlined">check</i>
            <i *ngSwitchCase=EnumEtat.COMPTABILISE_CLOTURE class="nio icon-comptabilisation"></i>
            <i *ngSwitchCase=EnumEtat.ARCHIVE class="nio icon-archive icone"></i>
            <i *ngSwitchCase=EnumEtat.ANNULE class="material-icons-outlined">cancel</i>
        </ng-container>    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'workflow.etat.demande'"></strong><span>{{ nbActionsDemande }}</span></li>
            <li><strong [translate]="'workflow.etat.annulation'"></strong><span>{{ nbActionsAnnulation }}</span></li>
            <li><strong [translate]="'workflow.etat.modification'"></strong><span>{{ nbActionsModification }}</span></li>
            <li><strong [translate]="'workflow.etat.commande'"></strong><span>{{ nbActionsCommande }}</span></li>
        </ul>
    </div>
</div>