<splash></splash>
<navigator (menuToggle)="menuRef.toggle()" *ngIf="layoutService.isLayoutPostLogin$ | async"></navigator>

<div class="page-content">
    <header *ngIf="layoutService.isLayoutPostLogin$ | async"></header>
    <app-menu #menuRef [ngClass]="{ 'full-width': !(layoutService.isLayoutPostLogin$ | async)}" [settings]="settings">
        <div [ngClass]="{ 'container-fluid': layoutService.isLayoutPostLogin$ | async}">
            <router-outlet></router-outlet>
        </div>
    </app-menu>
</div>
