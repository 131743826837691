<h1 mat-dialog-title>
    <span [translate]="'rse.impactCO2' | translate"></span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form>
        <ng-container *ngIf="(rseService.paramRse | async).isIframeActive; else IframeInactive">
            <rse-iframe [libelleOrigine]="data.origine" [libelleDestination]="data.destination"></rse-iframe>
        </ng-container>
        <ng-template #IframeInactive>
            <div [innerHtml]="'rse.redirectAdeme' | translate"></div>
        </ng-template>
    </form>
</div>