<div class="with-floating-button" actionsFlottantesCustom>
    <div class="content" *ngIf="objetWorkflow">
        <page-header #pageHeaderComponent [title]="'ndf.title' | translate:{ numero: objetWorkflow.numeroPiece }"
                     [extraInfo]="objetWorkflow.statut.libelle"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="listeAlertes?.niveau"
                     [statutArchivage]="objetWorkflow.statutArchivage"
                     (onStatutArchivageClick)="isAvp(this.objetWorkflow) ? onStatutArchivageClick() : null"
                     [settings]="settings"
                     [selectedTab]="selectedItem$.asObservable()"
                     (onSelectedItemChange)="onSelectedItemChange($event)"
                     (onGoBack)="onGoBack()"
                     (onExtraInfoClick)="showChainage()"
                     [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem.code != Onglets.GENERALITES">
            <ndf-generalites
                    [ndf]="objetWorkflow" [canModifier]="canModifier"
                    [settings]="settings"
                    [listeComptesBancaires]="listeComptesBancaires"
                    [isCompteBancaireDisabled]="isCompteBancaireDisabled"
                    [isValideComptable]="isValideComptable">
            </ndf-generalites>

            <ndf-generation-frais [ndf]="objetWorkflow" *ngIf="canModifier" [settings]="settings" (checkFraisAlerte)="onCheckFraisAlerte(null)"></ndf-generation-frais>

            <analytique
				#analytique="analytique"
                [listeAnalytiques]="objetWorkflow.listeVentilations" [typeEntite]="objetWorkflow.typeEntite"
                [idMIB]="objetWorkflow.periode?.idPeriode"
                [settings]="settings"
                [canModifier]="canModifier" [canSaisie]="canModifierAnalytique"
                [preferenceAnalytiqueCount]="preferenceAnalytiqueCount"
                [idCollab]="objetWorkflow.getIdCollab()"
                [isOrgaFixe]="settings.isOrgaFixe"
                (onModification)="buildListeAlertes()">
            </analytique>

            <!-- Cadre enveloppes budgétaires -->
            <suivi-budget [idTypeObjet]="TypePorteeInt.NF" [idObjet]="objetWorkflow.idNDF"></suivi-budget>

            <!-- Cadre avances imputées -->
            <imputation-avances [idNdf]="objetWorkflow.idNDF"></imputation-avances>
        </div>

        <div *ngIf="selectedItem.code == Onglets.FRAIS">
            <ndf-frais-list
                    [ndf]="objetWorkflow"
                    [settings]="settings"
                    [canModifier]="canModifier"
                    (checkFraisAlerte)="onCheckFraisAlerte($event)"
                    [fraisRefreshObservable]="fraisRefreshObservable"
            ></ndf-frais-list>
        </div>

        <div *ngIf="selectedItem.code == Onglets.FICHECOLLABORATEUR">
            <fiche-collaborateur [idCollaborateur]="idCollaborateur"></fiche-collaborateur>
        </div>

        <div *ngIf="selectedItem.code == Onglets.COMPLEMENTS">
            <document-list
                    [idObjet]="objetWorkflow.idNDF"
                    [idObjetForPJ]="objetWorkflow.idNDF"
                    [idCollaborateur]="idCollaborateur"
                    [context]="'NDF'"
                    [settings]="settings"
                    [canAddDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
                    [canDeleteDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
            ></document-list>

            <avp-details *ngIf="isAvp(objetWorkflow)"
                         [ndf]="objetWorkflow"></avp-details>

            <referentiel-externe *ngIf="settings?.isReferentielExterneAvailable" [typeReferentiel]="portee" [idObject]="objetWorkflow.idNDF"></referentiel-externe>
        </div>

        <div *ngIf="selectedItem?.code === OngletsAdmin.WORKFLOW">
            <onglet-workflow
                    [isObjetValide]="isValid()"
                    [portee]="TypePortee.NF"
                    [listeVentilations]="objetWorkflow.listeVentilations"
                    [typeEntite]="objetWorkflow.typeEntite"
                    [globalSettings]="settings"
                    [idObjet]="objetWorkflow.idNDF">
            </onglet-workflow>
        </div>

        <div *ngIf="selectedItem?.isLoaded" [hidden]="selectedItem?.code != OngletsAdmin.OUTILS">
            <outils [idObjet]="objetWorkflow.idNDF" [typePortee]="TypePortee.NF" [isObjetValide]="isValid()"></outils>
        </div>
    </div>
    <please-wait class="content" *ngIf="objetWorkflow === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
