import {Component,OnDestroy,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Segment} from "@domain/admin/workflow/segment";
import {ListeVisualisationTooltipItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-tooltip-item.component";
import {Subscription} from "rxjs";

/**
 * Item de liste des segments
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-visualisation-sticky-item'},
	templateUrl: './liste-visualisation-sticky-item.component.html',
	styleUrls: ['./liste-visualisation-sticky-item.component.scss']
})
export class ListeVisualisationStickyItemComponent extends ListViewItem<Segment> implements OnInit,OnDestroy {
	/** Composant du tooltip */
	readonly ListeVisualisationTooltipItemComponent = ListeVisualisationTooltipItemComponent;

	/** Constante pour la mise en valeur d'une ligne */
	public static readonly HIGHLIGHT: string = "HIGHLIGHT";
	readonly HIGHLIGHT: string = ListeVisualisationStickyItemComponent.HIGHLIGHT;

	/** Constante pour le survol d'une ligne */
	public static readonly HOVER: string = "HOVER";
	readonly HOVER: string = ListeVisualisationStickyItemComponent.HOVER;

	/** Affichage de la colonne du nom pour le segment */
	showSegmentNamesColumn: boolean = false;

	/** Souscription */
	souscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor() {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Abonnement au bouton d'affichage/masquage du nom
		this.souscription = this.liste.extraOptions.showSegmentNamesColumn$.subscribe((showSegmentNamesColumn: boolean) => this.showSegmentNamesColumn = showSegmentNamesColumn);
	}

	/**
	 * �v�nement du clic sur un segment
	 */
	onClick() {
		//Affichage/masquage du highlight sur la ligne
		this.data[ListeVisualisationStickyItemComponent.HIGHLIGHT] = !this.data[ListeVisualisationStickyItemComponent.HIGHLIGHT];
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//D�sabonnement
		this.souscription.unsubscribe();
	}
}
