import {Component,OnInit} from '@angular/core';
import {ListView, TypeComparaison} from "@domain/common/list-view";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {Router} from "@angular/router";
import {Statut} from "@domain/workflow/statut";
import {StatutListeItemComponent} from "@components/admin/workflow/statuts/statuts/liste/statut-liste-item.component";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";
import {EnumEtat} from "@domain/workflow/etat";

/**
 * Composant de la liste des statuts
 *
 * @author Angeline Ha
 * @date /09/2024
 */
@Component({
  host: {'data-test-id': 'statuts-liste'},
  selector: 'statuts-liste',
  templateUrl: './statuts-liste.component.html'
})
export class StatutsListeComponent implements OnInit {
  /** Liste des statuts */
  listeStatuts: ListView<Statut,StatutListeItemComponent>;

  /** Liste des actions possibles */
  listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

  /**
   * Constructeur
   *
   * @param router Router
   * @param translateService
   */
  constructor(private router: Router,
              private translateService: TranslateService) {
  }

  /**
   * Initialisation
   */
  ngOnInit() {
    //Initialisation des actions possibles
    this.listeActions.next([{
      type: TypeAction.PRIMARY,
      icone: 'nio icon-ajouter',
      libelle: 'global.actions.creer',
      doAction: () => this.creerStatut(),
    }]);

    //Initialisation de la liste des statuts
    this.listeStatuts = new ListView<Statut,StatutListeItemComponent>({
      uri: '/controller/Statut/findAllStatuts',
      title: this.translateService.instant('workflow.statuts.title'),
      component: StatutListeItemComponent,
      defaultOrder: 'idEtat,libelle',
      isFilter: true,
      listeFilters: [{
        clef: 'libelle',
        title: this.translateService.instant('filter.libelle'),
        isDefault: true,
        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
      }, {
        clef: 'code',
        title: this.translateService.instant('filter.code'),
        isDefault: true,
        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
      }, {
        clef: 'idEtat',
        title: this.translateService.instant('workflow.statuts.etat'),
        listeValues: [
          {
            code: '' + EnumEtat.ANNULE,
            libelle: this.translateService.instant('global.etats.annule')
          },{
            code: '' + EnumEtat.ARCHIVE,
            libelle: this.translateService.instant('global.etats.archive')
          },{
            code: '' + EnumEtat.COMPTABILISE_CLOTURE,
            libelle: this.translateService.instant('global.etats.comptabilise_cloture')
          },{
            code: '' + EnumEtat.EN_CREATION,
            libelle: this.translateService.instant('global.etats.creation')
          },{
            code: '' + EnumEtat.EN_VALIDATION,
            libelle: this.translateService.instant('global.etats.validation')
          },{
            code: '' + EnumEtat.VALIDE,
            libelle: this.translateService.instant('global.etats.valide')
          }],
        typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
      }]
    });

    //Liste des colonnes triables
    this.listeStatuts.columns = [
      {key: 'idEtat',title: 'workflow.statuts.etat'},
      {key: 'libelle',title: 'workflow.roles.libelle'}
    ]

    //On initialise le tri
    this.listeStatuts.sorting = new Sorting(this.listeStatuts.columns,this.listeStatuts.defaultOrder);
  }

  /**
   * Redirection pour création du statut
   */
  creerStatut() {
    this.router.navigate(['/Admin/Workflow/Statuts/Statuts/0']);
  }
}
