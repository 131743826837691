import {Component,Input,OnInit,ViewChild} from '@angular/core';
import {Action,Condition,MailTo,Message,Role,Segment,Statut} from "@domain/admin/workflow/segment";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {first} from "rxjs/operators";
import {
	ACTIONS,
	PORTEES,
	STATUTS,
	FORMULAIRES,
	PROFILS,
	PROCEDURES_SUPP,
	ACTIONS_SUPP,
	PROCEDURES_BUDGET,
	OPTIONS_MOTIFS,
	MESSAGE_PAR_DEFAUT
} from "@components/admin/workflow/construction/segment/generalites/segment-generalites.data";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction} from "@share/component/floating-button/floating-button";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {MailToItemComponent} from "@components/admin/workflow/construction/segment/generalites/mail-to-item.component";
import {Result} from "@domain/common/http/result";
import {MatDialog} from "@angular/material/dialog";
import {MailToPopupComponent} from "@components/admin/workflow/construction/segment/mail-to-popup.component";
import {NgForm} from "@angular/forms";
import {CustomInputComponent} from "@share/component/custom-input/custom-input.component";

/**
 * Onglet généralités
 */
@Component({
	host: {'data-test-id': 'segment-generalites'},
	selector: 'segment-generalites',
	templateUrl: './segment-generalites.component.html',
	styleUrls: ['segment-generalites.component.scss']
})
export class SegmentGeneralitesComponent implements OnInit {

	/** Liste des formulaires possibles */
	protected readonly FORMULAIRES = FORMULAIRES;

	/** Liste des options de motifs possibles */
	protected readonly OPTIONS_MOTIFS = OPTIONS_MOTIFS;

	/** Segment visualisé */
	private _segment: Segment;
	@Input() set segment(segment: Segment) { this._segment = segment; this.initialisation(); }
	get segment(): Segment { return this._segment; }

	/** Liste des actions possibles */
	@Input() listeActions: Action[] = [];

	/** Liste des actions du floatingButton en bas à droite */
	@Input() listeFloatingButtonActions: BehaviorSubject<Array<FloatingButtonAction>>;

	/** Liste des statuts possibles */
	listeStatuts: Statut[];

	/** Liste des conditions possibles pour la portée de départ */
	listeConditions1: Condition[];

	/** Liste des conditions possibles pour la portée d'arrivée */
	listeConditions2: Condition[];

	/** liste des rôles possibles */
	listeRoles: Role[] = [];

	/** Flag pour la désactivation de certains champs */
	isFormulaire1EqualWith2: boolean = false;

	/** Liste des procédures supplémentaires possibles */
	listeProceduresSupplementaires: Action[] = [];

	/** Procédure supplémentaire sélectionnée */
	selectedProcedureSupplementaire: Action;

	/** Liste des actions complémentaires possibles */
	listeActionsPossibles: Action[] = [];

	/** Liste des actions complémentaires sélectionnées */
	listeSelectedActions: Action[] = [];

	/** Flag pour l'activation du champ de procédure complémentaire */
	isProcedureComplementaire: boolean = false;

	/** Liste des procédures budgétaires */
	listeProceduresBudget: Action[] = [];

	/** Option motif sélectionnée */
	selectedOptionMotif: any;

	/** Liste des confirmations */
	listeMessagesConfirmation: Message[] = [];

	/** Liste des notifications */
	listeMailsTo: ListView<MailTo,MailToItemComponent>;

	/** Flag de chargement des confirmations */
	confirmationLoaded: boolean = false;

	/** Flag de chargement des notifications */
	mailsToLoaded: boolean = false;

	/** Formulaire du composant */
	@ViewChild('form') form: NgForm;

	/** Input de sélection du rôle */
	@ViewChild('role') roleInput: CustomInputComponent;

	/** Input de sélection du statut 1 */
	@ViewChild('statut1') statut1Input: CustomInputComponent;

	/** Input de sélection du statut 2 */
	@ViewChild('statut2') statut2Input: CustomInputComponent;

	/** Input de sélection du statut cible */
	@ViewChild('statutCible') statutCibleInput: CustomInputComponent;

	/** Input de sélection du message de confirmation */
	@ViewChild('confirmation') confirmationInput: CustomInputComponent;

	/**
	 * Constructeur
	 */
	constructor(private workflowAdminService: WorkflowAdminService,private translateService: TranslateService,private matDialog: MatDialog) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement de la page
		this.initialisation();
	}

	/**
	 * Chargement de la page
	 */
	private initialisation(): void {
		//Si le segment n'est pas une copie
		if (!this.segment['isCopy']) {
			//Mise à jour de la liste des statuts possibles
			this.updateStatuts();

			//Mise à jour de la liste des rôles possibles
			this.updateRoles();

			//Chargement de la liste de conditions possibles pour le formulaire de départ
			this.updateConditions('départ');

			//Chargement de la liste de conditions possibles pour le formulaire d'arrivée
			this.updateConditions('arrivée');

			//Mise à jour de la liste des procédures supplémentaires selon le formulaire choisi
			this.updateProceduresSupplementaires();

			//Mise à jour de la liste des actions possibles selon le formulaire
			this.updateActionsPossibles(true);

			//Initialisation du flag d'égalité
			this.afterFormulaireChange();

			//Mise à jour de la liste des procédures budgétaires selon le formulaire choisi
			this.updateProceduresBudget();

			//Mise à jour de l'option de motif sélectionnée
			this.updateOptionMotif(true);

			//Mise à jour des confirmations
			this.updateListeConfirmation();

			//Découpage des actions supplémentaires "spéciales" (id<0)
			const actionsSupp: string[] = this.segment.lstActionSupp?.trim().split(" ") ?? [];

			//Récupération des actions supplémentaires
			this.listeSelectedActions = this.listeActionsPossibles.filter(a => this.segment.actionsSupplementaires.includes(a.id) || (actionsSupp.length && actionsSupp.includes(""+a.id)));

			//Récupère la liste des notifications du segment
			this.getListeMailTo();
		}
	}

	/**
	 * Mise à jour de la liste des statuts possibles
	 */
	private updateStatuts(): void {
		//Si la liste des statuts n'est pas vide
		if (!this.listeStatuts?.length) {
			//Initialisation du tableau
			const statutsListe: Statut[] = [
				new Statut({
					idStatut: null,
					idEtat: null,
					libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.statutEmpty',
					code: null
				})
			];

			//Chargement de la liste de statuts possibles
			this.workflowAdminService.getAllStatus().pipe(first()).subscribe(rawStatutsListe => {
				//Si la liste contient des données
				if (rawStatutsListe && rawStatutsListe.length) {
					//Création d'un objet buffer
					const list = {};
					list[STATUTS.ETAT_EN_CREATION.idEtat] = [{ ...STATUTS.ETAT_EN_CREATION, isSectionTitle: true }];
					list[STATUTS.ETAT_EN_VALIDATION.idEtat] = [{ ...STATUTS.ETAT_EN_VALIDATION, isSectionTitle: true }];
					list[STATUTS.ETAT_VALIDE.idEtat] = [{ ...STATUTS.ETAT_VALIDE, isSectionTitle: true }];
					list[STATUTS.ETAT_COMPTABILISE_CLOTURE.idEtat] = [{ ...STATUTS.ETAT_COMPTABILISE_CLOTURE, isSectionTitle: true }];
					list[STATUTS.ETAT_ARCHIVE.idEtat] = [{ ...STATUTS.ETAT_ARCHIVE, isSectionTitle: true }];
					list[STATUTS.ETAT_ANNULE.idEtat] = [{ ...STATUTS.ETAT_ANNULE, isSectionTitle: true }];

					//Tri
					rawStatutsListe.sort(function (a, b) { return a.libelle.localeCompare(b.libelle); });

					//Classement des statuts
					for (const statut of rawStatutsListe) {
						if (statut.idEtat != null) {
							statut['isSectionItem'] = true;
							list[statut.idEtat].push(statut);
						}
					}

					//Génération de la liste finale
					this.listeStatuts = statutsListe.concat(list[STATUTS.ETAT_EN_CREATION.idEtat])
													.concat(list[STATUTS.ETAT_EN_VALIDATION.idEtat])
													.concat(list[STATUTS.ETAT_VALIDE.idEtat])
													.concat(list[STATUTS.ETAT_COMPTABILISE_CLOTURE.idEtat])
													.concat(list[STATUTS.ETAT_ARCHIVE.idEtat])
													.concat(list[STATUTS.ETAT_ANNULE.idEtat]);
				}
			});
		} else {
			//Forçage de la prise en compte d'une nouvelle valeur dans les custom inputs
			this.statut1Input.forceNgModelReset();
			this.statut2Input.forceNgModelReset();
			this.statutCibleInput.forceNgModelReset();
		}
	}

	/**
	 * Synchronisation du formulaire d'arrivée sur le formulaire de départ
	 */
	syncFormulaires(): void {
		//Synchronisation des deux formulaires
		this.segment.formulaireModifie.idPortee = this.segment.formulaireDepart?.idPortee;
	}

	/**
	 * Traitement effectué au changement d'un formulaire
	 */
	afterFormulaireChange(): void {
		//Mise à jour du flag en fonction des deux formulaires
		this.isFormulaire1EqualWith2 = this.segment.formulaireDepart.idPortee == this.segment.formulaireModifie?.idPortee;

		//Si les deux formulaires sont identiques
		if (this.isFormulaire1EqualWith2) {
			//Synchronisation du formulaire d'arrivée sur le formulaire de départ
			this.segment.statutDebutFormulaireModifie = this.segment.statutFormulaireDepart;

			//Copie de la liste des conditions de départ dans la liste des conditions d'arrivée
			this.listeConditions2 = this.listeConditions1;

			//Copie de la condition de départ dans la condition d'arrivée (avec débrayage du cycle Angular standard pour forcer la prise en compte)
			setTimeout(() => this.segment.conditionFormulaireModifie = this.segment.conditionFormulaireDepart);
		}
	}

	/**
	 * Chargement de la liste de conditions possibles
	 *
	 * @param formulaire formulaire concerné
	 */
	updateConditions(formulaire: 'départ'|'arrivée'): void {
		//Initialisation de la liste
		const listeConditions = [{
			id: null,
			libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.conditionEmpty'
		}];

		//Chargement de la liste de conditions possibles en fonction de la portée
		this.workflowAdminService.getConditions(formulaire == 'départ' ? this.segment.formulaireDepart?.idPortee : this.segment.formulaireModifie?.idPortee).pipe(first()).subscribe(conditions => {
			//Mise à jour de la liste concernée
			formulaire == 'départ' ? this.listeConditions1 = listeConditions.concat(conditions) as Condition[] : this.listeConditions2 = listeConditions.concat(conditions) as Condition[];
		});
	}

	/**
	 * Mise à jour de la liste des rôles possibles
	 */
	private updateRoles(): void {
		//Si la liste des rôles n'est pas vide
		if (!this.listeRoles?.length) {
			//Appel au back pour obtention de la liste des rôles
			this.workflowAdminService.getRoles().subscribe(result => {
				//Purge de la liste des rôles
				this.listeRoles = [];

				//Vérifie si la liste des rôles collaborateur n'est pas vide
				if (result.data.listeRolesCollaborateur.length) {
					//Ajout du séparateur
					this.listeRoles.push({ ...PROFILS.PROFIL_COLLABORATEUR, isSectionTitle: true } as Role);

					//Ajout des rôles dans la liste des rôles
					this.listeRoles = this.listeRoles.concat(result.data.listeRolesCollaborateur.map(r => { return { ...new Role(r), isSectionItem: true }}));
				}

				//Vérifie si la liste des rôles assistant n'est pas vide
				if (result.data.listeRolesAssistant.length) {
					//Ajout du séparateur
					this.listeRoles.push({ ...PROFILS.PROFIL_ASSISTANT, isSectionTitle: true } as Role);

					//Ajout des rôles dans la liste des rôles
					this.listeRoles = this.listeRoles.concat(result.data.listeRolesAssistant.map(r => { return { ...new Role(r), isSectionItem: true }}));
				}

				//Vérifie si la liste des rôles responsable n'est pas vide
				if (result.data.listeRolesResponsable.length) {
					//Ajout du séparateur
					this.listeRoles.push({ ...PROFILS.PROFIL_RESPONSABLE, isSectionTitle: true } as Role);

					//Ajout des rôles dans la liste des rôles
					this.listeRoles = this.listeRoles.concat(result.data.listeRolesResponsable.map(r => { return { ...new Role(r), isSectionItem: true }}));
				}

				//Vérifie si la liste des rôles comptable n'est pas vide
				if (result.data.listeRolesComptable.length) {
					//Ajout du séparateur
					this.listeRoles.push({ ...PROFILS.PROFIL_COMPTABLE, isSectionTitle: true } as Role);

					//Ajout des rôles dans la liste des rôles
					this.listeRoles = this.listeRoles.concat(result.data.listeRolesComptable.map(r => { return { ...new Role(r), isSectionItem: true }}));
				}

				//Vérifie si la liste des rôles fournisseur n'est pas vide
				if (result.data.listeRolesFournisseur.length) {
					//Ajout du séparateur
					this.listeRoles.push({ ...PROFILS.PROFIL_FOURNISSEUR, isSectionTitle: true } as Role);

					//Ajout des rôles dans la liste des rôles
					this.listeRoles = this.listeRoles.concat(result.data.listeRolesFournisseur.map(r => { return { ...new Role(r), isSectionItem: true }}));
				}

				//Mise à jour de la liste des rôles pour la popup des notifications
				this.listeMailsTo.extraOptions.listeRoles = this.listeRoles;
			});
		} else {
			//Forçage de la prise en compte d'une nouvelle valeur dans le custom input
			this.roleInput.forceNgModelReset();
		}
	}

	/**
	 * Mise à jour des libellés pré-renseignés en accord avec l'action choisie
	 */
	updateLibellesAction(): void {
		//Récupération du libellé de l'action
		const libelle: string = this.translateService.instant(this.listeActions?.find(a => a.id == this.segment.action)?.libelle);

		//Application sur le bouton et l'action
		this.segment.libelleBouton = libelle;
		this.segment.libelleAction = libelle;
	}

	/**
	 * Mise à jour de la liste des procédures supplémentaires selon le formulaire choisi
	 */
	updateProceduresSupplementaires() {
		//Sortie du cycle standard pour forcer la prise en compte d'une éventuelle nouvelle portée sélectionnée
		setTimeout(() => {
			//Suppression de la liste des procédures supplémentaires
			this.listeProceduresSupplementaires = [];
			this.listeProceduresSupplementaires.push({
				id: null,
				libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.aucune'
			});

			//Établissement de la portée concernée
			const portee: string = this.segment.formulaireModifie?.idPortee || this.segment.formulaireDepart?.idPortee;

			//Activation ou non du champ correspondant
			this.isProcedureComplementaire = portee != PORTEES.OM && portee != PORTEES.OMP;

			//Vérification si un formulaire est sélectionné
			if (portee) {
				//Ajout des actions possibles pour le formulaire DV
				if (portee == PORTEES.DV) {
					this.listeProceduresSupplementaires.push({ ...PROCEDURES_SUPP.DEMANDE_DV, isDv: true } as Action);
					this.listeProceduresSupplementaires.push({ ...PROCEDURES_SUPP.MODIFICATION_DV, isDv: true } as Action);
					this.listeProceduresSupplementaires.push({ ...PROCEDURES_SUPP.COMMANDE_DV, isDv: true } as Action);
					this.listeProceduresSupplementaires.push({ ...PROCEDURES_SUPP.ANNULATION_DV, isDv: true } as Action);
				}

				//Ajout des actions possibles pour le formulaire NDF
				if (portee == PORTEES.NDF) {
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.CALCULS_FINAUX_NDF);
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.RAPPEL_APRES_CALCULS_FINAUX_NDF);
				}

				//Ajout des actions possibles pour le formulaire AV
				if (portee == PORTEES.AVANCE) {
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.OCTROI_AVANCE);
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.DESOCTROI_AVANCE);
				}

				//Ajout des actions possibles pour le formulaire Facture
				if (portee == PORTEES.FACTURE) {
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.CALCULS_FINAUX_FACTURE);
					this.listeProceduresSupplementaires.push(PROCEDURES_SUPP.RAPPEL_APRES_CALCULS_FINAUX_FACTURE);
				}
			}

			//Si le segment comporte une procédure complémentaire
			if (this.segment?.softAction) {
				//Sélection correspondante dans la liste
				this.selectedProcedureSupplementaire = this.listeProceduresSupplementaires.find(a => a.id == this.segment.softAction);
			} else if (this.segment?.eltAction) {
				//Sélection correspondante dans la liste
				this.selectedProcedureSupplementaire = this.listeProceduresSupplementaires.find(a => a.id == this.segment.eltAction);
			} else {
				//Sinon, sélection par défaut
				this.selectedProcedureSupplementaire = this.listeProceduresSupplementaires[0];
			}
		});
	}

	/**
	 * Mise à jour de la liste des actions possibles selon le formulaire
	 *
	 * @param isSynchroneExecution true si l'appel doit être immédiat (false par défaut, donc désynchronisé)
	 */
	updateActionsPossibles(isSynchroneExecution: boolean = false) {
		//Définition de la méthode à exécuter dans tous les cas
		const executeCode = () => {
			//Suppression de la liste des actions
			this.listeActionsPossibles = [];
			this.listeSelectedActions = [];

			//Établissement de la portée concernée
			const portee: string = this.segment.formulaireModifie?.idPortee || this.segment.formulaireDepart?.idPortee;

			//Vérification si une action et un formulaire sont sélectionnés
			if (this.segment.action && portee) {
				//Ajout des actions possibles pour le formulaire OM
				if (portee == PORTEES.OM) {
					//Ajout des actions possibles pour l'action d'émission
					if (this.segment.action == ACTIONS.EMISSION.id) {
						this.listeActionsPossibles.push(ACTIONS_SUPP.GESTION_TEMPS);
					}

					//Ajout des actions possibles pour l'action émission ou validation
					if (this.segment.action == ACTIONS.EMISSION.id || this.segment.action == ACTIONS.VALIDATION.id) {
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_AVANCE_AUTO);
					}

					//Ajout des actions possibles pour les actions modification ou complétion
					if (this.segment.action == ACTIONS.MODIFICATION.id || this.segment.action == ACTIONS.COMPLETION.id) {
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_NDF);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_FRAIS_PREVISION);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_FACTURE);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_FACTURE_PREVISION);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_DEMANDE_AVANCE);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_DOSSIER_VOYAGE);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_HEBERGEMENT);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_TRANSPORTS);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_FORMULAIRE_OM);
						this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_FORMULAIRE_AUTRE);
					}
				}

				//Ajout des actions possibles pour le formulaire NDF
				if (portee == PORTEES.NDF) {
					//Ajout des actions possibles pour l'action émission ou validation
					if (this.segment.action == ACTIONS.EMISSION.id || this.segment.action == ACTIONS.VALIDATION.id) {
						this.listeActionsPossibles.push(ACTIONS_SUPP.APPLICATION_FRANCHISE);
					}

					//Ajout des actions possibles pour l'action rejet ou suppression ou invalidation
					if (this.segment.action == ACTIONS.REJET.id || this.segment.action == ACTIONS.SUPPRESSION.id || this.segment.action == ACTIONS.INVALIDATION.id) {
						this.listeActionsPossibles.push(ACTIONS_SUPP.SUPPRESSION_FRANCHISE);
					}
				}

				//Ajout des actions possibles pour le formulaire OMP et l'action modification ou complétion
				if (portee == PORTEES.OMP && (this.segment.action == ACTIONS.MODIFICATION.id || this.segment.action == ACTIONS.COMPLETION.id)) {
					this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_OM);
					this.listeActionsPossibles.push(ACTIONS_SUPP.CREATION_NDF);
				}

				//Tri alphabétique des actions
				this.listeActionsPossibles.sort(function(a, b) {
					const x = a.libelle.toLowerCase();
					const y = b.libelle.toLowerCase();
					if (x < y) { return -1; }
					if (x > y) { return 1; }
					return 0;
				});
			}
		};

		//Si l'exécution est synchrone
		if (isSynchroneExecution) {
			//Exécution immédiate
			executeCode();
		} else {
			//Sinon, exécution en dehors du cycle standard pour forcer la prise en compte d'une éventuelle nouvelle portée sélectionnée
			setTimeout(() => executeCode());
		}
	}

	/**
	 * Mise à jour de la liste des procédures budgétaires selon le formulaire choisi
	 */
	updateProceduresBudget() {
		//Sortie du cycle standard pour forcer la prise en compte d'une éventuelle nouvelle portée sélectionnée
		setTimeout(() => {
			//Réinitialisation de la liste des procédures budgétaires
			this.listeProceduresBudget = [];

			//Établissement de la portée concernée
			const portee: string = this.segment.formulaireModifie?.idPortee || this.segment.formulaireDepart?.idPortee;

			//Vérification si un formulaire est sélectionné
			if (portee) {
				//Si la portée est concernée
				if ([PORTEES.OM,PORTEES.DV,PORTEES.NDF,PORTEES.FACTURE].includes(portee)) {
					//Peuplement avec la liste commune
					this.listeProceduresBudget.push({
						id: null,
						libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresBudgetaires.aucune'
					});
					this.listeProceduresBudget.push(PROCEDURES_BUDGET.IMPUTATION);
					this.listeProceduresBudget.push(PROCEDURES_BUDGET.LIBERATION_BUDGET);
				}

				//Ajout de la procédure possible pour le formulaire OM
				if (portee == PORTEES.OM) {
					this.listeProceduresBudget.push(PROCEDURES_BUDGET.CLOTURE);
				}
			}
		});
	}

	/**
	 * Mise à jour de l'option de motif sélectionnée
	 *
	 * @param isUpdateFromBackend true si lecture depuis le backend (false par défaut)
	 */
	updateOptionMotif(isUpdateFromBackend: boolean = false): void {
		//Si lecture depuis le backend
		if (isUpdateFromBackend) {
			//Si les deux booléens valent true
			if (this._segment.saisieMotif && this._segment.saisieMotifObligatoire) {
				//Sélection de 'obliger'
				this.selectedOptionMotif = OPTIONS_MOTIFS.find(o => o.code == 'obliger');
			} else if (this._segment.saisieMotif) {
				//Sinon, si la saisie est obligatoire, alors sélection de 'proposer'
				this.selectedOptionMotif = OPTIONS_MOTIFS.find(o => o.code == 'proposer');
			} else {
				//Sinon aucune obligation
				this.selectedOptionMotif = OPTIONS_MOTIFS.find(o => o.code == 'sans');
			}
		} else {
			//Sinon, synchronisation des booléens de l'objet avec la sélection
			this._segment.saisieMotif = this.selectedOptionMotif.code == 'proposer' || this.selectedOptionMotif.code == 'obliger';
			this._segment.saisieMotifObligatoire = this.selectedOptionMotif.code == 'obliger';
		}
	}

	/**
	 * Mise à jour de la liste des confirmations
	 */
	updateListeConfirmation(): void {
		//Si l'appel n'a pas encore été effectué
		if (!this.confirmationLoaded && !this.listeMessagesConfirmation?.length) {
			//Interrogation du backend
			this.workflowAdminService.getConfirmations().pipe(first()).subscribe(result => {
				//Ajout du message par défaut
				this.listeMessagesConfirmation.push(MESSAGE_PAR_DEFAUT);

				//Ajout des autres messages
				this.listeMessagesConfirmation = this.listeMessagesConfirmation.concat(result.data.messageNotifListe);

				//Débrayage du cycle standard pour mise à jour de la valeur
				setTimeout(() => {
					//Si le message est déjà sélectionné
					if (this.segment.message) {
						//Récupération du message dans la liste
						this.segment.message = this.listeMessagesConfirmation.find(m => m.idMessage == this.segment.message.idMessage);
					} else {
						//Sinon initialisation du message de confirmation avec le message par défaut
						this.segment.message = this.listeMessagesConfirmation[0];
					}
				});
			});
		} else {
			//Forçage de la prise en compte d'une nouvelle valeur dans le custom input
			this.confirmationInput.forceNgModelReset();
		}
	}

	/**
	 * Mise à jour de la confirmation
	 */
	updateConfirmation(): void {
		//Si motif = 'sans' ou choix appro = true
		if (this.selectedOptionMotif.code != OPTIONS_MOTIFS[0].code || this.segment.choixAppro) {
			//Débrayage du cycle standard pour mise à jour forcée de la valeur
			setTimeout(() => {
				//Forçage du message de confirmation avec le message par défaut
				this.segment.message = this.listeMessagesConfirmation[0];
			});
		}
	}

	/**
	 * Récupère la liste des notifications du segment
	 */
	getListeMailTo(): void {
		//Si le segment est chargé et que l'appel n'a pas déjà eu lieu
		if (this.segment && !this.mailsToLoaded) {
			//Création de la liste des devises
			this.listeMailsTo = new ListView<MailTo,MailToItemComponent>({
				uri: `/controller/MailTo/listeMailToBySegment/${this.segment.idSegment}`,
				title: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.title'),
				isSimple: true,
				isFilter: false,
				emptyMessage: 'admin.workflow.construction.segment.onglets.generalites.notificationsCard.emptyMessage',
				mapResult: (result: Result) => {
					return result.data?.listeMailsTo?.map(mailTo => new MailTo(mailTo));
				},
				extraOptions: {
					segmentId: this.segment.idSegment,
					listeRoles: this.listeRoles,
					refreshListeMailTo: () => this.listeMailsTo.refresh && this.listeMailsTo.refresh()
				},
				listeActions: [{
					icon: 'add',
					onPress: () => {
						this.matDialog.open(MailToPopupComponent,{
							data: {
								segmentId: this.segment.idSegment,
								listeRoles: this.listeRoles
							}
						}).afterClosed().subscribe((refresh) => { if (refresh) { this.listeMailsTo.refresh && this.listeMailsTo.refresh(); }});
					},
					isVisible: () => !!this.segment?.idSegment
				}],
				component: MailToItemComponent,
				defaultOrder: 'idMailTo'
			});

			//Flag de chargement
			this.mailsToLoaded = true;
		} else if (this.segment) {
			//Si le segment a changé
			if (this.listeMailsTo?.extraOptions?.segmentId != this.segment?.idSegment) {
				//Mise à jour des paramètres de la liste
				this.listeMailsTo.extraOptions.segmentId = this.segment.idSegment;
				this.listeMailsTo.uri = `/controller/MailTo/listeMailToBySegment/${this.segment.idSegment}`;

				//Refresh de la liste
				this.listeMailsTo.refresh && this.listeMailsTo.refresh();
			}
		}
	}

	/**
	 * Détermine la visibilité du champ notification obligatoire
	 */
	isDisabledNotificationObligatoire(): boolean {
		return this.listeMailsTo?.data?.listeResultats?.some(m => m.globalise);
	}
}
