import { Component,Input } from '@angular/core';
import { Statut } from "../../domain/workflow/statut";

@Component({
    selector: 'statut',
    templateUrl: './statut.component.html',
    styleUrls: ['./statut.component.scss']
})
export class StatutComponent {

    /** Statut */
    @Input() statut?: Statut;

    /** Image */
    @Input() image?: string;

    /** Définit la taille des bulles (positionner à true pour les listes dashboard) */
    @Input() isMinimized?: boolean = false;

    /** Tableau des blocs de couleurs */
    get colors(): String[] { return this.statut ? this.statut?.image?.split("|") : this.image?.split("|"); }

    /**
     * Constructeur
     */
    constructor() {}
}