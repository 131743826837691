import {Action} from '../domain/action';

/**
 * Export des actions
 */
export const RULE_FULFILLED = 'RULE_FULFILLED';
export const IS_STICKY_LIST = 'IS_STICKY_LIST';
export const IS_NOT_STICKY_LIST = 'IS_NOT_STICKY_LIST';
export const IS_TOGGLED = 'IS_TOGGLED';

/**
 * Interface
 */
export interface ListViewState {
    /** Données **/
    rule?: any;
    isSticky?: boolean;
    isToggled?: boolean;
}

/**
 * Création du reducer
 */
export function listviewReducer(state: ListViewState = {},action: Action<any>): ListViewState {
    //Vérification de l'action
    switch (action.type) {
        case RULE_FULFILLED:
            //Session de l'utilisateur
            state = {
                ...state,
                rule: action.payload
            };
            break;
        case IS_STICKY_LIST:
            //Sticky list
            state = {
                ...state,
                isSticky: true
            };
            break;
        case IS_NOT_STICKY_LIST:
            //Not sticky list
            state = {
                ...state,
                isSticky: false
            };
            break;
        case IS_TOGGLED:
            //Pliage/dépliage du menu
            state = {
                ...state,
                isToggled: action.payload
            };
            break;
    }

    //Retour de l'état
    return state;
}
