import {AbstractParametreType} from "@domain/admin/parametre/AbstractParametreType";

/**
 * Classe de gestion des paramètres du RSE
 *
 * @author Laurent SCIMIA
 * @date 23/04/2024
 */
export class ParamRse {
	/** Constante de la section des paramètres du RSE */
	private readonly sectionRseParam: string = 'RSE';

	/** Liste brute des paramètres du RSE */
	private _listeParams: Array<AbstractParametreType>

	/** Liste brute des paramètres du RSE */
	get listeParams(): Array<AbstractParametreType> {
		return this._listeParams;
	}

	/** Indique si le calcul du RSE est actif */
	public get isCalculActif(): boolean {
		return this.getParamRse(RseParamClef.CALCUL_RSE_ACTIF).valeur ?? false;
	}
	public set isCalculActif(value: boolean) {
		this.getParamRse(RseParamClef.CALCUL_RSE_ACTIF).valeur = value;
	}

	/** Indique si l'iframe du comparatif carbone est active */
	public get isIframeActive(): boolean {
		return  this.getParamRse(RseParamClef.IFRAME_RSE_ACTIF).valeur ?? false;
	}
	public set isIframeActive(value: boolean) {
		this.getParamRse(RseParamClef.IFRAME_RSE_ACTIF).valeur = value;
	}

	/** Clé d'identification pour l'API RSE */
	public get cleApi(): string {
		return this.getParamRse(RseParamClef.CLE_API_RSE).valeur;
	}
	public set cleApi(value: string) {
		this.getParamRse(RseParamClef.CLE_API_RSE).valeur = value;
	}

	/**
	 * Constructeur
	 *
	 * @param listeParams Liste des paramètres de RSE à recopier
	 */
	constructor(listeParams?: Array<AbstractParametreType>) {
		//Si des paramètres sont passés en... paramètre
		if (listeParams) {
			//On récupère les paramètres
			this._listeParams = listeParams;

			//On va parser les booléens en string pour en faire de vrais booléens.
			this._listeParams.forEach(p => {
				if (p.valeur === "true") {
					p.valeur = true;
				} else if (p.valeur === "false") {
					p.valeur = false;
				}
			});
		}

		//On parcourt tous les paramètres possibles pour le RSE
		for (let rseParamClefKey in RseParamClef) {
			//Si la liste des paramètres ne contient pas le paramètre courant
			if (!this._listeParams.some(p => p.clef === rseParamClefKey)) {
				//On ajoute la valeur par défaut du paramètre
				this.listeParams.push({
					section: this.sectionRseParam,
					clef: rseParamClefKey,
					valeur: [RseParamClef.CALCUL_RSE_ACTIF.toString(), RseParamClef.IFRAME_RSE_ACTIF.toString()].includes(rseParamClefKey) ? false : ""
				});
			}
		}
	}

	/**
	 * Récupère le paramètre concerné par la clef dans la liste des paramètres
	 *
	 * @param clef Clef recherchée
	 */
	private getParamRse(clef: RseParamClef): AbstractParametreType {
		return this.listeParams.find(p => p.section == this.sectionRseParam && p.clef == clef.toString());
	}
}

/**
 * Énumération des paramètres possible pour le RSE
 */
export enum RseParamClef {
	CALCUL_RSE_ACTIF = "CALCUL_RSE_ACTIF",
	IFRAME_RSE_ACTIF = "IFRAME_RSE_ACTIF",
	CLE_API_RSE = "CLE_API_RSE"
}