import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from '@environments/environment';
import {Result} from "@domain/common/http/result";
import {ParamRse} from "@domain/admin/parametre/api/partenaires/ParamRse";

/**
 * Service de gestion du RSE
 */
@Injectable()
export class ApiRseService {

	/**
	 * Constructeur
	 *
	 * @param http Service HTTP
	 */
	constructor(private http: HttpClient) {
	}

	/**
	 * Appel à l'API de calcul d'équivalent CO2e
	 */
    testRse(): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/RSE/testRse`, null);
    }

	/**
	 * Chargement des paramètres du RSE
	 */
	loadRseParam(): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/RSE/loadRseParam`);
	}

	/**
	 * Enregistrement des paramètres du RSE
	 *
	 * @param paramRse Paramètres à enregistrer
	 */
	saveParamRse(paramRse: ParamRse): Observable<any> {
        return this.http.put<any>(`${environment.baseUrl}/controller/RSE/saveParamRse`, paramRse.listeParams);
    }
}