<mat-card>
    <mat-card-title>
        <span
                [class.success]="avance.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.NO_CONTROL"
                [class.error]="avance.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.ERROR">
            {{'avance.generalites.title' | translate}}
            <proprietaire [proprietaireUser]="avance.user"></proprietaire>
        </span>
    </mat-card-title>
    <mat-card-content>
        <card-alerte *ngIf="avance.lastWorkflowHistoriqueActionAlerte" [niveau]="avance.lastWorkflowHistoriqueActionAlerte.niveau">
            <preview-alerte [alerte]="avance.lastWorkflowHistoriqueActionAlerte"></preview-alerte>
        </card-alerte>
        <form autocomplete="off"  #form="ngForm" name="avance_form" *ngIf="settings">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row" [ngClass]="{ 'has-error': type.invalid }">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.type'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <mat-select name="type" #type="ngModel" [(ngModel)]="avance.nature" (selectionChange)="onNatureChanged($event)" required [disabled]="!canModifier || !(listeComptesBancaires?.length > 0)">
                                        <mat-option [value]="'ESPECE'">{{'avance.generalites.optionType.esp' | translate}}</mat-option>
                                        <mat-option [value]="'VIREMENT'">{{'avance.generalites.optionType.vir' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" [ngClass]="{ 'has-error': libelleObjet.invalid }">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.objet'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <input matInput name="objet" #libelleObjet="ngModel" [(ngModel)]="avance.libelleObjet" required/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="avance.od">
                            <label class="col-md-4"><span [translate]="'avance.generalites.om'"></span></label>
                            <div class="col-md-6 r-flex mb-2 align-self-center">
                                <span [nioTooltip]="tplOdTooltip" [nioTooltipPosition]="'track'">
                                    <span>{{ avance.od.idOd }} - {{ avance.od.objet }}</span>
                                    <a [routerLink]="" (click)="navigateToOd(avance.od)">
                                        <span class="material-icons align-middle">open_in_new</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" *ngIf="listeComptesBancaires?.length > 0 && avance.nature == 'VIREMENT'">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.compteBancaire'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <mat-select name="compteBancaire" [(ngModel)]="avance.compteCollaborateur" [compareWith]="compareComptesBancaires" required (selectionChange)="changeDeviseToCompteDevise()" [disabled]="!canModifier">
                                        <mat-option *ngFor="let compteBancaire of listeComptesBancaires" [value]="compteBancaire">{{ compteBancaire.libelle }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="avance.od?.plafondAvance">
                            <label class="col-md-4"><span [translate]="'avance.generalites.plafond'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <input name="plafond" matInput #plafond="ngModel" [(ngModel)]="avance.od.plafondAvance" nNumber="2" class="m-r-10" disabled/>
                                    <select name="devisePlafond" matNativeControl disabled>
                                        <option [value]="settings.deviseEntreprise" selected="selected">{{ settings.deviseEntreprise }}</option>$
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" [ngClass]="{ 'has-error': montant.invalid }">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.montant'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <span class="m-r-10 wrapper-input-loading">
                                        <input name="montant" matInput #montant="ngModel" nNumber="2" [(ngModel)]="avance.montant"
                                            (ngModelChange)="onMontantChanged()"
                                            required="true" [readonly]="isMontantLoading" />
                                        <mat-spinner diameter="20" *ngIf="isMontantLoading"></mat-spinner>
                                    </span>
                                    <mat-select name="devise" [(ngModel)]="deviseAvance" [compareWith]="compareDevises" (selectionChange)="onDeviseChanged($event)" [disabled]="!canModifier || avance.nature == 'VIREMENT'">
                                        <mat-option *ngFor="let devise of settings.listeDevises | avanceDevise:avance.nature" [value]="devise">{{ devise.code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.contrepartie'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <input name="montantContrepartie" matInput #montantContrepartie="ngModel" [(ngModel)]="avance.montantContrepartie" nNumber="2" class="m-r-10" required="true" disabled/>
                                    <select name="deviseContrepartie" matNativeControl disabled>
                                        <option [value]="settings.deviseEntreprise" selected="selected">{{ settings.deviseEntreprise }}</option>$
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4 required"><span [translate]="'avance.generalites.restant'"></span></label>
                            <div class="col-md-6">
                                <mat-form-field class="d-flex">
                                    <input name="montantRestant" matInput [(ngModel)]="avance.montantRestant" nNumber="2" class="m-r-10" required="true" disabled/>
                                    <select name="montantRestant" matNativeControl disabled>
                                        <option [value]="settings.deviseEntreprise" selected="selected">{{ settings.deviseEntreprise }}</option>$
                                    </select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Zones utilisateurs-->
                <zone-utilisateur #zu="zu" [listeZus]="avance.listeZU" [listeZuSaisies]="avance.listeZUSaisies" [readonly]="!canModifier" canListeZuChange="true"></zone-utilisateur>

                <div class="row">
                    <label class="col-md-2 align-items-start p-t-15"><span [translate]="'avance.generalites.remarque'"></span></label>
                    <div class="col-md-10">
                        <mat-form-field class="d-flex">
                            <textarea matInput rows="3" name="remarque" [(ngModel)]="avance.remarque" maxlength="1000"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>

<!-- Template du tooltip d'information de l'OD -->
<ng-template #tplOdTooltip>
    <table>
        <tr>
            <td><label translate>od.generalites.destination</label></td>
            <td>{{ avance.od.ville ? avance.od.ville.libelle + ', ' + avance.od.pays?.libelle : avance.od.pays?.libelle }}</td>
        </tr>
        <tr>
            <td><label translate>od.generalites.debut</label></td>
            <td>{{ avance.od.depart_le | date:'shortDate' }} {{ avance.od.heureDepart }}</td>
        </tr>
        <tr>
            <td><label translate>od.generalites.fin</label></td>
            <td>{{ avance.od.retour_le | date:'shortDate' }} {{ avance.od.heureRetour }}</td>
        </tr>
    </table>
</ng-template>
