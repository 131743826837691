<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons">{{ data.icone }}</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.frais.referentiels.infos.usage.compte'"></strong>
                <span>{{ data.compte }}</span>
            </li>
            <li>
                <strong [translate]="'admin.frais.referentiels.infos.usage.type'"></strong>
                <span> {{ ("admin.frais.referentiels.infos.usage.typeListe." + data.type) | translate }}</span>
            </li>
        </ul>
    </div>
</div>