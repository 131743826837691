import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Statut d'un objet
 */
export class Statut implements ListItem {
	idStatut: number;
	code: string;
	libelle: string;
	image: string;
	lienImage?: string;
	idEtat: number;
	ordre?: number;
	commentaire?: string;

	getKey(): number {
		return this.idStatut;
	}

	/**
	 * Constructeur
	 *
	 * @param dto à partir duquel créer un nouveau statut
	 */
	constructor(dto?: any) {
		if (dto) {
			//Copie des propriétés
			Object.assign(this,dto);
		}
	}

	isSelected?: boolean;
}
