import {Component,EventEmitter,Input,OnInit,Output,ViewChild} from '@angular/core';
import {TypeGenre,TypeProfil,User} from "@domain/user/user";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {PasswordPopinComponent,PasswordPopinMode} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/password-popin/password-popin.component";
import {ToastrService} from "ngx-toastr";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Router} from "@angular/router";
import {UserHabilitations} from "@domain/user/user-habilitations";
import {ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {HabilitationListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/habilitation-list-item/habilitation-list-item.component";
import {Page} from "@domain/common/http/list-result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {AddHabilitationPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/add-habilitation-popin/add-habilitation-popin.component";
import {UserEntreeSortiePopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/user-entree-sortie-popin/user-entree-sortie-popin.component";
import * as moment from "moment";
import {HabilitationHistoryPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/habilitation-history-popin/habilitation-history-popin.component";
import {environment} from '@environments/environment';
import {Onglets} from "@components/admin/entreprise/utilisateurs/user-detail/user-detail.component";
import {TypeRoleOption} from "@domain/role/typeRoleOption.enum";
import {LoginService} from "@share/login/login.service";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as sessionActions from "@reducers/session";
import {UserHabilitationService} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/user-habilitation.service";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {DelegationListItemComponent} from "@share/component/delegation/delegation-list-item/delegation-list-item.component";
import {DelegationHistoryPopinComponent} from "@share/component/delegation/delegation-history-popin/delegation-history-popin.component";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Onglet "Généralités" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-generalites'},
	selector: 'user-generalites',
	templateUrl: './user-generalites.component.html'
})
export class UserGeneralitesComponent implements OnInit {
	/** Utilisateur courant */
	@Input() user: User;

	/** Utilisateur connecté */
	@Input() userConnecte: User;

	/** Indicateur de modification de l'utilisateur */
	@Output() userChanged: EventEmitter<void> = new EventEmitter();

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/** Liste des habilitations de l'utilisateur */
	listeUserHabilitations: UserHabilitations[] = [];

	/** ListView des habilitations de l'utilisateur */
	listeHabilitations: ListView<UserHabilitations,HabilitationListItemComponent>;

	/** Liste des habilitations de l'utilisateur */
	listeUserDelegationRecues: LienRoleUser[] = [];

	/** ListView des habilitations reçues de l'utilisateur */
	listeDelegationRecue: ListView<LienRoleUser,DelegationListItemComponent>;

	/** Liste des habilitations de l'utilisateur */
	listeUserDelegationAccordees: LienRoleUser[] = [];

	/** ListView des habilitations accordées de l'utilisateur */
	listeDelegationAccordee: ListView<LienRoleUser,DelegationListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Indicateur de traitement en cours */
	isPending: boolean = false;

	/** Lecture seule sur les infos générales */
	@Input() isReadOnly: boolean;

	/** Visibilité des habilitations */
	@Input() isDroitHabilitations: boolean;

	/** Liste des types de genres disponibles */
	readonly listeTypeGenre = [
		{
			code: TypeGenre.MONSIEUR,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.monsieur')
		},{
			code: TypeGenre.MADAME,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.madame')
		},{
			code: TypeGenre.MADEMOISELLE,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.mademoiselle')
		}
	];

	/** Utilisation de MomentJS dans le DOM */
	moment = moment;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param matDialog le service de gestion des popins
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le service de gestion du toaster
	 * @param router le routeur Angular
	 * @param confirmService la popup de confirmation d'une action
     * @param loginService le service de login
     * @param store service du store
	 * @param userHabilitationService le service des habilitations de l'utilisateur
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService,
		private router: Router,
        private confirmService: ConfirmService,
        private loginService: LoginService,
        private store: Store<AppState>,
		private userHabilitationService: UserHabilitationService,
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Si l'utilisateur est créé
		if (this.user.idUser) {
			this.initListeHabilitation();
			this.initListeDelegationAccordee();
			this.initListeDelegationRecue();
		}

		//Ajout des actions
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				doAction: () => this.saveUser(),
				isDisabled: () => this.form?.invalid
			},{
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				doAction: () => this.deleteUser(),
				isVisible: () => this.user.idUser > 0
			},{
				type: TypeAction.SECONDARY,
				icone: 'material-icons-outlined',
				iconeName: 'people',
				libelle: 'admin.entreprise.utilisateurs.detail.generalites.connectAs',
				isVisible: () => this.canConnectAs(),
				//Changement d'utilisateur et redirection vers la page d'accueil
				doAction: () => this.doConnectAs()
			}
		]);
	}

	/**
	 * Initialisation de la liste habilitation
	 */
	private initListeHabilitation(): void {
		this.listeUserHabilitations = this.userHabilitationService.buildListeUserHabilitationGenerale(this.user.listeLienRoleUsers);

		//Initialisation de la liste des habilitations
		this.listeHabilitations = new ListView<UserHabilitations,HabilitationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.title'),
			component: HabilitationListItemComponent,
			isFrontendList: true,
			listeActions: [{
				icon: "history",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.showHistory'),
				onPress: () => this.showHistory()
			},{
				icon: "add",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitation'),
				onPress: () => this.addHabilitation()
			}],
			data: {
				listeResultats: this.listeUserHabilitations,
				numPage: 0,
				nbPagesTotal: 0,
				nbObjetsTotal: this.listeUserHabilitations.length,
				nbObjetsDansPage: this.listeUserHabilitations.length,
				nbObjetsParPage: this.listeUserHabilitations.length
			} as Page<UserHabilitations>,
			extraOptions: {
				userChanged: this.userChanged,
				isCollab: this.user.listeLienRoleUsers.some(lru => lru.role.fonction === TypeProfil.COLLABORATEUR),
			}
		});
	}

    /**
     * Changement d'utilisateur et redirection vers la page d'accueil
     */
    doConnectAs() {
        //On indique la session n'est plus à jour
        //Problème notamment avec le statut-application qui se chargeait en admin alors que c'est plus le cas
        const session = this.loginService.getSession();
        session.isUpToDate = false;

        //dispatch session
        this.store.dispatch({
            type: sessionActions.SESSION_FULFILLED,
            payload: session
        });

        //Changement d'utilisateur et redirection vers la page d'accueil
        window.top.location.href = `${environment.baseUrl}/controller/User/connectAs?idUser=${this.user.idUser}`
    }
	/**
	 * Initialisation de la délégation reçue
	 */
	private initListeDelegationRecue(): void {
		//Initialisation de la liste des habilitations
		this.listeDelegationRecue = new ListView<LienRoleUser,DelegationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.historiqueDelegationRecues'),
			component: DelegationListItemComponent,
			uri: `/controller/User/listeDelegationRecue/${this.user.idUser}`,
			listeActions: [{
				icon: "history",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.showHistory'),
				onPress: () => this.showHistoryDelegationRecues()
			}],
			extraOptions: {
				canModifier: false,
				canShowDeleguant: true,
				canShowDelegue: false,
				userChanged: this.userChanged,
				titleHistorique: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.titleHistoriqueRecue',
			},
			defaultOrder: 'dateDebut',
			listeFilters: [
				{
					clef: 'role.fonction',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'),
					listeValues: [
						{
							code: '2',
							libelle: this.translateService.instant('profil.responsable')
						},{
							code: '4',
							libelle: this.translateService.instant('profil.assistant')
						}
					]
				},{
					clef: 'role.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.role'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: '*population.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.population'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'userDelegant.matricule,userDelegant.nom,userDelegant.prenom',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				}
			]
		});

		//Définition des colonnes de tri
		this.listeDelegationRecue.columns = [
			{key: 'role.fonction',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'},
			{key: 'role.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'},
			{key: 'userDelegant.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'},
			{key: 'dateDebut',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'},
			{key: 'dateFin',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'},
			{key: 'population.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeDelegationRecue.sorting = new Sorting(this.listeDelegationRecue.columns,"dateDebut");
	}

	/**
	 * Initialisation de la liste des délégations accordées
	 */
	private initListeDelegationAccordee(): void {
		//Initialisation de la liste des habilitations
		this.listeDelegationAccordee = new ListView<LienRoleUser,DelegationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.historiqueDelegationAccordees'),
			uri: `/controller/User/listeDelegationAccordee/${this.user.idUser}`,
			component: DelegationListItemComponent,
			listeActions: [{
				icon: "history",
				tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.showHistory'),
				onPress: () => this.showHistoryDelegationAccordees()
			}],
			extraOptions: {
				canModifier: true,
				canShowDeleguant: false,
				canShowDelegue: true,
				userChanged: this.userChanged,
				titleAdd: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.titleConsultationAccordee',
			},
			defaultOrder: 'dateDebut',
			listeFilters: [
				{
					clef: 'role.fonction',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'),
					listeValues: [
						{
							code: '2',
							libelle: this.translateService.instant('profil.responsable')
						},{
							code: '4',
							libelle: this.translateService.instant('profil.assistant')
						}
					]
				},{
					clef: 'role.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.role'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'),
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					isDefault: true,
				},{
					clef: '*population.libelle',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.population'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'user.matricule,user.nom,user.prenom',
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.delegue'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				}
			]
		});

		//Définition des colonnes de tri
		this.listeDelegationAccordee.columns = [
			{key: 'role.fonction',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.fonction'},
			{key: 'role.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'},
			{key: 'user.nom',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'},
			{key: 'dateDebut',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'},
			{key: 'dateFin',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'},
			{key: 'population.libelle',title: 'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeDelegationAccordee.sorting = new Sorting(this.listeDelegationAccordee.columns,"dateDebut");
	}

	/**
	 * Indique si l'utilisateur peut voir le bouton "se connecter en tant que"
	 */
	canConnectAs(): boolean {
		//Si l'utilisateur cible peut se connecter, et que l'utilisateur connecté est admin ou a le droit de connexion pour autrui)
		return this.user.canLogin && (
			this.userConnecte.fonctionSousAdministrateur && this.userConnecte.specificites.some(spe => spe === "" + TypeRoleOption.CONNEXION_POUR)
			|| this.userConnecte.idUser === 0)
	}

	/**
	 * Ouverture de la popup de gestion des dates d'entrée/sortie
	 */
	onClickActif(): void {
		//Ouverture de la popup
		this.matDialog.open(UserEntreeSortiePopinComponent,{
			data: {
				user: this.user
			}
		}).afterClosed().subscribe((user: User) => {
			//On regarde si l'utilisateur a été modifié
			if (user) {
				//Remplacement de l'utilisateur courant
				this.user = user;
			}
		});
	}

	/**
	 * Fonction appelée à chaque changement de service
	 */
	onOrgaChange(): void {
		//Mise à jour du champ idOrga
		this.user.idOrga = this.user.orga.idOrga;
	}

	/**
	 * Ouverture de la popup d'activation d'un compte / Reset d'un mdp
	 */
	onClickPassword(): void {
		//Ouverture de la popup
		this.matDialog.open(PasswordPopinComponent,{
			data: {
				mode: this.user.passDate ? PasswordPopinMode.RESET_MDP : PasswordPopinMode.ACTIVATION,
				user: this.user
			}
		}).afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				//Chargement en cours
				this.isPending = true;

				//Appel au service
				this.userService.resetPassword(this.user.idUser)
					.pipe(first(),finalize(() => this.isPending = false))
					.subscribe((result: Result) => {
						//Vérification du résultat de l'appel
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.email'));
						} else {
							//Toast error
							this.toastrService.error(this.translateService.instant('global.errors.email'));
						}
					});
			}
		});
	}

	/**
	 * Ouverture de la popin d'ajout d'une habilitation
	 */
	addHabilitation(): void {
		this.matDialog.open(AddHabilitationPopinComponent,{
			data: {
				isCreation: true,
				idUser: this.user.idUser,
				isCollab: this.user.listeLienRoleUsers.some(lru => lru.role.fonction === TypeProfil.COLLABORATEUR),
				isHistory: false,
				canChangeEnveloppe: true,
			},
			width: '80%'
		}).afterClosed().subscribe((result: boolean) => {
			//Vérification du résultat
			if (result) {
				//Rechargement de l'utilisateur
				this.userChanged.emit();
			}
		});
	}

	/**
	 * Ouverture de la popin de consultation des habilitations inactives
	 */
	showHistory(): void {
		this.matDialog.open(HabilitationHistoryPopinComponent,{
			data: {user: this.user},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		});
	}

	/**
	 * Ouverture de la popin des délégations reçues
	 */
	showHistoryDelegationRecues(): void {
		this.matDialog.open(DelegationHistoryPopinComponent,{
			data: {
				uriHistory: `/controller/User/listeDelegationRecueHistory/${this.user.idUser}`,
				canShowDelegue: false,
				canShowDeleguant: true,
			},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		});
	}

	/**
	 * Ouverture de la popin des délégations accordées
	 */
	showHistoryDelegationAccordees(): void {
		this.matDialog.open(DelegationHistoryPopinComponent,{
			data: {
				uriHistory: `/controller/User/listeDelegationAccordeeHistory/${this.user.idUser}`,
				canShowDelegue: true,
				canShowDeleguant: false,
			},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		});
	}

	/**
	 * Sauvegarde de l'utilisateur
	 */
	saveUser(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.userService.saveUser(this.user)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Si l'on est en création
					if (this.user.idUser == 0) {
						//Initialisation du collaborateur
						this.userService.saveCollabAtCreation(result.data.id_user)
							.pipe(first())
							.subscribe((result2: Result) => {
								//Vérification du result
								if (result2.codeErreur === TypeCodeErreur.NO_ERROR) {
									//On redirige vers l'utilisateur nouvellement créé, onglet Métier
									this.router.navigate(['Admin/Entreprise/Utilisateurs/User',result.data.id_user],{state: {'tabToLoad': Onglets.METIER}});
								} else if (result2.codeErreur === TypeCodeErreur.ERROR_SAVE) {
									//Si c'est codeErreur = 1, erreur bloquante => toast error puis redirection
									this.toastrService.warning(this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.errors.initCollab'));

									//On redirige vers l'utilisateur nouvellement créé
									this.router.navigate(['Admin/Entreprise/Utilisateurs/User',result.data.id_user]);
								} else {
									//Gestion de l'erreur
									TypeCodeErreur.showError(result2.codeErreur,this.translateService,this.toastrService);
								}
							});
					}
				} else if (result.codeErreur === 900) {
					//Toast erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.errors.matricule'));
				} else if (result.codeErreur === 901) {
					//Toast erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.infosGenerales.errors.nomAcces'));
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de l'utilisateur
	 */
	deleteUser(): void {
		//Ouverture de la boîte de dialogue pour confirmer la suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Chargement en cours
					this.isPending = true;

					//Appel au service
					this.userService.deleteUser(this.user.idUser)
						.pipe(first(),finalize(() => this.isPending = false))
						.subscribe((result: Result) => {
							//Vérification du résultat de l'appel
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Retour à la liste
								this.onGoBack();
							} else {
								//Toast erreur
								this.toastrService.error(this.translateService.instant(
									'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.errors.suppression'));
							}
						});
				}
			});
	}

	/**
	 * Retour à la liste
	 */
	onGoBack(): void {
		//Retour à la liste
		this.router.navigate(['Admin/Entreprise/Utilisateurs/Internes']);
	}
}