<h1 mat-dialog-title>
    <span [translate]="'workflow.statut.selectRepresentation'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div *ngIf="listeRepresentation" class="listview">
        <div class="listview-body">
            <div *ngFor="let representation of listeRepresentation | keyvalue" class="listview-item clickable" (click)="selectRepresentation(representation.value,representation.key)">
                <div class="listview-item-hover">
                    <div class="lvi-content">
                        <statut class="image" [image]="representation.value"></statut>
                        <div><span>{{ representation.key }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <please-wait class="content" *ngIf="!listeRepresentation"></please-wait>
</div>