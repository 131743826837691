import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { DynamicDragClickInterceptorService } from "@share/component/list-view/dynamic-drag-click-interceptor.service";

/**
 * Directive pour l'interception du scroll 2D type cliquer-d�placer
 */
@Directive({
    selector: '[dynamicDragClickInterceptor]',
})
export class DynamicDragClickInterceptorDirective {
    /** M�thode � appeler lorsque le clic est actif */
    @Input() dynamicDragClickInterceptor: ()=> any;

    /**
     * Constructeur
     */
    constructor(public elementRef: ElementRef,private dynamicDragClickInterceptorService: DynamicDragClickInterceptorService) {}

    /**
     * D�tection de la pr�sence ou non de la classe CSS d�sactivant le drag
     */
    @HostBinding('class.no-drag-active')
    get isDragDisabled() {
        //True si l'�l�ment contient la classe CSS
        return this.elementRef.nativeElement.classList.contains('drag-disabled');
    }

    /**
     * Clic
     */
    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        //Annulation en cas de clic droit
        if (event.button !== 0) return;

        //Sauvegarde du statut du cliquer-d�placer (via la classe CSS)
        this.dynamicDragClickInterceptorService.isDragDisabled = this.isDragDisabled;

        //Si le blocage du cliquer-d�placer est activ� par la pr�sence de la classe CSS
        if (this.isDragDisabled) {
            //Emp�che le d�clenchement de la fonctionnalit� de cliquer-d�placer
            event.stopPropagation();
            event.preventDefault();

            //Fin
            return;
        }

        //Mouvement en cours
        this.dynamicDragClickInterceptorService.isDragging = true;

        //Reset du flag de d�placement
        this.dynamicDragClickInterceptorService.isMoved = false;

        //Position de d�part
        this.dynamicDragClickInterceptorService.startX = event.clientX;
        this.dynamicDragClickInterceptorService.startY = event.clientY;
    }

    /**
     * Mouvement
     */
    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent): void {
        //Annulation si aucun clic n'a eu lieu
        if (!this.dynamicDragClickInterceptorService.isDragging) return;

        //Calcul du d�placement
        const x = event.clientX - this.dynamicDragClickInterceptorService.startX;
        const y = event.clientY - this.dynamicDragClickInterceptorService.startY;

        //Si le d�placement est sup�rieur � 1 pixel
        if (Math.abs(x) > 1 || Math.abs(y) > 1) {
            //Marque qu'un d�placement a eu lieu
            this.dynamicDragClickInterceptorService.isMoved = true;
        }
    }

    /**
     * Rel�chement du clic
     */
    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent): void {
        //Si la balise de d�part du clic n'avait pas le clic d�sactiv� ou que la souris n'a pas du tout boug�
        if (!this.dynamicDragClickInterceptorService.isMoved) {
            //Ex�cution du clic
            this.dynamicDragClickInterceptor();
        } else {
            //Sinon interception du clic
            event.preventDefault();
        }

        //Fin du d�placement
        this.dynamicDragClickInterceptorService.isDragging = false;
        this.dynamicDragClickInterceptorService.isMoved = false;
    }
}
