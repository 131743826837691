<mat-card>
    <mat-card-title>
    <span [translate]="'admin.parametres.api.partenaires.rse'"></span>
    </mat-card-title>
    <mat-card-content>
        <form>
            <fieldset *ngIf="paramRse">
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="actif"
                                  name="rseActif"
                                  [ngModel]="paramRse.isCalculActif"
                                  [(customModel)]="paramRse.isCalculActif"
                                  customType="checkbox"
                                  libelle="admin.parametres.api.partenaires.activer"></custom-input>
                    <custom-input ngDefaultControl
                                  id="iframeActive"
                                  name="iframeActive"
                                  [ngModel]="paramRse.isIframeActive"
                                  [(customModel)]="paramRse.isIframeActive"
                                  customType="checkbox"
                                  libelle="admin.parametres.api.partenaires.activerIframe"
                                  [postTooltip]="'admin.parametres.api.partenaires.activerIframeTooltip' | translate"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="clefApi"
                                  name="clefApi"
                                  [canReadClear]="false"
                                  [(ngModel)]="paramRse.cleApi"
                                  customType="input"
                                  [placeholder]="(paramRse.cleApi ? '' : 'admin.parametres.api.partenaires.defautClef' | translate)"
                                  libelle="admin.parametres.api.partenaires.clefApi"></custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>