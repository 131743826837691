<div class="header-item-content"
     [nioTooltip]="ListeVisualisationTooltipItemComponent"
     [nioTooltipContext]="{ segment: data }"
     nioTooltipPosition="track"
     [class.highlight]="data[HIGHLIGHT]"
     (mouseover)="onMouseOver()"
     (mouseleave)="onMouseLeave()">
    <div *ngFor="let etat of listeStatuts; trackBy: trackByEtat" class="header-item">
        <div class="header-item-liste-statut">
            <div *ngFor="let statut of etat[1]; trackBy: trackByStatut"
                 class="header-item-statut"
                 [dynamicDragClickInterceptor]="onClick"
                 [class.drag-disabled]="data?.statutDebutFormulaireModifie?.code == statut.code || data?.statutFinFormulaireModifie?.code == statut.code || (statut['index'] >= indexDebut && statut['index'] <= indexFin) || (statut['index'] >= indexFin && statut['index'] <= indexDebut)"
                 [class.non-clickable]="!data.formulaireModifie"
                 [class.odd-index]="statut['index'] % 2 != 0">
                <div *ngIf="!displayAction && statut['index'] == indexDebut && indexFin == indexDebut" class="connector"></div>
                {{ statut.libelle || ('admin.workflow.visualisation.vide' | translate) }}
                <div *ngIf="statut['index'] == indexDebut || statut['index'] == indexFin || (indexFin > indexDebut && statut['index'] > indexDebut) || (indexDebut > indexFin && statut['index'] < indexDebut)"
                     class="action-container"
                     [class.static]="indexFin == indexDebut">
                    <div *ngIf="statut['index'] == indexDebut && data.formulaireModifie"
                         class="action"
                         [class.forward]="indexFin >= indexDebut"
                         [class.backward]="indexDebut > indexFin"
                         [class.static]="indexFin == indexDebut"
                         [class.inactif]="!data.actif"
                         [ngClass]="$any(data.formulaireModifie) && $any(data.formulaireDepart) != $any(data.formulaireModifie) ? $any(data.formulaireDepart) : $any(data.formulaireModifie)">
                        <span [ngSwitch]="data.action">
                            <i *ngSwitchCase="TypeAction.VALIDER" class="nio icon-validation icone"></i>
                            <i *ngSwitchCase="TypeAction.REJETER" class="nio icon-rejet icone"></i>
                            <i *ngSwitchCase="TypeAction.INVALIDER" class="nio icon-invalidation icone"></i>
                            <i *ngSwitchCase="TypeAction.ANNULER" class="nio icon-annulation icone"></i>
                            <i *ngSwitchCase="TypeAction.MODIFIER" class="nio icon-modification icone"></i>
                            <i *ngSwitchCase="TypeAction.SUPPRIMER" class="nio icon-suppression icone"></i>
                            <i *ngSwitchCase="TypeAction.EMETTRE" class="nio icon-emission icone"></i>
                            <i *ngSwitchCase="TypeAction.ARCHIVER" class="nio icon-archive icone"></i>
                            <i *ngSwitchCase="TypeAction.COMPTABILISER" class="nio icon-comptabilisation icone"></i>
                            <i *ngSwitchCase="TypeAction.COMPLETER" class="nio icon-completion icone"></i>
                            <i *ngSwitchCase="TypeAction.RESTITUER" class="nio icon-restitution icone"></i>
                            <i *ngSwitchCase="TypeAction.CLOTURER" class="nio icon-cloture icone"></i>
                            <i *ngSwitchCase="TypeAction.ATTRIBUER" class="nio icon-octroi icone"></i>
                            <i *ngSwitchDefault class="material-icons-outlined icone" style="font-weight: normal;">find_in_page</i>
                        </span>
                    </div>
                    <div *ngIf="data?.statutDebutFormulaireModifie?.code == statut.code || data?.statutFinFormulaireModifie?.code == statut.code || (statut['index'] >= indexDebut && statut['index'] <= indexFin) || (statut['index'] >= indexFin && statut['index'] <= indexDebut)"
                         class="between-bar"
                         [class.full]="(statut['index'] > indexDebut && statut['index'] < indexFin) || (statut['index'] > indexFin && statut['index'] < indexDebut)"
                         [class.forward]="indexFin > indexDebut"
                         [class.backward]="indexDebut > indexFin"
                         [class.static]="indexFin == indexDebut"
                         [class.no-action]="!data.formulaireModifie"
                         [class.debut]="statut['index'] == indexDebut"
                         [class.fin]="statut['index'] == indexFin"
                         [class.with-condition]="data.conditionFormulaireDepart || data.conditionFormulaireModifie"
                         [class.inactif]="!data.actif"
                         [ngClass]="$any(data.formulaireModifie) || $any(data.formulaireDepart)">
                        {{ indexFin == indexDebut ? displayAction || (data.action < 0 && ('admin.workflow.visualisation.actionsSupp.' + data.action | translate)) || ('admin.workflow.visualisation.actions.' + data.action | translate) : null }}
                        <span *ngIf="indexFin == indexDebut && (data.softAction !== 0 || data.actionBudget !== 0 || !!data.lstActionSupp)">&nbsp;(<span class="material-icons-outlined procedure">list</span>)</span>
                        <div *ngIf="statut['index'] == indexDebut && (data.conditionFormulaireDepart || data.conditionFormulaireModifie) && (displayAction || statut['index'] != indexDebut || indexFin != indexDebut)"
                             class="condition"
                             [class.inactif]="!data.actif">
                            {{ data.conditionFormulaireDepart || data.conditionFormulaireModifie }}
                        </div>
                    </div>
                    <div *ngIf="statut['index'] == indexDebut && indexFin != indexDebut"
                         class="action-title"
                         [class.forward]="indexFin >= indexDebut"
                         [class.backward]="indexDebut > indexFin"
                         [class.with-condition]="data.conditionFormulaireDepart || data.conditionFormulaireModifie"
                         [class.inactif]="!data.actif">
                        <span *ngIf="indexDebut > indexFin" class="material-icons-outlined chevron">chevron_left</span>
                        {{ displayAction || ('admin.workflow.visualisation.actions.' + data.action | translate) }}
                        <span *ngIf="data.softAction !==0 || data.actionBudget !== 0 || !!data.lstActionSupp">(<span class="material-icons-outlined procedure">list</span>)</span>
                        <span *ngIf="indexFin > indexDebut" class="material-icons-outlined chevron">chevron_right</span>
                    </div>
                    <div *ngIf="statut['index'] == indexFin && indexDebut != indexFin"
                         class="target"
                         [class.forward]="indexFin >= indexDebut"
                         [class.backward]="indexDebut > indexFin"
                         [class.inactif]="!data.actif"
                         [ngClass]="$any(data.formulaireModifie) || $any(data.formulaireDepart)">
                        <mat-icon class="material-icons-outlined chevron">{{ indexFin >= indexDebut ? 'chevron_right' : 'chevron_left' }}</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
