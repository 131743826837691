import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {WfConstructionComponent} from "./wf-construction.component";
import {SegmentComponent} from "./segment/segment.component";
import {ListeWfComponent} from "@components/admin/workflow/construction/liste-wf/liste-wf.component";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {SegmentGeneralitesComponent} from "@components/admin/workflow/construction/segment/generalites/segment-generalites.component";
import {SegmentInformationsComponent} from "@components/admin/workflow/construction/segment/informations/segment-informations.component";
import {MailToItemComponent} from "@components/admin/workflow/construction/segment/generalites/mail-to-item.component";
import {MailToPopupComponent} from "@components/admin/workflow/construction/segment/mail-to-popup.component";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ListeWfItemComponent} from "@components/admin/workflow/construction/liste-wf/liste-wf-item.component";
import {WorkflowPopupComponent} from "@components/admin/workflow/construction/liste-wf/workflow-popup.component";
import {VisualisationComponent} from "@components/admin/workflow/construction/visualisation/visualisation.component";
import {ListeVisualisationItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-item.component";
import {ListeVisualisationStickyItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-sticky-item.component";
import {ListeVisualisationHeaderItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-header-item.component";
import {ListeVisualisationStickyHeaderItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-sticky-header-item.component";
import {ListeVisualisationTooltipItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-tooltip-item.component";

export const workflowConstructionRoutes : Routes = [
    {
        path: 'Segment/:idSegment',
        component: SegmentComponent,
        canActivate: [AdminGuardProvider],
        data: { isHeaderHidden: true }
    }, {
        path: 'ListeWf',
        component: ListeWfComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Visualisation',
        component: VisualisationComponent,
        canActivate: [AdminGuardProvider],
        data: { isHeaderHidden: true }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ListeWf'
    }, {
        path: '**',
        redirectTo: 'ListeWf'
    }
];

@NgModule({
    imports: [ShareModule,PreviewAlerteModule],
    declarations: [
        WfConstructionComponent,
        SegmentComponent,
        ListeWfComponent,
        SegmentGeneralitesComponent,
        SegmentInformationsComponent,
        MailToItemComponent,
        MailToPopupComponent,
        ListeWfItemComponent,
        WorkflowPopupComponent,
        VisualisationComponent,
        ListeVisualisationItemComponent,
        ListeVisualisationStickyItemComponent,
        ListeVisualisationHeaderItemComponent,
        ListeVisualisationStickyHeaderItemComponent,
        ListeVisualisationTooltipItemComponent
    ],
    entryComponents: [],
    exports: [
        SegmentComponent,
        ListeWfComponent,
        SegmentGeneralitesComponent,
        SegmentInformationsComponent
    ],
    providers: [WorkflowAdminService]
})
export class WfConstructionModule {}
