import {Injectable,OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ParamRse} from "@domain/admin/parametre/api/partenaires/ParamRse";
import {environment} from '@environments/environment';
import {Result} from "@domain/common/http/result";
import {first} from "rxjs/operators";
import {BehaviorSubject,Observable} from "rxjs";

/**
 * Service de gestion du RSE
 *
 * Injecté dans le root pour pouvoir être utilisé dans toute l'application
 */
@Injectable({providedIn: 'root'})
export class RseService {
	/** Paramètres actifs pour le RSE */
	private _paramRse: BehaviorSubject<ParamRse> = new BehaviorSubject<ParamRse>(null);

	/**
	 * Contructeur
	 *
	 * @param http Service HTTP
	 */
	constructor(protected http: HttpClient) {
		this.initRseParam();
	}

	/**
	 * Initialisation des paramètres du RSE
	 */
	initRseParam(): void {
		this.http.get<Result>(`${environment.baseUrl}/controller/RSE/loadRseParam`).pipe(first()).subscribe(result => {
			this._paramRse.next(new ParamRse(result.data.rseParam));
		});
	}

	/** Getter des paramètres du RSE */
	get paramRse(): Observable<ParamRse>  {
		return this._paramRse.asObservable();
	}
}