import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Segment,Statut} from "@domain/admin/workflow/segment";
import {TranslateService} from "@ngx-translate/core";
import {TypeAction} from "@domain/workflow/workflow";
import {Router} from "@angular/router";
import {ListeVisualisationTooltipItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-tooltip-item.component";
import {ListeVisualisationStickyItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-sticky-item.component";

/**
 * Item de liste des segments
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-visualisation-item'},
	templateUrl: './liste-visualisation-item.component.html',
	styleUrls: ['./liste-visualisation-item.component.scss']
})
export class ListeVisualisationItemComponent extends ListViewItem<Segment> implements OnInit {
	/** Enum des actions */
	readonly TypeAction = TypeAction;

	/** Composant du tooltip */
	readonly ListeVisualisationTooltipItemComponent = ListeVisualisationTooltipItemComponent;

	/** Constante pour la mise en valeur d'une ligne */
	readonly HIGHLIGHT: string = ListeVisualisationStickyItemComponent.HIGHLIGHT;

	/** Statuts tri�s par �tat dans un tableau (l'emploi de getters sur une map dans le template engendre des probl�mes de perfs avec leur appel constant)*/
	listeStatuts: [number,Statut[]][];

	/** Index de d�but */
	indexDebut = 0;

	/** Index de fin */
	indexFin = 0;

	/** Action � afficher pour le segment */
	displayAction: String;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private router: Router) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la map
		let index: number = 1;
		const mapStatuts: Map<number,Statut[]> = new Map();
		mapStatuts.set(0,[new Statut({})]);
		mapStatuts.get(0)[0]['index'] = 0;

		//It�ration sur les statuts
		for (const statut of (this.liste.headerData as Statut[])) {
			//Tentative de r�cup�ration de la liste des statuts tri�s
			let listeStatuts: Statut[] = mapStatuts.get(statut.idEtat);

			//Si la liste n'existe pas encore
			if (!listeStatuts) {
				//Alors, on la cr��e et on l'ajoute � la map
				listeStatuts = [];
				mapStatuts.set(statut.idEtat,listeStatuts);
			}

			//Ajout du statut
			listeStatuts.push(statut);

			//Sauvegarde des index si n�cessaire
			if (this.data?.statutDebutFormulaireModifie?.code == statut.code) { this.indexDebut = index; }
			if (this.data?.statutFinFormulaireModifie?.code == statut.code) { this.indexFin = index; }

			//Stockage de l'index en dur sur l'objet statut
			statut['index'] = index;

			//Incr�ment de l'index
			index++;
		}

		//Sauvegarde des index dans l'objet
		this.data['indexDebut'] = this.indexDebut;
		this.data['indexFin'] = this.indexFin;

		//Conversion de la Map en array � plusieurs dimensions
		this.listeStatuts = Array.from(mapStatuts);

		//Si aucun des deux statuts n'est d�fini
		if (!this.data?.statutDebutFormulaireModifie && !this.data?.statutFinFormulaireModifie) {
			//For�age sur cr�ation
			this.data.statutDebutFormulaireModifie = mapStatuts.get(1)[0];
			this.data.statutFinFormulaireModifie = mapStatuts.get(1)[0];
			this.indexDebut = 1;
			this.indexFin = 1;
		}

		//Stockage de l'action du segment
		this.displayAction = this.data.action == -1 ? this.translateService.instant('admin.workflow.visualisation.actions.-1') : this.data.libelleAction;

		//V�rification des liens d'arborescence
		this.checkPreviousForTreeLink();
	}

	/**
	 * �v�nement du clic sur un segment
	 */
	onClick = () => {
		//V�rification que le segment est cliquable
		if (this.data.formulaireModifie) {
			//Navigation vers la page du segment
			this.router.navigate([`Admin/Workflow/Construction/Segment/${this.data.idSegment}`]);
		}
	}

	/**
	 * Survol de la ligne
	 */
	onMouseOver(): void {
		//Forcage de l'information true
		this.data[ListeVisualisationStickyItemComponent.HOVER] = true;
	}

	/**
	 * Fin du survol de la ligne
	 */
	onMouseLeave(): void {
		//Forcage de l'information false
		this.data[ListeVisualisationStickyItemComponent.HOVER] = false;
	}

	/**
	 * Tracking des objets de la boucle for des �tats
	 *
	 * @param index index de la boucle
	 * @param item objet � suivre
	 */
	trackByEtat(index: number, item: [number,Statut[]]) {
		return item[0];
	}

	/**
	 * Tracking des objets de la boucle for des statuts
	 *
	 * @param index index de la boucle
	 * @param item objet � suivre
	 */
	trackByStatut(index: number, item: any) {
		return item.idStatut;
	}

	/**
	 * D�tecte les liens d'arborescence entre segments
	 */
	checkPreviousForTreeLink(): void {
		//Si le segment n'a pas d'action
		if (!this.displayAction) {
			//Recherche du segment pr�c�dent
			let previous: Segment = this.data['index'] > 0 ? this.liste.data.listeResultats[this.data['index']-1] : null;

			//Tant que le segment pr�c�dent n'a pas d'action
			while (!(previous.action == -1 || !!previous.libelleAction)) {
				//Recherche du segment pr�c�dent
				previous = previous['index'] > 0 ? this.liste.data.listeResultats[previous['index']-1] : null;
			}

			//Synchronisation avec la position du segment parent
			this.indexDebut = previous['indexDebut'];
			this.indexFin = this.data.formulaireModifie ? previous['indexFin'] : previous['indexDebut'];
		}
	}
}
