import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Vehicule} from "@domain/vehicule/vehicule";
import {ProfilDonneesVehiculeListItemComponent} from "@components/profil/donnees/vehicule/profil-donnees-vehicule-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {BehaviorSubject,Subscription} from "rxjs";
import {SettingsVehiculeState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as settingsActions from "@reducers/settings";
import {TypePortee} from "@domain/workflow/workflow";
import {filter,first} from "rxjs/operators";
import {ProfilService} from "@components/profil/profil.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {ProfilDonneesAddVehiculeComponent} from "@components/profil/donnees/vehicule/profil-donnees-add-vehicule.component";
import {Router} from "@angular/router";
import {ProfilAlertes} from "@domain/profil/profilAlertes";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {TypeSaisie} from "@domain/profil/profilVoyageur";
import {TypeFormulaire} from "@domain/profil/DocumentAlerte";
import {DatePipe} from "@angular/common";
import {Moment} from "moment";
import * as moment from "moment";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";

/**
 * Onglet "Véhicules" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-vehicules'},
	selector: 'user-vehicules',
	templateUrl: './user-vehicules.component.html'
})
export class UserVehiculesComponent implements OnInit,OnDestroy {
	/** ID de l'utilisateur courant */
	@Input() idUser: number;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Indique si le rgpd est suspendu */
	@Input() isRgpdSuspendu: boolean;

	/** Liste des véhicules de l'utilisateur */
	listeVehicule: ListView<Vehicule,ProfilDonneesVehiculeListItemComponent>;

	/** Paramètres relatifs aux véhicules */
	vehiculeSettings: BehaviorSubject<SettingsVehiculeState> = new BehaviorSubject<SettingsVehiculeState>(undefined);

	/** Souscription aux paramètres */
	subscription: Subscription = new Subscription();

	/** Liste des alertes des véhicules */
	listeAlertes: Alerte[];

	/** Niveau d'alerte de l'écran */
	niveauAlerte: NiveauAlerte;

	/**
	 * Constructeur
	 *
	 * @param store le store de l'application
	 * @param translateService le moteur de traduction
	 * @param profilService le service de gestion du profil
	 * @param toastrService le toaster
	 * @param matDialog le gestionnaire de popin
	 * @param router le routeur Angular
	 * @param datePipe le pipe des dates
	 * @param userService le service de gestion de l'utilisateur
	 */
	constructor(
		private store: Store<AppState>,
		private translateService: TranslateService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private matDialog: MatDialog,
		private router: Router,
		private datePipe: DatePipe,
		private userService: EntrepriseUtilisateursService
	) {
	}

	/**
	 * Initialsation du composant
	 */
	ngOnInit(): void {
		//Chargement des paramètres liés aux véhicules
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.VP
		});

		//Souscription aux paramètres
		this.subscription = this.store.select((state: AppState) => state.settings?.[TypePortee.VP])
			.subscribe((settings: SettingsVehiculeState) => this.vehiculeSettings.next(settings));

		//Si on peut afficher les informations RGPD
		if (!this.isRgpdSuspendu) {
			//On attend que les paramètres soient chargés
			this.vehiculeSettings.pipe(filter(settings => !!settings)).subscribe(() => {
				//Initialisation de la lister des véhicules
				this.listeVehicule = new ListView<Vehicule, ProfilDonneesVehiculeListItemComponent>({
					uri: `/controller/User/getListeVehicule?idUser=${this.idUser}`,
					title: this.translateService.instant('profilUser.vehicule.title'),
					component: ProfilDonneesVehiculeListItemComponent,
					isSimple: true,
					mapResult: (result: Result) => result?.data?.listeVehicule,
					listeActions: [{
						icon: 'add',
						onPress: () => this.createVehicule(),
						disabled: !this.isCollabInit,
						tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
					}],
					extraOptions: {
						settings: this.vehiculeSettings.getValue(),
						isAdmin: true
					}
				});

				//Chargement des alertes à la fin du chargement de la liste des véhicules
				this.listeVehicule.loaded
					.pipe(first())
					.subscribe(() => this.refreshAlertes());
			});

		} else {
			this.addRgprAlerteOnly();
		}
	}

	/**
	 * Ouverture de la popin de création d'un véhicule
	 */
	createVehicule(): void {
		//Chargement de la liste des puissances fiscales
		this.profilService.loadPuissanceFiscale()
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Ouverture de la popin de création d'un véhicule
					this.matDialog.open(ProfilDonneesAddVehiculeComponent,{
						data: {
							listePuissance: result?.data?.listePuissance,
							idUser: this.idUser
						},
						width: '80%'
					}).afterClosed().subscribe((data: { vehicule: Vehicule }) => {
						//Si un véhicule a été créé
						if (data) {
							//Redirection vers la page de ce véhicule
							this.router.navigate(['Admin/Entreprise/Utilisateurs/User',data.vehicule.idCollab,'Vehicule',data.vehicule.idPa]);
						}
					});
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			})
	}

	/**
	 * Affiche l'alerte rgpd
	 */
	addRgprAlerteOnly(): void {
		this.listeAlertes = this.userService.listeAlerteRgpd();

		this.niveauAlerte = NiveauAlerte.RGPD;
	}

	/**
	 * Rafraîchissement des alertes
	 */
	refreshAlertes(): void {
		//Initialisation
		this.listeAlertes = [];
		this.niveauAlerte = null;

		//Chargement des alertes
		this.profilService.loadProfilAlertes(this.idUser)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Gestion des alertes à afficher
					this.handleAlertesAff(result.data as ProfilAlertes);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Gestion de l'affichage des alertes
	 *
	 * @param profilAlertes les alertes du profil
	 */
	handleAlertesAff(profilAlertes: ProfilAlertes): void {
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante) {
			//On filtre la liste pour ne garder que les alertes sur les véhicules
			profilAlertes.saisieObligatoire.listeSaisieManquante = profilAlertes.saisieObligatoire.listeSaisieManquante.filter(saisie => saisie === TypeSaisie.VEHICULE);
		}

		if (profilAlertes?.listeDocumentEchu) {
			//On filtre la liste pour ne garder que les alertes sur les véhicules
			profilAlertes.listeDocumentEchu = profilAlertes.listeDocumentEchu.filter(doc => doc.typeFormulaire === TypeFormulaire.VEHICULE);
		}

		if (profilAlertes?.listeDocumentEcheance) {
			//On filtre la liste pour ne garder que les alertes sur les véhicules
			profilAlertes.listeDocumentEcheance = profilAlertes.listeDocumentEcheance.filter(doc => doc.typeFormulaire === TypeFormulaire.VEHICULE);
		}

		//Gestion des saisies manquantes et des documents échus
		if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length || profilAlertes?.listeDocumentEchu?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerte = Math.max(this.niveauAlerte ?? NiveauAlerte.ERROR,NiveauAlerte.ERROR);

			//S'il y a des saisies manquantes
			if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
				//Passage de la liste des véhicules en erreur
				this.listeVehicule.alertLevel = NiveauAlerte.ERROR;

				//Parcours des saisies manquantes
				for (const saisie of (profilAlertes?.saisieObligatoire?.listeSaisieManquante ?? [])) {
					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.obligatoire." + saisie),
						niveau: NiveauAlerte.ERROR,
					}));
				}
			}

			//S'il y a des documents échus
			if (profilAlertes?.listeDocumentEchu?.length) {
				//Passage de la liste des véhicules en erreur
				this.listeVehicule.alertLevel = NiveauAlerte.ERROR;

				//Parcours des documents échus
				for (const document of (profilAlertes?.listeDocumentEchu ?? [])) {
					//Récupération de la date
					const dateEcheance: Date = new Date(document.dateEcheance);

					//Ajout de l'alerte
					this.listeAlertes.push(new Alerte({
						titre: null,
						message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
							+ this.translateService.instant("profil.alerte.echu." + document.typeFormulaire,{
								libelle: document.libelle,
								numero: document.numero,
								date: this.datePipe.transform(dateEcheance,'shortDate')
							}),
						niveau: NiveauAlerte.ERROR
					}));
				}
			}

		}

		//Gestion des documents arrivant à échéance
		if (profilAlertes?.listeDocumentEcheance?.length) {
			//Définition du niveau d'alerte
			this.niveauAlerte = Math.max(this.niveauAlerte ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Définition du niveau d'alerte de la liste des véhicules
			this.listeVehicule.alertLevel = Math.max(this.listeVehicule.alertLevel ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

			//Parcours des documents arrivant à échéance
			for (const document of profilAlertes?.listeDocumentEcheance) {
				//Définition de la date du jour
				const today: Moment = moment();

				//Récupération de la date
				const dateEcheance: Moment = moment(document.dateEcheance);

				//Ajout de l'alerte
				this.listeAlertes.push(new Alerte({
					titre: null,
					message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
						+ this.translateService.instant("profil.alerte.echeance." + document.typeFormulaire,{
							libelle: document.libelle,
							numero: document.numero,
							date: this.datePipe.transform(dateEcheance.toDate(),'shortDate'),
							nbJours: dateEcheance.diff(today,'days') + 1
						}),
					niveau: NiveauAlerte.WARNING
				}));
			}
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désouscription à l'abonnement
		this.subscription.unsubscribe();
	}
}
