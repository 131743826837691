<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">account_tree</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'admin.workflow.liste.personnes'"></strong>{{ data.nombrePersonnesRattachees }}</li>
            <li><strong [translate]="'admin.workflow.liste.segments'"></strong>{{ data.nombreSegmentsRattaches }}</li>
            <li *ngIf="data.commentaire" (click)="onCommentClick()" class="clickable">
                <strong [translate]="'admin.workflow.liste.commentaire'"></strong>
                <mat-icon style="cursor:pointer;">comment</mat-icon>
            </li>
        </ul>
    </div>
    <div>
        <i class="clickable primary nio icon-suppression" style="font-size:40px;" [matTooltip]="'admin.workflow.liste.supprimer' | translate" matTooltipPosition="left" (click)="onDeleteClick()"></i>
    </div>
</div>
