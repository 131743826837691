<div class="content" *ngIf="etat">
    <page-header [title]="'workflow.etat.title' | translate : {libelle: etat.libelle}"
                 (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [listeItems]="listeTabItems">
    </page-header>
    <card-alerte [niveau]="alerte.niveau" [isCard]="true">
        <preview-alerte [alerte]="alerte"></preview-alerte>
    </card-alerte>

    <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
        <form #form="ngForm" *ngIf="etat">
            <fieldset>
                <mat-card>
                    <mat-card-title>
                        <span [translate]="'workflow.etat.proceduresTravel'"></span>
                    </mat-card-title>
                    <mat-card-content>
                        <fieldset>
                            <div class="row">
                                <custom-input ngDefaultControl name="demande"
                                              customType="multiselect"
                                              libelle="workflow.etat.demande"
                                              [(ngModel)]="listeActionsDemande"
                                              optionValue="id"
                                              optionDisplay="libelle"
                                              [placeholder]="'workflow.etat.aucuneAction' | translate"
                                              [selectOptions]="listeActionsWf">
                                </custom-input>
                                <custom-input ngDefaultControl name="modification"
                                              customType="multiselect"
                                              libelle="workflow.etat.modification"
                                              [(ngModel)]="listeActionsModification"
                                              optionValue="id"
                                              optionDisplay="libelle"
                                              [placeholder]="'workflow.etat.aucuneAction' | translate"
                                              [selectOptions]="listeActionsWf">
                                </custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl name="commande"
                                              customType="multiselect"
                                              libelle="workflow.etat.commande"
                                              [(ngModel)]="listeActionsCommande"
                                              optionValue="id"
                                              optionDisplay="libelle"
                                              [placeholder]="'workflow.etat.aucuneAction' | translate"
                                              [selectOptions]="listeActionsWf">
                                </custom-input>
                                <custom-input ngDefaultControl name="annulation"
                                              customType="multiselect"
                                              libelle="workflow.etat.annulation"
                                              [(ngModel)]="listeActionsAnnulation"
                                              optionValue="id"
                                              optionDisplay="libelle"
                                              [placeholder]="'workflow.etat.aucuneAction' | translate"
                                              [selectOptions]="listeActionsWf">
                                </custom-input>
                            </div>
                        </fieldset>
                    </mat-card-content>
                </mat-card>
            </fieldset>
        </form>
    </div>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
<please-wait class="content" *ngIf="!etat"></please-wait>