import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {TypeAiguillage, TypeNature} from "@domain/voyage/travel/constants";
import {Observable} from "rxjs";
import {TriListeSbtPipe} from "./tri-liste-sbt.pipe";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";
import {ODRseService} from "@components/od/od-rse.service";
import {RseService} from "@services/share/rse/rse.service";
import {MatDialog} from "@angular/material/dialog";
import {OdVoyageTravelRsePopupComponent} from "@components/od/detail/voyage/voyage/travel/popup/voyage/rse/od-voyage-travel-rse-popup.component";

/**
 * Composant de sélection de la nature du trajet
 *
 * @author Laurent SCIMIA
 * @date 18/11/2021
 */
@Component({
	selector: 'voyage-popup-voyage',
	templateUrl: './od-voyage-travel-popup-voyage.component.html',
	styleUrls: ['./od-voyage-travel-popup-voyage.component.scss'],
	providers: [TriListeSbtPipe]//Pipe de tri de la liste des SBT
})
export class OdVoyageTravelPopupVoyageComponent {
	/** Accès à l'enum des types nature */
	readonly TypeNature = TypeNature;
	readonly TypeAiguillage = TypeAiguillage;

	/** Liste des SBT disponibles */
	@Input()
	listeSbt: Observable<SynchroSBTConfigUser[]>;

	/** SBT sélectionné dans la liste */
	@Output()
	niveauNatureSelectionne = new EventEmitter<NiveauNature>();

	/** Liste des regroupements par nature des SBT */
	@Input()
	listeSbtParNature: Observable<Array<NiveauNature>>;

	/**
	 * Constructeur
	 *
	 * @param odRseService	Service du RSE
	 * @param matDialog		Service de popup
	 */
	constructor(public odRseService: ODRseService,
				private matDialog: MatDialog) {
	}

	/**
	 * Sélection d'un regroupement par nature pour le trajet
	 *
	 * @param niveauNature Regroupement par nature sélectionné
	 */
	selectionne(niveauNature: NiveauNature) {
		//On remonte l'info
		this.niveauNatureSelectionne.emit(niveauNature);
	}

	/**
	 * Ouverture de la popup de détail de l'équivalent CO2
	 */
	openPopupRse(): void {
		this.odRseService.openPopupRse(this.odRseService.libelleOrigine, this.odRseService.libelleDestination);
	}
}