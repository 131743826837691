import {ListItem} from "../../common/list-view/list-item";

/**
 * Modèle représentant un segment wf
 */
export class Segment implements ListItem {
	idSegment: number;
	libelle: string;
	actif: boolean;
	formulaireDepart: Portee;
	formulaireModifie: Portee;
	statutFormulaireDepart: Statut;
	statutDebutFormulaireModifie: Statut;
	statutFinFormulaireModifie: Statut;
	conditionFormulaireDepart: Condition;
	conditionFormulaireModifie: Condition;
	role: Role;
	action: number;
	groupement: boolean;
	choixAppro: boolean;
	libelleAction: string;
	libelleBouton: string;
	softAction: number;
	actionsSupplementaires: number[];
	saisieMotif: boolean;
	saisieMotifObligatoire: boolean;
	message: Message;
	mailObligatoire: boolean;
	commentaire: string;
	workflow: Workflow;
	lstActionSupp: string;
	segmentSourceId: number;
	withMail: boolean;
	actionBudget: number;
	eltAction: number;

	constructor(dto?: any) {
		if (dto) {
			this.idSegment = dto.idSegment;
			this.libelle = dto.libelle;
			this.actif = dto.actif;
			this.formulaireDepart = dto.formulaireDepart?.idPortee ? new Portee(dto.formulaireDepart) : dto.formulaireDepart;
			this.formulaireModifie = dto.formulaireModifie?.idPortee ? new Portee(dto.formulaireModifie) : dto.formulaireModifie;
			this.statutFormulaireDepart = dto.statutFormulaireDepart as Statut;
			this.statutDebutFormulaireModifie = dto.statutDebutFormulaireModifie as Statut;
			this.statutFinFormulaireModifie = dto.statutFinFormulaireModifie as Statut;
			if (dto.conditionFormulaireDepart?.libelle) { this.conditionFormulaireDepart = new Condition(dto.conditionFormulaireDepart); } else { this.conditionFormulaireDepart = dto.conditionFormulaireDepart; }
			if (dto.conditionFormulaireModifie?.libelle) { this.conditionFormulaireModifie = new Condition(dto.conditionFormulaireModifie); } else { this.conditionFormulaireModifie = dto.conditionFormulaireModifie; }
			if (dto.role) { this.role = new Role(dto.role); }
			this.action = dto.action;
			this.groupement = dto.groupement;
			this.choixAppro = dto.choixAppro;
			this.libelleAction = dto.libelleAction;
			this.libelleBouton = dto.libelleBouton;
			this.softAction = dto.softAction;
			this.actionsSupplementaires = dto.actionsSupplementaires;
			this.saisieMotif = dto.saisieMotif;
			this.saisieMotifObligatoire = dto.saisieMotifObligatoire;
			if (dto.message) { this.message = new Message(dto.message); }
			this.mailObligatoire = dto.mailObligatoire;
			this.commentaire = dto.commentaire;
			if (dto.workflow) this.workflow = dto.workflow as Workflow;
			this.segmentSourceId = dto.segmentSourceId;
			this.withMail = dto.withMail;
			this.lstActionSupp = dto.lstActionSupp;
			this.actionBudget = dto.actionBudget;
			this.eltAction = dto.eltAction;
		}
	}

	getKey(): number { return this.idSegment; }
}

export class Portee {
	idPortee: Formulaire;

	constructor(dto?: any) {
		if (dto) {
			this.idPortee = dto.idPortee as Formulaire;
		}
	}
}

/**
 * Enum des formulaires possibles pour un segment
 */
export enum Formulaire {
	AV = "AV",
	DV = "DV",
	FC = "FC",
	NF = "NF",
	OD = "OD",
	OT = "OT"
}

/**
 * Classe de stockage d'un statut
 */
export class Statut {
	idStatut: number;
	idEtat: number;
	libelle: string;
	code: string;

	constructor(dto?: any) {
		if (dto) {
			this.idStatut = dto.idStatut || 0;
			this.idEtat = dto.idEtat;
			this.libelle = dto.libelle;
			this.code = dto.code || null;
		}
	}
}

/**
 * Classe de stockage d'une condition
 */
export class Condition {
	id: number;
	libelle: string;

	constructor(dto?: any) {
		if (dto) {
			this.id = dto.id || 0;
			this.libelle = dto.libelle;
		}
	}
}

/**
 * Classe de stockage d'une action
 */
export class Action {
	id: number;
	libelle: string;

	constructor(dto?: any) {
		if (dto) {
			this.id = dto.id || 0;
			this.libelle = dto.libelle;
		}
	}
}

/**
 * Classe de stockage d'un workflow
 */
export class Workflow implements ListItem {
	id: number;
	libelle: string;
	commentaire: string;
	nombrePersonnesRattachees: number;
	nombreSegmentsRattaches: number;

	constructor(dto?: any) {
		if (dto) {
			this.id = dto.id || 0;
			this.libelle = dto.libelle;
			this.commentaire = dto.commentaire;
			this.nombrePersonnesRattachees = dto.nombrePersonnesRattachees;
			this.nombreSegmentsRattaches = dto.nombreSegmentsRattaches;
		}
	}

	getKey(): number { return this.id; }
}

/**
 * Classe de stockage d'un rôle
 */
export class Role {
	idRole: number;
	libelle: string;
	fonction: number;

	constructor(dto?: any) {
		if (dto) {
			this.idRole = dto.idRole || 0;
			this.libelle = dto.libelle;
			this.fonction = dto.fonction;
		}
	}
}

/**
 * Classe de stockage d'un message
 */
export class Message {
	idMessage: number;
	titre: string;
	titreAffichage: string;
	globalise: boolean;

	constructor(dto?: any) {
		if (dto) {
			this.idMessage = dto.idMessage || 0;
			this.titre = dto.titre;
			this.titreAffichage = dto.titreAffichage;
			this.globalise = dto.globalise;
		}
	}
}

/**
 * Classe de stockage d'une notification
 */
export class MailTo implements ListItem {
	id: number;
	message: Message;
	role: Role;
	priorite: number;
	manuel: boolean;
	globalise: boolean;
	segment: Segment;

	constructor(dto?: any) {
		if (dto) {
			this.id = dto.id || 0;
			if (dto.message) { this.message = new Message(dto.message); }
			if (dto.role) { this.role = new Role(dto.role); }
			this.manuel = dto.manuel;
			this.globalise = dto.globalise;
			this.priorite = dto.priorite;
			if (dto.segment) { this.segment = dto.segment; }
		}
	}

	getKey(): number { return this.id; }
}
