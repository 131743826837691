import {Injectable} from '@angular/core';
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypeProfil,User} from "@domain/user/user";
import {Population} from "@domain/admin/entreprise/population/population";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {tap} from "rxjs/operators";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Service lié à un composant pour les populations
 *
 * @author Lionel Gimenez
 * @date 10/12/2024
 */
@Injectable()
export class EntreprisePopulationService {

    //Utilisateur connecté
    private connectedUser: User;

    //La population actuelle
    private population: Population;

    //Indicateur du chargement de la population
    isLoaded: boolean;

    /**
     * Getter de l'indicateur de can modifier
     */
    getCanModifier(): boolean {
        //Si c'est l'administrateur ou le propriétaire de la population ou sous admin avec le droit administration des populations
        return this.population != null
            && (this.connectedUser.fonction === TypeProfil.ADMINISTRATEUR
                || this.population.idUser === this.connectedUser.idUser
                || (this.connectedUser.fonction === TypeProfil.SOUS_ADMINISTRATEUR
                    && this.connectedUser.listeDroits?.some(da => da === DroitAdmin.DROIT_POPULATION_ADMIN)));
    }

    /**
     * Constructeur
     */
    constructor(
        private store: Store<AppState>,
        private populationsService: PopulationsService,) {
        this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe(user => {
            //On récupère l'utilisateur
            this.connectedUser = user;
        });
    }

    /**
     * Retourne la population en chargeant le service
     * @param idPopulation id de la population
     */
    getPopulation(idPopulation: number): Observable<Result> {
        return this.populationsService.getPopulation(idPopulation).pipe(
            tap(result => {
                //On récupère la population
                this.population = result.data.population;
                //Et on indique c'est chargé
                this.isLoaded = true;
            }))
    }
}