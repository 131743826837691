<form #form="ngForm">
    <mat-card *ngIf="population != null">
        <mat-card-title>
            <span *ngIf="!isCreation">{{ population.libelle }}</span>
            <span *ngIf="isCreation" [translate]="'admin.entreprise.populations.generalites.title'">
                        </span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="population.libelle"
                                  customType="input"
                                  libelle="admin.entreprise.populations.generalites.libelle"
                                  [readonly]="!canModifie"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="actif"
                                  name="actif"
                                  [ngModel]="population.actif"
                                  [(customModel)]="population.actif"
                                  customType="checkbox"
                                  [readonly]="!canModifie"
                                  libelle="admin.entreprise.populations.generalites.actif">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="reference"
                                  name="reference"
                                  [(ngModel)]="population.reference"
                                  customType="input"
                                  [readonly]="!canModifie"
                                  libelle="admin.entreprise.populations.generalites.reference">
                    </custom-input>

                    <!-- Origine-->
                    <label class="col-md-2">
                        <span translate>admin.entreprise.populations.generalites.origine</span>
                    </label>
                    <div class="col-md-4 d-flex justify-content-center mb-2">
                        <span>{{origine}}</span>
                    </div>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="remarque"
                                  name="remarque"
                                  [(ngModel)]="population.remarque"
                                  customType="textarea"
                                  [readonly]="!canModifie"
                                  libelle="admin.entreprise.populations.generalites.remarque">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</form>
<floating-button *ngIf="canModifie" [listeActions]="listeActions"></floating-button>

