<form *ngIf="segmentBuffer">
    <fieldset>
        <mat-card>
            <mat-card-title>
                <span translate="admin.workflow.construction.segment.onglets.informations.title"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="commentaire"
                            name="commentaire"
                            [(ngModel)]="segment.commentaire"
                            customType="textarea"
                            rCol="10"
                            libelle="admin.workflow.construction.segment.onglets.informations.commentaire">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <list-view [liste]="liste" *ngIf="displayListe && liste"></list-view>
        <mat-card *ngIf="!displayListe">
            <mat-card-title>
                <span>{{ getListeTitle() }}</span>
            </mat-card-title>
            <mat-card-content style="text-align: center;">
                <span translate="admin.workflow.construction.segment.onglets.informations.attenteWorkflow"></span>
            </mat-card-content>
        </mat-card>
    </fieldset>
</form>
<mat-spinner diameter="20" *ngIf="!segmentBuffer"></mat-spinner>
<floating-button [listeActions]="listeFloatingButtonActions"></floating-button>
