import {Component,OnInit} from "@angular/core";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {ListeWfItemComponent} from "@components/admin/workflow/construction/liste-wf/liste-wf-item.component";
import {Sorting} from "@domain/common/list-view/sorting";
import {Workflow} from "@domain/admin/workflow/segment";
import {MatDialog} from "@angular/material/dialog";
import {WorkflowPopupComponent} from "@components/admin/workflow/construction/liste-wf/workflow-popup.component";

/**
 * Onglet construction WF
 *
 * @author Guillaume TRAVOT
 * @date 26/04/2024
 */
@Component({
    host: {'data-test-id': 'liste-wf'},
    selector: 'liste-wf',
    templateUrl: './liste-wf.component.html'
})
export class ListeWfComponent implements OnInit {
    /** Liste des workflow */
    listeWorkflow: ListView<Workflow,ListeWfItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,private router: Router,private matDialog: MatDialog) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Définition de la liste
        this.listeWorkflow = new ListView<Workflow,ListeWfItemComponent>({
            uri: '/controller/Workflow/listeWorkflow',
            title: this.translateService.instant('admin.workflow.liste.title'),
            component: ListeWfItemComponent,
            defaultOrder: 'libelle',
            isFilter: true,
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('workflow.roles.libelle'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                }
            ],
            mapResult: (result) => {
                result.listeResultats = result.listeResultats?.map(r => new Workflow(r));
                return result;
            }
        });

        //Liste des colonnes triables
        this.listeWorkflow.columns = [
            {key: 'libelle',title: 'workflow.roles.libelle'},
        ]

        //On initialise le tri
        this.listeWorkflow.sorting = new Sorting(this.listeWorkflow.columns,'libelle');

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.SECONDARY,
            icone: 'material-icons-outlined',
            iconeName: 'view_timeline',
            libelle: 'admin.workflow.liste.visualiser',
            doAction: () => this.visualisationWorkflow(),
        },{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.addWorkflow(),
        }]);
    }

    /**
     * Visualisation du Workflow
     */
    visualisationWorkflow() {
        this.router.navigate(['Admin/Workflow/Construction/Visualisation']);
    }

    /**
     * Ajout d'un workflow
     */
    addWorkflow() {
        //Ouverture de la boîte de dialogue
        this.matDialog.open(WorkflowPopupComponent, {
            width: '80%'
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste
                this.listeWorkflow.refresh && this.listeWorkflow.refresh();
            }
        });
    }
}
