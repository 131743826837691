<page-header
        [title]="'admin.workflow.construction.segment.title' | translate"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        [niveauAlerte]="segmentBuffer?.commentaire ? alerte?.niveau : null"
        (onGoBack)="onGoBack()">
        <preview-alerte *ngIf="segmentBuffer?.commentaire" [alerte]="alerte"></preview-alerte>
</page-header>

<div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
    <please-wait *ngIf="!segment"></please-wait>
    <segment-generalites #segmentGeneralitesComponent *ngIf="segment" [segment]="segment" [listeActions]="listeActions" [listeFloatingButtonActions]="listeFloatingButtonActions"></segment-generalites>
</div>

<div [hidden]="selectedItem?.code !== Onglets.INFORMATIONS">
    <please-wait *ngIf="!segment"></please-wait>
    <segment-informations *ngIf="segment" [segment]="segment" [segmentBuffer]="segmentBuffer" [listeFloatingButtonActions]="listeFloatingButtonActions"></segment-informations>
</div>
