import {Component,Input} from "@angular/core";
import {ModuleItem,TypeModule} from "@domain/admin/parametre/demat/module-item";
import {EnvironnementType} from "@components/admin/parametre/demat/module/demat-module.component";

/**
 * Composant d'affichage d'un module de la page Dématérialisation.
 *
 * @author François Turin
 * @date 27/06/2023
 */
@Component({
	host: {'data-test-id': 'module-item'},
	selector: 'module-item',
	templateUrl: './module-item.component.html'
})
export class ModuleItemComponent {
	@Input() moduleItem: ModuleItem;

	get statut(): string {
		if (this.moduleItem.type === TypeModule.LICENCE || this.moduleItem.type === TypeModule.RSE || this.moduleItem.type === TypeModule.ANTIVIRUS) {
			return this.moduleItem.statut ? "admin.parametres.demat.modules.actif" : "admin.parametres.demat.modules.inactif";
		} else if (this.moduleItem.type === TypeModule.ENV) {
			return "admin.parametres.demat.modules.typeEnvironnement." + this.moduleItem.statut;
		} else if (this.moduleItem.type === TypeModule.MAIL) {
			return String(this.moduleItem.statut);
		} else if (this.moduleItem.type === TypeModule.GED) {
			return this.moduleItem.statut ? "admin.parametres.demat.modules.cloud" : "admin.parametres.demat.modules.noCloud";
		}
	}

	get statutIcon(): string {
		if (this.moduleItem.type === TypeModule.LICENCE || this.moduleItem.type === TypeModule.GED || this.moduleItem.type === TypeModule.RSE || this.moduleItem.type === TypeModule.ANTIVIRUS) {
			return this.moduleItem.statut ? "cloud_done" : "cloud_off";
		} else if (this.moduleItem.type === TypeModule.ENV) {
			return this.moduleItem.statut === EnvironnementType.PROD ? "cloud_done" : (this.moduleItem.statut === EnvironnementType.TEST ? "cloud" : "cloud_off");
		} else if (this.moduleItem.type === TypeModule.MAIL) {
			return this.moduleItem.statut === "API" ? "cloud_done" : "cloud";
		}
	}

	get statutClass(): string {
		if (this.moduleItem.type === TypeModule.LICENCE || this.moduleItem.type === TypeModule.GED || this.moduleItem.type === TypeModule.RSE || this.moduleItem.type === TypeModule.ANTIVIRUS) {
			return this.moduleItem.statut ? "success" : "danger";
		} else if (this.moduleItem.type === TypeModule.ENV) {
			return this.moduleItem.statut === EnvironnementType.PROD ? "success" : (this.moduleItem.statut === EnvironnementType.TEST ? "warning" : "error");
		} else if (this.moduleItem.type === TypeModule.MAIL) {
			return this.moduleItem.statut === "API" ? "success" : "warning";
		}
	}
}