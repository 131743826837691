import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CoreComponentsModule } from '../core-components.module';
import { RowDataComponent } from './row-data.component';

@NgModule({
	imports: [CoreComponentsModule, TranslateModule],
	declarations: [RowDataComponent],
	exports: [RowDataComponent]
})
export class RowDataModule {
}