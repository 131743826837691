import {Injectable} from '@angular/core';
import {Actions,Effect,ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Observable,throwError,timer} from 'rxjs';
import {concatMap,first,map,retryWhen,take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

import {CHANGE_PROFIL,PROFIL_CHANGED,SESSION_FULFILLED,UPDATE_USER} from '@reducers/session';
import {Action} from '@domain/action';
import {Session} from '@domain/security/session';
import {environment} from '@environments/environment';
import {AppState} from '@domain/appstate';
import {PleaseWaitDialogComponent} from '../component/please-wait/please-wait-dialog.component';
import {PleaseWaitService} from '../component/please-wait/please-wait.service';
import {CLEAR_SETTINGS} from "@reducers/settings";
import {LoginService} from "./login.service";
import {AuthFactory,LoginType} from "@domain/security/auth";
import {Result} from "@domain/common/http/result";
import {AuthState,LOAD_AUTH,SET_AUTH} from "@reducers/auth";
import {TypeProfil} from "@domain/user/user";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class LoginEffects {
    /**
     * Constructeur
     */
    constructor(private actions$: Actions,
                private store: Store<AppState>,
                private router: Router,
                private http: HttpClient,
                private loginService: LoginService,
                private translateService: TranslateService,
                private pleaseWaitService: PleaseWaitService,
                private toastrService: ToastrService) {
    }

    /**
     * Chargement initial
     */
    @Effect({ dispatch: false })
    intialLoad$: Observable<Action<any>> = this.actions$.pipe(
        ofType(SESSION_FULFILLED),
        map((action: Action<any>) => {
            let session: Session;

            //Lecture de la session
            session = action.payload as Session;

            //Vérification de l'absence de redirection
            if (!session.isLogged || session.isLogged && !session.isAdmin && !session.isPasswordExpired) {
                //Vérification si l'utilisateur est logué (et non sous-admin) ou non
                if (session.isLogged && !session.isSousAdmin) {
                    //Utilisateur authentifié : redirection vers le dashboard
                    //(Nécessaire même dans le cas d'une redirection post-login car cela permet de charger l'utilisateur et ses droits. TODO 2077 : à améliorer)
                    this.router.navigate(['Dashboard']);
                } else if (!session.isLogged) {
                    //Si l'utilisateur n'est pas authentifié dans le cas du mode "LOCAL" ou mode local forcé redirection vers l'écran de login, sinon redirection sur l'écran informant de la déconnexion pour éviter une boucle
                    this.router.navigate([this.loginService.getAuth().loginType == LoginType.LOCAL ? 'Login' : session.loginLocal ? 'SSO/LoginLocal' : 'SSO/Logout']);
                }
            } else if (session.isPasswordExpired) {
                //Le mdp est expiré, on retourne au login pour le modifier
                this.router.navigate(['Login']);
            } else if (session.isAdmin && !session.redirect) {
                //Bascule sur l'accueil d'admin sauf s'il y a une redirection post-login, dans ce cas elle sera effectuée dans layout.effects.ts
                this.router.navigate(['Admin']);
            }

            //Vérification de la déconnexion
            if (!session.isLogged) {
                //Déconnexion du serveur
                this.http.post(`${environment.baseUrl}/servlet/NDFServlet?action=Logout`, null,{ responseType: 'text' }).subscribe();

                //Suppression des settings du store
                this.store.dispatch({
                    type: CLEAR_SETTINGS,
                    payload: {}
                });
            }

            //Retour de la connexion
            return action;
        })
    );

    /**
     * Changement de profil
     */
    @Effect({ dispatch: false })
    changeProfil$: Observable<Action<any>> = this.actions$.pipe(
        ofType(CHANGE_PROFIL),
        map((action: Action<any>) => {
            let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

            //Sélection de l'identifiant de l'utilisateur
            this.store.select<Session>(s => s.session).pipe(take(1)).subscribe(session => {
                let user = session.user;

                //On vérifie que le profil demandé n'est pas déjà celui actif
                if (user.fonction != action.payload) {
                    //On vérifie que l'utilisateur a bien une habilitation active pour ce profil
                    this.http.get<Result>(`${environment.baseUrl}/controller/Perso/checkUserHabilitation/${action.payload}`)
                        .pipe(first())
                        .subscribe((result: Result) => {
                            //Si le flag retourné est true, c'est ok
                            if (result.data.isOk) {
                                //Si le nouveau profil est de type Sous-Admin
                                if (action.payload == TypeProfil.SOUS_ADMINISTRATEUR) {
                                    //Affichage temporaire d'une page vide pour éviter le chargement du Dashboard (route par défaut sinon)
                                    this.router.navigate(['Blank']);
                                }

                                //On commence par retirer le user de la session pour qu'il ne soit pas utilisé à tort
                                this.store.dispatch({
                                    type: UPDATE_USER,
                                    payload: null
                                });

                                //Récupération de la traduction
                                this.translateService.get('login.changementProfil').subscribe(title => {
                                    //Affichage de la popup d'attente
                                    matDialogRef = this.pleaseWaitService.show({title});

                                    //Suppression des settings du store avant le changement de profil pour que les settings soient correctement rechargés au chargement du dashboard
                                    this.store.dispatch({
                                        type: CLEAR_SETTINGS,
                                        payload: {}
                                    });

                                    //Changement de profil
                                    this.http.post(`${environment.baseUrl}/servlet/NDFServlet?action=Login&id_user=${user.idUser}&fct=${action.payload}`,null,{
                                        responseType: 'text'
                                    }).pipe(take(1)).subscribe({
                                        complete: () => {
                                            //Rechargement de l'utilisateur
                                            this.store.dispatch({
                                                type: PROFIL_CHANGED,
                                                payload: {session,matDialogRef}
                                            });
                                        }
                                    });
                                });
                            } else {
                                //Log error
                                this.toastrService.error(this.translateService.instant('global.errors.noHabilitation'));
                            }
                        });
                }
            });

            return action;
        })
    )

    /**
     * Chargement du type de login (Local / SAML (sso))
     */
    @Effect({ dispatch: false })
    loadAuth$: Observable<Action<any>> = this.actions$.pipe(
        ofType(LOAD_AUTH),
        map((action: Action<any>) => {
            this.store.select<AuthState>(s => s.auth).pipe(first()).subscribe(state => {
                if (state?.auth === null) {
                    this.store.dispatch({
                        type: SET_AUTH,
                        payload: undefined
                    });

                    this.http.post<Result>(`${environment.baseUrl}/controller/Auth/infos`,null)
                        //Attente d'une réponse OK en 60s maximum (12 essais toutes les 5 secondes)
                        .pipe(retryWhen(concatMap((err, index) => index < 12 ? timer(5000) : throwError(err))))
                        .subscribe((result) => {
                            //Construction du mode de connexion à partir de la réponse
                            this.store.dispatch({
                                type: SET_AUTH,
                                payload: AuthFactory(result.data)
                            });
                        },
                        () => {
                            this.store.dispatch({
                                type: SET_AUTH,
                                payload: null
                            });
                        });
                }
            });

            return action;
        }));
}
