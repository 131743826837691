<div class="with-floating-button">
    <div class="content">
        <page-header *ngIf="isCreation" [title]="'admin.frais.referentiels.infos.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [isPending]="isSaving">
        </page-header>
        <page-header *ngIf="!isCreation" [title]="'admin.frais.referentiels.infos.titleModification' | translate : { libelle: referentiel?.libelle || '' }" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [isPending]="isSaving">
        </page-header>

        <form ngDefaultControl name="adm-frais-ref" autocomplete="off" #form="ngForm">
            <please-wait *ngIf="!referentiel"></please-wait>
            <div *ngIf="referentiel" [hidden]="selectedItem?.code !== Onglet.REFERENTIEL">
                <mat-card>
                    <mat-card-title>
                        <span [translate]="'admin.frais.referentiels.infos.title'"></span>
                    </mat-card-title>
                    <mat-card-content>
                        <fieldset>
                            <!-- On attend que les listes aient été initialisées puis on les envoie au composant -->
                            <filling-row *ngIf="!isTypeChanged && (listeTemplatesGauche.length > 0 || listeTemplatesDroite.length > 0)" [listeGauche]="listeTemplatesGauche"
                                         [listeDroite]="listeTemplatesDroite"></filling-row>
                        </fieldset>
                    </mat-card-content>
                </mat-card>
            </div>

            <div [hidden]="selectedItem?.code !== Onglet.USAGE">
                <frais-refrentiel-usage
                        *ngIf="this.referentiel?.type != null"
                        [typeReferentiel]="referentiel.type"
                        [idReferentiel]="referentiel.id"
                ></frais-refrentiel-usage>
            </div>

            <ng-template #tplType>
                <custom-input name="Type"
                              id="Type"
                              customType="select"
                              libelle="admin.frais.referentiels.infos.type"
                              [(ngModel)]="referentiel.type"
                              (onChange)="changeType()"
                              optionValue="code"
                              optionDisplay="libelle"
                              [selectOptions]="listeTypeReferentiel"
                              ngDefaultControl
                              [readonly]="!isCreation"
                              [required]="true"></custom-input>
            </ng-template>
            <ng-template #tplIcone>
                <label class="col-md-2"
                       [class.has-error]="(referentiel.type === TypeReferentiel.FAMILLE || referentiel.type === TypeReferentiel.INDEMNITE) && !referentiel.icone">
                    <span [translate]="'admin.frais.referentiels.infos.icone'"></span>
                    <span *ngIf="(referentiel.type === TypeReferentiel.FAMILLE || referentiel.type === TypeReferentiel.INDEMNITE) && !referentiel.icone">*</span>
                </label>
                <div class="col-md-1 py-3 r-flex">
                    <i class="material-icons clickable" (click)="onSelect()">{{ referentiel.icone ? referentiel.icone : 'search' }}</i>
                    <mat-icon class="material-icons mb-md-2 ml-2" [matTooltip]="'admin.frais.referentiels.infos.tips.icone' | translate" [inline]="true">
                        info_outline
                    </mat-icon>
                </div>
            </ng-template>
            <ng-template #tplLibelle>
                <custom-input [(ngModel)]="referentiel.libelle"
                              id="libelle"
                              name="libelle"
                              customType="input"
                              libelle="admin.frais.referentiels.infos.libelle"
                              [maxlength]="referentiel.type == TypeReferentiel.FAMILLE ? 30 : 50"
                              [required]="true"
                              ngDefaultControl>
                </custom-input>
            </ng-template>
            <ng-template #tplOrdre>
                <custom-input [(ngModel)]="referentiel.ordre"
                              id="ordre"
                              name="ordre"
                              customType="number"
                              [min]="referentiel.ordre != null ? 0 : null"
                              libelle="admin.frais.referentiels.infos.ordre"
                              rCol="1"
                              ngDefaultControl>
                </custom-input>
            </ng-template>
            <ng-template #tplDistance>
                <custom-input [(ngModel)]="referentiel.code"
                              id="code"
                              name="code"
                              customType="select"
                              libelle="admin.frais.referentiels.infos.codeDistance"
                              optionValue="code"
                              optionDisplay="libelle"
                              [selectOptions]="listeTypesDistance"
                              ngDefaultControl></custom-input>
            </ng-template>
            <ng-template #tplCode2>
                <custom-input [(ngModel)]="referentiel.code"
                              id="code2"
                              name="code2"
                              customType="select"
                              libelle="admin.frais.referentiels.infos.codeRubrique"
                              [selectOptions]="listeNaturesOCR"
                              optionValue="code"
                              optionDisplay="libelle"
                              [isTranslated]="false"
                              ngDefaultControl></custom-input>
            </ng-template>
            <ng-template #tplCode3>
                <custom-input [(ngModel)]="referentiel.code"
                              id="code3"
                              name="code3"
                              customType="input"
                              libelle="admin.frais.referentiels.infos.code"
                              ngDefaultControl></custom-input>
            </ng-template>
        </form>

    </div>
    <floating-button *ngIf="!!referentiel && listeActions?.value" [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
