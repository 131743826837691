import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {MailTo,Message,Segment} from "@domain/admin/workflow/segment";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {Role} from "@domain/workflow/role";
import {TranslateService} from "@ngx-translate/core";
import {first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

/**
 * Composant de popup pour la modification d'une notification
 */
@Component({
    selector: 'mail-to-popup',
    host: {'data-test-id': 'mail-to-popup'},
    templateUrl: './mail-to-popup.component.html'
})
export class MailToPopupComponent implements OnInit {
    /** Notification concernée */
    mailTo: MailTo;

    /** Liste des rôles possibles */
    listeRoles: Role[] = [];

    /** Liste des messages possibles */
    listeMessages: Message[] = [];

    /** Liste des priorités possibles */
    listePriorite = [{
        priorite: 1,
        libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.priorite.1')
    },{
        priorite: 2,
        libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.priorite.2')
    }];

    /** Liste des destinataires possibles */
    listeDestinataire = [{
        manuel: true,
        libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.destinataire.manuel')
    },{
        manuel: false,
        libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.destinataire.automatique')
    }];

    /**
     * Constructeur
     */
    constructor(
        private workflowAdminService: WorkflowAdminService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private dialogRef: MatDialogRef<MailToPopupComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { mailTo: MailTo, listeRoles: Role[], segmentId: number }
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Si une notification est fournie en paramètre
        if (this.data?.mailTo) {
            //Récupération de la notification fournie
            this.mailTo = new MailTo(this.data.mailTo);
        } else {
            //Création d'une nouvelle notification
            this.mailTo = new MailTo({
                idMailTo: undefined,
                message: undefined,
                role: undefined,
                priorite: 1,
                manuel: false,
                globalise: false
            });
        }

        //Peuplement de l'ID du segment
        this.mailTo.segment = new Segment({ idSegment: this.data.segmentId });

        //Récupération de la liste des rôles (économise un appel backend)
        this.listeRoles = this.data.listeRoles;

        //Récupération de la liste des messages possibles
        this.workflowAdminService.getMessages().pipe(first()).subscribe(listeMessages => {
            //Ajout des messages
            this.listeMessages = listeMessages;

            //Parcours des messages
            for (let i = 0 ; i < this.listeMessages.length ; i++) {
                //Ajout d'un label " - Message globalisé" au titre pour affichage (si besoin)
                this.listeMessages[i].titreAffichage = this.listeMessages[i].titre + (this.listeMessages[i].globalise ? this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.messageGlobalise') : '');
            }
        });
    }

    /**
     * Détection d'un changement sur le rôle
     */
    onRoleChange(): void {
        //Débrayage du cycle standard pour forcer la MAJ
        setTimeout(() => this.mailTo.role = this.listeRoles.find(r => r.idRole == this.mailTo.role.idRole));
    }

    /**
     * Suppression de la notification
     */
    supprimer(): void {
        this.workflowAdminService.deleteMailToPopup(this.mailTo.id).pipe(first()).subscribe(result => {
            //Vérification du code d'erreur
            if (result.codeErreur == 0 || !result.codeErreur) {
                //Fermeture de la popup
                this.dialogRef.close(true );

                //Message de réussite
                this.toastrService.success(this.translateService.instant('global.success.suppression'));
            } else if (result.codeErreur == 2) {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurSuppressionDependance'));
            } else {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurSuppression'));
            }
        });
    }

    /**
     * Duplication de la notification
     */
    dupliquer(): void {
        this.workflowAdminService.duplicateMailTo(this.mailTo).pipe(first()).subscribe(result => {
            //Vérification du code d'erreur
            if (result.codeErreur == 0 || !result.codeErreur) {
                //Fermeture de la popup
                this.dialogRef.close(true );

                //Message de réussite
                this.toastrService.success(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.duplicationSucces'));
            } else if (result.codeErreur == 6) {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurDuplicationDoublon'));
            } else {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurDuplication'));
            }
        });
    }

    /**
     * Enregistrement de la notification
     */
    enregistrer(): void {
        this.workflowAdminService.saveMailTo(this.mailTo).pipe(first()).subscribe(result => {
            //Vérification du code d'erreur
            if (result.codeErreur == 0 || !result.codeErreur) {
                //Fermeture de la popup
                this.dialogRef.close(true );

                //Message de réussite
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
            } else if (result.codeErreur == 6) {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurSaveDoublon'));
            } else {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.erreurSave'));
            }
        });
    }
}
