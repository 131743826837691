<h1 mat-dialog-title>
    <span translate>admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<form #mailToForm="ngForm">
    <div mat-dialog-content>
        <fieldset *ngIf="mailTo">
            <div class="row">
                <custom-input
                        *ngIf="listeMessages"
                        ngDefaultControl
                        id="messsage"
                        name="messsage"
                        customType="objectselect"
                        [(ngModel)]="mailTo.message"
                        [selectOptions]="listeMessages"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.message"
                        optionValue="idMessage"
                        optionDisplay="titreAffichage"
                        [isTranslated]="false"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        *ngIf="listeRoles"
                        ngDefaultControl
                        id="role"
                        name="role"
                        customType="objectselect"
                        [(ngModel)]="mailTo.role"
                        [selectOptions]="listeRoles"
                        (onChange)="onRoleChange()"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.role"
                        optionValue="idRole"
                        optionDisplay="libelle"
                        [isTranslated]="false"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="priorite"
                        name="priorite"
                        customType="select"
                        [(ngModel)]="mailTo.priorite"
                        [selectOptions]="listePriorite"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.priorite.title"
                        optionValue="priorite"
                        optionDisplay="libelle"
                        [isTranslated]="false"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="destinataire"
                        name="destinataire"
                        customType="select"
                        [(ngModel)]="mailTo.manuel"
                        [selectOptions]="listeDestinataire"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.destinataire.title"
                        [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.destinataire.manuelTooltip' | translate"
                        optionValue="manuel"
                        optionDisplay="libelle"
                        [isTranslated]="false"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="globalise"
                        name="globalise"
                        customType="checkbox"
                        [ngModel]="mailTo.globalise"
                        [(customModel)]="mailTo.globalise"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.grouper.title"
                        postLibelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.grouper.postLibelle"
                        [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.grouper.tooltip' | translate">
                </custom-input>
            </div>
        </fieldset>
        <please-wait *ngIf="!mailTo"></please-wait>
    </div>
    <div mat-dialog-actions align="end">
        <button *ngIf="mailTo.id" mat-stroked-button color="primary" type="submit" (click)="supprimer()">
            <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.supprimer'"></span>
        </button>
        <button *ngIf="mailTo.id" mat-flat-button color="primary" type="submit" (click)="dupliquer()">
            <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.dupliquer'"></span>
        </button>
        <button mat-flat-button color="primary" type="submit" (click)="enregistrer()" [disabled]="!mailToForm.form.valid">
            <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mailToPopup.enregistrer'"></span>
        </button>
    </div>
</form>
