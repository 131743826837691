import { AfterViewChecked, Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Etape } from "@domain/od/etape";
import { SaisieEtapeDTO } from "@domain/travel/saisie-etape-dto";
import { TypePresta } from '@domain/travel/type-presta';
import { NatureVoyage } from "@domain/voyage/nature/nature-voyage";
import { TypeNature } from "@domain/voyage/travel/constants";
import * as moment from "moment";
import { ODService } from "../../../od.service";
import { setTimeout } from "core-js";
import {finalize} from "rxjs/operators";
import {ODRseService} from "@components/od/od-rse.service";

@Component({
    host: {'data-test-id': 'voyage-item'},
    selector: 'voyage-item',
    templateUrl: './od-voyage-item.component.html',
    styleUrls: ['./od-voyage-item.component.scss']
})
export class ODVoyageItemComponent implements OnInit {
    /* Déclaration des Enum pour accès dans le HTML */
    TypePresta: typeof TypePresta = TypePresta;

    /** Étape courante */
    @Input() data: Etape;

    /** Liste de toutes les natures de l'OD de l'étape */
    @Input() listeNaturesOd: NatureVoyage[];

    /** Étape à visualiser */
    @Output() openEtape: EventEmitter<SaisieEtapeDTO> = new EventEmitter<SaisieEtapeDTO>();

    /** Indique s'il s'agit d'une étape d'hébergement online */
    isHebergementOnline: boolean = false;

    /** Nom de l'hébergement */
    nomHebergement: string;

    /** Indique si la popup est en cours de chargement */
    isLoading: boolean = false;

    /**
     * Constructeur
     */
    constructor(private odService: ODService,
                private odRseService: ODRseService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //On indique s'il s'agit d'une étape d'hébergement online
        setTimeout(() => {
            //On indique s'il s'agit d'une étape d'hébergement online
            this.isHebergementOnline = this.data.idPortee === 'HE' && !!this.data.idNature;
            // récupération du nom de l'hébergement
            this.nomHebergement = this.listeNaturesOd?.find(n => n.idNature === this.data?.idNature)?.nomHotel;
        });
    }

    /**
     * Ouvre le détail du voyage
     *
     * @param etape Etape à ouvrir
     */
    navigateToVoyage(etape: Etape) {
        //Si on n'est pas déjà en train d'ouvrir une étape
        if (!this.isLoading) {
            //On indique que ça charge
            this.isLoading = true;

            //On ouvre la popup pour l'étape
            this.odService.loadEtape(etape.idEtape).pipe(finalize(() => this.isLoading = false)).subscribe(etape => {
                if (etape) {
                    etape.jourDepartAller = moment(etape.jourDepartAller);

                    //S'il y a une date de fin
                    if (etape.jourDepartRetour) {
                        //On la convertit en moment
                        etape.jourDepartRetour = moment(etape.jourDepartRetour);
                    }
                    etape.nature = (<any>TypeNature)[etape.nature];

                    this.openEtape.emit(etape);
                }
            });
        }
    }

    /**
     * Retourne la clé de traduction de la prestation
     */
    getCleTradPresta(): string {
        return TypePresta.cleTraduction(this.data.typePresta);
    }

    /**
	 * Ouverture de la popup de détail de l'équivalent CO2
	 */
	openPopupRse(): void {
        let origine = this.data.villeDepart.libelle + ' ' + (this.data.villeDepart.pays?.libelle ?? '');
        let destination = this.data.villeArrivee.libelle + ' ' + (this.data.villeArrivee.pays?.libelle ?? '');

		this.odRseService.openPopupRse(origine, destination);
	}
}