<infinite-scroll (scrolled)="hasInfiniteScroll() && onEndReached()" [offset]="infiniteScrollOffset">
    <mat-card class="p-0" [class.mat-card-with-sticky]="liste.stickyColumnComponent" [ngClass]="{ 'fill-dashboard-list': liste.isDashboardList && data?.listeResultats.length < 5 }">
        <mat-card-content>
            <div class="listview" [ngClass]="{ 'local': liste.isLocal || liste.isSimple || liste.isDashboardList || !liste.showPagination,'has-selected-items': liste.nbSelectedItems }">
                <div [class.search-background]="!!liste.stickyColumnComponent" >
                    <div [class.search-wrapper]="!!liste.stickyColumnComponent" [class.toggled]="isMenuToggled" #stickyElement>
                        <list-view-search *ngIf="liste.title || liste.isFilter || liste.listeActions?.length > 0" class="listview-search" [liste]="liste" (hauteurChange)="hauteurChange($event)" (filterVisibleChange)="onFilterVisibleChange($event)"></list-view-search>
                        <div *ngIf="header">
                            <ng-template [ngTemplateOutlet]="header.template"></ng-template>
                        </div>
                    </div>
                </div>
                <div class="listview-body" [ngClass]="{ 'popin-list': popinList }">
                    <div [ngClass]="{'listview-body-row row' : liste.isAffichageDeuxColonnes}"
                         class="scroll-container"
                         dynamicDragScroll
                         [isDynamicDragScrollEnable]="liste.isDynamicDragScrollEnable">
                        <div *ngIf="liste.headerComponent" class="listview-item sticky-header" #stickyHeader [class.toggled]="isMenuToggled">
                            <div style="display: flex;">
                                <div class="sticky-column" [class.toggled]="isMenuToggled">
                                    <list-view-item [liste]="liste" [component]="liste.stickyHeaderComponent" [data]="liste.headerData" [extraOptions]="liste.extraOptions"></list-view-item>
                                </div>
                                <list-view-item [liste]="liste" [component]="liste.headerComponent" [data]="liste.headerData" [extraOptions]="liste.extraOptions"></list-view-item>
                            </div>
                        </div>
                        <div *ngFor="let data of data?.listeResultats" class="listview-item" [class.sticky-column]="liste.stickyColumnComponent" [ngClass]="{ 'active': data.isDisplayed, 'col-md-6' : liste.isAffichageDeuxColonnes }">
                            <div style="display: flex;" [ngClass]="{ 'warning': (data.listeAlertes?.niveau == 1 || data.niveauAlerte == 1),'danger': (data.listeAlertes?.niveau == 2 || data.niveauAlerte == 2), 'list-view-item-hover': hover, 'keyboard-selected': data['isKeyboardSelected'] }">
                                <div *ngIf="liste.stickyColumnComponent" class="sticky-column" [class.toggled]="isMenuToggled">
                                    <list-view-item *ngIf="liste.stickyColumnComponent" [data]="data" [toggleItem]="toggleItem"
                                                    [liste]="liste" [component]="liste.stickyColumnComponent" [extraOptions]="liste.extraOptions" (onRemove)="onRemove($event)">
                                    </list-view-item>
                                </div>
                                <list-view-item [data]="data" [toggleItem]="toggleItem"
                                                [liste]="liste" [component]="liste.component" [extraOptions]="liste.extraOptions" (onRemove)="onRemove($event)">
                                </list-view-item>
                            </div>
                        </div>
                    </div>
                    <div class="empty" *ngIf="!liste?.isLoading && data?.listeResultats?.length == 0">
                        <div *ngIf="!liste.isSearchWhenNoFilter && liste.listeSelectedFilters.length == 0; else noResult">
                            <!-- Pas de filtre renseigné alors que c'est obligatoire -->
                            <span [translate]="'liste.noFilter'"></span>
                        </div>
                        <ng-template #noResult>
                            <!-- Pas de résultat -->
                            <span [translate]="liste.emptyMessage ? liste.emptyMessage : (!isListeATraiter() ? 'liste.noData' : 'liste.noDataToProcess')"></span>
                            <a *ngIf="isListeATraiter()" [routerLink]="" (click)="viewAll()" [translate]="'liste.voirTout'"></a>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="liste?.isLoading" class="text-center">
                    <please-wait></please-wait>
                </div>
                <div *ngIf="footer">
                    <ng-template [ngTemplateOutlet]="footer.template"></ng-template>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</infinite-scroll>
