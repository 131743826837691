import {AfterViewInit,Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BehaviorSubject,Observable} from "rxjs";
import {Rse} from "@domain/rse/rse";
import {ParamRse} from "@domain/admin/parametre/api/partenaires/ParamRse";

/**
 * Composant de test des partenaires API
 *
 * @author Laurent SCIMIA
 * @date 05/02/2024
 */
@Component({
	host: {'data-test-id': 'api-partenaires-popup-test'},
	templateUrl: './api-partenaires-popup-test.component.html'
})
export class ApiPartenairesPopupTestComponent implements OnInit {
	/** Résultat du calcul RSE */
	calculRse: Rse;

	/**
	 * Constructeur
	 *
	 * @param data Données d'init de la popup
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: { appelRse: Observable<any>}) {
	}

	/** Initialisation du composant */
	ngOnInit(): void {
		//On appelle le test du RSE
		this.data.appelRse.subscribe((result) => {
			//On récupère le résultat du test
			this.calculRse = result.data.rse;
		})
	}
}