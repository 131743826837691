import {NgModule} from '@angular/core';

import {LoggedGuardProvider} from './security/logged-guard.provider';
import {SsoGuardProvider} from './security/sso-guard.provider';
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ObjetsWFGuardProvider} from './security/objetsWF-guard.provider';
import {httpInterceptorProviders} from "@core/security/http-interceptor-providers";
import {ProfilGuardProvider} from "@core/security/profil-guard.provider";
import {DashboardGuardProvider} from "./security/dashboard-guard.provider";
import {FonctionGuardProvider} from "@core/security/fonction-guard-provider.service";

@NgModule({
    providers: [
        httpInterceptorProviders,
        LoggedGuardProvider,
        SsoGuardProvider,
        AdminGuardProvider,
        ObjetsWFGuardProvider,
        ProfilGuardProvider,
        DashboardGuardProvider,
        FonctionGuardProvider,
    ]
})
export class CoreModule {
}
