<h1 mat-dialog-title>
    <span translate>admin.workflow.liste.popup.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<form #mailToForm="ngForm">
    <div mat-dialog-content>
        <fieldset *ngIf="workflow">
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="libelle"
                        name="libelle"
                        customType="input"
                        [(ngModel)]="workflow.libelle"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.liste.popup.libelle"
                        [isTranslated]="false"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="commentaire"
                        name="commentaire"
                        customType="input"
                        [(ngModel)]="workflow.commentaire"
                        lCol="4" rCol="8"
                        libelle="admin.workflow.liste.popup.commentaire"
                        [isTranslated]="false">
                </custom-input>
            </div>
        </fieldset>
        <please-wait *ngIf="!workflow"></please-wait>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-flat-button color="primary" type="submit" (click)="enregistrer()" [disabled]="!mailToForm.form.valid">
            <span [translate]="'global.actions.enregistrer'"></span>
        </button>
    </div>
</form>
