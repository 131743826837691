<mat-card>
    <mat-card-title>
        <span 
                [class.success]="omp.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.NO_CONTROL" 
                [class.error]="omp.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.ERROR">
            {{ omp.typeEntite.libelle }}
            <proprietaire [proprietaireUser]="omp.user"></proprietaire>
        </span>
    </mat-card-title>
    <mat-card-content>
        <card-alerte *ngIf="omp.lastWorkflowHistoriqueActionAlerte" [niveau]="omp.lastWorkflowHistoriqueActionAlerte.niveau">
            <preview-alerte [alerte]="omp.lastWorkflowHistoriqueActionAlerte"></preview-alerte>
        </card-alerte>
        <form autocomplete="off" #form="ngForm" name="form_generalites">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row" [ngClass]="{ 'has-error': objet.invalid }">
                            <label class="col-md-4 required"><span [translate]="'omp.generalites.objet'"></span></label>
                            <div class="col-md-8">
                                <mat-form-field class="d-flex">
                                    <input matInput #objet="ngModel" name="objet" maxlength="2000" required [(ngModel)]="omp.objet"/>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" [ngClass]="{ 'has-error': destination.invalid || !isDestinationValid() }">
                            <label class="col-md-4 required"><span>{{ 'omp.generalites.destination' | translate }}</span></label>
                            <div class="col-md-8">
                                <autocomplete type="geographie" name="geographie" #destination="ngModel"
                                              [filter]="{ listeTypes: typeEntiteParam?.villeObligatoireOT ? ['VILLE'] : !typeEntiteParam?.gestionVilleOT ? ['PAYS'] : ['PAYS','VILLE'] }"
                                              [(ngModel)]="omp.destination"
                                              (onSelect)="setDestination($event);"
                                              ngDefaultControl
                                              required
                                              floatLabel="never"
                                              [placeholder]="(typeEntiteParam?.villeObligatoireOT ? 'omp.generalites.ville' : !typeEntiteParam?.gestionVilleOT ? 'omp.generalites.pays' : 'omp.generalites.villePays') | translate"
                                              [disabled]="!canModifier || !typeEntiteParam?.destinationModifiableOT"></autocomplete>
                            </div>
                        </div>
                        <div *ngIf="typeEntiteParam?.gestionResidenceOT" class="row" [ngClass]="{ 'has-error': lieuDepart.invalid }">
                            <label class="col-md-4 required"><span [translate]="'omp.generalites.lieuDepart'"></span></label>
                            <div class="col-md-8">
                                <lieu ngDefaultControl #lieuDepart="ngModel" name="lieuDepart"
                                        [disabled]="!canModifier" [required]="true" [(ngModel)]="omp.lieuDepartOd" [(lieu)]="omp.lieuDepartOd" [(typeLieu)]="omp.lieuDepart"
                                        [idUser]="omp.user.idUser" [idPortee]="TypePortee.OT" [idObject]="omp.idOMPermanent" [typeEntiteParam]="typeEntiteParam"></lieu>
                            </div>
                        </div>
                        <div *ngIf="typeEntiteParam?.gestionResidenceOT" class="row" [ngClass]="{ 'has-error': lieuRetour.invalid }">
                            <label class="col-md-4 required"><span [translate]="'omp.generalites.lieuRetour'"></span></label>
                            <div class="col-md-8">
                                <lieu ngDefaultControl #lieuRetour="ngModel" name="lieuRetour"
                                        [disabled]="!canModifier" [required]="true" [(ngModel)]="omp.lieuRetourOd" [(lieu)]="omp.lieuRetourOd" [(typeLieu)]="omp.lieuRetour"
                                        [idUser]="omp.user.idUser" [idPortee]="TypePortee.OT" [idObject]="omp.idOMPermanent" [typeEntiteParam]="typeEntiteParam"></lieu>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" [ngClass]="{ 'has-error': dateDebut.invalid || heureDebut.invalid || !isDateDebutValid() || (isDateDebutValid() && isDateFinValid() && isCacheInit && !isPeriodeValid()) }">
                            <label class="col-md-4 required"><span [translate]="'omp.generalites.debut'"></span></label>
                            <div class="col-md-4">
                                <mat-form-field class="d-flex">
                                    <input name="dateDebut" matInput #dateDebut="ngModel" [matDatepicker]="dateDebutPicker" [(ngModel)]="omp.dateDebut" (dateInput)="computeDates()" (dateChange)="computeDates()" required>
                                    <mat-datepicker-toggle matSuffix [for]="dateDebutPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dateDebutPicker [disabled]="!canModifier"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="d-flex">
                                    <input type="time" name="heureDebut" matInput #heureDebut="ngModel" [(ngModel)]="omp.heureDebut" (change)="computeDates()" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 offset-md-4 danger" *ngIf="!isDateDebutValid()">{{ 'omp.generalites.errors.dateDebutInvalide' | translate }}</div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="isDateDebutValid() && !isGetExercicesPending && dateDebutHasExercice() === false">{{ 'omp.generalites.errors.dateDebutSansExercice' | translate }}</div>
                        </div>
                        <div class="row" [ngClass]="{ 'has-error': dateFin.invalid || heureFin.invalid || !isDateFinValid() || (isDateDebutValid() && isDateFinValid() && isCacheInit && !isPeriodeValid()) }">
                            <label class="col-md-4 required"><span [translate]="'omp.generalites.fin'"></span></label>
                            <div class="col-md-4">
                                <mat-form-field class="d-flex">
                                    <input name="dateFin" matInput #dateFin="ngModel" [matDatepicker]="dateFinPicker" [(ngModel)]="omp.dateFin" (dateInput)="computeDates()" (dateChange)="computeDates()" required>
                                    <mat-datepicker-toggle matSuffix [for]="dateFinPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #dateFinPicker [disabled]="!canModifier"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="d-flex">
                                    <input type="time" name="heureFin" matInput #heureFin="ngModel" [(ngModel)]="omp.heureFin" (change)="computeDates()" required>
                                </mat-form-field>
                            </div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="!isDateFinValid()">{{ 'omp.generalites.errors.dateRetourInvalide' | translate }}</div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="isDateFinValid() && !isGetExercicesPending && dateFinHasExercice() === false">{{ 'omp.generalites.errors.dateRetourSansExercice' | translate }}</div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="isDateDebutValid() && isDateFinValid() && !isDateFinAfterOrEqualsDateDebut()">{{ 'omp.generalites.errors.dateDebutAnterieureDateRetour' | translate }}</div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="isDateDebutValid() && isDateFinValid() && dateDebutHasExercice() === true && dateFinHasExercice() === true && !isExerciceChevalValid">{{ 'omp.generalites.errors.periodeCheval' | translate }}</div>
                            <div class="col-md-8 offset-md-4 danger" *ngIf="isDateDebutValid() && isDateFinValid() && !isPeriodeAnValid">{{ 'omp.generalites.errors.dureeMissionTropGrande' | translate }}</div>
                        </div>
                        <div *ngIf="!settings.isOmPermanentDesactiveLieu" class="row">
                            <label class="col-md-4"><span [translate]="'omp.generalites.lieuDeMission'"></span></label>
                            <div class="col-md-8">
                                <mat-form-field class="d-flex">
                                    <input matInput name="lieu" maxlength="2000" [(ngModel)]="omp.lieu"/>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Zones utilisateurs-->
                <zone-utilisateur #zu="zu" [listeZus]="omp.listeZU" [listeZuSaisies]="omp.listeZUSaisies" [readonly]="!canModifier"></zone-utilisateur>
                
                <div class="row">
                    <label class="col-md-2 align-items-start p-t-15"><span [translate]="'omp.generalites.remarque'"></span></label>
                    <div class="col-md-10">
                        <mat-form-field class="d-flex">
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" name="remarque" [(ngModel)]="omp.remarque"
                                      maxlength="1000"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
