import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Prestation} from "@domain/prestation/prestation";

/**
 * Composant d'affichage d'une item de la liste frais référentiel
 *
 * @author Lionel Gimenez
 * @date 26/11/2024
 */
@Component({
    host: {'data-test-id': 'frais-referentiel-usage-item'},
    templateUrl: './frais-referentiel-usage-item.component.html'
})
export class FraisReferentielUsageItemComponent extends ListViewItem<Prestation> {
}