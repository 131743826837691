import {Component,OnInit,ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {WorkflowStatutsService} from "@services/admin/workflow/workflow-statuts.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {EnumEtat,Etat} from "@domain/workflow/etat";
import {Pea,Procedure} from "@domain/workflow/pea";
import {TypeAction as TypeActionWf} from "@domain/workflow/workflow"
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Composant du détail de l'état
 */
@Component({
    selector: 'etat-detail',
    host: {'data-test-id': 'etat-detail'},
    templateUrl: './etat-detail.component.html'
})
export class EtatDetailComponent implements OnInit {
    /** Message d'information */
    alerte: Alerte = new Alerte({
        message: this.translateService.instant("workflow.etat.infos"),
        niveau: NiveauAlerte.PROFIL_USER,
    });

    /** Etat */
    etat: Etat;

    /** Liste des éléments "procédure état action" (pea) */
    listePeas: Array<Pea>

    /** Liste des actions possibles pour les selects */
    listeActionsWf: Array<OptionAction> = [{
        id: TypeActionWf.VALIDER,
        libelle: this.translateService.instant('global.actions.valider')
    },{
        id: TypeActionWf.REJETER,
        libelle: this.translateService.instant('global.actions.rejeter')
    },{
        id: TypeActionWf.INVALIDER,
        libelle: this.translateService.instant('global.actions.invalider')
    },{
        id: TypeActionWf.EMETTRE,
        libelle: this.translateService.instant('global.actions.emettre')
    }];

    /** Liste des actions sélectionnées pour 'Demande' */
    listeActionsDemande: Array<OptionAction>;

    /** Liste des actions sélectionnées pour 'Annulation' */
    listeActionsAnnulation: Array<OptionAction>;

    /** Liste des actions sélectionnées pour 'Modification' */
    listeActionsModification: Array<OptionAction>;

    /** Liste des actions sélectionnées pour 'Commande' */
    listeActionsCommande: Array<OptionAction>;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Liste des différents onglets */
    listeTabItems: Array<PageHeaderItem> = [{
        code: Onglets.GENERALITES,
        libelle: this.translateService.instant('workflow.statut.onglets.generalites')
    }];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Indique si on est en train d'enregistrer */
    isSaving: boolean = false;

    /**
     * Constructeur
     *
     * @param router Router Angular
     * @param translateService Service des traductions
     * @param route Route active
     * @param toastrService Service des toasts
     * @param etatService Service des états
     */
    constructor(private router: Router,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private toastrService: ToastrService,
                private etatService: WorkflowStatutsService
    ) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération de l'identifiant dans la route
        const idEtat = Number(this.route.snapshot.paramMap.get("idEtat"));

        //Initialisation de l'état
        this.initEtat(idEtat);

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveEtat(),
            isDisabled: () => !this.form?.valid
        }]);
    }

    /**
     * Initialisation de l'état
     *
     * @param idEtat Identifiant de l'état à initialiser
     */
    private initEtat(idEtat: number) {
        //Nouvel état
        this.etat = new Etat({idEtat: idEtat});

        //Libellé de l'état
        switch (idEtat) {
            case EnumEtat.EN_CREATION:
                this.etat.libelle = this.translateService.instant('global.etats.creation');
                break;
            case EnumEtat.EN_VALIDATION:
                this.etat.libelle = this.translateService.instant('global.etats.validation');
                break;
            case EnumEtat.VALIDE:
                this.etat.libelle = this.translateService.instant('global.etats.valide');
                break;
            case EnumEtat.COMPTABILISE_CLOTURE:
                this.etat.libelle = this.translateService.instant('global.etats.comptabilise_cloture');
                break;
            case EnumEtat.ARCHIVE:
                this.etat.libelle = this.translateService.instant('global.etats.archive');
                break;
            case EnumEtat.ANNULE:
                this.etat.libelle = this.translateService.instant('global.etats.annule');
                break;
        }

        //Chargement de la liste des peas
        this.etatService.getEtat(idEtat).pipe(first()).subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Récupération de la liste des peas
                this.listePeas = result.data.listePeas;

                //Configuration des multiselect
                this.configProcedures();
            } else {
                //Toast d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }

    /**
     * Initialisation options cochées dans les multiselect
     */
    configProcedures() {
        //Initialisation des listes
        this.listeActionsDemande = new Array<OptionAction>();
        this.listeActionsAnnulation = new Array<OptionAction>();
        this.listeActionsModification = new Array<OptionAction>();
        this.listeActionsCommande = new Array<OptionAction>();

        //Parcours de la liste des peas
        for (let pea of this.listePeas) {
            //En fonction de la procédure
            switch (pea.idProcedure) {
                case Procedure.WF_DEMANDE:
                    this.addActionToListe(pea.idAction,this.listeActionsDemande);
                    break;
                case Procedure.WF_ANNULATION:
                    this.addActionToListe(pea.idAction,this.listeActionsAnnulation);
                    break;
                case Procedure.WF_MODIFICATION:
                    this.addActionToListe(pea.idAction,this.listeActionsModification);
                    break;
                case Procedure.WF_COMMANDE:
                    this.addActionToListe(pea.idAction,this.listeActionsCommande);
                    break;
            }
        }
    }

    /**
     * Ajout d'une action dans une liste d'éléments
     *
     * @param idAction Identifiant de l'action à ajouter
     * @param listeActions Liste des éléments cochés d'un multiselect
     */
    addActionToListe(idAction: number,listeActions: Array<OptionAction>) {
        //En fonction de l'identifiant de l'action
        switch (idAction) {
            case TypeActionWf.VALIDER:
                listeActions.push({
                    id: TypeActionWf.VALIDER,
                    libelle: this.translateService.instant('global.actions.valider')
                });
                break;
            case TypeActionWf.REJETER:
                listeActions.push({
                    id: TypeActionWf.REJETER,
                    libelle: this.translateService.instant('global.actions.rejeter')
                });
                break;
            case TypeActionWf.INVALIDER:
                listeActions.push({
                    id: TypeActionWf.INVALIDER,
                    libelle: this.translateService.instant('global.actions.invalider')
                });
                break;
            case TypeActionWf.EMETTRE:
                listeActions.push({
                    id: TypeActionWf.EMETTRE,
                    libelle: this.translateService.instant('global.actions.emettre')
                });
                break;
        }
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des états
     */
    onGoBack() {
        // Retour à la liste des états
        this.router.navigate(['Admin/Workflow/Statuts/Etats']);
    }

    /**
     * Enregistrement de l'état
     */
    saveEtat() {
        //Enregistrement en cours
        this.isSaving = true;

        //Mise à jour de la liste des peas
        this.updateListePeas();

        //Enregistrement de l'état
        this.etatService.saveEtat(this.etat.idEtat,this.listePeas).pipe(finalize(() => this.isSaving = false)).subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
            } else {
                //Toast d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }

    /**
     * Mise à jour de la liste des peas
     */
    updateListePeas() {
        //Nettoyage de la liste initiale
        this.listePeas.length = 0;

        //Demande
        for (let action of this.listeActionsDemande) {
            this.listePeas.push({
                id: null,
                idProcedure: Procedure.WF_DEMANDE,
                idEtat: this.etat.idEtat,
                idAction: action.id
            });
        }

        //Annulation
        for (let action of this.listeActionsAnnulation) {
            this.listePeas.push({
                id: null,
                idProcedure: Procedure.WF_ANNULATION,
                idEtat: this.etat.idEtat,
                idAction: action.id
            });
        }

        //Modification
        for (let action of this.listeActionsModification) {
            this.listePeas.push({
                id: null,
                idProcedure: Procedure.WF_MODIFICATION,
                idEtat: this.etat.idEtat,
                idAction: action.id
            });
        }

        //Commande
        for (let action of this.listeActionsCommande) {
            this.listePeas.push({
                id: null,
                idProcedure: Procedure.WF_COMMANDE,
                idEtat: this.etat.idEtat,
                idAction: action.id
            });
        }
    }
}

/**
 * Enum des différents onglets d'un statut
 */
export enum Onglets {
    GENERALITES = "Généralités"
}

/**
 * Option des actions dans les select
 */
export type OptionAction = {
    id: TypeActionWf,
    libelle: string
}
