<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.entreprise.populations.title' | translate"
                [selectedIndex]="selectedIndex"
                [listeItems]="listeTabItems"
                (onSelectedItemChange)="onSelectedItemChange($event)"
                (onGoBack)="onGoBack()">
        </page-header>
        <div *ngIf="listeTabItems[0]?.code == Onglets.GENERALITES" [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <population-generalite [idPopulation]="idPopulation" (idPopulationChange)="idPopulationChange($event)"></population-generalite>
        </div>
        <!-- On affiche que si on a un idPopulation initialisé -->
        <div [hidden]="selectedItem?.code !== Onglets.PERIMETRE" *ngIf="idPopulation != -1 && entreprisePopulationService.isLoaded">
            <population-perimetre [idPopulation]="idPopulation"></population-perimetre>
        </div>
        <div *ngIf="idPopulation != -1 && selectedItem?.code === Onglets.HISTORIQUE && entreprisePopulationService.isLoaded">
            <population-historique [idPopulation]="idPopulation"></population-historique>
        </div>
    </div>
</div>
