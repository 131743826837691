import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IStatutApplication,KeyStatutWorkflow,LISTE_STATUTS_WORKFLOW,TEMPO_STATUT} from "@domain/admin/statut-application/statut-application";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first,share} from "rxjs/operators";
import {BehaviorSubject,Observable} from "rxjs";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";

/**
 * Service de gestion des statuts de synchro HistoWorkflow
 */
@Injectable()
export class StatutWorkflowService {

    /** Statut du Workflow */
    private _synchroWFStatut$: BehaviorSubject<IStatutApplication> = new BehaviorSubject<IStatutApplication>(undefined);
    get synchroWFStatut$(): Observable<IStatutApplication> { return this._synchroWFStatut$.asObservable().pipe(share()); }

    /** Flag indiquant si un long polling est déjà effectif */
    private isLongPollingAlreadyActive: boolean = false;

    /**
     * Constructeur
     */
    constructor(private http: HttpClient,private statutApplicationUserService: StatutApplicationUserService) {}

    /**
     * Rafraichissement du statut de la synchro Workflow
     *
     * @param isRecursiveCalling true si appel récursif (false par défaut)
     */
    loadSynchroWFStatut(isRecursiveCalling: boolean = false): void {
        //Appel au backend
        this.http.get<Result>(`${environment.baseUrl}/controller/Application/synchroWFStatut`).pipe(first()).subscribe(result => {
            //Extraction de la clé de statut retournée
            const statutKey: KeyStatutWorkflow = result.data.synchroWFStatut;

            //Récupération du statut dans la liste
            const statut: IStatutApplication = LISTE_STATUTS_WORKFLOW.get(statutKey);

            //Si le statut est COUNTDOWN
            if (statutKey == KeyStatutWorkflow.HISTO_WF_COUNTDOWN) {
                //Ajout de la tempo dans l'objet
                statut[TEMPO_STATUT] = result.data.millisecondsToTrigger;
            }

            //Recherche du statut associé
            this._synchroWFStatut$.next(statut);

            //Appel pour vérification du mode hors ligne
            this.statutApplicationUserService.checkPublicApplicationStatus();

            //Si le statut nécessite un long polling
            if (this._synchroWFStatut$?.getValue()?.isLongPolling && (!this.isLongPollingAlreadyActive || isRecursiveCalling)) {
                //On relance la fonction dans 5 secondes
                setTimeout(() => this.loadSynchroWFStatut(true), 1000);

                //Bascule du flag pour empêcher les appels redondants
                this.isLongPollingAlreadyActive = true;
            } else if (!this._synchroWFStatut$?.getValue()?.isLongPolling) {
                //Sinon, si le long polling est terminé, alors libération du flag
                this.isLongPollingAlreadyActive = false;
            }
        });
    }

    /**
     * Clic sur le bouton d'annulation de la tempo Workflow
     */
    handleSynchroWFClick(): void {
        //Appel au backend
        this.http.get<Result>(`${environment.baseUrl}/controller/Workflow/forceSyncHistoWorkflowBufferCountdown`).pipe(first()).subscribe(result => {
            //En cas de succès
            if (result.data.success) {
                //Chargement du statut Workflow
                this.loadSynchroWFStatut();
            }
        });
    }
}
