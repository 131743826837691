import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Statut} from "@domain/workflow/statut";
import {Router} from "@angular/router";
import {EnumEtat} from "@domain/workflow/etat";

/**
 * Composant d'un statut dans la liste des statuts
 *
 * @author Angeline Ha
 * @date /09/2024
 */
@Component({
  host: {'data-test-id': 'statut-liste-item'},
  templateUrl: './statut-liste-item.component.html'
})
export class StatutListeItemComponent extends ListViewItem<Statut> {
  //Accès à l'enum dans la vue
  readonly EnumEtat = EnumEtat;

  /**
   * Constructeur
   *
   * @param router Router Angular
   */
  constructor(private router: Router) {
    super();
  }

  /**
   * Ouverture du détail du statut
   */
  onSelect() {
    this.router.navigate(['/Admin/Workflow/Statuts/Statuts/' + this.data.idStatut]);
  }

}
