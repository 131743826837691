<h1 mat-dialog-title>
    <span [translate]="'admin.parametres.api.partenaires.iframeTest'"></span>
    <span [mat-dialog-close]=""><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form>
        <div class="row">
            <label class="col-md-8">
                <span translate="{{ 'admin.parametres.api.partenaires.testLyonMarseille' | translate }}"></span>
            </label>
            <div class="col-md-4" *ngIf="this.calculRse?.value; else tplSpinnerRse">
                <span [innerHTML]="'admin.parametres.api.partenaires.testValeurs' | translate:{CO2e: this.calculRse.value | number:'1.3-3'}"></span>
            </div>
            <ng-template #tplSpinnerRse><mat-spinner diameter="20"></mat-spinner></ng-template>
        </div>
        <rse-iframe [libelleOrigine]="'Lyon France'" [libelleDestination]="'Marseille France'"></rse-iframe>
    </form>
</div>