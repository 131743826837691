/**
 * Classe représentant une configuration de logs
 */
export class ConfigLogging {
    idConfig: number;
    adresse: string;
    port: number;
    actif: boolean;
    logFile: string;
    varEnvAdresse?: boolean;
    varEnvPort?: boolean;

    constructor(dto?: any) {
        if (dto) {
            this.idConfig = dto.idConfig;
            this.adresse = dto.adresse;
            this.port = dto.port;
            this.actif = dto.actif;
            this.logFile = dto.logFile;
            this.varEnvAdresse = dto.varEnvAdresse;
            this.varEnvPort = dto.varEnvPort;
        }
    }
}
