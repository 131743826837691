import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Formulaire,Segment} from "@domain/admin/workflow/segment";
import {TypeProfil} from "@domain/user/user";
import {TypeAction} from "@domain/workflow/workflow";

/**
 * Tooltip liste des segments
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-visualisation-tooltip-item'},
	templateUrl: './liste-visualisation-tooltip-item.component.html',
	styleUrls: ['./liste-visualisation-tooltip-item.component.scss']
})
export class ListeVisualisationTooltipItemComponent implements OnInit {
	/** Enum des formulaires */
	readonly Formulaire = Formulaire;

	/** Enum des profils */
	readonly TypeProfil = TypeProfil;

	/** Enum des actions */
	readonly TypeAction = TypeAction;

	/** Segment concern� */
	@Input()
	segment: Segment;

	/** Action � afficher pour le segment */
	displayAction: String;

	/** Proc�dures � afficher */
	procedures: String;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Stockage de l'action du segment
		this.displayAction = this.segment.action == -1 ? this.translateService.instant('admin.workflow.visualisation.actions.-1') : this.segment.libelleAction;

		//Stockage des proc�dures
		this.procedures = this.getProcedures();
	}

	/**
	 * Retourne la synth�se des proc�dures d'un segment
	 */
	private getProcedures(): String {
		//Initialisation
		let procedures: string = "";

		//S'il y a une action
		if (this.segment.softAction !== 0) {
			//Ajout de l'action � la liste
			procedures += this.translateService.instant('admin.workflow.visualisation.softActions.' + this.segment.softAction);
		}

		//S'il y a une proc�dure voyage
		if (this.segment.eltAction !== 0) {
			//Ajout de l'action � la liste
			procedures += this.translateService.instant('admin.workflow.visualisation.eltActions.' + this.segment.eltAction);
		}

		//S'il y une action budget
		if (this.segment.actionBudget !== 0) {
			//Ajout de l'action � la liste
			procedures += " " + this.translateService.instant('admin.workflow.visualisation.actionsBudget.' + this.segment.actionBudget);
		}

		//S'il y a des proc�dures suppl�mentaires
		if (!!this.segment.lstActionSupp) {
			//Ajout � la liste
			procedures += " " + this.segment.lstActionSupp.trim().split(' ').map(a => {
				//Choix de la traduction en fonction de la valeur
				if (parseInt(a) >= 0) {
					return this.translateService.instant('admin.workflow.visualisation.eltActions.' + a);
				} else {
					return this.translateService.instant('admin.workflow.visualisation.actionsSupp.' + a);
				}
			}).join(' ');
		}

		//Retour
		return procedures.trim();
	}
}
