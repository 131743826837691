/**
 * Procédure état action
 */
export class Pea {
	id: number;
    idProcedure: Procedure;
	idEtat: number;
	idAction: number;
}

/**
 * Enumération des procédures
 */
export enum Procedure {
	WF_DEMANDE= 3001,
	WF_ANNULATION = 3002,
	WF_MODIFICATION = 3003,
	WF_COMMANDE = 3004
}