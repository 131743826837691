import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {ActivatedRoute,Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';
import {ToastrService} from 'ngx-toastr';
import {combineLatest,Subscription} from 'rxjs';
import {first,map,take} from 'rxjs/operators';
import * as sessionActions from '../../reducers/session';

import {Result} from '@domain/common/http/result';
import {User} from '@domain/user/user';
import {LoginService} from './login.service';
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {environment} from "@environments/environment";
import {Auth,AuthSAML,LoginType,ModeSAML} from "@domain/security/auth";
import {AppState} from '@domain/appstate';
import {Store} from '@ngrx/store';
import {Logo} from '@domain/settings/logo';
import {Session} from '@domain/security/session';
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";
import {KeyStatutApplication} from "@domain/admin/statut-application/statut-application";


/** Nom du cookie de stockage de l'identifiant **/
const COOKIE_NAME: string = 'login.userLogin';

/** Les types d'actions possibles pour un mode d'authentification SAML/SSO */
export type typeActionSSO = 'Auth' | 'Logout' | 'LoginLocal' | 'Error';

/**
 * Connexion
 */
@Component({
    selector: 'start-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    LoginType = LoginType;
    LoginService = LoginService;

    /** Modèle du champ de confirmation du mot de passe */
    @ViewChild('confirmPassword')
    private confirmPassword: NgModel;

    /** Captcha */
    @ViewChild('loginCaptcha')
    private loginCaptcha: any;

    /** Données */
    public user: User = <User>({});

    /** Données du mot de passe oublié */
    public forgottenPassword: { email?: string,matricule?: string,identifiant?: string,isSent: boolean } = { isSent: false };

    /** Données du changement de mot de passe */
    public passwordChange: { newPassword?: string,confirmPassword?: string,accountToken?: string,passwordParams?: any,error?: number | null,success: boolean } = { passwordParams: { minChiffre: 1,minLength: 8,minSpecial: 0,minMajuscule: 1,minMinuscule: 1 },success: false };

    /** Type d'action */
    public typeAction?: 'ForgottenPassword' | 'PasswordChange' | 'ActivateAccount' | 'PasswordExpired' | typeActionSSO;

    /** Etape de connexion */
    public step: 'LOGIN' | 'PASSWORD' = 'LOGIN';

    /** Etat du chargement */
    public isLoading = false;

    /** Token de captcha */
    public captchaToken: string = null;

    /** Erreur d'authentification */
    public loginError: string | null;

    /** Logo personalisé */
    public logoPersonnalise: string;

    /** Logo personnalisé fallback */
    public logoPersonnaliseFallback = "./assets/logo/logo_white.svg";

    /** Logo secondaire */
    public logoSecondaire: string;

    /** Image aléatoire */
    public randomImage: string;

    /** Logo fallback */
    public logoSecondaireFallback='./assets/logo/logo.png';

    /** Redirection post login */
    private redirect: string;

    /** Mode LoginLocal forcé (SSO) */
    forceLoginLocal: boolean;

    /** Expressions régulières sur la politique de mot de passe */
    public RGX_MIN_LENGTH: RegExp;
    public RGX_MIN_MINUSCULE: RegExp;
    public RGX_MIN_MAJUSCULE: RegExp;
    public RGX_MIN_CHIFFRE: RegExp;
    public RGX_MIN_SPECIAL: RegExp;
    public RGX_FULL_PASSWORD: RegExp;

    /** Année courante pour le copyright */
    currentYear: number = moment().year();

    /** Souscription aux observables */
    listeSubscription: Array<Subscription> = new Array<Subscription>();

    /** Message de maintenance administrateur */
    messageMaintenance: string;

    /** Statut de l'application */
    statut: KeyStatutApplication;

    /** Clés de statut de l'application */
    readonly KeyStatutApplication = KeyStatutApplication;

    /**
     * Constructeur
     */
    constructor(private loginService: LoginService,
                private toastr: ToastrService,
                private cookieService: CookieService,
                private activatedRoute: ActivatedRoute,
                private translateService: TranslateService,
                private sessionStorageService: SessionStorageService,
                private store: Store<AppState>,
                private statutApplicationUserService: StatutApplicationUserService) {}

    /**
     * Initialisation du composant
     */
    async ngOnInit() {
        //Indicateur de chargement
        this.isLoading = true;

        //Initialisation du verrouillage du rafraichissement du statut
        this.statutApplicationUserService.lockRefresh();

        //Abonnement à la mise à jour du statut de l'application
        this.listeSubscription.push(this.statutApplicationUserService.statut$.subscribe(statut => {
            //Sauvegarde du statut
            this.statut = statut;

            //Si le statut est différent de en ligne
            if (this.statut != KeyStatutApplication.EN_LIGNE) {
                //Déverrouillage du rafraichissement de statut
                this.statutApplicationUserService.unlockRefresh();

                //Lancement de la surveillance du statut de l'application
                this.statutApplicationUserService.checkPublicApplicationStatus();
            } else {
                //Sinon, maintien ou réinstauration du verrouillage sur le rafraichissement du statut
                this.statutApplicationUserService.lockRefresh();
            }
        }));

        //Abonnement à la mise à jour du message de maintenance
        this.listeSubscription.push(this.statutApplicationUserService.messageMaintenance$.subscribe(messageMaintenance => this.messageMaintenance = messageMaintenance));

        //Attente du chargement du mode d'authentification
        await this.loginService.loadAuth().toPromise();

        //Lecture des paramètres de la route active
        combineLatest([this.activatedRoute.params,this.activatedRoute.queryParams]).pipe(
            take(1),
            map((response: Array<Params>): RouteParams => ({
                params: response[0],
                queryParams: response[1]
            }))
        ).subscribe((routeParams: RouteParams) => {
            //Récupération du type d'action
            this.typeAction = routeParams.params.typeAction;

            //Récupération du mode LoginLocal le cas échéant
            this.forceLoginLocal = this.typeAction === 'LoginLocal';

            //Vérification de la récupération du mode d'authentification
            if (this.auth) {
                //Vérification du mode
                if (this.auth.loginType === LoginType.SAML && !this.forceLoginLocal) {
                    this.listeSubscription.push(
                        this.statutApplicationUserService.statut$
                            .pipe(first(statut => statut === KeyStatutApplication.EN_LIGNE))
                            .subscribe(statut => {
                                //Traitement du mode SAML
                                this.processSAML();
                            })
                    );
                } else {
                    let login;

                    //Vérification de la présence du cookie
                    if (login = this.cookieService.get(COOKIE_NAME)) {
                        //Définition de l'utilisateur
                        this.user.login = login;

                        //Passage à l'étape de saisie du mot de passe
                        this.step = 'PASSWORD';
                    }

                    //Fin de chargement
                    this.isLoading = false;

                    //Traitement du mode Local
                    this.processLocal(routeParams);
                }
            } else {
                //Fin de chargement
                this.isLoading = false;

                //Message indiquant que le mode d'authentification n'a pas pu être déterminé
                this.loginError = 'login.sso.noAuth';
            }
        });

        //Met à jour les logos
        this.loginService.getLogo().subscribe((logo: Logo) => {
            this.logoPersonnalise = logo.logoPersonnalise;
            this.logoSecondaire = logo.logoSecondaire;
            this.randomImage = logo.randomImage;
        });

        //On se connecte au changement de session pour le cas ou l'user retourne sur le login mais avec une session sans password expiré
        //C'est le cas si l'user refuse les cgu avec un compte qui avait un mdp périmé
        this.listeSubscription.push(this.store.select(state => state.session).subscribe(session => {
            //Si l'action en cours est le password périmé alors que la la session n'a plus cette notion
            if (!session.isPasswordExpired && this.typeAction === 'PasswordExpired') {
                //On reset le type d'action
                this.typeAction = null;
            }
        }));
    }

    /**
     * Getter du mode d'authentification courant
     */
    get auth(): Auth {
        return this.loginService.getAuth();
    }

    /**
     * Vérifie si l'utilisateur est logué (côté front)
     */
    isLogged(): boolean {
        return this.loginService.getSession()?.isLogged;
    }

    /**
     * Authentification SAML
     */
    private processSAML() {
        const auth = this.auth as AuthSAML;

        //Vérification de la présence du cookie contenant le login en mode local
        if (this.cookieService.check(COOKIE_NAME)) {
            //Suppression du cookie de rétention du login qui n'a pas lieu d'être en mode SAML
            this.cookieService.delete(COOKIE_NAME);
        }

        //Vérification du type d'action
        if (!this.typeAction) { // login
            //Vérification du mode SAML SP (Service Provider)
            if (auth.mode === ModeSAML.SP) {
                //Redirection dans un setTimeout pour ne pas bloquer le chargement des ressources comme les images et logo le temps d'être effectivement redirigé
                setTimeout(() => {
                    window.location.href = `${environment.baseUrl}/servlet/NDFServlet?action=Login`;
                });
            } else {
                //Fin du chargement
                this.isLoading = false;

                //Test du statut de l'application
                if (this.statut !== KeyStatutApplication.EN_LIGNE) {
                    //Application hors-ligne
                    this.loginError = 'login.error.appliNotOnline';
                } else {
                    //Message d'erreur pour indiquer qu'en mode IDP l'utilisateur doit d'abord s'authentifier auprès de l'idp
                    this.loginError = 'login.sso.erreurIdp';
                }
            }
        } else if (this.typeAction === 'Auth') { //Redirection depuis le serveur après authentification auprès de l'idp
            //Vérification de l'authentification de l'utilisateur côté serveur
            this.loginService.checkAuth()
                .pipe(first())
                .subscribe((authResponse) => {
                    //Utilisateur authentifié
                    if (authResponse.body.data.isAuth) {
                        //Initialisation de la session
                        this.loginService.loginSAML(authResponse);
                    } else {
                        //Fin de chargement
                        this.isLoading = false;

                        //Erreur : utilisateur authentifié
                        this.loginError = "login.sso.erreurAuth";
                    }
                },
                () => {
                    //Fin de chargement
                    this.isLoading = false;

                    //Erreur lors de la vérification de l'authentification
                    this.loginError = "login.sso.erreurAuth";
                });
        } else if (this.typeAction === 'Logout') {
            //Vérification si l'utilisateur est logué
            if (this.isLogged()) {
                //Délog
                this.loginService.logout();
            }

            //Fin de chargement
            this.isLoading = false;
        } else if (this.typeAction === 'LoginLocal') {
            //Vérification si l'utilisateur est déjà logué
            if (this.isLogged()) {
                //Délog
                this.loginService.logout();
            }

            //Fin de chargement
            this.isLoading = false;
        } else if (this.typeAction === 'Error') {
            //Si l'authentification a échoué parce que l'application est hors-ligne
            if (!!this.statut && this.statut !== KeyStatutApplication.EN_LIGNE) {
                //Affichage du message adéquat
                this.loginError = "login.error.appliNotOnline";
            } else {
                //Sinon affichage d'un message générique
                this.loginError = "login.sso.erreurAuth";
            }
        } else {
            //Action inconnue : erreur
            this.loginError = 'login.sso.erreur';
        }
    }

    /**
     * Authentification locale
     *
     * @param routeParams Paramètres de la route
     */
    private processLocal(routeParams: RouteParams) {
        //Définition des expressions régulières (par défaut)
        this.computePasswordPatterns();

        //Vérification d'une redirection suite à un delog de la partie admin
        if (sessionStorage.getItem('redirect-admin')) {
            //Récupération de l'url pour la redirection post login
            this.redirect = sessionStorage.getItem('redirect-admin');

            //Suppression immédiate de la redirection de la session
            sessionStorage.removeItem('redirect-admin');
        }

        //Vérification du type d'action
        if (this.typeAction == 'PasswordChange' || this.typeAction == 'ActivateAccount' || this.typeAction =='PasswordExpired') {
            //Définition du token
            this.passwordChange.accountToken = routeParams.queryParams.account_token;

            //Chargement de la politique de mot de passe
            this.loginService.loadPasswordParams().subscribe((result: Result) => {
                //Vérification du code d'erreur
                if (result?.codeErreur == 0) {
                    //Vérification de la politique de gestion des mots de passe
                    if (result?.data?.passwordParams)
                        //Mise à jour de la politique de gestion des mots de passe
                        this.passwordChange.passwordParams = result.data.passwordParams;

                    //Définition des expressions régulières (depuis la politique de mot de passe)
                    this.computePasswordPatterns();
                }
            })
        }
    }

    /**
     * Poursuite de la connexion - Passage à l'étape suivante
     */
    doContinue() {
        //Enregistrement du cookie
        this.cookieService.set(COOKIE_NAME, this.user.login, {
            expires: moment().add(1, 'y').toDate(),
            secure: true,
            sameSite: "None"
        });

        //Définition de l'étape de saisie du mot de passe
        this.step = 'PASSWORD';

        this.loginError = null;
    }

    /**
     * Remise à zéro du login
     */
    resetLogin() {
        //Purge de l'utilisateur
        this.user = <User>({});

        //Définition de l'étape de saisie de l'identifiant
        this.step = 'LOGIN';

        //Suppression du cookie
        this.cookieService.delete(COOKIE_NAME);
    }

    /**
     * Connexion
     */
    doLogin() {
        //Purge du Session Storage
        this.sessionStorageService.clear();

        //Définition de l'état du chargement
        this.isLoading = true;

        //Connexion de l'utilisateur
        this.loginService.login(this.user,this.captchaToken,this.redirect,this.forceLoginLocal).subscribe({
            next: session => {
                //Vérification de la session
                if (!session || !session.isLogged) {
                    //Fin du chargement
                    this.isLoading = false;

                    //Définition des erreurs
                    this.loginError = session.loginError;

                    //Remise à zéro du captcha
                    this.loginCaptcha?.reset();

                    //Vérification du type d'erreur
                    if (this.loginError == 'logErrorAppliNotOnline') {
                        //Affichage d'un message d'erreur
                        this.toastr.error(this.translateService.instant('login.error.appliNotOnline'));
                    } else if (this.loginError != 'logErrorAccountLocked' && this.loginError != 'logErrorMustWait' && this.loginError != 'logErrorAccountDisabled')
                        //Affichage d'un message d'erreur
                        this.toastr.error(this.translateService.instant('login.connexionError'));
                } else if (session && session.isPasswordExpired) {
                    //Fin du chargement
                    this.isLoading = false;

                    //Définition des erreurs
                    this.loginError = session.loginError;

                    //Assignation du type pour afficher les éléments du password expiré
                    this.typeAction = 'PasswordExpired'
                } else {
                    //Session OK : suppression de la redirection le cas échéant
                    this.redirect = null;
                }
            },
            error: () => {
                //Définition de l'état du chargement
                this.isLoading = false;

                //Affichage d'un message d'erreur
                this.toastr.error(this.translateService.instant('login.connexionError'));
            }
        });
    }

    /**
     * Demande d'envoi de mail pour récupérer un mot de passe oublié
     */
    recoverForgottenPassword() {
        //Définition de l'état du chargement
        this.isLoading = true;

        //Demande d'envoi de mail pour récupérer un mot de passe oublié
        this.loginService.recoverForgottenPassword(this.forgottenPassword,this.captchaToken).subscribe({
            next: (result: Result) => {
                //Vérification du résultat
                if (result?.codeErreur == 0)
                    //Mail envoyé
                    this.forgottenPassword.isSent = true;
            },
            error: () => {
                //Définition de l'état du chargement
                this.isLoading = false;
            },
            complete: () => {
                //Définition de l'état du chargement
                this.isLoading = false;
            }
        });
    }

    /**
     * Récupération d'un token de captcha
     */
    onRecaptchaSuccess(token) {
        //Mise à jour du token
        this.captchaToken = token;
    }

    /**
     * Calcul des expressions régulières pour la validation du mot de passe
     */
    computePasswordPatterns() {
        const minLengthRgx: string = `(?=.{${this.passwordChange?.passwordParams?.minLength || 8},})`;
        const minMinusculeRgx: string = `(?=.*[a-z]{${this.passwordChange?.passwordParams?.minMinuscule || 0},})`;
        const minMajusculeRgx: string = `(?=.*[A-Z]{${this.passwordChange?.passwordParams?.minMajuscule || 0},})`;
        const minChiffreRgx: string = `(?=.*[0-9]{${this.passwordChange?.passwordParams?.minChiffre || 0},})`;
        const minSpecialRgx: string = `(?=.*\W{${this.passwordChange?.passwordParams?.minSpecial || 0},})`;

        //Définition des expressions régulières
        this.RGX_MIN_LENGTH = new RegExp(minLengthRgx);
        this.RGX_MIN_MINUSCULE = new RegExp(minMinusculeRgx);
        this.RGX_MIN_MAJUSCULE = new RegExp(minMajusculeRgx);
        this.RGX_MIN_CHIFFRE = new RegExp(minChiffreRgx);
        this.RGX_MIN_SPECIAL = new RegExp(minSpecialRgx);
        this.RGX_FULL_PASSWORD = new RegExp(minLengthRgx + minMinusculeRgx + minMajusculeRgx + minChiffreRgx + minSpecialRgx);
    }

    /**
     * Vérification de l'égalité des mots de passe
     */
    checkPasswords() {
        //Définition des erreurs
        this.confirmPassword.control.setErrors(this.passwordChange.newPassword != this.passwordChange.confirmPassword && { nomatch: true } || null);
    }

    /**
     * Enregistrement du nouveau mot de passe
     */
    saveNewPassword() {
        //Définition de l'état du chargement
        this.isLoading = true;

        if (this.typeAction == "PasswordExpired") {
            this.loginService.changePassword(this.user.password,this.passwordChange.newPassword).subscribe({
                next: (result: Result) => {
                    //Vérification du résultat
                    if (result?.codeErreur == 0) {
                        //Suppressions des erreurs
                        this.passwordChange.error = null;

                        //Mise à jour du mot de passe réussie
                        this.passwordChange.success = true;

                        //Le password a été modifié, on modifie la session et propage la modification pour passer l'écran de login
                        this.store.select<Session>(s => s.session).pipe(take(1)).subscribe(session => {
                            session.isPasswordExpired = false;

                            this.store.dispatch({
                                type: sessionActions.SESSION_FULFILLED,
                                payload: session
                            });
                        });
                    } else {
                        //Mise à jour du code d'erreur
                        this.passwordChange.error = result?.codeErreur;
                    }
                },
                error: () => {
                    //Définition de l'état du chargement
                    this.isLoading = false;
                },
                complete: () => {
                    //Définition de l'état du chargement
                    this.isLoading = false;
                }
            });
        } else {
            //Enregistrement du nouveau mot de passe
            this.loginService.createPassword(this.passwordChange.newPassword,this.passwordChange.accountToken,this.captchaToken).subscribe({
                next: (result: Result) => {
                    //Vérification du résultat
                    if (result?.codeErreur == 0) {
                        //Suppressions des erreurs
                        this.passwordChange.error = null;

                        //Mise à jour du mot de passe réussie
                        this.passwordChange.success = true;
                    } else
                        //Mise à jour du code d'erreur
                        this.passwordChange.error = result?.codeErreur;
                },
                error: () => {
                    //Définition de l'état du chargement
                    this.isLoading = false;
                },
                complete: () => {
                    //Définition de l'état du chargement
                    this.isLoading = false;
                }
            });
        }
    }

    /**
     * Supression des cookies
     */
    deleteCookies(){
        //Supression caches locaux
        sessionStorage.clear();
        localStorage.clear();

        //Supression des cookies
        this.cookieService.deleteAll();

        //On recharge la page afin de supprimer les cookies
        location.reload();
    }

    /** Retourne le logo secondaire */
    getLogoSecondaire(): string {
        return this.logoSecondaire ?? this.logoSecondaireFallback;
    }

    /** Retourne le logo personnalisé */
    getlogoPersonnalise(): string {
        return this.logoPersonnalise ?? this.logoPersonnaliseFallback;
    }

    /** C'est la fin pour le composant */
    ngOnDestroy() {
        //Désabonnements
        this.listeSubscription.forEach(sub => sub.unsubscribe());
    }

    /** Affiche la mire de connexion */
    returnToLogin() {
        //Reset des éventuelles valeurs saisies
        if (this.passwordChange) {
            this.passwordChange.newPassword = null;
            this.passwordChange.confirmPassword = null;
        }

        //Retour à l'étape 1
        this.typeAction = null;
        this.step = 'LOGIN';
    }
}

/**
 * Type décrivant les différents paramètres de la route
 */
export type RouteParams = {
    params: Params,
    queryParams: Params
}
