import {NDFGeneralitesComponent} from './ndf-details/ndf-generalites/ndf-generalites.component';
import {Component,ViewChild} from '@angular/core';
import {ActivatedRoute,Params,Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ChainageService} from '../chainage/chainage.service';
import {AppState} from '@domain/appstate';
import {TypeAction,TypePortee,TypePorteeInt} from '@domain/workflow/workflow';
import {AnalytiqueComponent} from '../analytique/analytique.component';
import {NDFService} from './ndf.service';
import {WorkflowService} from '../workflow/workflow.service';
import {TypeProfil} from '@domain/user/user';
import {TypeEntiteService} from "../type-entite/type-entite.service";
import {Ndf} from '@domain/ndf/ndf';
import {AbstractObjetWorkflowComponent,OngletsAdmin} from '../workflow/abstract-objet-workflow.component';
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {SuiviBudgetComponent} from "../budget/suivi/suivi-budget.component";
import {BehaviorSubject,Observable,of,Subject} from "rxjs";
import {first,mergeMap} from "rxjs/operators";
import {ReportingService} from "@components/reporting/reporting.service";
import {MatDialog} from "@angular/material/dialog";
import {TypeCodeErreur} from "@domain/common/http/result";
import {SettingsNFState} from "@domain/settings/settings";
import {Location} from "@angular/common"
import {SettingsService} from "@components/settings/settings.service";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {SuiviBudget} from "@domain/budget/suivi-budget";
import {NiveauAlerte} from '@domain/common/alerte/alerte';

@Component({
    selector: 'ndf',
    templateUrl: './ndf.component.html'
})
export class NDFComponent extends AbstractObjetWorkflowComponent<Ndf, SettingsNFState> {
    /** Accès à l'enum dans la vue */
    readonly TypePorteeInt = TypePorteeInt;
    readonly TypePortee = TypePortee;
    readonly OngletsAdmin = OngletsAdmin;
    readonly StatutArchivage = StatutArchivage;
    readonly Onglets = Onglets;

    /** Indicateur de modification possible de l'analytique */
    canModifierAnalytique: boolean = false;

    /** Nombre de préférence analytique disponible */
    preferenceAnalytiqueCount: number = 0;

    /** Comptes bancaires du collab */
    listeComptesBancaires?: Array<any>;

    /** Indique si on doit désactiver les comptes bancaires */
    isCompteBancaireDisabled = false;

    /** Indique si la ndf a été validée par un comptable */
    isValideComptable: boolean = false;

    /** Répartition analytique */
    @ViewChild(AnalytiqueComponent)
    private analytique: AnalytiqueComponent;

    /** Composant enfant NDFGeneralites */
    @ViewChild(NDFGeneralitesComponent)
    private ndfGeneralitesComponent: NDFGeneralitesComponent;

    /** Composant enfant SuiviBudget */
    @ViewChild(SuiviBudgetComponent)
    suiviBudgetComponent: SuiviBudgetComponent;

    /** Subject pour refresh la liste des frais */
    fraisRefreshSubject: Subject<void> = new Subject<void>();

    /** Observable pour refresh la liste des frais */
    fraisRefreshObservable: Observable<void> = this.fraisRefreshSubject.asObservable();

    /** Onglet courant */
    selectedItem$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        protected store: Store<AppState>,
        protected toastrService: ToastrService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected workflowService: WorkflowService,
        protected settingsService: SettingsService,
        protected chainageService: ChainageService,
        protected analytiqueService: AnalytiqueService,
        protected ndfService: NDFService,
        private typeEntiteService: TypeEntiteService,
        protected reportingService: ReportingService,
        protected matDialog: MatDialog,
        protected location: Location
    ) {
        super(
            translateService,
            store,
            toastrService,
            activatedRoute,
            router,
            workflowService,
            chainageService,
            analytiqueService,
            reportingService,
            matDialog,
            location,
            TypePortee.NF,
            ndfService
        );
    }

    /**
     * Initialisation des onglets
     */
    initTabs(): void {
        //Définition des onglets
        this.listeTabItems = [
            {
                code: Onglets.GENERALITES,
                libelle: this.translateService.instant('ndf.navigation.generalites')
            }, {
                code: Onglets.FRAIS,
                libelle: this.translateService.instant('ndf.navigation.frais')
            }
        ];

        if (this.user.fonction === TypeProfil.RESPONSABLE || this.user.fonction === TypeProfil.ASSISTANT || this.user.fonction === TypeProfil.COMPTABLE || this.isAdmin) {
            this.listeTabItems.push({
                code: Onglets.FICHECOLLABORATEUR,
                libelle: this.translateService.instant('ndf.navigation.ficheCollaborateur'),
            });
        }

        this.listeTabItems.push({
            code: Onglets.COMPLEMENTS,
            libelle: this.translateService.instant('ndf.navigation.complements')
        });

        //Ajout de l'onglet outils
        super.initTabs();
    }

    /**
     * Recharge l'objet après un enregistrement.
     * @param objetId Identifiant de l'objet
     */
    protected reloadObjetAfterSave(objetId: any): Observable<Ndf> {
        return super.reloadObjetAfterSave(objetId).pipe(mergeMap((objet) => {
            /** Chargement des informations du suivi budgétaire */
            this.suiviBudgetComponent?.loadSuiviBudget();

            return of(objet);
        }));
    }

    /**
     * Construction de la NDF à partir des données brutes non typées.
     *
     * @param data données brutes non typées
     * @returns une instance de la NDF
     */
    protected buildLoadedObjet(data: any): Ndf {
        //Mise à jour de la note de frais
        const ndf = new Ndf(data.ndf);

        //Liste des comptes bancaires
        this.listeComptesBancaires = data.listeComptesBancaires;

        //Mise à jour de isCompteBancaireDisabled
        this.isCompteBancaireDisabled = data.isCompteBancaireDisabled;

        //Maj de isValideComptable
        this.isValideComptable = data.isValideComptable;

        //Vérification que la NDF est en modification
        if (ndf.canModifier()) {
            //vérification du cas d'une NDF liée à un OD / OMP parent
            if (ndf.od?.idOd || ndf.omPermanent?.idOMPermanent) {
                //NDF liée : vérification de la configuration du type entité du parent
                this.typeEntiteService.isAnalytiqueHeriteModifiable(ndf.typeEntite?.idTypeEntite, TypePortee.NF, ndf.od?.idOd ? TypePortee.OD : TypePortee.OT)
                    .subscribe(isEditable => {
                        this.canModifierAnalytique = isEditable;
                    });
            } else {
                //NDF non liée : modification de l'analytique possible
                this.canModifierAnalytique = true;
            }
        }

        this.preferenceAnalytiqueCount = data.preferenceAnalytiqueCount;

        //Retour
        return ndf;
    }

    /**
     * Construction d'une nouvelle NDF
     * @returns une instance de la NDF
     */
    protected buildNewObjet(): Promise<Ndf> {
        //Retour
        return new Promise<Ndf>((resolve, reject) => {
            resolve(new Ndf());
        });
    }

    /**
     * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
     *
     * @return boolean True si le formulaire est valide, False sinon
     */
    isValid(): boolean {
        return this.ndfGeneralitesComponent?.isValid() && this.analytique?.isValid();
    }

    /**
     * Préparation de la NDF avant sauvegarde.
     *
     * @param ndf NDF à préparer
     * @returns NDF préparée
     */
    protected beforeSaveObjet(ndf: Ndf): Ndf {
        //Met à jour le dto avant la sauvegarde
        this.ndfGeneralitesComponent.beforeSave();

        //Retour
        return ndf;
    }

    /**
     * Gère l'évenement du contrôle de l'alerte des frais
     *
     * @param message à traduire pour les toasts
     */
    onCheckFraisAlerte(message: string) {
        //On lance le refresh pour les composants qui en ont besoin
        this.fraisRefreshSubject.next();

        //Met à jour les alertes
        this.getAlertesAfterSave()
            .pipe(first())
            .subscribe();

        //Synchro Workflow
        this.workflowService.synchroWorkflow('NF', this.objetWorkflow.getId()).subscribe({
            next: result => {
                if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                } else {
                    //Rechargement de l'objet notamment pour MAJ des droits workflow
                    super.reloadObjetAfterSave(this.objetWorkflow.getId());

                    //On affiche le toast selon le message
                    if (message != null) {
                        this.toastrService.success(this.translateService.instant(message));
                    } else {
                        //Toast par défaut indiquant le succès de l'enregistrement
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    }
                }
            },
            error: () => { this.toastrService.error(this.translateService.instant('global.errors.enregistrement')); }
        });
    }

    /**
     * Récupération des paramètres du contexte de navigation
     *
     * @param activatedRouteParams query params de la route courante
     */
    protected initRoutingContext(activatedRouteParams: Params): void {
        //Si l'utilisateur est admin ou sous-admin
        if (this.user.fonction == TypeProfil.ADMINISTRATEUR || this.user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
            if (!!activatedRouteParams?.idLot) {
                this.routingContext = {
                    returnRoute: `Admin/Comptabilite/LotsComptables/Lot/${activatedRouteParams.idLot}`,
                    extras: {
                        queryParams: {
                            tabIndex: 1
                        }
                    }
                };
            } else {
                this.routingContext = {
                    returnRoute: 'Admin/Frais/Ndf/Ndf'
                };
            }
        } else {
            if (!!activatedRouteParams?.idLot) {
                this.routingContext = {
                    returnRoute: `Lot/${activatedRouteParams.idLot}`,
                    extras: {
                        queryParams: {
                            tabIndex: 1
                        }
                    }
                };
            } else {
                this.routingContext = {
                    returnRoute: 'ListeNDF'
                };
            }
        }
    }

    /**
     * Retourne true si le statut de la NDF permet le téléchargement de l'archive à valeur probante, false sinon.
     */
    isAvp(ndf: Ndf): boolean {
        return [StatutArchivage.SYNCHRONISE,StatutArchivage.PARTIEL].includes(ndf.statutArchivage);
    }

    /**
     * Affichage du cadre avp dans l'onglet Compléments
     */
    onStatutArchivageClick(): void {
        this.selectedItem$.next(this.listeTabItems.findIndex(tab => tab.code === Onglets.COMPLEMENTS));
    }

    /**
     * Appelé avant d'exécuter une action workflow sur l'objet en vérifiant l'absence d'alerte bloquante
     *
     * @param typeAction le type d'action workflow
     */
    protected checkBeforeActionWorkflow(typeAction: TypeAction): Observable<boolean> {
        //Vérification de la présence d'alerte bloquante dans le budget
        if (this.suiviBudgetComponent?.listeSuivi?.some((suivi: SuiviBudget) => suivi.niveauAlerte === NiveauAlerte.ERROR)) {
            //Message d'erreur
            this.toastrService.error(this.translateService.instant('global.errors.budget'));

            //Retour de l'échec de la vérification
            return of(false);
        } else {
            //Continuation du processus d'exécution de l'action
            return super.checkBeforeActionWorkflow(typeAction);
        }
    }
}

/**
 * Enum pour les noms des différents onglets de la page Entreprise
 */
export enum Onglets {
    GENERALITES = 'GENERALITES',
    FRAIS = 'FRAIS',
    FICHECOLLABORATEUR = 'FICHECOLLABORATEUR',
    COMPLEMENTS = 'COMPLEMENTS',
}
