<div class="content" *ngIf="statut">
    <page-header [title]="isCreation ? ('workflow.statut.titleCreation' | translate) : ('workflow.statut.title' | translate : {libelle: statut.libelle})"
                 (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [listeItems]="listeTabItems">
    </page-header>

    <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
        <form #form="ngForm" *ngIf="statut">
            <statut-generalites [statut]="statut"></statut-generalites>
        </form>
    </div>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
<please-wait class="content" *ngIf="!statut"></please-wait>