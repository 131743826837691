import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {EnumEtat,Etat} from "@domain/workflow/etat";
import {Router} from "@angular/router";
import {WorkflowStatutsService} from "@services/admin/workflow/workflow-statuts.service";
import {first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Pea,Procedure} from "@domain/workflow/pea";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";


/**
 * Item de la liste état
 */
@Component({
  host: {'data-test-id': 'etat-liste-item'},
  templateUrl: './etat-liste-item.component.html'
})
export class EtatListeItemComponent extends ListViewItem<Etat> implements OnInit{
  /** Accès à l'enum dans la vue */
  readonly EnumEtat = EnumEtat;

  /** Liste des éléments "procédure état action" (pea) */
  listePeas: Array<Pea>

  /* Nombre d'actions pour chaque procédure */
  nbActionsDemande: number;
  nbActionsAnnulation: number;
  nbActionsModification: number;
  nbActionsCommande: number;

  /**
   * Constructeur
   *
   * @param router Router Angular
   * @param etatService Service des états
   * @param translateService Service des traductions
   * @param toastrService Service des toasts
   */
  constructor(private router: Router,
              private etatService: WorkflowStatutsService,
              private translateService: TranslateService,
              private toastrService: ToastrService) {
    super();
  }

  /**
   * Initialisation
   */
  ngOnInit() {
    //Chargement de la liste des peas
    this.etatService.getEtat(this.data.idEtat).pipe(first()).subscribe((result: Result) => {
      if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
        //Récupération de la liste des peas
        this.listePeas = result.data.listePeas;

        //Récupération des informations à afficher
        this.nbActionsDemande = 0;
        this.nbActionsAnnulation = 0;
        this.nbActionsModification = 0;
        this.nbActionsCommande = 0;

        for (let pea of this.listePeas) {
          switch (pea.idProcedure) {
            case Procedure.WF_DEMANDE:
              this.nbActionsDemande += 1;
              break;
            case Procedure.WF_ANNULATION:
              this.nbActionsAnnulation += 1;
              break;
            case Procedure.WF_MODIFICATION:
              this.nbActionsModification += 1;
              break;
            case Procedure.WF_COMMANDE:
              this.nbActionsCommande += 1;
              break;
          }
        }
      } else {
        //Toast d'erreur
        TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
      }
    });
  }

  /**
   * Ouverture du détail du statut
   */
  onSelect() {
    this.router.navigate(['/Admin/Workflow/Statuts/Etats/' + this.data.idEtat]);
  }
}
