import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {DocumentModule} from "../../../document/document.module";
import {LieuModule} from "../../../lieu/lieu.module";
import {ProfilModule} from "../../../profil/profil.module";
import {ProfilService} from "../../../profil/profil.service";
import {EntreprisePopulationsComponent} from "./entreprise-populations.component";
import {PopulationDetailsComponent} from "./population-details/population-details.component";
import {PopulationPerimetreComponent} from "./population-details/population-perimetre/population-perimetre.component";
import {PopulationListComponent} from "./population-list/population-list.component";
import {PopulationListItemComponent} from "./population-list/population-list-item/population-list-item.component";
import {PopulationCollaborateurItemComponent} from "./population-details/population-perimetre/population-collaborateur-item/population-collaborateur-item.component";
import {PopulationServiceItemComponent} from "./population-details/population-perimetre/population-service-item/population-service-item.component";
import {PopulationAddCollaborateurComponent} from "./population-details/population-perimetre/population-add-collaborateur/population-add-collaborateur.component";
import {PopulationAddServiceComponent} from "./population-details/population-perimetre/population-add-service/population-add-service.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatRadioModule} from "@angular/material/radio";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {PopulationGeneraliteComponent} from "@components/admin/entreprise/populations/population-details/population-generalite/population-generalite.component";
import {PopulationHistoriqueComponent} from "@components/admin/entreprise/populations/population-details/admin-historique/population-historique.component";
import {PopulationHistoriqueItemComponent} from "@components/admin/entreprise/populations/population-details/admin-historique/admin-historique-item/population-historique-item.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {EntreprisePopulationService} from "@components/admin/entreprise/populations/entreprise-population.service";

export const entreprisePopulationsRoutes : Routes = [
    {
        path: 'Population/:idPopulation',
        component: PopulationDetailsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_POPULATION_GESTION,DroitAdmin.DROIT_POPULATION_ADMIN] }
    },{
        path: 'Liste',
        component: PopulationListComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_POPULATION_GESTION,DroitAdmin.DROIT_POPULATION_ADMIN] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Liste'
    }, {
        path: '**',
        redirectTo: 'Liste'
    }
];

@NgModule({
    imports: [
        ShareModule,
        LieuModule,
        ProfilModule,
        DocumentModule,
        PreviewAlerteModule,
        MatDividerModule,
        TranslateModule,
        MatRadioModule,
    ],
    declarations: [
        EntreprisePopulationsComponent,
        PopulationListComponent,
        PopulationDetailsComponent,
        PopulationListItemComponent,
        PopulationPerimetreComponent,
        PopulationCollaborateurItemComponent,
        PopulationServiceItemComponent,
        PopulationAddCollaborateurComponent,
        PopulationAddServiceComponent,
        PopulationGeneraliteComponent,
        PopulationHistoriqueComponent,
        PopulationHistoriqueItemComponent
    ],
    exports: [
        EntreprisePopulationsComponent,
        PopulationListComponent,
        PopulationDetailsComponent
    ],
    providers: [ProfilService,LoginService,PopulationsService,EntreprisePopulationService]
})
export class EntreprisePopulationsModule {}
