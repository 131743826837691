import {Component,OnInit} from '@angular/core';
import {WorkflowStatutsService} from "@services/admin/workflow/workflow-statuts.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MatDialogRef} from "@angular/material/dialog";

/**
 * Composant de la popin de choix de représentation du statut
 */
@Component({
  host: {'data-test-id': 'select-statut-representation-popin'},
  templateUrl: './select-statut-representation-popin.component.html',
  styleUrls: ['./select-statut-representation-popin.component.scss']
})
export class SelectStatutRepresentationPopinComponent implements OnInit {
  /** Liste des représentations */
  listeRepresentation: Map<string,string>;

  /**
   * Constructeur
   *
   * @param dialogRef Référence à la boîte de dialogue
   * @param statutService Service des statuts
   * @param translateService Service des traductions
   * @param toastrService Service des toasts
   */
  constructor(private dialogRef: MatDialogRef<SelectStatutRepresentationPopinComponent>,
              private statutService: WorkflowStatutsService,
              private translateService: TranslateService,
              private toastrService: ToastrService) { }

  /**
   * Initialisation
   */
  ngOnInit(): void {
    this.statutService.getListeRepresentationStatut().subscribe((result: Result) => {
      if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
        this.listeRepresentation = result.data.listeImg;
      } else {
        TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
      }
    })
  }

  /**
   * Sélection d'une représentation
   *
   * @param image Image sélectionnée
   * @param lienImage Lien de l'image sélectionnée
   */
  selectRepresentation(image: string,lienImage: string) {
    //Fermeture de la popin avec les informations de la représentation sélectionnée
    this.dialogRef.close({image: image, lienImage: "../img/progression/" + lienImage});
  }
}
