import {Component,Input,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Prestation,TypePrestation} from "@domain/prestation/prestation";
import {FraisReferentielUsageItemComponent} from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-usage/frais-referentiel-usage-item/frais-referentiel-usage-item.component";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage de la page référentiel usage
 *
 * @author Lionel Gimenez
 * @date 26/11/2024
 */
@Component({
    host: {'data-test-id': 'frais-refrentiel-usage'},
    selector: 'frais-refrentiel-usage',
    templateUrl: './frais-referentiel-usage.component.html'
})
export class FraisReferentielUsageComponent implements OnInit {

    /** Type de référentiel */
    @Input() typeReferentiel: string;

    /** Id du référentiel */
    @Input() idReferentiel: number;

    /** Liste des prestations */
    listePrestation: ListView<Prestation,FraisReferentielUsageItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Initialisation de la liste
        this.listePrestation = new ListView<Prestation,FraisReferentielUsageItemComponent>({
            uri: `/controller/Referentiel/listePrestationReferentiel/${this.typeReferentiel}/${this.idReferentiel}`,
            title: this.translateService.instant('admin.frais.referentiels.infos.usage.title'),
            component: FraisReferentielUsageItemComponent,
                defaultOrder: 'libelleNettoye',
                listeFilters: [{
                    title: this.translateService.instant("admin.frais.referentiels.infos.usage.libelle"),
                    clef: "libelle",
                    isDefault: true
                },{
                    title: this.translateService.instant("admin.frais.referentiels.infos.usage.compte"),
                    clef: "compte",
                    isDefault: true
                },{
                    title: this.translateService.instant('admin.frais.referentiels.infos.usage.type'),
                    clef: 'type',
                    listeValues: [{
                        code: TypePrestation.REEL.toString(),
                        libelle: this.translateService.instant('ndf.prestation.typeRemboursement.REEL')
                    },{
                        code: TypePrestation.FORFAIT.toString(),
                        libelle: this.translateService.instant('ndf.prestation.typeRemboursement.forfaitShort')
                    },{
                        code: TypePrestation.PLAFOND.toString(),
                        libelle: this.translateService.instant('ndf.prestation.typeRemboursement.plafondShort')
                    }],
                }],
            }
        );
    }
}