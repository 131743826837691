import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {UserDto} from "@domain/user/user-dto";

/**
 * Composant d'affichage de l'item des responsables des enveloppes
 *
 * @author Lionel Gimenez
 * @date 04/12/2024
 */
@Component({
    host: {'data-test-id': 'responsable-item'},
    templateUrl: './responsable-item.component.html'
})
export class ResponsableItemComponent extends ListViewItem<UserDto> {
}