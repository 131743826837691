<div class="listview-search" #listviewSearch>
    <div class="search-container">
        <div class="title" *ngIf="liste.title" [class.warning]="liste.alertLevel == NiveauAlerte.WARNING" [class.error]="liste.alertLevel == NiveauAlerte.ERROR">
            <span *ngIf="!liste.isDashboardList">
                {{ liste.title }}<br/>
                <span class="pagination" *ngIf="!liste.nbSelectedItems && liste.showPagination">
                    {{ pagination | async }}
                    <ng-container *ngIf="liste.loadCount != null && (liste.loadCount.loadStatus | async) !== CountStatus.LOADED && liste.data?.nbObjetsTotal === 9000000000000000000 && liste.nbElementsCharges >= liste.data?.nbObjetsParPage">
                        &nbsp;
                        <span class="pagination clickable" (click)="loadCount()" [matTooltip]="((liste.loadCount.loadStatus | async) === CountStatus.NONE ? 'liste.calculCount' : 'liste.calculCountEnCours') | translate">
                            <u>{{liste.nbElementsCharges + '+'}}</u>
                        </span>
                    </ng-container>
                </span>
                <span class="pagination" *ngIf="liste.nbSelectedItems == 1" [translate]="'liste.unSelectionne'" [translateParams]="{ 'nbTotal': liste.nbElementsCharges }"></span>
                <span class="pagination" *ngIf="liste.nbSelectedItems > 1" [translate]="'liste.plusieursSelectionnes'" [translateParams]="{ 'nbElements': liste.nbSelectedItems,'nbTotal': liste.nbElementsCharges }"></span>
            </span>
            <span *ngIf="liste.isDashboardList" [ngClass]="{ 'clickable': this.liste.redirect }" (click)="this.liste.redirect && goToList()">
                {{ liste.title }}
            </span>
        </div>
        <div (click)="onSearchFocus();" [ngClass]="{ 'search-spaceholder': !hasSearchBarVisible(),'search-bar': hasSearchBarVisible() }">
            <mat-icon *ngIf="hasSearchBarVisible()" color="primary">search</mat-icon>
            <mat-form-field *ngIf="hasSearchBarVisible()" [floatLabel]="'never'">
                <mat-label [translate]="'global.actions.rechercher'"></mat-label>
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let filter of (liste.listeSelectedFilters | filterBy:isChipVisible());" [disabled]="liste.nbSelectedItems > 0" [removable]="!(liste?.isLoading)" (removed)="removeFilter(filter)">
                        <strong *ngIf="filter.title">{{ filter.title }}&#160;:&#160;</strong>{{ filter.displayedValeur || filter.valeur }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input #searchInput (matChipInputTokenEnd)="addFilter($event)" [disabled]="liste.nbSelectedItems > 0" [matChipInputAddOnBlur]="true"
                           [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="listeSeparateurs" [placeholder]="'liste.recherche.saisirValeur' | translate">
                </mat-chip-list>
            </mat-form-field>
        </div>
        <button *ngIf="hasFilterVisible()" mat-icon-button (click)="filterVisible()" [disabled]="liste.nbSelectedItems > 0">
            <mat-icon>{{ !isFilterVisible ? 'filter_list' : 'close' }}</mat-icon>
        </button>
        <ng-container *ngIf="liste.isLocal || (liste.isInfiniteScroll != null && !liste.isInfiniteScroll)">
            <button mat-icon-button (click)="goToPreviousPage()" [disabled]="liste.nbSelectedItems > 0 || numPage == 0">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-icon-button (click)="goToNextPage()" [disabled]="liste.nbSelectedItems > 0 || numPage >= (liste.data?.nbPagesTotal - 1)">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </ng-container>
        <button mat-icon-button *ngFor="let action of (liste.listeActions | filterBy:filterActionVisible)" (click)="action.onPress();resetButtons();" [disabled]="action.disabled">
            <mat-icon [matTooltip]="action.tooltip">{{ action.icon }}</mat-icon>
        </button>
    </div>
    <div (click)="$event.stopPropagation();" *ngIf="isFilterVisible && !liste.nbSelectedItems && !liste.isSimple && !liste.isDashboardList" class="filter-container">
        <div class="menu-buttons" [class.with-selected-filters]="(liste.listeSelectedFilters | filterBy:isChipVisible()).length > 0" [class.disabled]="liste?.isLoading" *ngIf="liste?.sorting">
            <div class="search" [matTooltip]="liste?.isLoading ? undefined : ((liste.sorting.search == SearchType.CONTAINS ? 'global.liste.parametrage.search.contient' : 'global.liste.parametrage.search.commence_par') | translate)"><i class="nio" [class.icon-contient]="liste.sorting.search == SearchType.CONTAINS" [class.icon-commence_par]="liste.sorting.search == SearchType.STARTS_WITH" (click)="!liste?.isLoading && liste.sorting.toggleSearch()"></i></div>
            <div class="sort" [matTooltip]="liste?.isLoading ? undefined : ('global.liste.parametrage.sort' | translate)"><mat-icon (click)="!liste?.isLoading && showSortPopup()">sort</mat-icon></div>
        </div>
        <ul>
            <li *ngFor="let filter of liste.listeFilters" (click)="filterClick(filter)" [ngClass]="{ 'active': filter.isSelected,'default': filter.isDefault }">
                <span>{{ filter.title }}</span>
            </li>
        </ul>
        <form #form="ngForm" autocomplete="off">
            <div *ngFor="let filter of liste.listeFilters | filterBy:{ isSelected: true }">
                <div class="row">
                    <div class="col-sm-2" *ngIf="!filter.listeValues && (filter.type == TypeFilter[TypeFilter.DECIMAL] || filter.type == TypeFilter[TypeFilter.DATE])">
                        <mat-form-field class="d-flex">
                            <mat-label translate="filter.rule.typeComparaison"></mat-label>
                            <mat-select name="typeComparaison" [(ngModel)]="filter.typeComparaison">
                                <mat-option *ngFor="let type of listeTypesComparaison" [value]="type.code">{{ type.libelle }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4" *ngIf="!filter.listeValues && (!filter.type || filter.type == TypeFilter[TypeFilter.STRING])">
                        <mat-form-field class="d-flex">
                            <mat-label class="required">{{ filter.title }}</mat-label>
                            <input matInput type="text" [name]="filter.clef" [(ngModel)]="filter.valeur" (keydown.enter)="onPressEnterInFilter(form)" required/>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6" *ngIf="!filter.listeValues && filter.type == TypeFilter[TypeFilter.DECIMAL]">
                        <div class="d-flex flex-row" style="margin-top: 1px;">
                            <mat-form-field>
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <input [name]="filter.clef" matInput [(ngModel)]="filter.min" nNumber="2" (keydown.enter)="onPressEnterInFilter(form)" required/>
                            </mat-form-field>
                            <span class="m-t-15 m-l-10 m-r-10" *ngIf="filter.typeComparaison == 'BETWEEN'" translate="filter.rule.et"></span>
                            <mat-form-field *ngIf="filter.typeComparaison == 'BETWEEN'">
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <input [name]="filter.clef" matInput [(ngModel)]="filter.max" nNumber="2" (keydown.enter)="onPressEnterInFilter(form)" required/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="!filter.listeValues && filter.type == TypeFilter[TypeFilter.LONG]">
                        <div class="d-flex">
                            <mat-form-field>
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <input [name]="filter.clef" matInput [(ngModel)]="filter.min" nNumber="0" (keydown.enter)="onPressEnterInFilter(form)" required/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-4 mb-2" *ngIf="!filter.listeValues && filter.type == TypeFilter[TypeFilter.BOOLEAN]">
                        <div class="d-flex align-items-start">
                            <mat-label>{{ filter.title }}</mat-label>
                            <div class="btn-group">
                                <button type="button" class="btn m-0" [ngClass]="{ 'btn-primary': filter.valeur == 'true','btn-secondary': filter.valeur != 'true' }" (click)="filter.valeur = 'true';">{{ 'filter.valeurOuiNon.true' | translate }}</button>
                                <button type="button" class="btn m-0" [ngClass]="{ 'btn-primary': filter.valeur == 'false','btn-secondary': filter.valeur != 'false' }" (click)="filter.valeur = 'false';">{{ 'filter.valeurOuiNon.false' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="!filter.listeValues && filter.type == TypeFilter[TypeFilter.DATE]">
                        <div class="d-flex flex-row">
                            <mat-form-field>
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <input [name]="filter.clef" matInput [matDatepicker]="datePicker" [(ngModel)]="filter.dateDebut" (keydown.enter)="onPressEnterInFilter(form)" required/>
                                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                            </mat-form-field>
                            <span class="m-t-15 m-l-10 m-r-10" *ngIf="filter.typeComparaison == 'BETWEEN'" translate="filter.rule.et"></span>
                            <mat-form-field *ngIf="filter.typeComparaison == 'BETWEEN'">
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <input [name]="filter.clef" matInput [matDatepicker]="datePicker" [(ngModel)]="filter.dateFin" (keydown.enter)="onPressEnterInFilter(form)" required/>
                                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="filter.listeValues">
                        <div class="d-flex">
                            <mat-form-field appearance="none" floatLabel="always" style="margin-bottom:-0.5em;">
                                <mat-label class="required">{{ filter.title }}</mat-label>
                                <mat-select [multiple]="filter.multiple" class="form-control" panelClass="listview-filter-select" [name]="filter.clef"
                                            [ngModel]="filter.multiple ? filter.listeObjects : filter.valeur"
                                            (ngModelChange)="filter.multiple ? filter.listeObjects = $event : filter.valeur = $event"
                                            (selectionChange)="filterSelectionChange(filter)" required>
                                    <mat-select-trigger *ngIf="filter.multiple">
                                        {{ listViewService.formatFilterValuesMultiple(filter) }}
                                    </mat-select-trigger>
                                    <mat-option class="option-loading" *ngIf="filter.loading">
                                        <span class="loading">
                                            <!-- En faire un composant si utile ailleurs un jour -->
                                            <span translate="global.liste.filtres.loadingInProgress"></span>
                                            <span class="one">.</span>
                                            <span class="two">.</span>
                                            <span class="three">.</span>
                                        </span>
                                    </mat-option>
                                    <mat-option *ngFor="let value of filter.listeValues" [value]="value.code">{{ value.libelle }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <span class="pull-right">
            <button mat-stroked-button *ngIf="liste.listeSelectedFilters && liste.listeSelectedFilters.length > 0" [disabled]="liste?.isLoading" (click)="resetListeFilters();"><span [translate]="'global.actions.reinitialiser'"></span></button>
            <button mat-flat-button color="primary" *ngIf="(liste.listeFilters | filterBy:{ isSelected: true })?.length > 0" [disabled]="liste?.isLoading || form.invalid" (click)="updateListeFilters();"><span [translate]="'global.actions.rechercher'"></span></button>
        </span>
    </div>
</div>
