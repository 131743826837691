import {Component,Inject,OnDestroy,OnInit,Optional} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {FloatingButtonService} from "@share/component/floating-button/floating-button.service";
import {ApiRseService} from "@services/admin/parametre/api/api-rse.service";
import {MatDialog} from "@angular/material/dialog";
import {ApiPartenairesPopupTestComponent} from "@components/admin/parametre/api/partenaires/popup-test/api-partenaires-popup-test.component";
import {ParamRse} from "@domain/admin/parametre/api/partenaires/ParamRse";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {RseService} from "@services/share/rse/rse.service";

/**
 * Composant des partenaires API
 *
 * @author Laurent SCIMIA
 * @date 01/02/2024
 */
@Component({
	host: {'data-test-id': 'api-partenaires'},
	templateUrl: './api-partenaires.component.html'
})
export class ApiPartenairesComponent implements OnInit,OnDestroy {
	/** Paramétrage du RSE */
	paramRse: ParamRse;

	/** Indique si la page est en cours d'enregistrement */
	isSaving: boolean = false;

	/** Liste des actions pour les boutons de la page */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param floatingButtonService Service des boutons
	 * @param apiRseService			Service de gestiond des paramètres du RSE
	 * @param matDialog				Service de popup
	 * @param translateService		Service de traduction
	 * @param toastr				Service de toast
	 * @param rseService			Service de gestion du RSE
	 */
	constructor(@Optional() @Inject('FLOATING_BUTTON_SERVICE') private floatingButtonService: FloatingButtonService,
				private apiRseService: ApiRseService,
				private matDialog: MatDialog,
				private translateService: TranslateService,
				private toastr: ToastrService,
				private rseService: RseService) {
	}

	/** Initialisation du composant */
	ngOnInit(): void {
		//Initialisation des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				doAction: () => this.saveRseParams(),
			},{
				type: TypeAction.SECONDARY,
				icone: 'material-icons-outlined',
				iconeName: 'assignment_turned_in',
				libelle: 'admin.parametres.api.partenaires.tester',
				isDisabled: () => !this.paramRse?.isCalculActif,
				doAction: () => this.testRse(),
			}]);

		//Chargement des paramètres du RSE
		this.loadRseParam();

		//On définit les actions spécifiques à l'onglet (dans un timeout pour laisser le service s'initialiser)
		setTimeout(() => this.floatingButtonService.setListeActions(this.listeActions));
	}

	/**
	 * Chargement des paramètres du RSE
	 */
	loadRseParam(): void {
		this.apiRseService.loadRseParam().subscribe(result => {
			this.paramRse = new ParamRse(result.data.rseParam);
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Quand on quitte l'onglet, on reset les actions
		this.floatingButtonService.resetFloatingButtonActions();
	}

	/**
	 * Enregistrement des paramètres du RSE
	 */
	saveRseParams(): void {
		//On enregistre les paramètres
		this.apiRseService.saveParamRse(this.paramRse).subscribe(result => {
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				//On affiche un message de succès
				this.toastr.success(this.translateService.instant('global.success.enregistrement'));

				//On s'assure que le service du RSE soit à jour
				this.rseService.initRseParam();
			} else {
				//Gestion de l'erreur
				TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastr);
			}
		});
	}

	/**
	 * Appel à la popup de test du RSE
	 */
	testRse(): void {
		//Ouverture de la popup de test
		this.matDialog.open<ApiPartenairesPopupTestComponent,any,void>(ApiPartenairesPopupTestComponent,{
			data: {
				appelRse: this.apiRseService.testRse()
			}
		});
	}
}