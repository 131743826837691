import {Component, OnDestroy, OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Segment} from "@domain/admin/workflow/segment";
import {Subscription} from "rxjs";

/**
 * Item de liste des segments
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-visualisation-sticky-header-item'},
	templateUrl: './liste-visualisation-sticky-header-item.component.html',
	styleUrls: ['./liste-visualisation-sticky-header-item.component.scss']
})
export class ListeVisualisationStickyHeaderItemComponent extends ListViewItem<Segment> implements OnInit,OnDestroy {
	/** Affichage de la colonne du nom pour le segment */
	showSegmentNamesColumn: boolean = false;

	/** Souscription */
	souscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor() {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Abonnement au bouton d'affichage/masquage du nom
		this.souscription = this.liste.extraOptions.showSegmentNamesColumn$.subscribe((showSegmentNamesColumn: boolean) => this.showSegmentNamesColumn = showSegmentNamesColumn);
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//D�sabonnement
		this.souscription.unsubscribe();
	}
}
