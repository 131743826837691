import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {DroitAdmin} from "@core/security/droit-admin";
import {ApiSoapComponent} from "./soap/api-soap.component";
import {ApiRestComponent} from "./rest/api-rest.component";
import {ApiClefsComponent} from "./clefs/api-clefs.component";
import {ParametreApiComponent} from "@components/admin/parametre/api/parametre-api.component";
import {ApiPartenairesComponent} from "@components/admin/parametre/api/partenaires/api-partenaires.component";
import {ApiRseService} from "@services/admin/parametre/api/api-rse.service";
import {ApiPartenairesPopupTestComponent} from "@components/admin/parametre/api/partenaires/popup-test/api-partenaires-popup-test.component";
import {RseIframeModule} from "@share/component/rse-iframe/rse-iframe.module";

export const parametreApiRoutes: Routes = [
	{
		path: 'Rest',
		component: ApiRestComponent,
		canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERFACE_COMPTE_API] }
	},{
		path: 'Soap',
		component: ApiSoapComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Clefs',
		component: ApiClefsComponent,
		canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERFACE_REF] }
    }, {
        path: 'Partenaires',
        component: ApiPartenairesComponent,
        canActivate: [AdminGuardProvider]
	},{
        path: 'Partenaires',
        component: ApiPartenairesComponent,
        canActivate: [AdminGuardProvider]
    }, {
		path: '',
		pathMatch: 'full',
		redirectTo: 'Rest'
	},{
		path: '**',
		redirectTo: 'Rest'
	}
];

@NgModule({
    imports: [ShareModule,PreviewAlerteModule,MatDividerModule,TranslateModule,MatRadioModule,RseIframeModule],
	declarations: [
        ApiRestComponent,ApiSoapComponent,ApiClefsComponent,ParametreApiComponent,ApiPartenairesComponent,ApiPartenairesPopupTestComponent
	],
	exports: [
        ApiRestComponent,ApiSoapComponent,ApiClefsComponent,ApiPartenairesComponent
	],
    providers: [ProfilService, LoginService, ApiRseService]
})
export class ParametreApiModule {
}