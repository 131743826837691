<!-- Panneau participants -->
<od-participants-list *ngIf="modeParticipant != ModeParticipants.AUCUN" [modeParticipant]="modeParticipant" [od]="od" [settings]="settings" [canModifier]="canModifier"></od-participants-list>

<!-- Panneau Voyage -->
<mat-card *ngIf="isVoyageAutorise">
    <mat-card-title>
        <span [translate]="'od.voyage.voyage'"></span>
        <!-- On peut faire une nouvelle étape si on a les droits et qu'on est en ONLINE ou qu'il n'y a pas encore de proposition choisie ET que le profil voyageur est correctement renseigné -->
        <span class="actions" [ngClass]="{'disabled':!isProfilVoyageurValide}" [matTooltip]="isProfilVoyageurValide ? '' : ('od.voyage.profilInvalide' | translate)" *ngIf="!isLoading && isActionsAutorisees && (!hasPropositionChoisie() || od.aiguillage === TypeAiguillage.ONLINE)" (click)="isProfilVoyageurValide && addVoyage()">
            <mat-icon>add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body" *ngIf="!isLoading">
                <div class="listview-item" *ngFor="let etape of listeEtapes">
                    <voyage-item [data]="etape" [listeNaturesOd]="listeNaturesOd" (openEtape)="addVoyage($event)"></voyage-item>
                </div>
                <div class="empty" *ngIf="!listeEtapes?.length && !isLoading"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
        <please-wait class="content" *ngIf="isLoading"></please-wait>
    </mat-card-content>
</mat-card>

<!-- Panneau Propositions -->
<mat-card *ngIf="isVoyageAutorise">
    <mat-card-title>
        <span [ngClass]="{ 'warning': etapeNonReservee | async }">
            <mat-icon class="m-r-10" *ngIf="etapeNonReservee | async" [matTooltip]="'od.voyage.prestaNonReservee' | translate">error_outline</mat-icon>
            <span [translate]="'od.voyage.propositions'"></span>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body" *ngIf="!isLoading">
                <div class="listview-item" *ngFor="let proposition of listePropositions; let index = index">
                    <proposition-item [data]="proposition"
                                      [index]="index + 1"
                                      [nbPropositions]="listePropositions.length"
                                      [hasPropositionChoisie]="hasPropositionChoisie()"
                                      [devise]="settings.deviseEntreprise"
                                      [deviseOrigine]="od.deviseTravel"
                                      [idOd]="od.idOd"
                                      [odMapActions]="od.getMapAction()"
                                      [listeEtapes]="listeEtapes"
                                      (needRefresh)="loadVoyage();etapesChanged.emit()"
                                      [aiguillageOd]="aiguillageOd"
                                      [dvActions]="dvActions"></proposition-item>
                </div>
                <div class="empty" *ngIf="!listePropositions?.length && !isLoading"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
        <please-wait class="content" *ngIf="isLoading"></please-wait>
    </mat-card-content>
</mat-card>