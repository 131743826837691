import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive pour la gestion du scroll 2D type cliquer-d�placer
 */
@Directive({
    selector: '[dynamicDragScroll]'
})
export class DynamicDragScrollDirective implements OnInit {
    /** Activation ou non de la fonctionnalit� */
    @Input() isDynamicDragScrollEnable = false;

    /** D�placement en cours */
    private isDragging = false;

    /** Position de d�part */
    private startX = 0;
    private startY = 0;

    /** Mesures du d�placement en cours */
    private scrollLeft = 0;
    private scrollTop = 0;

    /**
     * Constructeur
     */
    constructor(private el: ElementRef,private renderer: Renderer2) {}

    /**
     * Initialisation de la directive
     */
    ngOnInit() {
        //Si la directive est activ�e
        if (this.isDynamicDragScrollEnable) {
            //Application de la classe CSS correspondante
            this.renderer.addClass(this.el.nativeElement, 'dynamic-scroll');
        }
    }

    /**
     * Clic
     */
    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent): void {
        //Annulation si directive inactive ou clic droit
        if (!this.isDynamicDragScrollEnable || event.button !== 0) return;

        //D�placement en cours
        this.isDragging = true;

        //Position de d�part
        this.startX = event.clientX;
        this.startY = event.clientY;

        //Mesures du d�placement en cours
        this.scrollLeft = window.scrollX;
        this.scrollTop = window.scrollY;

        //Application de la classe CSS indiquant le d�placement en cours
        this.el.nativeElement.classList.add('dragging');
    }

    /**
     * Mouvement
     */
    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent): void {
        //Annulation si aucun mouvement en cours
        if (!this.isDragging) return;

        //Calcul du scroll horizontal et vertical
        const x = event.clientX - this.startX;
        const y = event.clientY - this.startY;

        //Application du scroll
        window.scrollTo(this.scrollLeft - x, this.scrollTop - y);
    }

    /**
     * Rel�chement du clic
     */
    @HostListener('mouseup', ['$event'])
    onMouseUp(event: MouseEvent): void {
        //D�placement termin�
        this.isDragging = false;

        //Suppression de la classe CSS indiquant le d�placement en cours
        this.el.nativeElement.classList.remove('dragging');
    }

    /**
     * Sortie de l'�cran
     */
    @HostListener('window:mouseleave', ['$event'])
    onMouseLeave(event: MouseEvent): void {
        //D�placement termin�
        this.isDragging = false;

        //Suppression de la classe CSS indiquant le d�placement en cours
        this.el.nativeElement.classList.remove('dragging');
    }
}
