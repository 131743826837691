import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {IjWeekday} from "@domain/od/engagements/indemnites/ij-weekday";
import {Regle} from "@domain/od/engagements/indemnites/regle";
import {IjPageItem} from "@domain/od/engagements/indemnites/ij-page-item";
import {OdEngagementsIndemnitesDegrevementsComponent} from "./od-engagements-indemnites-degrevements.component";
import {FraisMission} from "@domain/od/engagements/indemnites/frais-mission";
import {TypeModeGestion} from "@domain/od/engagements/indemnites/regle-attribution";

/**
 * Composant d'une ligne de la liste de la popin des indemnités / dégrèvements
 *
 * @author Laurent Convert
 * @date 10/11/2021
 */
@Component({
    templateUrl: './od-engagements-indemnites-degrevements-item.component.html',
    styleUrls: ['./od-engagements-indemnites-degrevements-item.component.scss']
})
export class OdEngagementsIndemnitesDegrevementsItemComponent implements OnInit {
    /* Déclaration pour accès dans le template */
    TypeModeGestion: typeof TypeModeGestion = TypeModeGestion;

    /** Elément courant */
    @Input() data: IjPageItem;

    /** Paramètres/options supplémentaires */
    @Input() extraOptions: any;

    /** Évènement émit lors du (re)chargement des données */
    @Output() onChangeDegrevement = new EventEmitter<Regle>();

    /** Structure contenant les données pour le jour de la semaine */
    weekday: IjWeekday = new IjWeekday();

    /** Indicateur de modification possible */
    canModifier: boolean = false;

    /** Indicateur de complétion possible */
    canCompleter: boolean = false;

    /** Devise entreprise */
    deviseEntreprise: string;

    /** Coefficient modificateur de la mission */
    coefficientModificateurOd: number;

    /** Coefficient modificateur du collaborateur */
    coefficientModificateurCollaborateur: number;

    /** Référence vers le composant parent pour pouvoir intéragir directement */
    parentComponent: OdEngagementsIndemnitesDegrevementsComponent;

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        // Récupération des données transmises à l'ouverture de la popin
        this.canModifier = this.extraOptions?.canModifier;
        this.canCompleter = this.extraOptions?.canCompleter;
        this.deviseEntreprise = this.extraOptions?.deviseEntreprise;
        this.coefficientModificateurOd = this.extraOptions?.coefficientModificateurOd;
        this.coefficientModificateurCollaborateur = this.extraOptions?.coefficientModificateurCollaborateur;
        this.parentComponent = this.extraOptions?.parentComponent;

        //Copie des données dans la structure
        if (this.data?.date) {
            Object.assign(this.weekday,this.data);
        }
    }

    /**
     * Sélection/déselection d'un frais pour dégrèvement
     *
     * @param regle La règle associée
     */
    changeDegrevement(regle: Regle): void {
        this.parentComponent?.changeDegrevement(regle);
    }

    /**
     * Choix d'un frais dans le dropdown
     *
     * @param regle La règle associée
     */
    changeFrais(regle: Regle): void {
        this.parentComponent?.changeFrais(regle,regle.selectedFrais);
    }

    /**
     * Fonction de comparaison entre 2 frais. Retourne true s'ils sont identiques, False sinon.
     *
     * @param frais1 Frais à comparer
     * @param frais2 Frais à comparer
     */
    isSameFrais(frais1:FraisMission,frais2:FraisMission): boolean {
        return frais1?.idTaux == frais2?.idTaux;
    }

    /**
     * Retourne le code de la devise du montant local à partir des données géographiques dans l'ordre suivant :
     * Pays > ville > Paramètre du Frais de mission (indemnités) > (vide)
     *
     * @param regle La règle de laquelle est extraite la devise
     */
    getDeviseLocale(regle: Regle): string {
        return regle.selectedFrais?.fraisMissionRegion?.pays?.devise
            ?? regle.selectedFrais?.fraisMissionRegion?.ville?.pays?.devise
            ?? regle?.details[0]?.ij?.devise?.code
            ?? '';
    }

    /*
     * Survol d'un frais dans la drop down.
     * Permet de recalculer le montant et d'actualiser l'infobulle
     */
    computeMontantFraisSpecial(regle, frais): number {
        let montantFraisSpecial = 0;

        //Parcours des détails d'ij de la case pour recalcul du montant total pour l'infobulle
        for (let detail of regle.details) {
            //Identifiant du domaine de prestation du détail d'ij concerné
            const idDom: number = detail.ij.prestation.idDomainePrestation;

            let fraisSpecial = null;

            //Parcours des FraisMissionIndemnite contenus pour le frais choisi
            for (let fraisIndemn of frais.listeFraisMissionIndemnite) {
                //Si l'id du domaine de prestation correspond, on mémorise le fraisIndemn
                if (fraisIndemn.idDomaine == idDom) {
                    fraisSpecial = fraisIndemn;
                    break;
                }
            }

            //Rafraichissement du montant total pour la case
            if (fraisSpecial != null) {
                montantFraisSpecial += fraisSpecial.montant * this.coefficientModificateurOd / 100;
            } else {
                montantFraisSpecial += detail.montantAbattementCoeff;
            }
        }

        return montantFraisSpecial;
    }

    /**
     * Vérifie si le frais pour la règle est utilisé ou non
     *
     * @param regle La règle
     */
    isFraisNotUsed(regle: Regle): boolean {
        return !!regle.selectedFrais && !regle.details[0]?.used;
    }

}
