<div class="document-viewer">
    <div class="switcher">
        <div class="switcher-inner" [class.clickable]="isShowBullets" [ngClass]="{ active: currentIndex == idxDocument }"
                *ngFor="let document of listeDocuments; let idxDocument = index" (click)="isShowBullets && showDocument()">
            <div class="content" *ngIf="IMAGE_TYPES.indexOf(document.contentType) != -1 || !!document.thumbnailName; else tplPdfNoPreview" [ngStyle]="{ 'background-image': 'url(' + getUrlForDocument(document) + ')' }"></div>
            <ng-template #tplPdfNoPreview>
                <div class="content">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span>{{ document.libelle || document.fichier }}</span>
                </div>
            </ng-template>
        </div>
        <div class="btn-container">
            <ul>
                <li>
                    <button mat-icon-button [disabled]="currentIndex == 0" (click)="currentIndex = currentIndex - 1;">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                </li>
                <li *ngIf="!isShowBullets; else tplBullets;">
                    <button mat-icon-button (click)="showDocument()">
                        <mat-icon>search</mat-icon>
                    </button>
                </li>
                <ng-template #tplBullets>
                    <li class="bullets">
                        <i *ngFor="let doc of listeDocuments; index as iDoc;" class="material-icons small clickable"
                           [class.active]="currentIndex === iDoc"
                           (click)="currentIndex = iDoc;">circle</i>
                    </li>
                </ng-template>
                <li>
                    <button mat-icon-button [disabled]=" listeDocuments == null || currentIndex == listeDocuments.length - 1" (click)="currentIndex = currentIndex + 1;">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>