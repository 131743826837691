import {HttpClient,HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from '@environments/environment';
import {TypeProfil,User} from "@domain/user/user";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {UserContrat} from "@domain/user/user-contrat";
import {Collaborateur} from "@domain/user/collaborateur";
import {Device} from "@domain/admin/entreprise/user/device";
import {Filtre} from "@domain/admin/entreprise/user/filtre";
import {ParticipantExterne} from "@domain/participant/participant-externe";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {Alerte, NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Service du menu Entreprise > Utilisateurs
 */
@Injectable({
	providedIn: 'root'
})
export class EntrepriseUtilisateursService {
	/**
	 * Constructeur
	 *
	 * @param http client HTTP
	 * @param translateService service des traductions
	 */
	constructor(
		private http: HttpClient,
		private translateService: TranslateService
	) {
	}

	/**
	 * Chargement d'un utilisateur
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 */
	loadUser(idUser: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/loadUser/${idUser}`);
	}

	/**
	 * Chargement du collaborateur
	 *
	 * @param idCollab l'identifiant du collaborateur
	 */
	loadCollab(idCollab: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/loadCollab/${idCollab}`);
	}

	/**
	 * Sauvegarde d'un utilisateur
	 *
	 * @param user l'utilisateur
	 */
	saveUser(user: User): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveUserBody`,user);
	}

	/**
	 * Initialisation du collaborateur à la création d'un utilisateur
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 */
	saveCollabAtCreation(idUser: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveCollabAtCreation?idUser=${idUser}`,null);
	}

	/**
	 * Sauvegarde d'un collaborateur
	 *
	 * @param collab le collaborateur
	 * @param idRole l'ID du rôle du collaborateur
	 */
	saveCollab(collab: Collaborateur,idRole: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveCollabBody?idRole=${idRole}`,collab);
	}

	/**
	 * Suppression d'un utilisateur
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 */
	deleteUser(idUser: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/deleteUser/${idUser}`);
	}

	/**
	 * Réinitialisation du mot de passe d'un utilisateur
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 */
	resetPassword(idUser: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Connexion/reinitPass/${idUser}`,null);
	}

	/**
	 * Ajout d'une ou plusieurs enveloppes à une habilitation
	 *
	 * @param idLienRoleUser l'ID de l'habilitation
	 * @param listeIdEnveloppe les ID des enveloppes à attacher à l'habilitation
	 */
	addEnveloppeToHabilitation(idLienRoleUser: number,listeIdEnveloppe: number[]): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/addEnveloppeToHabilitation?idLienRoleUser=${idLienRoleUser}`,listeIdEnveloppe);
	}

	/**
	 * Suppression d'une ou plusieurs enveloppes d'une habilitation
	 *
	 * @param idLienRoleUser l'ID de l'habilitation
	 * @param listeIdEnveloppe les ID des enveloppes à détacher de l'habilitation
	 */
	deleteEnveloppeFromHabilitation(idLienRoleUser: number,listeIdEnveloppe: number[]): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/deleteEnveloppesFromHabilitation?idLienRoleUser=${idLienRoleUser}`,listeIdEnveloppe);
	}

	/**
	 * Sauvegarde d'une habilitation
	 *
	 * @param habilitation l'habilitation
	 */
	saveHabilitation(habilitation: LienRoleUser): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveRoleBody`,habilitation);
	}

	/**
	 * Sauvegarde de la date de fin d'habilitation
	 *
	 * @param habilitation l'habilitation
	 */
	saveDateFinHabilitation(habilitation: LienRoleUser): Observable<Result> {
		return this.http.post<Result>(
			`${environment.baseUrl}/controller/Perso/saveDateFinRoleBody/${habilitation.idLienRoleUser}`,
			moment(habilitation.dateFin));
	}

	/**
	 * Suppression d'une habilitation
	 *
	 * @param idLienRoleUser l'ID de l'habilitation
	 */
	deleteHabilitation(idLienRoleUser: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/deleteRole/${idLienRoleUser}`);
	}

	/**
	 * Sauvegarde d'un contrat
	 *
	 * @param contrat le contrat
	 */
	saveContrat(contrat: UserContrat): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveContrat`,contrat);
	}

	/**
	 * Suppression d'un contrat
	 *
	 * @param contrat le contrat
	 */
	deleteContrat(contrat: UserContrat): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/deleteContrat`,contrat);
	}

	/**
	 * Récupération du profil voyageur
	 *
	 * @param idUser l'ID de l'utilisateur
	 */
	getProfilVoyageur(idUser: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/profilVoyageur/${idUser}`,null);
	}

	/**
	 * Chargement des infos du compte bancaire par défaut
	 */
	loadDefaultCompte(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/CompteBancaireAdmin/loadCompte/0`,null);
	}

    /**
     * Sauvegarde d'un appareil de l'utilisateur
     *
     * @param device l'appareil
     */
    saveMobileDevice(device: Device): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveMobileDevice`,device);
    }

    /**
     * Suppression d'un appareil de l'utilisateur
     *
     * @param device l'appareil
     */
    deleteMobileDevice(device: Device): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/User/deleteMobileDevice/${device.idDevice}`,null);
    }

    /**
     * Ajout d'un filtre
     *
     * @param filtre le filtre
     * @param fonction le profil auquel ajouter le filtre (collaborateur = 3, responsable = 2)
     */
    addFiltre(filtre: Filtre,fonction: TypeProfil): Observable<Result> {
        //Construction des paramètres HTTP
        const params: HttpParams = new HttpParams()
            .set('id_user',String(filtre.idUser))
            .set('id_filtre',String(filtre.idFiltre))
            .set('type_filtre',String(filtre.typeFiltre))
            .set('fonction',String(fonction))
            .set('is_delete',String(false));

        return this.http.post<Result>(`${environment.baseUrl}/controller/User/updateFiltre`,null,{params: params});
    }

    /**
     * Suppression d'un filtre
     *
     * @param filtre le filtre
     * @param fonction le profil auquel ajouter le filtre (collaborateur = 3, responsable = 2)
     */
    deleteFiltre(filtre: Filtre,fonction: TypeProfil): Observable<Result> {
        //Construction des paramètres HTTP
        const params: HttpParams = new HttpParams()
            .set('id_user',String(filtre.idUser))
            .set('id_filtre',String(filtre.idFiltre))
            .set('type_filtre',String(filtre.typeFiltre))
            .set('fonction',String(fonction))
            .set('is_delete',String(true));

        return this.http.post<Result>(`${environment.baseUrl}/controller/User/updateFiltre`,null,{params: params});
    }

	/**
	 * Sauvegarde d'un participant externe
	 *
	 * @param participantExterne le participant externe
	 */
	saveExterne(participantExterne: ParticipantExterne): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Externe/saveExterne`,participantExterne);
	}

	/**
	 * Promotion d'un externe
	 *
	 * @param idParticipantExterne l'identifiant du participant externe
	 */
	promouvoirExterne(idParticipantExterne: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Externe/promouvoirExterne`,idParticipantExterne);
	}

	/**
	 * Fusion de plusieurs externes
	 *
	 * @param listeExternes la liste des externes à fusionner
	 */
	fusionnerExternes(listeExternes: ParticipantExterne[]): Observable<Result> {
		//Définition de la liste des IDs des externes à fusionner
		const listeIds: number[] = listeExternes.map(externe => externe.idParticipantExterne);

		return this.http.post<Result>(`${environment.baseUrl}/controller/Externe/fusionProfil`,listeIds);
	}

	/**
	 * Suppression d'un participant externe
	 *
	 * @param idParticipantExterne l'identifiant du participant externe
	 */
	deleteExterne(idParticipantExterne: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Externe/deleteExterne/${idParticipantExterne}`);
	}

	/**
	 * Chargement de la liste des habilitations
	 *
	 * @param idUser id de l'user
	 */
	loadListeHabilitations(idUser: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/listeHabilitations/${idUser}`);
	}

	/**
	 * Renvoie une liste d'alertes contenant seulement l'alerte rgpd
	 */
	listeAlerteRgpd(): Alerte[] {
		return [{
			titre: null,
			message: this.translateService.instant("profil.alerte.rgpd"),
			niveau: NiveauAlerte.RGPD
		}];
	}
}
