<div class="lvi-content">
    <div *ngIf="!extraOptions.canChangeEnveloppe" class="avatar">
        <i class="material-icons">mail_outline</i>
    </div>
    <div *ngIf="extraOptions.canChangeEnveloppe" class="avatar" [nSelector]="{ liste: liste, item: data }">
        <i class="material-icons">mail_outline</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.reference != null && data.reference != '' ? data.reference + ' - ' : '' }} {{ data.libelleEnveloppe }}</a>
        </div>
    </div>
</div>