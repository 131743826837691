<div class="sticky-item"
     (click)="onClick()"
     [class.non-clickable]="!data.formulaireModifie"
     [nioTooltip]="ListeVisualisationTooltipItemComponent"
     [nioTooltipContext]="{ segment: data }"
     nioTooltipPosition="track"
     [class.highlight]="data[HIGHLIGHT]"
     [class.hover]="data[HOVER]">
    <div class="sticky-item-role">
        {{ data.role?.libelle }}
    </div>
    <div *ngIf="showSegmentNamesColumn" class="sticky-item-name">
        {{ data.libelle }}
    </div>
</div>
