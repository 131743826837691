<mat-card>
    <mat-card-title>
        <span [class.success]="od.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.NO_CONTROL" [class.error]="od.lastWorkflowHistoriqueActionAlerte?.niveau == NiveauAlerte.ERROR">{{ od.typeEntite.libelle }}
            <proprietaire [proprietaireUser]="od.user"></proprietaire></span>
        <!-- On affiche si on n'est pas en création -->
        <span
                *ngIf="od?.statut?.idEtat !== EnumEtat.EN_CREATION && typeEntiteParam.gestionTemps !== 3 && od?.dateDebRL"
                (click)="openSaisieTempsPopup()" [matTooltip]="'od.saisieTemps.title' | translate"
                class="actions">
            <mat-icon>search</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <card-alerte *ngIf="od.lastWorkflowHistoriqueActionAlerte" [niveau]="od.lastWorkflowHistoriqueActionAlerte.niveau">
            <preview-alerte [alerte]="od.lastWorkflowHistoriqueActionAlerte"></preview-alerte>
        </card-alerte>
        <form autocomplete="off" #form="ngForm" name="form_generalites">
            <fieldset [disabled]="!canModifier">
                <!-- On attend que les listes aient été initialisées puis on les envoie au composant -->
                <filling-row *ngIf="listeTemplatesGauche.length > 0 || listeTemplatesDroite.length > 0" [listeGauche]="listeTemplatesGauche"
                             [listeDroite]="listeTemplatesDroite"></filling-row>

                <!-- Zones utilisateurs-->
                <zone-utilisateur #zu="zu" [listeZus]="od.listeZU" [listeZuSaisies]="od.listeZUSaisies" [readonly]="!canModifier"></zone-utilisateur>

                <!-- Champs remarque -->
                <div class="row">
                    <label class="col-md-2 align-items-start p-t-15" for="remarque"><span [translate]="'od.generalites.remarque'"></span></label>
                    <div class="col-md-9">
                        <mat-form-field class="d-flex">
                            <textarea id="remarque" matInput name="remarque" [(ngModel)]="od.obs" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5" maxlength="1000"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>

<!-- Objet -->
<ng-template #objet>
    <custom-input ngDefaultControl name="objet" customType="input" libelle="od.generalites.objet" [disabled]="!canModifier" [(ngModel)]="od.objet" [required]="true"
                  [postTooltipNio]="isChevauchement ? tooltipChevauchement : null"></custom-input>
</ng-template>

<!-- Mois d'imputation -->
<ng-template #moisImputation>
    <custom-input name="mois_imputation" customType="objectselect" libelle="od.generalites.moisImputation" [disabled]="!canModifier"
                  [(ngModel)]="od.periode" [required]="true" rCol="2"
                  optionValue="idPeriode" optionDisplay="libelle" [selectOptions]="settings.listePeriodes" ngDefaultControl></custom-input>
</ng-template>

<!-- Lien vers l'od permanent lié -->
<ng-template #omp>
    <label class="col-md-2"><span [translate]="'od.generalites.omp'" [class.text-muted]="!canModifier" [class.text-secondary]="!canModifier"></span></label>
    <div class="col-md-4" style="margin-bottom: 0.5rem;align-self: center;" [nioTooltip]="ompTooltip">
        <div class="d-flex flex-row">
            <span>{{ od.omPermanent.idOMPermanent }} - {{ od.omPermanent.objet }} </span>
            <a [routerLink]="" (click)="navigateToOMP()">
                <span class="material-icons">open_in_new</span>
            </a>
        </div>
    </div>
</ng-template>

<!-- Début -->
<ng-template #debut>
    <label class="col-md-2 required" for="date_debut" [ngClass]="{ 'has-error': isLoaded && isDebutInvalid(), 'warning': isChevauchement }">
        <span [translate]="'od.generalites.debut'" [class.text-muted]="!canModifier" [nioTooltip]="isChevauchement ? ('od.generalites.tooltipChevauchement' | translate) : null"
        [class.text-secondary]="!canModifier"></span>
    </label>
    <div class="col-md-2" [ngClass]="{ 'has-error': isDateDebutInvalid() }">
        <mat-form-field class="d-flex">
            <input id="date_debut" name="date_debut" #dateDebut="ngModel" matInput [matDatepicker]="datePickerDebut" [(ngModel)]="od.depart_le" (ngModelChange)="onDateDebutChange()" required>
            <mat-datepicker-toggle matSuffix [for]="datePickerDebut"></mat-datepicker-toggle>
            <mat-datepicker #datePickerDebut [disabled]="!canModifier"></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="isDateDebutInvalid() && !isDateDebutOutsideOMPDates()">
            <span translate>od.errors.dateInvalide</span>
        </mat-error>
        <mat-error *ngIf="isDateDebutInvalid() && isDateDebutOutsideOMPDates()">
            <span translate>od.errors.dateOutsideOmpDates</span>
        </mat-error>
    </div>
    <div class="col-md-2" [hidden]="!settings.isPlageHoraire" [ngClass]="{ 'has-error': isHeureDebutInvalid() }">
        <mat-form-field class="d-flex">
            <input #heureDepart="ngModel" type="time" name="heureDepart" matInput [(ngModel)]="od.heureDepart" (ngModelChange)="onHeureDebutChange()" required>
        </mat-form-field>
        <mat-error *ngIf="isHeureDebutInvalid()">
            <span translate>od.errors.heureInvalide</span>
        </mat-error>
    </div>
</ng-template>

<!-- Fin -->
<ng-template #fin>
    <label class="col-md-2 required" for="date_fin" [ngClass]="{ 'has-error': isLoaded && isFinInvalid(), 'warning': isChevauchement }">
        <span [translate]="'od.generalites.fin'" [class.text-muted]="!canModifier" [nioTooltip]="isChevauchement ? ('od.generalites.tooltipChevauchement' | translate) : null"
        [class.text-secondary]="!canModifier"></span>
    </label>
    <div class="col-md-2" [ngClass]="{ 'has-error': isDateFinInvalid() }">
        <mat-form-field class="d-flex">
            <input id="date_fin" name="date_fin" #dateFin="ngModel" matInput [matDatepicker]="datePickerFin" [(ngModel)]="od.retour_le" required (ngModelChange)="onDateFinChange()">
            <mat-datepicker-toggle matSuffix [for]="datePickerFin"></mat-datepicker-toggle>
            <mat-datepicker #datePickerFin [disabled]="!canModifier" [startAt]="od.depart_le"></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="isDateFinInvalid() && !isDateFinSameOrBeforeDateDebut() && !isDateFinOutsideOMPDates()">
            <span translate>od.errors.dateInvalide</span>
        </mat-error>
        <mat-error *ngIf="isDateFinInvalid() && isDateFinSameOrBeforeDateDebut() && !isDateFinOutsideOMPDates()">
            <span translate>od.errors.dateFinAntDateDeb</span>
        </mat-error>
        <mat-error *ngIf="isDateFinInvalid() && isDateFinOutsideOMPDates()">
            <span translate>od.errors.dateOutsideOmpDates</span>
        </mat-error>
    </div>
    <div class="col-md-2" [hidden]="!settings.isPlageHoraire" [ngClass]="{ 'has-error': isHeureFinInvalid() }">
        <mat-form-field class="d-flex">
            <input #heureRetour="ngModel" type="time" name="heureRetour" matInput [(ngModel)]="od.heureRetour" (ngModelChange)="onHeureFinChange()" required>
        </mat-form-field>
        <mat-error *ngIf="isHeureFinInvalid()">
            <span translate>od.errors.heureInvalide</span>
        </mat-error>
    </div>
</ng-template>

<!-- Destination -->
<ng-template #destination>
    <label class="col-md-2 required" [for]="'idDestination'" [ngClass]="{ 'has-error': !isDestinationValid() }" [class.text-muted]="!canModifier"
        [class.text-secondary]="!canModifier"><span translate>od.generalites.destination</span></label>
    <div class="col-md-4" [ngClass]="{ 'has-error': !isDestinationValid() }">
        <autocomplete type="geographie" name="geographie" #geographie="ngModel"
                      [filter]="{ listeTypes: typeEntiteParam?.villeObligatoireOD ? ['VILLE'] : !typeEntiteParam?.gestionVilleOD ? ['PAYS'] : ['PAYS','VILLE'], contexte: porteeGeographie.OD }"
                      [(ngModel)]="od.destination"
                      (onSelect)="setDestination($event);"
                      ngDefaultControl
                      required
                      [placeholder]="(typeEntiteParam?.villeObligatoireOD ? 'omp.generalites.ville' : !typeEntiteParam?.gestionVilleOD ? 'omp.generalites.pays' : 'omp.generalites.villePays') | translate"
                      [floatLabel]="'never'"
                      [inputId]="'idDestination'"
                      [disabled]="!canModifier || !typeEntiteParam?.destinationModifiableOD"></autocomplete>
    </div>
</ng-template>

<!-- Lieu de départ -->
<ng-template #lieuDepart>
    <label class="col-md-2 required" [ngClass]="{ 'has-error': isLoaded && lieuDepart.invalid }" [class.text-muted]="!canModifier"
        [class.text-secondary]="!canModifier"><span translate>od.generalites.lieuDepart</span></label>
    <div class="col-md-4" [ngClass]="{ 'has-error': isLoaded && lieuDepart.invalid }">
        <lieu ngDefaultControl #lieuDepart="ngModel" name="lieuDepart"
                [disabled]="!canModifier" [required]="true" [(ngModel)]="od.lieuDepartOd" [(lieu)]="od.lieuDepartOd" [(typeLieu)]="od.lieuDepart"
                [idUser]="od.user.idUser" [idPortee]="TypePortee.OD" [idObject]="od.idOd" [typeEntiteParam]="typeEntiteParam"></lieu>
    </div>
</ng-template>

<!-- A traiter avant le -->
<ng-template #traiterLe>
    <label class="col-md-2" for="date_pour_le" [ngClass]="{ 'has-error': isLoaded && datePourLe.invalid, 'required': settings.isTraiterAvantOblig }" [class.text-muted]="!canModifier"
        [class.text-secondary]="!canModifier"><span [translate]="'od.generalites.traiterAvant'"></span></label>
    <div class="col-md-2" [ngClass]="{ 'has-error': isLoaded && datePourLe.invalid }">
        <mat-form-field class="d-flex">
            <input #datePourLe="ngModel" id="date_pour_le" name="date_pour_le" #date="ngModel" matInput [matDatepicker]="datePicker" [(ngModel)]="od.pour_le"
                   [required]="settings.isTraiterAvantOblig" [disabled]="!canModifier || !settings.isTraiterAvantModifiable">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker [disabled]="!canModifier || !settings.isTraiterAvantModifiable"></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #lieuRetour>
    <!-- Lieu de retour -->
    <label class="col-md-2 required" [ngClass]="{ 'has-error': isLoaded && lieuRetour.invalid }" [class.text-muted]="!canModifier"
        [class.text-secondary]="!canModifier"><span [translate]="'od.generalites.lieuRetour'"></span></label>
    <div class="col-md-4" [ngClass]="{ 'has-error': isLoaded && lieuRetour.invalid }">
        <lieu ngDefaultControl #lieuRetour="ngModel" name="lieuRetour"
                [disabled]="!canModifier" [required]="true" [(ngModel)]="od.lieuRetourOd" [(lieu)]="od.lieuRetourOd" [(typeLieu)]="od.lieuRetour"
                [idUser]="od.user.idUser" [idPortee]="TypePortee.OD" [idObject]="od.idOd" [typeEntiteParam]="typeEntiteParam"></lieu>
    </div>
</ng-template>

<!-- Template du tooltip d'information de l'OMP -->
<ng-template #ompTooltip>
    <div style="min-width: 300px">
        <div class="row">
            <label class="col-sm-2 offset-md-2 pr-1" translate>omp.generalites.destination</label>
            <div class="col-sm-8 pl-1">{{ od.omPermanent.ville ? od.omPermanent.ville.libelle + ', ' + od.omPermanent.pays.libelle : od.omPermanent.pays.libelle }}</div>
        </div>
        <div class="row">
            <label class="col-sm-2 offset-md-2 pr-1" translate>omp.generalites.debut</label>
            <div class="col-sm-4 pl-1">{{ od.omPermanent.dateDebut | date:'shortDate' }}</div>
            <div class="col-sm-4 pl-1">{{ od.omPermanent.heureDebut }}</div>
        </div>
        <div class="row">
            <label class="col-sm-2 offset-md-2 pr-1" translate>omp.generalites.fin</label>
            <div class="col-sm-4 pl-1">{{ od.omPermanent.dateFin | date:'shortDate' }}</div>
            <div class="col-sm-4 pl-1">{{ od.omPermanent.heureFin }}</div>
        </div>
    </div>
</ng-template>

<!-- Case départ urgent -->
<ng-template #departUrgent>
    <custom-input ngDefaultControl name="departUrgent"
                  customType="checkbox"
                  [ngModel]="od.urgent"
                  [(customModel)]="od.urgent"
                  libelle="od.generalites.departUrgent"
                  postLibelle="od.generalites.departUrgentDescr"
                  [disabled]="!canModifier"
                  (onChange)="departUrgentChanged()"></custom-input>
</ng-template>

<!-- Case demande d'avance -->
<ng-template #demandeAvance>
    <custom-input
            customType="checkbox"
            ngDefaultControl
            name="demandeAvance"
            [ngModel]="od.demandeAvance"
            [(customModel)]="od.demandeAvance"
            libelle="od.generalites.demandeAvance"
            postLibelle="od.generalites.demandeAvanceDescr"
            [postTooltip]="'od.generalites.demandeAvanceTooltip' | translate:{montant: ((od.plafondAvance || 0) | number:'1.2-2'), devise: settings.deviseEntreprise}"
            [disabled]="!canModifier || hasAvance"
    ></custom-input>
</ng-template>
