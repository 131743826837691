import {Component,Input} from '@angular/core';
import {Statut} from "@domain/workflow/statut";
import {EnumEtat} from "@domain/workflow/etat";
import {TranslateService} from "@ngx-translate/core";
import {SelectStatutRepresentationPopinComponent} from "@components/admin/workflow/statuts/statuts/detail/generalites/select-statut-representation-popin/select-statut-representation-popin.component";
import {MatDialog} from "@angular/material/dialog";
import {ControlContainer, NgForm} from "@angular/forms";

/**
 * Composant de l'onglet 'généralités' d'un statut
 *
 * @author Angeline Ha
 * @date /09/2024
 */
@Component({
  host: {'data-test-id': 'statut-generalites'},
  selector: 'statut-generalites',
  templateUrl: './statut-generalites.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class StatutGeneralitesComponent {

  /** Statut */
  @Input() statut: Statut;

  /** Liste des options du select */
  listeEtats: Array<OptionEtat> = [{
    id: EnumEtat.EN_CREATION,
    libelle: this.translateService.instant('global.etats.creation')
  }, {
    id: EnumEtat.EN_VALIDATION,
    libelle: this.translateService.instant('global.etats.validation')
  }, {
    id: EnumEtat.VALIDE,
    libelle: this.translateService.instant('global.etats.valide')
  }, {
    id: EnumEtat.COMPTABILISE_CLOTURE,
    libelle: this.translateService.instant('global.etats.comptabilise_cloture')
  }, {
    id: EnumEtat.ARCHIVE,
    libelle: this.translateService.instant('global.etats.archive')
  }, {
    id: EnumEtat.ANNULE,
    libelle: this.translateService.instant('global.etats.annule')
  }]

  /**
   * Constructeur
   *
   * @param translateService Service des traductions
   * @param matDialog Boîte de dialogue
   */
  constructor(private translateService: TranslateService,
              private matDialog: MatDialog) {
  }

  /**
   * Appelé au clic sur la sélection de la représentation du statut
   */
  onClickRepresentation(): void {
    //Ouverture de la popin
    this.matDialog.open(SelectStatutRepresentationPopinComponent, {
      data: {
        // width: '10%'
      }
    }).afterClosed().subscribe((data?: {image: string, lienImage: string}) => {
      //Lors de la fermeture de la fenêtre, on récupère la représentation sélectionnée
     if (!!data) {
       this.statut.image = data.image;
       this.statut.lienImage = data.lienImage;
     }
    });
  }
}

/**
 * Option du select 'Etat'
 */
type OptionEtat = {
  id: number,
  libelle: string
}
