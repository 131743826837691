import {User} from "@domain/user/user";
import {OrgaDTO} from "@domain/od/od";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Modèle population
 */
export class Population implements ListItem {
    idPopulation?: number;
    origine?: Origine;
    typeCreation?: TypeCreation;
    actif?: boolean;
    reference?: string;
    libelle?: string;
    remarque?: string;
    listeServices?: OrgaDTO[];
    listeUtilisateurs?: User[];
    idUser: number;

    getKey(): number {
        return this.idPopulation;
    }
}

/**
 * Enumération des origines
 */
export enum Origine {
    ORIGINE_ADMINISTRATEUR = 0,
    ORIGINE_IMPORT = 1,
    ORIGINE_USER = 2
}

/**
 * Enumération des types création
 */
export enum TypeCreation {
    TYPE_CREATION_STANDARD = 0,
    TYPE_CREATION_VOLEE = 1
}

