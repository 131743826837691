import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {UserDto} from "@domain/user/user-dto";
import {ResponsableItemComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-responsable/responsable-item/responsable-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Composant d'affichage pour la liste des responsables budg�taires des enveloppes
 *
 * @author Lionel Gimenez
 * @date 04/12/2024
 */
@Component({
    host: {'data-test-id': 'enveloppe-responsable'},
    selector: 'enveloppe-responsable',
    templateUrl: './enveloppe-responsable.component.html'
})
export class EnveloppeResponsableComponent implements OnInit {
    /** Id de l'enveloppe */
    @Input() idEnveloppe: number;

    /** Liste des responsables */
    listeResponsable: ListView<UserDto,ResponsableItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Initialisation de la liste
        this.listeResponsable = new ListView<UserDto,ResponsableItemComponent>({
            uri: `/controller/Budget/listeResponsableBudgetaireEnveloppe/${this.idEnveloppe}`,
            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.responsable.title'),
            component: ResponsableItemComponent,
            isFilter: true,
            defaultOrder: 'user.nom',
            listeFilters: [
                {
                    title: this.translateService.instant('filter.prenom'),
                    clef: 'user.prenom',
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    title: this.translateService.instant('filter.nom'),
                    clef: 'user.nom',
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    title: this.translateService.instant('filter.matricule'),
                    clef: 'user.matricule',
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: true
                },{
                    title: this.translateService.instant('admin.entreprise.analytique.enveloppes.responsable.actif'),
                    clef: 'user.isActif',
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: false,
                    onloadValue: 'true'
                }
            ]
        });

        //D�finition des colonnes de tri
        this.listeResponsable.columns = [
            {key: 'user.prenom',title: 'filter.prenom'},
            {key: 'user.nom',title: 'filter.nom'},
            {key: 'user.matricule',title: 'filter.matricule'},
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeResponsable.sorting = new Sorting(this.listeResponsable.columns,'user.nom');

        //Persistence des filtres
        this.listeResponsable.isPersistFilters = true;
        this.listeResponsable.className = 'EnveloppeResponsableComponent';
    }
}