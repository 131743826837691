import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {WfStatutsComponent} from "./wf-statuts.component";
import {StatutsListeComponent} from "@components/admin/workflow/statuts/statuts/liste/statuts-liste.component";
import {StatutDetailComponent} from "@components/admin/workflow/statuts/statuts/detail/statut-detail.component";
import {EtatsListeComponent} from "@components/admin/workflow/statuts/etats/liste/etats-liste.component";
import {EtatDetailComponent} from "@components/admin/workflow/statuts/etats/detail/etat-detail.component";
import {WorkflowModule} from "@components/workflow/workflow.module";
import {StatutGeneralitesComponent} from "@components/admin/workflow/statuts/statuts/detail/generalites/statut-generalites.component";
import {StatutListeItemComponent} from "@components/admin/workflow/statuts/statuts/liste/statut-liste-item.component";
import {EtatListeItemComponent} from "@components/admin/workflow/statuts/etats/liste/etat-liste-item.component";
import {SelectStatutRepresentationPopinComponent} from "@components/admin/workflow/statuts/statuts/detail/generalites/select-statut-representation-popin/select-statut-representation-popin.component";
import {WorkflowStatutsService} from "@services/admin/workflow/workflow-statuts.service";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";

export const workflowStatutsRoutes : Routes = [
    {
        path: 'Statuts',
        component: StatutsListeComponent
    },{
        path: 'Statuts/:idStatut',
        component: StatutDetailComponent
    },{
        path: 'Etats',
        component: EtatsListeComponent
    },{
        path: 'Etats/:idEtat',
        component: EtatDetailComponent
    },{
        path: '',
        pathMatch: 'full',
        redirectTo: 'Statuts'
    },{
        path: '**',
        redirectTo: 'Statuts'
    }
];

@NgModule({
    imports: [
        ShareModule,
        WorkflowModule,
        PreviewAlerteModule
    ],
    declarations: [
        WfStatutsComponent,
        StatutsListeComponent,
        StatutDetailComponent,
        StatutGeneralitesComponent,
        StatutListeItemComponent,
        EtatDetailComponent,
        EtatsListeComponent,
        EtatListeItemComponent,
        SelectStatutRepresentationPopinComponent
    ],
    entryComponents: [],
    exports: [
    ],
    providers: [
        WorkflowStatutsService
    ]
})
export class WfStatutsModule {}
