import {Action, Formulaire, Message, Role, Statut} from "@domain/admin/workflow/segment";

/**
 * Liste des états possibles
 */
export const STATUTS = {
    ETAT_EN_CREATION : {
        idEtat: 1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.enCreation'
    } as Statut,
    ETAT_EN_VALIDATION : {
        idEtat: 2,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.enValidation'
    } as Statut,
    ETAT_VALIDE : {
        idEtat: 3,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.valide'
    } as Statut,
    ETAT_COMPTABILISE_CLOTURE : {
        idEtat: 4,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.comptabiliseCloture'
    } as Statut,
    ETAT_ARCHIVE : {
        idEtat: 5,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.archive'
    } as Statut,
    ETAT_ANNULE : {
        idEtat: 6,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.etats.annule'
    } as Statut
};

/**
 * Liste des actions possibles
 */
export const ACTIONS = {
    CONSULTATION: {
        id: -1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.CONSULTATION'
    } as Action,
    CREATION: {
        id: 1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.CREATION'
    } as Action,
    VALIDATION: {
        id: 1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.VALIDATION'
    } as Action,
    REJET: {
        id: 2,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.REJET'
    } as Action,
    INVALIDATION: {
        id: 3,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.INVALIDATION'
    } as Action,
    ANNULATION: {
        id: 4,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.ANNULATION'
    } as Action,
    MODIFICATION: {
        id: 5,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.MODIFICATION'
    } as Action,
    SUPPRESSION: {
        id: 6,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.SUPPRESSION'
    } as Action,
    EMISSION: {
        id: 7,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.EMISSION'
    } as Action,
    ARCHIVAGE: {
        id: 8,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.ARCHIVAGE'
    } as Action,
    COMPTABILISATION: {
        id: 9,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.COMPTABILISATION'
    } as Action,
    COMPLETION: {
        id: 10,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.COMPLETION'
    } as Action,
    COMMANDE: {
        id: 11,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.COMMANDE'
    } as Action,
    RECEPTION: {
        id: 12,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.RECEPTION'
    } as Action,
    ATTRIBUTION: {
        id: 13,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.ATTRIBUTION'
    } as Action,
    RESTITUTION: {
        id: 14,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.RESTITUTION'
    } as Action,
    WF_ACTION_1: {
        id: 16,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.WF_ACTION_1'
    } as Action,
    CLOTURE: {
        id: 20,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actions.CLOTURE'
    } as Action
};

/**
 * Liste des portées possibles
 */
export const PORTEES = {
    AVANCE: "AV",
    OM: "OD",
    OMP: "OT",
    DV: "DV",
    NDF: "NF",
    FACTURE: "FC"
};

/**
 * Liste des procédures supplémentaires possibles
 */
export const PROCEDURES_SUPP = {
    CALCULS_FINAUX_NDF: {
        id: 2501,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.CALCULS_FINAUX_NDF'
    } as Action,
    RAPPEL_APRES_CALCULS_FINAUX_NDF: {
        id: 2502,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.RAPPEL_APRES_CALCULS_FINAUX_NDF'
    } as Action,
    OCTROI_AVANCE: {
        id: 3503,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.OCTROI_AVANCE'
    } as Action,
    DESOCTROI_AVANCE: {
        id: 3502,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.DESOCTROI_AVANCE'
    } as Action,
    CALCULS_FINAUX_FACTURE: {
        id: 4501,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.CALCULS_FINAUX_FACTURE'
    } as Action,
    RAPPEL_APRES_CALCULS_FINAUX_FACTURE: {
        id: 4502,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.RAPPEL_APRES_CALCULS_FINAUX_FACTURE'
    } as Action,
    DEMANDE_DV: {
        id: 3001,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.DEMANDE_DV'
    } as Action,
    ANNULATION_DV: {
        id: 3002,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.ANNULATION_DV'
    } as Action,
    MODIFICATION_DV: {
        id: 3003,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.MODIFICATION_DV'
    } as Action,
    COMMANDE_DV: {
        id: 3004,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresComplementaires.COMMANDE_DV'
    } as Action
};

/**
 * Liste des actions supplémentaires possibles
 */
export const ACTIONS_SUPP = {
    CREATION_NDF: {
        id: 1001,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_NDF',
        icon: { class: 'nio icon-note_de_frais' }
    } as Action,
    CREATION_DEMANDE_AVANCE: {
        id: 1002,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_DEMANDE_AVANCE',
        icon: { class: 'nio icon-avance_2' }
    } as Action,
    CREATION_HEBERGEMENT: {
        id: 1003,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_HEBERGEMENT',
        icon: { icon: 'bed', class: 'material-icons-outlined' }
    } as Action,
    CREATION_FACTURE: {
        id: 1004,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_FACTURE',
        icon: { class: 'nio icon-facture' }
    } as Action,
    CREATION_TRANSPORTS: {
        id: 1005,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_TRANSPORTS',
        icon: { icon: 'airplane_ticket', class: 'material-icons-outlined' }
    } as Action,
    CREATION_DOSSIER_VOYAGE: {
        id: 1009,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_DOSSIER_VOYAGE',
        icon: { icon: 'flight', class: 'material-icons-outlined' }
    } as Action,
    CREATION_OM: {
        id: 1015,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_OM',
        icon: { class: 'nio icon-ordre_de_mission' }
    } as Action,
    CREATION_FORMULAIRE_OM: {
        id: 1016,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_FORMULAIRE_OM',
        icon: { class: 'nio icon-ordre_de_mission' }
    } as Action,
    CREATION_FORMULAIRE_AUTRE: {
        id: 1017,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_FORMULAIRE_AUTRE',
        icon: { icon: 'list', class: 'material-icons-outlined' }
    } as Action,
    GESTION_TEMPS: {
        id: 1018,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.GESTION_TEMPS',
        icon: { icon: 'timer', class: 'material-icons-outlined' }
    } as Action,
    CREATION_FACTURE_PREVISION: {
        id: 1019,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_FACTURE_PREVISION',
        icon: { class: 'nio icon-facture_previsionnelle' }
    } as Action,
    CREATION_FRAIS_PREVISION: {
        id: 1101,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_FRAIS_PREVISION',
        icon: { class: 'nio icon-frais_previsionnel_2' }
    } as Action,

    /* /!\ Bizarrerie historique :
        Actions supplémentaires "spéciales", ne donnant pas lieu à la création d'un segment en bdd, mais stockés dans la même colonne que le plugin : lst_action_supp
    */
    CREATION_AVANCE_AUTO: {
        id: -1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.CREATION_AVANCE_AUTO',
        icon: { class: 'nio icon-avance_2' }
    } as Action,
    APPLICATION_FRANCHISE: {
        id: -2,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.APPLICATION_FRANCHISE',
        icon: { icon: 'speed', class: 'material-icons-outlined' }
    } as Action,
    SUPPRESSION_FRANCHISE: {
        id: -3,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionsSupp.SUPPRESSION_FRANCHISE',
        icon: { icon: 'speed', class: 'material-icons-outlined' }
    } as Action
};

/**
 * Liste des formulaires possibles
 */
export const FORMULAIRES = [{
    code: Formulaire.AV,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.AV'
}, {
    code: Formulaire.DV,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.DV'
}, {
    code: Formulaire.FC,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.FC'
}, {
    code: Formulaire.NF,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.NF'
}, {
    code: Formulaire.OD,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.OD'
}, {
    code: Formulaire.OT,
    libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaires.OT'
}];

/**
 * Liste des profils
 */
export const PROFILS = {
    PROFIL_COLLABORATEUR : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_COLLABORATEUR'
    } as Role,
    PROFIL_ASSISTANT : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_ASSISTANT'
    } as Role,
    PROFIL_RESPONSABLE : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_RESPONSABLE'
    } as Role,
    PROFIL_COMPTABLE : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_COMPTABLE'
    } as Role,
    PROFIL_FOURNISSEUR : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_FOURNISSEUR'
    } as Role,
    PROFIL_SOUS_ADMINISTRATEUR : {
        libelle: 'admin.workflow.construction.segment.onglets.generalites.generalitesCard.roles.PROFIL_SOUS_ADMINISTRATEUR'
    } as Role
};

/**
 * Liste des procédures budgétaires
 */
export const PROCEDURES_BUDGET = {
    IMPUTATION: {
        id: 1,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresBudgetaires.IMPUTATION'
    },
    LIBERATION_BUDGET: {
        id: 2,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresBudgetaires.LIBERATION_BUDGET'
    },
    CLOTURE: {
        id: 3,
        libelle: 'admin.workflow.construction.segment.onglets.generalites.optionsCard.proceduresBudgetaires.CLOTURE'
    }
};

/**
 * Liste des options de motifs
 */
export const OPTIONS_MOTIFS = [
    {
        code: 'sans',
        libelle: 'admin.workflow.construction.segment.onglets.generalites.notificationsCard.motif.sans'
    }, {
        code: 'proposer',
        libelle: 'admin.workflow.construction.segment.onglets.generalites.notificationsCard.motif.proposer'
    }, {
        code: 'obliger',
        libelle: 'admin.workflow.construction.segment.onglets.generalites.notificationsCard.motif.obliger'
    }
];

/**
 * Message par défaut
 */
export const MESSAGE_PAR_DEFAUT: Message = {
    idMessage: null,
    titre: "admin.workflow.construction.segment.onglets.generalites.notificationsCard.confirmation.default"
} as Message;
