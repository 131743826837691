import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TypeNature} from "@domain/voyage/travel/constants";
import {BehaviorSubject,Observable} from "rxjs";
import {Od} from "@domain/od/od";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {filter,first,switchMap,take} from "rxjs/operators";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";
import {RseService} from "@services/share/rse/rse.service";
import {Rse} from "@domain/rse/rse";
import {ParamRse} from "@domain/admin/parametre/api/partenaires/ParamRse";
import {OdVoyageTravelRsePopupComponent} from "@components/od/detail/voyage/voyage/travel/popup/voyage/rse/od-voyage-travel-rse-popup.component";
import {MatDialog} from "@angular/material/dialog";

/**
 * Service de gestion du RSE pour les OD
 */
@Injectable()
export class ODRseService {
	/**
	 * Contructeur
	 *
	 * @param http Service HTTP
	 * @param rseService Service de gestion du RSE
	 * @param matDialog Service des popin
	 */
	constructor(protected http: HttpClient,
				private rseService: RseService,
				private matDialog: MatDialog) {
	}

	/** Valeur du CO2e par nature */
	public odRseValueByNature: BehaviorSubject<Map<TypeNature,number>> = new BehaviorSubject<Map<TypeNature,number>>(null);

	/** Lieu de départ pour le calcul du CO2e */
	private idLieuDepartCalcul: number;

	/** Lieu de retour pour le calcul du CO2e */
	private idLieuRetourCalcul: number;

	/** Libellé du lieu d'origine à pré-renseigner dans l'iframe (au format 'Ville Pays") */
	private _libelleOrigine: string;

	/** Libellé du lieu de destination à pré-renseigner dans l'iframe (au format 'Ville Pays") */
	private _libelleDestination: string;

	get libelleDestination(): string {
		return this._libelleDestination;
	}

	get libelleOrigine(): string {
		return this._libelleOrigine;
	}

	/**
	 * Chargement du calcul CO2e pour les natures voulues
	 * @param od				OD concerné
	 * @param listeSBTParNature	Liste des SBT disponibles
	 */
	public loadRseValueByNature(od: Od,listeSBTParNature: Observable<Array<NiveauNature>>): void {
		//On s'assure que le calcul du CO2e soit actif
		this.rseService.paramRse.pipe(filter(param => param?.isCalculActif),take(1)).subscribe(param => {
			//Si le calcul du CO2e est nécessaire
			if (this.needCalculCO2(od)) {
				//On garde en mémoire les lieux utilisés pour le calcul
				this.idLieuDepartCalcul = od.lieuDepartOd.idLieuOd;
				this.idLieuRetourCalcul = od.lieuRetourOd.idLieuOd;

				//Si l'iframe est activée
				if (param.isIframeActive) {
					//On récupère les libellés d'origine et de destination
					this._libelleOrigine = od.lieuDepartOd.ville + ' ' + (od.lieuDepartOd.pays ?? '')
					this._libelleDestination = od.lieuRetourOd.ville + ' ' + (od.lieuRetourOd.pays ?? '')
				}

				//On récupère la liste des SBT disponibles pour l'OD
				listeSBTParNature.pipe(first(),switchMap(listeSBT => {
					//On récupère les natures disponibles
					let map = listeSBT.map(sbt => sbt.nature);

					//On demande le calcul du CO2e pour les natures dispo
					return this.http.post<Result>(`${environment.baseUrl}/controller/RSE/calculCO2?id_lieu_depart=${od.lieuDepartOd.idLieuOd}&id_lieu_arrivee=${od.lieuRetourOd.idLieuOd}`,map)
						.pipe(first())
				})).subscribe(result => {
					const resultCo2 = result.data.calculCO2;

					//Si on a bien un résultat sur le calcul CO2e
					if (resultCo2) {
						let map = new Map<TypeNature,number>();

						//On affecte les valeurs CO2e par nature
						Object.keys(resultCo2).forEach(co2 => {
							map.set(TypeNature[TypeNature[Number(co2)]],resultCo2[co2]);
							this.odRseValueByNature.next(map);
						});
					}
				});
			}
		});
	}

	/**
	 * Indique s'il faut demander un calcul de CO2e
	 *
	 * @param od	OD concerné
	 */
	private needCalculCO2(od: Od) {
		return od.lieuDepartOd?.ville //On vérifie qu'on a une ville de départ
			&& od.lieuRetourOd?.ville//qu'on a une ville d'arrivée
			&& (od.lieuDepartOd?.idLieuOd != this.idLieuDepartCalcul || od.lieuRetourOd?.idLieuOd != this.idLieuRetourCalcul);//que les lieux recherchés soient inédits
	}

	/**
	 * Ouverture de la popup de détail de l'équivalent CO2
	 * @param origine Libellé d'origine pour le RSE (ville ' ' pays)
	 * @param destination Libellé de destination pour le RSE (ville ' ' pays)
	 */
	openPopupRse(origine: string,destination: string): void {
		this.matDialog.open<OdVoyageTravelRsePopupComponent,any,void>(OdVoyageTravelRsePopupComponent,{
			data: {
				origine: origine,
				destination: destination
			}
		});
	}
}