import {Component,OnInit,ViewChild} from '@angular/core';
import {Statut} from "@domain/workflow/statut";
import {WorkflowStatutsService} from "@services/admin/workflow/workflow-statuts.service";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {NgForm} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

/**
 * Composant du détail d'un statut
 *
 * @author Angeline Ha
 * @date /09/2024
 */
@Component({
  host: {'data-test-id': 'statut-detail'},
  selector: 'statut-detail',
  templateUrl: './statut-detail.component.html'
})
export class StatutDetailComponent implements OnInit {
  /** Statut */
  statut: Statut;

  /** Formulaire */
  @ViewChild('form') form: NgForm;

  /** Indique si on est en création ou en modification */
  isCreation: boolean;

  /** Liste des différents onglets */
  listeTabItems: Array<PageHeaderItem> = [{
    code: Onglets.GENERALITES,
    libelle: this.translateService.instant('workflow.statut.onglets.generalites')
  }];

  /** Onglet courant */
  selectedItem: PageHeaderItem = this.listeTabItems[0];

  /** Liste des actions possibles */
  listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

  /** Référence vers l'enum pour l'utiliser dans le template */
  Onglets = Onglets;

  /** Indique si on est en train d'enregistrer */
  isSaving: boolean = false;

  /**
   * Constructeur
   *
   * @param router Router Angular
   * @param confirmService Service de confirmation
   * @param translateService Service des traductions
   * @param route Route active
   * @param toastrService Service des toasts
   * @param statutService Service des statuts
   */
  constructor(private router: Router,
              private confirmService: ConfirmService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private toastrService: ToastrService,
              private statutService: WorkflowStatutsService
  ) {
  }

  /**
   * Initialisation
   */
  ngOnInit(): void {
    //Récupération de l'identifiant
    const idStatut = Number(this.route.snapshot.paramMap.get("idStatut"));

    //Mode création
    this.isCreation = idStatut === 0;

    //Initialisation du statut dont l'identifiant est récupéré dans la route
    this.initStatut(idStatut);

    //Définition de la liste des actions
    this.defineListeActions();
  }

  /**
   * Initialisation du statut
   *
   * @param idStatut Identifiant du statut à initialiser
   */
  private initStatut(idStatut: number) {
    //Si l'id est 0, c'est une création
    if (idStatut == 0) {
      //Création
      this.isCreation = true;

      //Nouveau statut
      this.statut = new Statut();
    } else {
      //Modification
      this.isCreation = false;

      //Si on a un id, on charge le statut
      this.statutService.getStatut(idStatut).pipe(first()).subscribe((result: Result) => {
        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
          //Récupération du statut
          this.statut = result.data.statut;
        } else {
          //Toast d'erreur
          TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
        }
      });
    }
  }

  /**
   * Définition de la liste des actions
   */
  defineListeActions() {
    const liste: FloatingButtonAction[] = [{
      type: TypeAction.PRIMARY,
      icone: 'nio icon-sauvegarde',
      libelle: 'global.actions.enregistrer',
      doAction: () => this.saveStatut(),
      isDisabled: () => !this.form?.valid
    }];

    if (!this.isCreation) {
      //Ajout de l'action de suppression
      liste.push({
        type: TypeAction.SECONDARY,
        icone: 'nio icon-suppression',
        libelle: 'global.actions.supprimer',
        doAction: () => this.deleteStatut()
      });
    }

    //Définition de la liste des actions
    this.listeActions.next(liste);
  }

  /**
   * Changement d'onglet
   *
   * @param selectedItem Onglet sélectionné
   */
  onSelectedItemChange(selectedItem: PageHeaderItem) {
    //Mise à jour de l'onglet sélectionné
    this.selectedItem = selectedItem;
  }

  /**
   * Retour vers la liste des statuts
   */
  onGoBack() {
    // Retour à la liste des statuts
    this.router.navigate(['Admin/Workflow/Statuts/Statuts']);
  }

  /**
   * Enregistrement du statut
   */
  saveStatut() {
    //Enregistrement en cours
    this.isSaving = true;

    const statutToSave = new Statut(this.statut);
    statutToSave.image = this.statut.lienImage;

    //Enregistrement du statut
    this.statutService.saveStatut(statutToSave).pipe(finalize(() => this.isSaving = false)).subscribe((result: Result) => {
      if (result.codeErreur === TypeCodeErreur.NO_ERROR) {

        //Toast succès
        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

        if (this.isCreation) {
          //Récupération de l'identifiant du statut
          this.statut.idStatut = result.data.idStatut;

          //On n'est plus en mode création
          this.isCreation = false;

          //Ajout de l'action de suppression
          this.defineListeActions();

          //Navigation avec le bon id
          this.router.navigate(['../' + result.data.idStatut], {relativeTo: this.route});
        }
      } else {
        //Toast d'erreur
        TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
      }
    });
  }

  /**
   * Suppression du statut
   */
  deleteStatut() {
    //On demande une confirmation de suppression
    this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
        .pipe(filter(isConfirmed => isConfirmed))
        .subscribe(() => {
          //Suppression du statut
          this.statutService.deleteStatut(this.statut.idStatut).pipe(first()).subscribe((result: Result)=> {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
              //Toast succès
              this.toastrService.success(this.translateService.instant('global.success.suppression'));

              //Retour à la liste
              this.onGoBack();
            } else {
              //Toast d'erreur
              TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
          })
        });
  }
}

/**
 * Enum des différents onglets d'un statut
 */
export enum Onglets {
  GENERALITES = "Généralités"
}
