<div class="tooltip-segment" [class.inactif]="!segment.actif">
    <table>
        <tr>
            <td>
                <table>
                    <tr>
                        <td><label translate>admin.workflow.visualisation.tooltip.formulaire1</label></td>
                        <td>{{ ('admin.workflow.visualisation.portees.' + segment.formulaireDepart) | translate }}</td>
                    </tr>
                    <tr *ngIf="segment.formulaireModifie">
                        <td><label translate>admin.workflow.visualisation.tooltip.formulaire2</label></td>
                        <td>{{ ('admin.workflow.visualisation.portees.' + segment.formulaireModifie) | translate }}</td>
                    </tr>
                    <tr>
                        <td><label translate>admin.workflow.visualisation.tooltip.role</label></td>
                        <td>{{ segment.role?.libelle }}</td>
                    </tr>
                    <tr *ngIf="displayAction">
                        <td><label translate>admin.workflow.visualisation.tooltip.action</label></td>
                        <td>{{ displayAction }}</td>
                    </tr>
                    <tr>
                        <td><label translate>admin.workflow.visualisation.tooltip.statutDebut</label></td>
                        <td>{{ segment.statutDebutFormulaireModifie?.libelle }}</td>
                    </tr>
                    <tr *ngIf="segment.statutFinFormulaireModifie">
                        <td><label translate>admin.workflow.visualisation.tooltip.statutFin</label></td>
                        <td>{{ segment.statutFinFormulaireModifie?.libelle }}</td>
                    </tr>
                    <tr *ngIf="segment.conditionFormulaireDepart">
                        <td><label translate>admin.workflow.visualisation.tooltip.condition1</label></td>
                        <td>{{ segment.conditionFormulaireDepart }}</td>
                    </tr>
                    <tr *ngIf="segment.conditionFormulaireModifie">
                        <td><label translate>admin.workflow.visualisation.tooltip.condition2</label></td>
                        <td>{{ segment.conditionFormulaireModifie }}</td>
                    </tr>
                    <tr *ngIf="!!procedures">
                        <td><label translate>admin.workflow.visualisation.tooltip.procedures</label></td>
                        <td>{{ procedures }}</td>
                    </tr>
                    <tr *ngIf="!!segment.saisieMotifObligatoire">
                        <td><label translate>admin.workflow.visualisation.tooltip.motif</label></td>
                        <td>{{ 'admin.workflow.visualisation.tooltip.motifObligatoire' | translate }}</td>
                    </tr>
                </table>
            </td>
            <td>
                <table class="icones">
                    <tr>
                        <td>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.OD" class="nio icon-ordre_de_mission icone"></i>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.NF" class="nio icon-frais icone"></i>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.FC" class="nio icon-releve_de_facture icone"></i>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.AV" class="nio icon-avance_2 icone"></i>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.OT" class="nio icon-ordre_de_mission_permanent icone"></i>
                            <i *ngIf="$any(segment.formulaireDepart) == Formulaire.DV" class="material-icons-outlined icone">confirmation_number</i>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <i *ngIf="segment.role?.fonction == TypeProfil.COMPTABLE" class="nio icon-comptable icone"></i>
                            <i *ngIf="segment.role?.fonction == TypeProfil.RESPONSABLE" class="nio icon-responsable icone"></i>
                            <i *ngIf="segment.role?.fonction == TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs icone"></i>
                            <i *ngIf="segment.role?.fonction == TypeProfil.ASSISTANT" class="nio icon-assistant icone"></i>
                            <i *ngIf="segment.role?.fonction == TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur icone"></i>
                            <i *ngIf="segment.role?.fonction == TypeProfil.FOURNISSEUR" class="nio icon-fournisseur icone"></i>
                        </td>
                    </tr>
                    <tr>
                        <td>
                                <span [ngSwitch]="segment.action">
                                    <i *ngSwitchCase="TypeAction.VALIDER" class="nio icon-validation icone"></i>
                                    <i *ngSwitchCase="TypeAction.REJETER" class="nio icon-rejet icone"></i>
                                    <i *ngSwitchCase="TypeAction.INVALIDER" class="nio icon-invalidation icone"></i>
                                    <i *ngSwitchCase="TypeAction.ANNULER" class="nio icon-annulation icone"></i>
                                    <i *ngSwitchCase="TypeAction.MODIFIER" class="nio icon-modification icone"></i>
                                    <i *ngSwitchCase="TypeAction.SUPPRIMER" class="nio icon-suppression icone"></i>
                                    <i *ngSwitchCase="TypeAction.EMETTRE" class="nio icon-emission icone"></i>
                                    <i *ngSwitchCase="TypeAction.ARCHIVER" class="nio icon-archive icone"></i>
                                    <i *ngSwitchCase="TypeAction.COMPTABILISER" class="nio icon-comptabilisation icone"></i>
                                    <i *ngSwitchCase="TypeAction.COMPLETER" class="nio icon-completion icone"></i>
                                    <i *ngSwitchCase="TypeAction.RESTITUER" class="nio icon-restitution icone"></i>
                                    <i *ngSwitchCase="TypeAction.CLOTURER" class="nio icon-cloture icone"></i>
                                    <i *ngSwitchCase="TypeAction.ATTRIBUER" class="nio icon-octroi icone"></i>
                                </span>
                        </td>
                    </tr>
                    <tr *ngIf="segment.withMail">
                        <i class="material-icons-outlined icone">mail</i>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>
