import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {AdminWorkflowComponent} from "./admin-workflow.component";
import {BudgetModule} from "@components/budget/budget.module";
import {ActionWfComponent} from "@components/admin/workflow/action-wf/action-wf.component";
import {RepartitionAnalytique} from "@components/admin/workflow/repartition-analytique/repartition-analytique";
import {EnveloppeComponent} from '@components/admin/workflow/repartition-analytique/enveloppe/enveloppe.component';
import {VentilationComponent} from '@components/admin/workflow/repartition-analytique/ventilation/ventilation.component';
import {RouterModule,Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {WorkflowConditionsModule,workflowConditionsRoutes} from "@components/admin/workflow/conditions/workflow-conditions.module";
import {WorkflowConditionsComponent} from "@components/admin/workflow/conditions/workflow-conditions.component";
import {WorkflowConditionsService} from "@services/admin/workflow/workflow-conditions.service";
import {OngletWorkflowComponent} from "@components/admin/workflow/onglet-workflow/onglet-workflow.component";
import {WorkflowRolesModule,workflowRolesRoutes} from "@components/admin/workflow/roles/workflow-roles.module";
import {WorkflowRolesComponent} from "@components/admin/workflow/roles/workflow-roles.component";
import {WfControleModule,workflowControleRoutes} from "@components/admin/workflow/controle/wf-controle.module";
import {WfControleComponent} from "@components/admin/workflow/controle/wf-controle.component";
import {WfStatutsModule,workflowStatutsRoutes} from "@components/admin/workflow/statuts/wf-statuts.module";
import {WfStatutsComponent} from "@components/admin/workflow/statuts/wf-statuts.component";
import {WorkflowMotifsComponent} from './motifs/workflow-motifs.component';
import {WorkflowMotifsModule,workflowMotifsRoutes} from "@components/admin/workflow/motifs/workflow-motifs-module";
import {DroitAdmin} from "@core/security/droit-admin";
import {WfConstructionModule,workflowConstructionRoutes} from "@components/admin/workflow/construction/wf-construction.module";
import {WfConstructionComponent} from "@components/admin/workflow/construction/wf-construction.component";

export const adminWorkflowRoutes: Routes = [
	{
		path: 'Conditions',
		component: WorkflowConditionsComponent,
		canActivate: [AdminGuardProvider],
		children: workflowConditionsRoutes
	},{
		path: 'Construction',
		component: WfConstructionComponent,
		canActivate: [AdminGuardProvider],
		children: workflowConstructionRoutes
	},{
		path: 'Controle',
		component: WfControleComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_CONTROLE_WORKFLOW] },
		children: workflowControleRoutes
	},{
		path: 'Motifs',
		component: WorkflowMotifsComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_MOTIF_GESTION] },
		children: workflowMotifsRoutes
	},{
		path: 'Roles',
		component: WorkflowRolesComponent,
		children: workflowRolesRoutes,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Statuts',
		component: WfStatutsComponent,
		canActivate: [AdminGuardProvider],
		children: workflowStatutsRoutes
	},{
		path: '',
		redirectTo: 'Conditions',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Conditions'
	}
];

/**
 * Module de gestion de l'onglet workflow
 */
@NgModule({
	imports: [
		ShareModule,
		BudgetModule,
		WorkflowRolesModule,
		RouterModule,
		WorkflowConditionsModule,
		WfConstructionModule,
		WfControleModule,
		WfStatutsModule,
        WorkflowConditionsModule,
        WorkflowMotifsModule
	],
	declarations: [
		AdminWorkflowComponent,
		ActionWfComponent,
		RepartitionAnalytique,
		EnveloppeComponent,
		VentilationComponent,
		OngletWorkflowComponent
	],
	exports: [
		AdminWorkflowComponent,
		OngletWorkflowComponent
	],
	providers: [
		WorkflowConditionsService
	]
})
export class AdminWorkflowModule {
}
