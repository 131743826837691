import {Component,Inject,Input} from '@angular/core';
import {RseService} from "@services/share/rse/rse.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/**
 * Popup de détail de l'équivalent CO2
 *
 * @author Laurent SCIMIA
 * @date 07/05/2024
 */
@Component({
	host: {'data-test-id': 'od-voyage-travel-rse-popup'},
	templateUrl: './od-voyage-travel-rse-popup.component.html'
})
export class OdVoyageTravelRsePopupComponent {

	/**
	 * Constructeur
	 *
	 * @param rseService	Service du RSE
	 * @param data			Données pour la popup
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: { origine: string,destination: string },
				public rseService: RseService) {
	}
}