import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {first,map} from "rxjs/operators";
import {environment} from "@environments/environment";
import {Condition,Formulaire,MailTo,Message,Segment,Statut,Workflow} from "@domain/admin/workflow/segment";
import {SearchSpec} from "@domain/common/list-view/searchSpec";

/**
 * Service de gestion du workflow admin
 */
@Injectable()
export class WorkflowAdminService {

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Récupération d'un segment par son ID
	 *
	 * @param id ID du segment à récupérer
	 */
	getSegmentById(id: number): Observable<Segment> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Segment/loadSegment/${id}`,null).pipe(first(),map((result) => {
			//Création du segment
			const segment = new Segment(result?.data?.segment);

			//Intégration des actions supplémentaires
			segment.actionsSupplementaires = result?.data?.listeActionsSupp?.map(a => a.action);

			//Retour du segment instancié
			return segment;
		}));
	}

	/**
	 * Récupération de la liste des statuts possibles
	 */
	getAllStatus(): Observable<Statut[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Statut/all`,null).pipe(first(),map((result) => result?.data?.listeStatuts?.map(statut => new Statut(statut))));
	}

	/**
	 * Récupération de la liste des conditions possibles pour une portée donnée
	 *
	 * @param portee la portée concernée
	 */
	getConditions(portee: Formulaire): Observable<Condition[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Portee/conditions/${portee}`,null).pipe(first(),map((result) => result?.data?.listeConditions?.map(condition => new Condition(condition))));
	}

	/**
	 * Récupération des rôles
	 */
	getRoles(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Role/all/sorted`,null).pipe(first());
	}

	/**
	 * Récupération des messages
	 */
	getMessages(): Observable<Message[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/MessageNotif/all/email`,null).pipe(first(),map((result) => result?.data?.listeMessages?.map(message => new Message(message))));
	}

	/**
	 * Récupération des confirmations
	 */
	getConfirmations(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/MessageNotif/all/confirmation`,null).pipe(first());
	}

	/**
	 * Suppression d'une notification
	 *
	 * @param id ID de la notification à supprimer
	 */
	deleteMailToPopup(id: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/MailTo/deleteMailTo/${id}`).pipe(first());
	}

	/**
	 * Duplication d'une notification
	 *
	 * @param mailTo notification à dupliquer
	 */
	duplicateMailTo(mailTo: MailTo): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/MailTo/duplicateMailTo`, mailTo).pipe(first());
	}

	/**
	 * Enregistrement d'une notification
	 *
	 * @param mailTo notification à enregistrer
	 */
	saveMailTo(mailTo: MailTo): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/MailTo/saveMailTo`, mailTo).pipe(first());
	}

	/**
	 * Sauvegarde un segment Workflow
	 *
	 * @param segment le segment à sauvegarder
	 */
	saveSegment(segment: Segment): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Segment/saveSegment/inWorkflow/${segment?.workflow?.id}`, segment).pipe(first(),map((result) => {
			//Création du segment
			result.data.segment = new Segment(result?.data?.segment);

			//Intégration des actions supplémentaires
			result.data.segment.actionsSupplementaires = result?.data?.listeActionsSupp?.map(a => a.action);

			//Retour du result avec segment instancié
			return result;
		}));
	}

	/**
	 * Duplication d'un segment Workflow
	 *
	 * @param segment le segment à dupliquer
	 */
	duplicateSegment(segment: Segment): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Segment/duplicateSegment/inWorkflow/${segment?.workflow?.id}`, segment).pipe(first(),map((result) => {
			//Création du segment
			result.data.segment = new Segment(result?.data?.segment);

			//Intégration des actions supplémentaires
			result.data.segment.actionsSupplementaires = result?.data?.listeActionsSupp?.map(a => a.action);

			//Retour du result avec segment instancié
			return result;
		}));
	}

	/**
	 * Suppression d'un segment Workflow
	 *
	 * @param segment le segment à supprimer
	 */
	deleteSegment(segment: Segment): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Segment/deleteSegment/${segment?.idSegment}`).pipe(first());
	}

	/**
	 * Suppression d'un Workflow
	 *
	 * @param workflow workflow à supprimer
	 */
	deleteWorkflow(workflow: Workflow): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Workflow/${workflow?.id}`).pipe(first());
	}

	/**
	 * Enregistrement d'un workflow
	 *
	 * @param workflow workflow à enregistrer
	 */
	saveWorkflow(workflow: Workflow): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Workflow/save`,workflow).pipe(first());
	}

	/**
	 * Liste filtrée et ordonnée des statuts workflow
	 *
	 * @param searchSpec critères de recherche
	 */
	listeStatuts(searchSpec: SearchSpec): Observable<Result> {
		//Appel au backend
		return this.http.post<Result>(`${environment.baseUrl}/controller/Segment/listeStatuts`,searchSpec).pipe(first());
	}

	/**
	 * Récupération de tous les filtres possibles sur les segments
	 */
	listeFiltres(): Observable<Result> {
		//Appel au backend
		return this.http.get<Result>(`${environment.baseUrl}/controller/Segment/listeFiltres`).pipe(first());
	}
}
