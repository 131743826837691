<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">mail</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onMailToClick()">
                {{ data.message?.titre }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.role'"></strong>
                <span>{{ data.role?.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.priorite.title'"></strong>
                <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.priorite.' + data.priorite"></span>
            </li>
            <li>
                <strong [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mode.title'"></strong>
                <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.mode.' + (data.manuel ? 'manuel' : 'automatique')"></span>
            </li>
            <li>
                <strong [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.globalise.title'"></strong>
                <span [translate]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.globalise.' + (data.globalise ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
</div>
