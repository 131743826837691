import {Component,Input,OnInit} from "@angular/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {PopulationServiceItemComponent} from "./population-service-item/population-service-item.component";
import {PopulationCollaborateurItemComponent} from "./population-collaborateur-item/population-collaborateur-item.component";
import {TranslateService} from "@ngx-translate/core";
import {LienPopulationOrga} from "@domain/admin/entreprise/population/lienPopulationOrga";
import {LienPopulationUser} from "@domain/admin/entreprise/population/lienPopulationUser";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {MatDialog} from "@angular/material/dialog";
import {PopulationAddServiceComponent} from "./population-add-service/population-add-service.component";
import {PopulationAddCollaborateurComponent} from "./population-add-collaborateur/population-add-collaborateur.component";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {ToastrService} from "ngx-toastr";
import {Sorting} from "@domain/common/list-view/sorting";
import {EntreprisePopulationService} from "@components/admin/entreprise/populations/entreprise-population.service";

/**
 * Périmètre d'une population
 *
 * @author Tom JEAMMET
 */
@Component({
    host: {'data-test-id': 'population-perimetre'},
    selector: 'population-perimetre',
    templateUrl: './population-perimetre.component.html',
})
export class PopulationPerimetreComponent implements OnInit {

    /** Id de la population */
    @Input() idPopulation: number;

    /** Liste des services */
    listeServices: ListView<LienPopulationOrga,PopulationServiceItemComponent>;

    /** Liste des utilisateurs */
    listeUtilisateurs: ListView<LienPopulationUser,PopulationCollaborateurItemComponent>;

    /**
     * Constructeur de la classe.
     *
     * @param translateService Le service de traduction utilisé dans la classe.
     * @param matDialog Le service MatDialog utilisé dans la classe pour ouvrir des boîtes de dialogue modales.
     * @param populationsService Le service PopulationsService utilisé dans la classe pour gérer les populations.
     * @param toastrService Le service ToastrService utilisé dans la classe pour afficher des messages de notification.
     */
    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private populationsService: PopulationsService,
        private toastrService: ToastrService,
        private entreprisePopulationService: EntreprisePopulationService,
    ) { }

    /** Hook initialisation */
    ngOnInit(): void {
        //Initialise la liste des services
        this.initListeServices();

        //Initialise la liste des utilisateurs
        this.initListeUtilisateurs();
    }

    /** Initialise la liste des services */
    initListeServices(): void {
        //Initialisation de la liste des services
        this.listeServices = new ListView<LienPopulationOrga,PopulationServiceItemComponent>({
            uri: `/controller/Population/getPopulationServices/${this.idPopulation}`,
            title: this.translateService.instant('admin.entreprise.populations.liste.servicesListTitle'),
            component: PopulationServiceItemComponent,
            defaultOrder: 'idOrga',
            nbObjetsParPage: 10,
            isLocal: true,
            extraOptions: {
                canModifier: this.entreprisePopulationService.getCanModifier()
            },
            mapResult: (result: Result) => {
                return result?.data?.orgas;
            },
            isFilter: true,
            listeFilters: [
                {
                    clef: 'orga.libelle',
                    title: this.translateService.instant('admin.entreprise.populations.services.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'orga.numService',
                    title: this.translateService.instant('admin.entreprise.populations.services.numService'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ]
        });

        //Si on peut modifier la population
        if (this.entreprisePopulationService.getCanModifier()) {
            this.listeServices.listeActions = [{
                icon: "add",
                onPress: () => this.openAddOrgaDialog()
            }];
        }

        this.listeServices.columns = [
            {key: 'orga.libelle',title: 'admin.entreprise.populations.services.libelle'},
            {key: 'orga.numService',title: 'admin.entreprise.populations.services.numService'},
        ]

        this.listeServices.sorting = new Sorting(this.listeServices.columns,"orga.libelle");
    }

    /** Initialise la liste des utilisateurs */
    initListeUtilisateurs(): void {
        //Initialisation de la liste des utilisateurs
        this.listeUtilisateurs = new ListView<LienPopulationUser,PopulationCollaborateurItemComponent>({
            title: this.translateService.instant('admin.entreprise.populations.liste.usersListTitle'),
            uri: `/controller/Population/getPopulationUsers/${this.idPopulation}`,
            component: PopulationCollaborateurItemComponent,
            defaultOrder: 'idUser',
            nbObjetsParPage: 10,
            isLocal: true,
            extraOptions: {
                canModifier: this.entreprisePopulationService.getCanModifier()
            },
            mapResult: (result: Result) => {
                return result?.data?.users;
            },
            isFilter: true,
            listeFilters: [
                {
					title: this.translateService.instant('filter.nom'),
					clef: 'user.nom',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('filter.prenom'),
					clef: 'user.prenom',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('filter.matricule'),
					clef: 'user.matricule',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('admin.entreprise.description.profilsRestreints.identifiant'),
					clef: 'user.idUser',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('admin.entreprise.description.profilsRestreints.serviceAffectation'),
					clef: 'user.orga.numService,user.orga.libelle',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
                    title: this.translateService.instant('filter.isActif'),
                    clef: 'user.isActif',
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: false
                }
            ]
        });

        //Si on peut modifier la population
        if (this.entreprisePopulationService.getCanModifier()) {
            this.listeUtilisateurs.listeActions = [{
                icon: "add",
                onPress: () => this.openAddUserDialog()
            }];
        }

        this.listeUtilisateurs.columns = [
            {key: 'user.nom',title: 'filter.nom'},
            {key: 'user.prenom',title: 'filter.prenom'},
            {key: 'user.matricule',title: 'filter.matricule'},
            {key: 'user.idUser',title: 'admin.entreprise.description.profilsRestreints.identifiant'},
            {key: 'user.orga.numService,user.orga.libelle',title: 'admin.entreprise.description.profilsRestreints.serviceAffectation'},
        ]

        this.listeUtilisateurs.sorting = new Sorting(this.listeUtilisateurs.columns,"user.nom");
    }

    /**
     * Permet l'ajout d'un service à une population
     */
    private openAddOrgaDialog() {
        this.matDialog.open(PopulationAddServiceComponent, {
            data: {
                idPopulation: this.idPopulation
            }
        }).afterClosed().subscribe(lienPopulationOrga => {
            //Si l'user a sauvegardé
            if (lienPopulationOrga != null) {
                this.addOrgaToPopulation(lienPopulationOrga);
            }
        });
    }

    /**
     * Permet l'ajout d'un utilisateur à une population
     */
    private openAddUserDialog(): void {
        this.matDialog.open(PopulationAddCollaborateurComponent, {
            data: {
                idPopulation: this.idPopulation
            }
        }).afterClosed().subscribe(lienPopulationUser => {
            //Si l'user a sauvegardé un lienPopulationUser
            if (lienPopulationUser != null) {
                this.addUserToPopulation(lienPopulationUser);
            }
        });
    }

    /**
     * Ajoute l'organisme à la population
     *
     * @param lienPopulationOrga le lien population organisme
     */
    addOrgaToPopulation(lienPopulationOrga: LienPopulationOrga): void {
        this.populationsService.addOrgaToPopulation(lienPopulationOrga).subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                this.listeServices.refresh();
            } else if (result.codeErreur === 902) {
                this.toastrService.error(this.translateService.instant('admin.entreprise.populations.errors.orgaDejaAjoute'));
            } else {
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }

    /**
     * Ajoute l'user à la population
     *
     * @param lienPopulationUser le lien population user
     */
    addUserToPopulation(lienPopulationUser: LienPopulationUser): void {
        this.populationsService.addUserToPopulation(lienPopulationUser).subscribe(result => {
            if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                this.listeUtilisateurs.refresh();
            } else if (result?.codeErreur === 902) {
                this.toastrService.error(this.translateService.instant('admin.entreprise.populations.errors.userDejaAjoute'));
            } else {
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }
}