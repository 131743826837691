import {Component,ViewChild} from '@angular/core';
import {TypeProfil,User} from "@domain/user/user";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {ActivatedRoute,Params,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {WorkflowService} from "../workflow/workflow.service";
import {AvanceService} from "./avance.service";
import {TypePortee} from "@domain/workflow/workflow";
import {AvanceGeneralitesComponent} from "./generalite/avance-generalites.component";
import {Avance} from "@domain/avance/avance";
import {ChainageService} from "../chainage/chainage.service";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {Od} from "@domain/od/od";
import {AbstractObjetWorkflowComponent,OngletsAdmin} from '../workflow/abstract-objet-workflow.component';
import {MapAction} from '@domain/workflow/mapAction';
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {ReportingService} from "@components/reporting/reporting.service";
import {MatDialog} from "@angular/material/dialog";
import {SettingsAVState} from "@domain/settings/settings";
import * as _ from "lodash";
import {Location} from "@angular/common";
import {UserDto} from "@domain/user/user-dto";
import {SettingsService} from "@components/settings/settings.service";

@Component({
	selector: 'avance',
	templateUrl: './avance.component.html',
})
export class AvanceComponent extends AbstractObjetWorkflowComponent<Avance,SettingsAVState> {
	/** Accès à l'enum dans la vue */
	readonly TypePortee = TypePortee;
	readonly OngletsAdmin = OngletsAdmin;

	/** Généralités */
	@ViewChild(AvanceGeneralitesComponent)
	avanceGeneralitesComponent: AvanceGeneralitesComponent;

	/** Od à associer à l'avance */
	od: Od;

	/** Utilisateur à associer à l'avance lors d'une création */
	collaborateur: UserDto;

	/** Devise de l'entreprise */
	deviseEntreprise: string;

	/** Enumération des niveaux d'alerte */
	public niveauAlerteEnum: typeof NiveauAlerte = NiveauAlerte;

	/**
	 * Constructeur
	 */
	constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected toastrService: ToastrService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected workflowService: WorkflowService,
		protected settingsService: SettingsService,
		protected chainageService: ChainageService,
		protected analytiqueService: AnalytiqueService,
		protected avanceService: AvanceService,
		protected reportingService: ReportingService,
		protected matDialog: MatDialog,
		protected location: Location
	) {
		super(
			translateService,
			store,
			toastrService,
			activatedRoute,
			router,
			workflowService,
			chainageService,
			analytiqueService,
			reportingService,
			matDialog,
			location,
			TypePortee.AV,
			avanceService
		);

		//Récupération de l'od à associer à l'avance, le cas échéant (on le récupère dans le constructeur car la navigation est encore en cours, après c'est trop tard dans le cycle de vie du composant)
		this.od = this.routeState?.od;

		//Récupération de l'utilisateur à associer à l'avance, le cas échéant
		this.collaborateur = this.routeState?.collab;
	}

	/**
	 * Construction de l'avance à partir des données brutes non typées
	 * @param data données brutes non typées
	 * @returns une instance de l'avance
	 */
	protected buildLoadedObjet(data: any): Avance {
		//Chargement des onglets
		this.initTabs(false);

		//Mise à jour de l'avance
		const avance = new Avance(data?.avance);

		//Retour
		return avance;
	}

	/**
	 * Construction d'un nouvel objet
	 * @returns une instance de l'objet
	 */
	protected buildNewObjet(): Promise<Avance> {
		//Chargement des onglets
		this.initTabs(true);

		//Création de l'avance
		const avance = new Avance();

		//Si l'avance doit être associée à un OD, on la lie et on initialise les champs de l'avance
		if (this.od) {
			avance.idOd = this.od.idOd;
			avance.libelleObjet = this.od.objet;
		}

		avance.user = new User();
		//Si l'avance doit être associée à un collab, on la lie et on initialise les champs de l'avance
		if (this.collaborateur) {
			avance.user.idUser = this.collaborateur.idUser;
			avance.user.nom = this.collaborateur.nom;
			avance.user.prenom = this.collaborateur.prenom;
		} else {
			avance.user.idUser = this.user.idUser;
		}

		//Définition des actions de base
		avance.listeHistoWorkflow = new MapAction({canModifier: {possible: true,possibleMasse: false}});

		//Retour
		return new Promise<Avance>((resolve) => {
			resolve(avance);
		});
	}

	/**
	 * Initialisation des onglets
	 * @param creation boolean pour représenter si on est en mode création d'avance
	 */
	initTabs(creation?: boolean) {
		//Définition des onglets
		if (creation) {
			this.listeTabItems = [{
				code: 'GENERALITES',
				libelle: this.translateService.instant('avance.navigation.generalites')
			}];
		} else {
			this.listeTabItems = [{
				code: 'GENERALITES',
				libelle: this.translateService.instant('avance.navigation.generalites')
			},{
				code: 'HISTORIQUE',
				libelle: this.translateService.instant('avance.navigation.historique')
			},];

			if (this.user.fonction === TypeProfil.RESPONSABLE || this.user.fonction === TypeProfil.ASSISTANT || this.user.fonction === TypeProfil.COMPTABLE || this.isAdmin) {
				this.listeTabItems.push({
					code: 'FICHECOLLABORATEUR',
					libelle: this.translateService.instant('avance.navigation.ficheCollaborateur'),
				});
			}

			this.listeTabItems.push({
				code: 'COMPLEMENTS',
				libelle: this.translateService.instant('avance.navigation.complements')
			})

		}

		//Ajout de l'onglet outils
		super.initTabs();
	}

	/**
	 * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
	 *
	 * @return boolean True si le formulaire est valide, False sinon
	 */
	isValid(): boolean {
		return this.avanceGeneralitesComponent?.form?.valid && this.avanceGeneralitesComponent?.isValid();
	}

	/**
	 * Préparation de l'objet avant sauvegarde.
	 *
	 * @param avanceToSave avance à préparer
	 * @returns avance préparée
	 */
	protected beforeSaveObjet(avanceToSave: Avance): Avance {
		//Met à jour l'avance à partir des valeurs saisies
		this.avanceGeneralitesComponent.beforeSave();

		//On copie l'objet pour procéder au nettoyage des valeurs inutiles
		const avanceCleaned = _.cloneDeep(avanceToSave);

		//Retrait des valeurs indésirables pour la sauvegarde
		avanceCleaned.od = null;
		avanceCleaned.listeAvanceChainage = null;

		//Retour
		return avanceCleaned;
	}

	/**
	 * Récupération des paramètres du contexte de navigation
	 *
	 * @param activatedRouteParams query params de la route courante
	 */
	protected initRoutingContext(activatedRouteParams: Params): void {
		//Si l'utilisateur est admin ou sous-admin
		if (this.user.fonction == TypeProfil.ADMINISTRATEUR || this.user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
			if (!!activatedRouteParams?.idLot) {
				this.routingContext = {
					returnRoute: `Admin/Comptabilite/LotsComptables/Lot/${activatedRouteParams.idLot}`,
					extras: {
						queryParams: {
							tabIndex: 2
						}
					}
				}
			} else {
				this.routingContext = {
					returnRoute: 'Admin/Frais/Avances'
				};
			}
		} else {
			if (!!activatedRouteParams?.idLot) {
				this.routingContext = {
					returnRoute: `Lot/${activatedRouteParams.idLot}`,
					extras: {
						queryParams: {
							tabIndex: 2
						}
					}
				}
			} else if (!!activatedRouteParams?.idNDF) {
				this.routingContext = {
					returnRoute: `NDF/${activatedRouteParams.idNDF}`,
				}
			} else {
				this.routingContext = {
					returnRoute: 'ListeAvance'
				};
			}
		}
	}
}
