<form #form="ngForm" *ngIf="segment">
    <fieldset>
        <mat-card>
            <mat-card-title>
                <span translate="admin.workflow.construction.segment.onglets.generalites.title"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <custom-input
                        ngDefaultControl
                        id="libelle"
                        name="libelle"
                        customType="input"
                        [(ngModel)]="segment.libelle"
                        lCol="2" rCol="4"
                        libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.libelle"
                        [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.generalitesCard.libelleTooltip' | translate">
                    </custom-input>
                    <custom-input
                        ngDefaultControl
                        id="actif"
                        name="actif"
                        customType="checkbox"
                        [ngModel]="segment.actif"
                        [(customModel)]="segment.actif"
                        lCol="2" rCol="4"
                        libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.actif"
                        postLibelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.actifPostLibelle">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="formulaire1"
                            name="formulaire1"
                            customType="select"
                            [(ngModel)]="segment.formulaireDepart.idPortee"
                            [selectOptions]="FORMULAIRES"
                            (onChange)="updateConditions('départ');updateProceduresSupplementaires();updateActionsPossibles();updateProceduresBudget();syncFormulaires();afterFormulaireChange();"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaire1"
                            optionValue="code"
                            optionDisplay="libelle"
                            [isTranslated]="false"
                            [required]="true">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="formulaire2"
                            name="formulaire2"
                            customType="select"
                            [(ngModel)]="segment.formulaireModifie.idPortee"
                            [selectOptions]="FORMULAIRES"
                            (onChange)="updateConditions('arrivée');updateProceduresSupplementaires();updateActionsPossibles();updateProceduresBudget();afterFormulaireChange();"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.formulaire2"
                            optionValue="code"
                            optionDisplay="libelle"
                            [isTranslated]="false"
                            [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            #role
                            *ngIf="listeRoles"
                            ngDefaultControl
                            id="role"
                            name="role"
                            customType="objectselect"
                            [(ngModel)]="segment.role"
                            [selectOptions]="listeRoles"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.role"
                            optionValue="idRole"
                            optionDisplay="libelle"
                            [isTranslated]="false"
                            [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
						    #statut1
                            *ngIf="listeStatuts"
                            ngDefaultControl
                            id="statut1"
                            name="statut1"
                            customType="objectselect"
                            [(ngModel)]="segment.statutFormulaireDepart"
                            [selectOptions]="listeStatuts"
                            (onChange)="afterFormulaireChange()"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.statut1"
                            [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.generalitesCard.statut1Tooltip' | translate"
                            optionValue="code"
                            optionDisplay="libelle"
                            [isTranslated]="false">
                    </custom-input>
                    <custom-input
						    #statut2
                            *ngIf="listeStatuts"
                            ngDefaultControl
                            id="statut2"
                            name="statut2"
                            customType="objectselect"
                            [(ngModel)]="segment.statutDebutFormulaireModifie"
                            [selectOptions]="listeStatuts"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.statut2"
                            [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.generalitesCard.statut2Tooltip' | translate"
                            optionValue="code"
                            optionDisplay="libelle"
                            [disabled]="isFormulaire1EqualWith2"
                            [isTranslated]="false">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            *ngIf="listeConditions1"
                            ngDefaultControl
                            id="condition1"
                            name="condition1"
                            customType="objectselect"
                            [(ngModel)]="segment.conditionFormulaireDepart"
                            [selectOptions]="listeConditions1"
                            (onChange)="afterFormulaireChange()"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.condition1"
                            optionValue="id"
                            optionDisplay="libelle"
                            [isTranslated]="false">
                    </custom-input>
                    <custom-input
                            *ngIf="listeConditions2"
                            ngDefaultControl
                            id="condition2"
                            name="condition2"
                            customType="objectselect"
                            [(ngModel)]="segment.conditionFormulaireModifie"
                            [selectOptions]="listeConditions2"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.condition2"
                            optionValue="id"
                            optionDisplay="libelle"
                            [disabled]="isFormulaire1EqualWith2"
                            [isTranslated]="false">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            *ngIf="listeActions"
                            ngDefaultControl
                            id="action"
                            name="action"
                            customType="select"
                            [(ngModel)]="segment.action"
                            [selectOptions]="listeActions"
                            (onChange)="updateLibellesAction();updateActionsPossibles();"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.action"
                            optionValue="id"
                            optionDisplay="libelle"
                            [isTranslated]="false"
                            [required]="true">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="actionMasse"
                            name="actionMasse"
                            customType="checkbox"
                            [ngModel]="segment.groupement"
                            [(customModel)]="segment.groupement"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionMasse"
                            postLibelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.actionMassePostLibelle">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            #statutCible
                            *ngIf="listeStatuts"
                            ngDefaultControl
                            id="statutCible"
                            name="statutCible"
                            customType="objectselect"
                            [(ngModel)]="segment.statutFinFormulaireModifie"
                            [selectOptions]="listeStatuts"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.statutCible"
                            optionValue="code"
                            optionDisplay="libelle"
                            [isTranslated]="false">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="choixAppro"
                            name="choixAppro"
                            customType="checkbox"
                            [ngModel]="segment.choixAppro"
                            [(customModel)]="segment.choixAppro"
                            (onChange)="updateConfirmation()"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.choixAppro"
                            postLibelle="admin.workflow.construction.segment.onglets.generalites.generalitesCard.choixApproPostLibelle"
                            [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.generalitesCard.choixApproTooltip' | translate">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title>
                <span translate="admin.workflow.construction.segment.onglets.generalites.optionsCard.title"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="libelleAction"
                            name="libelleAction"
                            customType="input"
                            [(ngModel)]="segment.libelleAction"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.optionsCard.libelleAction">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="libelleBouton"
                            name="libelleBouton"
                            customType="input"
                            [(ngModel)]="segment.libelleBouton"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.optionsCard.libelleBouton">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            *ngIf="listeProceduresSupplementaires"
                            ngDefaultControl
                            id="procedureComplementaire"
                            name="procedureComplementaire"
                            customType="objectselect"
                            [(ngModel)]="selectedProcedureSupplementaire"
                            [selectOptions]="listeProceduresSupplementaires"
                            (onChange)="afterFormulaireChange()"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.optionsCard.procedureComplementaire"
                            optionValue="id"
                            optionDisplay="libelle"
                            [disabled]="!isProcedureComplementaire"
                            [isTranslated]="false">
                    </custom-input>
                    <custom-input
                            *ngIf="listeStatuts"
                            ngDefaultControl
                            id="actionComplementaire"
                            name="actionComplementaire"
                            customType="multiselect"
                            [(ngModel)]="listeSelectedActions"
                            [selectOptions]="listeActionsPossibles"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.optionsCard.actionComplementaire"
                            optionValue="id"
                            optionDisplay="libelle"
                            [disabled]="!listeActionsPossibles.length"
                            [isSelectAvecInput]="false"
                            [isTranslated]="false">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            *ngIf="listeProceduresBudget"
                            ngDefaultControl
                            id="procedureBudgetaire"
                            name="procedureBudgetaire"
                            customType="select"
                            [(ngModel)]="segment.actionBudget"
                            [selectOptions]="listeProceduresBudget"
                            lCol="2" rCol="4"
                            libelle="admin.workflow.construction.segment.onglets.generalites.optionsCard.procedureBudgetaire"
                            optionValue="id"
                            optionDisplay="libelle"
                            [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.optionsCard.procedureBudgetaireTooltip' | translate"
                            [disabled]="!listeProceduresBudget.length"
                            [isTranslated]="false">
                    </custom-input>
                    <div class="col-2"></div>
                    <div class="col-4 line-option-container">
                        <span *ngFor="let action of listeSelectedActions">
                            <span *ngIf="action['icon']?.icon" [ngClass]="action['icon'].class" class="line-option" [matTooltip]="action.libelle | translate">{{ action['icon'].icon }}</span>
                            <i *ngIf="action['icon'] && !action['icon'].icon" [ngClass]="action['icon'].class" class="line-option-icon" [matTooltip]="action.libelle | translate"></i>
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <list-view [liste]="listeMailsTo">
            <list-view-header>
                <div class="liste-header">
                    <div class="row">
                        <custom-input
                                *ngIf="listeProceduresSupplementaires"
                                ngDefaultControl
                                id="motif"
                                name="motif"
                                customType="objectselect"
                                [(ngModel)]="selectedOptionMotif"
                                [selectOptions]="OPTIONS_MOTIFS"
                                (onChange)="updateOptionMotif();updateConfirmation();"
                                lCol="2" rCol="4"
                                libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.motif.title"
                                optionValue="code"
                                optionDisplay="libelle"
                                [isTranslated]="false">
                        </custom-input>
                        <custom-input
                                #confirmation
                                *ngIf="listeMessagesConfirmation"
                                ngDefaultControl
                                id="confirmation"
                                name="confirmation"
                                customType="objectselect"
                                [(ngModel)]="segment.message"
                                [selectOptions]="listeMessagesConfirmation"
                                [disabled]="selectedOptionMotif.code != OPTIONS_MOTIFS[0].code || segment.choixAppro"
                                lCol="2" rCol="4"
                                libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.confirmation.title"
                                optionValue="idMessage"
                                optionDisplay="titre"
                                [isTranslated]="false">
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="notification"
                                name="notification"
                                customType="checkbox"
                                [ngModel]="segment.mailObligatoire"
                                [(customModel)]="segment.mailObligatoire"
                                [disabled]="isDisabledNotificationObligatoire()"
                                lCol="2" rCol="4"
                                libelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.notifObligatoire"
                                [postTooltip]="'admin.workflow.construction.segment.onglets.generalites.notificationsCard.notifObligatoireTooltip' | translate"
                                postLibelle="admin.workflow.construction.segment.onglets.generalites.notificationsCard.notifObligatoirePostLibelle">
                        </custom-input>
                    </div>
                </div>
            </list-view-header>
        </list-view>
    </fieldset>
</form>
<mat-spinner diameter="20" *ngIf="!segment"></mat-spinner>
<floating-button [listeActions]="listeFloatingButtonActions"></floating-button>
