import {Component,OnDestroy,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Segment,Statut} from "@domain/admin/workflow/segment";
import {Subscription} from "rxjs";

/**
 * Item de liste des segments
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-visualisation-item'},
	templateUrl: './liste-visualisation-header-item.component.html',
	styleUrls: ['./liste-visualisation-header-item.component.scss']
})
export class ListeVisualisationHeaderItemComponent extends ListViewItem<Segment> implements OnInit,OnDestroy {
	/** Statuts tri�s par �tat dans un tableau (l'emploi de getters sur une map dans le template engendre des probl�mes de perfs avec leur appel constant)*/
	listeStatuts: [number,Statut[]][];

	/** Souscription */
	souscription: Subscription;

	/**
	 * Constructeur
	 */
	constructor() {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Mise � jour de la liste des statuts
		this.updateListeStatuts();

		//Abonnement � la mise � jour du header
		this.souscription = this.liste.onHeaderDataChange$.asObservable().subscribe(() => this.updateListeStatuts());
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//D�sabonnement
		this.souscription.unsubscribe();
	}

	/**
	 * Mise � jour de la liste des statuts
	 */
	updateListeStatuts(): void {
		//Initialisation de la map
		let index: number = 1;
		const mapStatuts: Map<number,Statut[]> = new Map();
		mapStatuts.set(0,[new Statut({})]);
		mapStatuts.get(0)[0]['index'] = 0;

		//It�ration sur les statuts
		for (const statut of (this.liste.headerData as Statut[])) {
			//Tentative de r�cup�ration de la liste des statuts tri�s
			let listeStatuts: Statut[] = mapStatuts.get(statut.idEtat);

			//Si la liste n'existe pas encore
			if (!listeStatuts) {
				//Alors, on la cr��e et on l'ajoute � la map
				listeStatuts = [];
				mapStatuts.set(statut.idEtat,listeStatuts);
			}

			//Ajout du statut
			listeStatuts.push(statut);

			//Stockage de l'index en dur sur l'objet statut
			statut['index'] = index;

			//Incr�ment de l'index
			index++;
		}

		//Conversion de la Map en array � plusieurs dimensions
		this.listeStatuts = Array.from(mapStatuts);
	}
}
