import {Component,OnDestroy,OnInit,ViewChild} from "@angular/core";
import {Location} from '@angular/common';
import {ActivatedRoute,Params,Router} from "@angular/router";
import {BehaviorSubject,Subscription} from "rxjs";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {Action,Segment} from "@domain/admin/workflow/segment";
import {filter,first} from "rxjs/operators";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ACTIONS,PORTEES} from "@components/admin/workflow/construction/segment/generalites/segment-generalites.data";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ToastrService} from "ngx-toastr";
import {SegmentGeneralitesComponent} from "@components/admin/workflow/construction/segment/generalites/segment-generalites.component";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Onglets de la page du détail d'un segment
 */
export enum Onglets {
    GENERALITES = 'GENERALITES',
    INFORMATIONS = 'INFORMATIONS'
}

/**
 * Page de détails d'un segment
 *
 * @author Guillaume TRAVOT
 * @date 23/05/2024
 */
@Component({
    host: {'data-test-id': 'segment'},
    templateUrl: './segment.component.html'
})
export class SegmentComponent implements OnInit,OnDestroy {

    /** Liste des onglets */
    listeTabItems: Array<PageHeaderItem> = [
        {
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.generalites.title')
        },{
            code: Onglets.INFORMATIONS,
            libelle: this.translateService.instant('admin.workflow.construction.segment.onglets.informations.title')
        }
    ];

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Souscription aux changements de route */
    routeSubscription: Subscription;

    /** Segment visualisé */
    segment: Segment;

    /** Segment enregistré */
    segmentBuffer: Segment;

    /** Liste des actions possibles */
    listeActions: Action[] = [];

    /** Récupération du composant enfant des généralités */
    @ViewChild('segmentGeneralitesComponent') segmentGeneralitesComponent: SegmentGeneralitesComponent;

    /** Alerte de la page */
    alerte: Alerte = new Alerte({
        niveau: NiveauAlerte.PROFIL_USER,
        titre: this.translateService.instant('admin.workflow.construction.segment.onglets.informations.alerte.title')
    });

    /** Import des onglets pour le DOM */
    readonly Onglets = Onglets;

    /** Liste des actions du floatingButton en bas à droite */
    listeFloatingButtonActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>([{
        type: TypeAction.PRIMARY,
        icone: 'nio icon-sauvegarde',
        libelle: 'global.actions.enregistrer',
        doAction: () => this.save(),
    },{
        type: TypeAction.SECONDARY,
        icone: 'material-icons-outlined',
        iconeName: 'save_as',
        libelle: 'admin.workflow.construction.segment.dupliquer.dupliquer',
        doAction: () => this.duplicateSegment(),
        isVisible: () => !!this.segment?.idSegment
    },{
        type: TypeAction.SECONDARY,
        icone: 'material-icons-outlined',
        iconeName: 'content_copy',
        libelle: 'admin.workflow.construction.segment.copier',
        doAction: () => this.copySegment(),
        isVisible: () => !!this.segment?.idSegment
    },{
        type: TypeAction.SECONDARY,
        icone: 'nio icon-suppression',
        libelle: 'global.actions.supprimer',
        doAction: () => this.deleteSegment(),
        isVisible: () => !!this.segment?.idSegment
    }]);

    /**
     * Constructeur
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private location: Location,
        private confirmService: ConfirmService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private workflowAdminService: WorkflowAdminService,
        private statutWorkflowService: StatutWorkflowService
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //On s'abonne aux changements de route
        this.routeSubscription = this.activatedRoute.params.subscribe((params: Params) => {
            //Récupération de l'identifiant
            const idSegment: number = +params['idSegment'];

            //Si l'ID est autre que zéro
            if (idSegment) {
                //Récupération du segment
                this.workflowAdminService.getSegmentById(idSegment).pipe(first()).subscribe((segment) => {
                    //Stockage local du segment dans le composant
                    this.segment = segment;
                    this.segmentBuffer = new Segment(segment);
                    this.alerte.message = segment.commentaire;

                    //Mise à jour de la liste des actions selon le rôle et le formulaire
                    this.updateActions();
                });
            } else {
                //Sinon création d'un segment vierge
                const dto = {
                    id: null,
                    libelle: null,
                    actif: true,
                    formulaireDepart: {
                        idPortee: null
                    },
                    formulaireModifie: {
                        idPortee: null
                    },
                    statutFormulaireDepart: {
                        idStatut: null,
                        idEtat: null,
                        libelle: null,
                        code: null
                    },
                    statutDebutFormulaireModifie: {
                        idStatut: null,
                        idEtat: null,
                        libelle: null,
                        code: null
                    },
                    statutFinFormulaireModifie: {
                        idStatut: null,
                        idEtat: null,
                        libelle: null,
                        code: null
                    },
                    conditionFormulaireDepart: {
                        id: null,
                        libelle: null
                    },
                    conditionFormulaireModifie: {
                        id: null,
                        libelle: null
                    },
                    role: {
                        idRole: null,
                        libelle: null,
                        fonction: null
                    },
                    action: null,
                    groupement: true,
                    choixAppro: false,
                    libelleAction: null,
                    libelleBouton: null,
                    softAction: null,
                    actionsSupplementaires: null,
                    saisieMotif: null,
                    saisieMotifObligatoire: null,
                    message: {
                        idMessage: null,
                        titre: null,
                        titreAffichage: null,
                        globalise: null,
                    },
                    mailObligatoire: false,
                    commentaire: null,
                    workflow: {
                        id: null,
                        libelle: null
                    },
                    lstActionSupp: null
                };

                //Instanciation d'un segment vierge
                this.segment = new Segment(dto);
                this.segmentBuffer = new Segment(dto);
            }
        });
    }

    /**
     * Clic sur le bouton retour
     */
    onGoBack(): void {
        //Retour à la liste des alertes
        this.router.navigateByUrl('Admin/Workflow/Construction/Visualisation');
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Mise à jour de la liste des actions selon le rôle et le formulaire
     */
    private updateActions(): void {
        //Suppression de la liste des actions
        this.listeActions = [];

        //Ajout des actions toujours disponibles
        this.listeActions.push(ACTIONS.ANNULATION);
        this.listeActions.push(ACTIONS.EMISSION);
        this.listeActions.push(ACTIONS.INVALIDATION);
        this.listeActions.push(ACTIONS.MODIFICATION);
        this.listeActions.push(ACTIONS.REJET);
        this.listeActions.push(ACTIONS.SUPPRESSION);

        //Affichage de "Validation" ou "Création" selon le statut
        if (this.segment.statutFormulaireDepart && this.segment.statutFormulaireDepart.idStatut) {
            this.listeActions.push(ACTIONS.VALIDATION);
        } else {
            this.listeActions.push(ACTIONS.CREATION);
        }

        //Modification des actions selon le rôle
        if (this.segment.role && (this.segment.role.fonction == 4 || this.segment.role.fonction == 2 || this.segment.role.fonction == 1 || this.segment.role.fonction == 6 || this.segment.role.fonction == 3))
        {
            this.listeActions.push(ACTIONS.CONSULTATION);
        }

        //Affichage de "Archivage" si le rôle n'est pas "Fournisseur"
        if (this.segment.role && this.segment.role.fonction != 6) {
            this.listeActions.push(ACTIONS.ARCHIVAGE);
        }

        //Modification des actions pour le formulaire avance
        if (this.segment.formulaireDepart?.idPortee && this.segment.formulaireDepart?.idPortee == PORTEES.AVANCE) {
            this.listeActions.push(ACTIONS.ATTRIBUTION);
            this.listeActions.push(ACTIONS.COMMANDE);
            this.listeActions.push(ACTIONS.RECEPTION);
            this.listeActions.push(ACTIONS.RESTITUTION);
        }

        //Modification des actions pour les formulaires OM, OMP, DV, NDF
        if (this.segment.formulaireDepart?.idPortee && (this.segment.formulaireDepart?.idPortee == PORTEES.OM || this.segment.formulaireDepart?.idPortee == PORTEES.OMP || this.segment.formulaireDepart?.idPortee == PORTEES.DV || this.segment.formulaireDepart?.idPortee == PORTEES.NDF || this.segment.formulaireDepart?.idPortee == PORTEES.FACTURE)) {
            this.listeActions.push(ACTIONS.COMPLETION);
        }

        //Modification des actions pour le formulaire OM
        if (this.segment.formulaireDepart?.idPortee && this.segment.formulaireDepart?.idPortee == PORTEES.OM) {
            this.listeActions.push(ACTIONS.CLOTURE);
        }

        //Modification des actions pour les formulaires NDF, AV et FAC pour le profil comptable
        if (this.segment.formulaireDepart?.idPortee && this.segment.role && this.segment.role.fonction == 1 && (this.segment.formulaireDepart?.idPortee == PORTEES.NDF || this.segment.formulaireDepart?.idPortee == PORTEES.AVANCE || this.segment.formulaireDepart?.idPortee == PORTEES.FACTURE)) {
            this.listeActions.push(ACTIONS.COMPTABILISATION);
        }

        //Modification des actions pour le formulaire DV
        if (this.segment.formulaireDepart?.idPortee && this.segment.formulaireDepart?.idPortee == PORTEES.DV) {
            this.listeActions.push(ACTIONS.WF_ACTION_1);
            this.listeActions.push(ACTIONS.RESTITUTION);
        }

        //Tri alphabétique des actions
        this.listeActions.sort(function(a, b) {
            const x = a.libelle.toLowerCase();
            const y = b.libelle.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });
    }

    /**
     * Formatage automatique du libellé
     */
    private autoFormatLibelle(): void {
        //Construction du libellé
        const codePortee: string = this.segment.formulaireDepart?.idPortee as string;
        const action3Letters: string = this.listeActions.find(a => a.id == this.segment.action).libelle.toUpperCase().substring(0,3);
        const statutDepart: string = this.segment.statutFormulaireDepart.code;

        //Mise à jour du libellé du segment
        this.segment.libelle = `${codePortee}_${action3Letters}_${statutDepart}`;
    }

    /**
     * Enregistrement du segment
     */
    save(): void {
        //Si le formulaire est valide
        if (this.segmentGeneralitesComponent?.form?.valid) {
            //Si le libellé du segment n'a pas été renseigné
            if (!this.segment.libelle) {
                //Formatage automatique du libellé
                this.autoFormatLibelle();
            }

            //Duplication du segment
            const segment: Segment = new Segment(this.segment);

            //Réinitialisation des actions supplémentaires sauvegardées
            segment.actionsSupplementaires = [];

            //Réinitialisation des actions supplémentaires
            segment.lstActionSupp = "";

            //Peuplement des actions supplémentaires
            this.segmentGeneralitesComponent.listeSelectedActions.forEach(a => {
                //Si l'action a un ID normal
                if (a.id >= 0) {
                    //Ajout de l'action à la liste
                    segment.actionsSupplementaires.push(a.id);
                } else {
                    //Sinon c'est une action "spéciale" et elle est stockée ailleurs
                    segment.lstActionSupp += " " + a.id;
                }
            });

            //Si la procédure supplémentaire porte sur les dossiers voyage
            if (this.segmentGeneralitesComponent.selectedProcedureSupplementaire?.["isDv"]) {
                //Stockage dans eltAction
                segment.eltAction = this.segmentGeneralitesComponent.selectedProcedureSupplementaire?.id;

                //Purge de softAction
                segment.softAction = undefined;
            } else {
                //Sinon stockage dans softAction
                segment.softAction = this.segmentGeneralitesComponent.selectedProcedureSupplementaire?.id;

                //Purge de eltAction
                segment.eltAction = undefined;
            }

            //Nettoyage des actions supplémentaires
            segment.lstActionSupp = segment.lstActionSupp.trim();

            //Appel au backend
            this.workflowAdminService.saveSegment(segment).pipe(first()).subscribe(result => {
                //Vérification du code d'erreur
                if (result.codeErreur == 0 || !result.codeErreur) {
                    //Récupération des données
                    this.segment = result.data.segment;
                    this.segmentBuffer = new Segment(this.segment);
                    this.alerte.message = segment.commentaire;

                    //Message de réussite
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Rafraichissement du bandeau Workflow
                    this.statutWorkflowService.loadSynchroWFStatut();

                    //Changement de l'URL
                    this.location.go(`Admin/Workflow/Construction/Segment/${this.segment.idSegment}`);
                } else if (result.codeErreur == 6) {
                    //Message d'échec
                    this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.enregistrer.erreurDoublon'));
                } else {
                    //Message d'échec
                    this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.enregistrer.erreur'));
                }
            });
        } else {
            //Sinon, message d'erreur
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Duplication du segment
     */
    duplicateSegment(): void {
        //Si le libellé du segment n'a pas été renseigné
        if (!this.segment.libelle) {
            //Formatage automatique du libellé
            this.autoFormatLibelle();
        }

        //Duplication du segment
        const segment: Segment = new Segment(this.segment);

        //Réinitialisation des actions supplémentaires sauvegardées
        segment.actionsSupplementaires = [];

        //Réinitialisation des actions supplémentaires
        segment.lstActionSupp = "";

        //Peuplement des actions supplémentaires
        this.segmentGeneralitesComponent.listeSelectedActions.forEach(a => segment.lstActionSupp += " " + a.id);

        //Nettoyage des actions supplémentaires
        segment.lstActionSupp = segment.lstActionSupp.trim();

        //Appel au backend
        this.workflowAdminService.duplicateSegment(segment).pipe(first()).subscribe(result => {
            //Vérification du code d'erreur
            if (result.codeErreur == 0 || !result.codeErreur) {
                //Message de réussite
                this.toastrService.success(this.translateService.instant('admin.workflow.construction.segment.dupliquer.success'));

                //Rafraichissement du bandeau Workflow
                this.statutWorkflowService.loadSynchroWFStatut();

                //Chargement du nouveau segment
                this.router.navigate([`Admin/Workflow/Construction/Segment/${result?.data?.segment?.id}`]);
            } else if (result.codeErreur == 6) {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.dupliquer.erreurDoublon'));
            } else {
                //Message d'échec
                this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.dupliquer.erreur'));
            }
        });
    }

    /**
     * Copie du segment
     */
    copySegment(): void {
        //Duplication du segment
        const segment: Segment = new Segment(this.segment);

        //Sauvegarde du segment source puis purge des informations de base
        segment.segmentSourceId = this.segment.idSegment;
        segment.idSegment = 0;
        segment.libelle = "";
        segment['isCopy'] = true;

        //Stockage local d'un nouveau segment dans le composant
        this.segment = segment;
        this.segmentBuffer = new Segment(segment);

        //Changement de l'URL
        this.location.go('Admin/Workflow/Construction/Segment/0');
    }

    /**
     * Suppression du segment
     */
    deleteSegment(): void {
        //Confirmation de la suppression du segment
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Appel au backend
                this.workflowAdminService.deleteSegment(this.segment).pipe(first()).subscribe(result => {
                    //Vérification du code d'erreur
                    if (result.codeErreur == 0 || !result.codeErreur) {
                        //Message de réussite
                        this.toastrService.success(this.translateService.instant('admin.workflow.construction.segment.supprimer.success'));

                        //Rafraichissement du bandeau Workflow
                        this.statutWorkflowService.loadSynchroWFStatut();

                        //Retour à la liste
                        this.onGoBack();
                    } else {
                        //Message d'échec
                        this.toastrService.error(this.translateService.instant('admin.workflow.construction.segment.supprimer.erreur'));
                    }
                });
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Désabonnement
        this.routeSubscription.unsubscribe();
    }
}
