<div class="header-item-content">
    <div *ngFor="let etat of listeStatuts" class="header-item">
        <div class="header-item-etat" [style.color]="etat[0] ? null : 'transparent'">
            {{ !!etat[0] ? ('admin.workflow.visualisation.etats.' + etat[0] | translate) : etat[0] }}
        </div>
        <div class="header-item-liste-statut">
            <div *ngFor="let statut of etat[1]"
                 class="header-item-statut"
                 [class.odd-index]="statut['index'] % 2 != 0">
                {{ statut.libelle || ('admin.workflow.visualisation.vide' | translate) }}
            </div>
        </div>
    </div>
</div>
