import {ActivatedRouteSnapshot,CanActivate,Router,RouterStateSnapshot,UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {LoginService} from "@share/login/login.service";
import {Session} from "@domain/security/session";
import {TypeProfil} from "@domain/user/user";
import {Injectable} from "@angular/core";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * GuardProvider pour contrôler les droits de la fonction du type profil
 *
 * @author Lionel GIMENEZ
 * @date 05/11/2024
 */
@Injectable()
export class FonctionGuardProvider implements CanActivate {

    /**
     * Constructeur
     *
     * @param loginService Service de gestion de la session et du login
     * @param router Routeur Angular
     */
    constructor(private loginService: LoginService,private router: Router) {
    }

    /**
     * Activation du composant
     */
    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        //Initialisation
        let session: Session;

        //Récupération de la session
        session = this.loginService.getSession();

        //Vérification que l'utilisateur est authentifié, et que la route autorise son profil courant
        if (session?.isLogged) {
            //Vérification du cas particulier du sous-administrateur pour qui il faut vérifier les droits spécifiques
            if (session.user?.fonction === TypeProfil.SOUS_ADMINISTRATEUR) {
                //S'il y a des droits spécifiques définis sur la route
                if (route.data?.sousAdminCredentials) {
                    //On vérifie que l'utilisateur possède au moins l'un d'eux
                    return (<Array<DroitAdmin>>route.data.sousAdminCredentials)?.some(droitAdmin => session.user?.listeDroits?.includes(droitAdmin));
                } else {
                    //Sinon, on vérifie que la route autorise le profil de sous admin
                    return route.data?.fonctions?.includes(TypeProfil.SOUS_ADMINISTRATEUR);
                }
            } else {
                //Si l'utilisateur n'est pas sous-admin, on vérifie que son profil courant fait partie de ceux autorisés
                return route.data?.fonctions?.includes(session?.user?.fonction);
            }
        }

        return false;
    }
}