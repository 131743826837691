import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'infinite-scroll',
    template: '<ng-content></ng-content><div style="position:relative;bottom:600px;" #infiniteScrollEnd></div>'
})
export class InfiniteScrollComponent implements OnInit,OnDestroy {
    /** Valeur d'offset pour la position de la div de détection du bottom */
    @Input() set offset(offset: number) {
        //Constantes
        const MAX_POSITION: number = 600;
        const MIN_POSITION: number = 10;
        const MARGIN: number = 5;
        const WINDOW_HEIGHT: number = window.innerHeight;

        //Calcul de la position théorique
        const calculatedPosition: number = Math.min((WINDOW_HEIGHT - offset - MARGIN), MAX_POSITION);

        //Protection contre une position négative dans le référentiel
        const effectivePosition: number = Math.max(MIN_POSITION, calculatedPosition);

        //Application de la position effective retenue
        this.infiniteScrollEnd.nativeElement.style.bottom = effectivePosition + "px";
    };

    /** Événement de scroll sur le composant **/
    @Output() scrolled = new EventEmitter();

    /** Élément situé à l'extrémité du composant **/
    @ViewChild('infiniteScrollEnd',{ static: true }) infiniteScrollEnd: ElementRef<HTMLElement>;

    /**  Observateur de l'apparition de l'extrémité du composant dans la fenêtre **/
    private observer: IntersectionObserver;

    /**
     * Constructeur
     */
    constructor() {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Définition de l'observateur
        this.observer = new IntersectionObserver(([entry]) => {
            //Notification du scroll lorsque l'élément observé apparait
            entry.isIntersecting && this.scrolled.emit();
        },{});

        //Détection de l'apparition de l'extrémité du composant
        this.observer.observe(this.infiniteScrollEnd.nativeElement);
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Déconnexion de l'observateur
        this.observer.disconnect();
    }
}