import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {Statut} from "@domain/workflow/statut";
import {Pea} from "@domain/workflow/pea";


/**
 * Service de gestion des Statuts
 */
@Injectable()
export class WorkflowStatutsService {
    /**
     * Constructeur
     *
     * @param http Client http
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Récupère un statut par son identifiant
     *
     * @param idStatut Identifiant du statut cherché
     * @returns {Observable<Result>}
     */
    public getStatut(idStatut: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Statut/getStatutDTO/${idStatut}`);
    }

    /**
     * Enregistrement d'un statut
     *
     * @param statut Statut à enregistrer
     * @returns {Observable<Result>}
     */
    public saveStatut(statut: Statut): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Statut/saveStatut`,statut);
    }

    /**
     * Suppression d'un statut
     *
     * @param idStatut Identifiant du statut à supprimer
     * @returns {Observable<Result>}
     */
    public deleteStatut(idStatut: number): Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Statut/deleteStatut/${idStatut}`);
    }

    /**
     * Récupération de la liste des représentations
     */
    public getListeRepresentationStatut(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Statut/showImages`);
    }

    /**
     * Récupère un état par son identifiant
     *
     * @param idEtat Identifiant de l'état cherché
     * @returns {Observable<Result>}
     */
    public getEtat(idEtat: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Statut/loadEtat`,idEtat);
    }

    /**
     * Enregistrement d'un état
     *
     * @param idEtat Identifiant de l'état à enregistrer
     * @param listePeas Liste des peas
     * @returns {Observable<Result>}
     */
    public saveEtat(idEtat: number,listePeas: Array<Pea>): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Statut/saveEtat/${idEtat}`,listePeas);
    }
}
