import { Injectable } from '@angular/core';

@Injectable()
export class DynamicDragClickInterceptorService {
    /** Déplacement en cours */
    isDragging = false;

    /** Mouvement ayant eu lieu */
    isMoved = false;

    /** Déplacement désactivé */
    isDragDisabled = false;

    /** Position de départ */
    startX = 0;
    startY = 0;
}
