<fieldset>
    <mat-card>
        <mat-card-title>
            <span [translate]="'workflow.statut.statut'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  customType="input"
                                  libelle="workflow.statut.libelle"
                                  [(ngModel)]="statut.libelle"
                                  required="true"
                                  [maxlength]="100">
                    </custom-input>
                    <label class="col-md-2">
                        <span [translate]="'workflow.statut.representation'"></span>
                    </label>
                    <statut [statut]="statut"></statut>
                    <button mat-button color="primary" (click)="onClickRepresentation()" class="mb-2">
                        <mat-icon color="primary">search</mat-icon>
                    </button>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="etat"
                                  name="etat"
                                  customType="select"
                                  libelle="workflow.statut.etat"
                                  [(ngModel)]="statut.idEtat"
                                  [selectOptions]="listeEtats"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  required="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="ordre"
                                  name="ordre"
                                  customType="input"
                                  libelle="workflow.statut.ordre"
                                  [(ngModel)]="statut.ordre">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="code"
                                  name="code"
                                  customType="input"
                                  libelle="workflow.statut.code"
                                  [(ngModel)]="statut.code"
                                  required="true"
                                  [maxlength]="5">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="commentaire"
                                  name="commentaire"
                                  customType="input"
                                  libelle="workflow.statut.commentaire"
                                  [(ngModel)]="statut.commentaire"
                                  [maxlength]="500"
                                  rCol="9">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
