<div #top style="position:absolute;top:0;"></div>
<div *ngIf="isToggled && isStickyList" class="menu-mask"></div>
<div class="drawer" [ngClass]="{ toggled: isToggled }">
    <div class="sidebar animated fadeIn" *ngIf="layoutService.isLayoutPostLogin$ | async" [class.sticky]="isStickyList" [class.toggled]="isToggled">
        <ul *ngIf="getSelectedMenuItem() as selectedMenuItem" class="profil">
            <li>
                <a>
                    <i class="{{ selectedMenuItem.icone }}"
                       [menu-dropdown]="getListeVisibleItems(listeItems | filterBy:isVisibleFor(Scope.PROFIL))"
                       [dropdown-title]="selectedMenuItem.libelle"></i>
                    <span [translate]="selectedMenuItem.libelle"></span>
                </a>
            </li>
        </ul>

        <ul class="menu {{ (session.isAdmin || session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) ? 'admin' : '' }}">
            <li *ngFor="let item of getListeVisibleItems(listeItems | filterBy:isVisibleFor((session.isAdmin || session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) ? Scope.MENU_ADMIN : Scope.MENU_USER))"
                class="top-menu" [ngClass]="{ 'selected': item.isSelected && item.isSelected(session,selectedTypePortee), 'open': item == menuOuvert, 'closed': item != menuOuvert }">
                <menu-content>
                    <a class="lienMenu" [routerLink]="item.url" (click)="doAction(item); open(item)">
                        <i *ngIf="isCustomIcone(item)" class="{{ item.icone }}"
                           [matTooltip]="item.libelle | translate"
                           [matTooltipDisabled]="isToggled || item.children?.length > 0"
                           matTooltipPosition="right"
                           [menu-dropdown]="getListeVisibleItems(item.children)"
                           [dropdown-title]="item.libelle"
                           [attr.aria-label]="item.libelle | translate"
                           [disabled]="(session.isAdmin || session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) && isToggled">
                        </i>
                        <i *ngIf="!isCustomIcone(item)" class="material-icons-outlined"
                           [matTooltip]="item.libelle | translate"
                           [matTooltipDisabled]="isToggled || item.children?.length > 0"
                           matTooltipPosition="right"
                           [menu-dropdown]="getListeVisibleItems(item.children)"
                           [dropdown-title]="item.libelle"
                           [attr.aria-label]="item.libelle | translate"
                           [disabled]="(session.isAdmin || session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) && isToggled">
                            {{ item.icone }}
                        </i>

                        <span [translate]="item.libelle"></span>
                    </a>

                    <!-- Liste des sous-menus pour affichage en mode déplié -->
                    <ul class="sousmenu" [hidden]="menuOuvert != item || !isToggled">
                        <li *ngFor="let child of getListeVisibleItems(item.children)" [ngClass]="{ 'sousMenuSelected': child == sousMenuSelected, 'sousMenuNotSelected': child != sousMenuSelected }">
                            <a [routerLink]="child.url" (click)="doAction(child); select(child); $event.stopPropagation();">
                                {{child.libelle | translate}}
                            </a>
                        </li>
                    </ul>
                </menu-content>
            </li>
        </ul>

        <!-- Icônes en base de page -->
        <ul class="{{ (session.isAdmin || session.user?.fonction == TypeProfil.SOUS_ADMINISTRATEUR) ? 'admin' : '' }}" style="position:fixed;bottom:10px;left:10px;">
            <!-- Affichage de la flèche pour revenir en haut de page-->
            <li *ngIf="displayScrollToTop">
                <a (click)="scrollToTop(top)">

                    <i class="material-icons" [matTooltip]="'menu.pageTop' | translate" matTooltipPosition="right">north</i>
                    <span [translate]="'menu.pageTop'"></span>
                </a>
            </li>
        </ul>
    </div>
    <div class="drawer-content animated fadeIn">
        <ng-content></ng-content>
    </div>
</div>