<div *ngIf="enveloppe">

    <page-header
            [title]="(isCreation ? 'admin.entreprise.analytique.enveloppes.titleCreation' : 'admin.entreprise.analytique.enveloppes.titleModification') | translate : { libelle: enveloppe.libelle }"
            [listeItems]="listeTabItems"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            (onGoBack)="onGoBack()"
            [niveauAlerte]="isConsommationTab() ? alerte?.niveau : null"
            [extraInfo]="getMontantTotalImpute()">
        <preview-alerte *ngIf="isConsommationTab() && settings && computeAlerte()" [alerte]="alerte"></preview-alerte>
    </page-header>


    <div [hidden]="selectedItem?.code !== Onglets.ENVELOPPE">
        <enveloppe-generalite
                [enveloppe]="enveloppe"
                [settings]="settings"
                [isCreation]="isCreation"
        ></enveloppe-generalite>
    </div>

    <div *ngIf="isMontantTabLoaded" [hidden]="selectedItem?.code !== Onglets.MONTANTS">
        <enveloppe-montants [enveloppe]="enveloppe"></enveloppe-montants>
    </div>

    <div *ngIf="isPrevisionnelTabLoaded" [hidden]="selectedItem?.code !== Onglets.CONSOMMATION_PREVISIONNEL">
        <enveloppe-consommation *ngIf="enveloppe"  [idEnveloppe]="enveloppe.idEnveloppe" [typeBudget]="TypeBudget.PREVISIONNEL"></enveloppe-consommation>
    </div>

    <div *ngIf="isReelTabLoaded" [hidden]="selectedItem?.code !== Onglets.CONSOMMATION_REEL">
        <enveloppe-consommation *ngIf="enveloppe"  [idEnveloppe]="enveloppe.idEnveloppe" [typeBudget]="TypeBudget.REEL"></enveloppe-consommation>
    </div>

    <div *ngIf="isResponsableTabLoaded" [hidden]="selectedItem?.code !== Onglets.RESPONSABLE">
        <enveloppe-responsable *ngIf="enveloppe" [idEnveloppe]="enveloppe.idEnveloppe"></enveloppe-responsable>
    </div>

    <floating-button [listeActions]="listeActions"></floating-button>
</div>