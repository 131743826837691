import {Component,EventEmitter,HostListener,Input,OnInit,Output,TemplateRef,ViewChild} from '@angular/core';
import {AppState} from "@domain/appstate";
import {Result} from "@domain/common/http/result";
import {Etape} from "@domain/od/etape";
import {Od} from "@domain/od/od";
import {Proposition} from "@domain/od/proposition";
import {ModeParticipants} from "@domain/settings/ns-params";
import {SettingsODState} from '@domain/settings/settings';
import {SaisieEtapeDTO} from "@domain/travel/saisie-etape-dto";
import {User} from "@domain/user/user";
import {NatureVoyage} from "@domain/voyage/nature/nature-voyage";
import {TypeAiguillage,TypeAiguillageLiteral,TypeNature} from "@domain/voyage/travel/constants";
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {MapAction} from "@domain/workflow/mapAction";
import {Store} from "@ngrx/store";
import {BehaviorSubject,Subject} from "rxjs";
import {first} from "rxjs/operators";
import {ODService} from "../../od.service";
import {ODVoyageService} from "./od-voyage.service";
import {OdVoyageTravelPopupComponent} from "./voyage/travel/popup/od-voyage-travel-popup.component";
import {ODRseService} from "@components/od/od-rse.service";

/**
 * Composant d'affichage de l'onglet voyage des OD
 *
 * @author Laurent SCIMIA
 * @date 04/01/2022
 */
@Component({
    host: {'data-test-id': 'od-voyage'},
    selector: 'od-voyage',
    templateUrl: './od-voyage.component.html',
    styleUrls : ['./od-voyage.component.scss']
})
export class ODVoyageComponent implements OnInit {
    /** Récupération de l'enum pour le front */
    TypeAiguillage = TypeAiguillageLiteral;

    /** Ordre de mission */
    @Input() od: Od;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Indicateur de completion possible */
    @Input() canCompleter: boolean;

    /** Paramétrage de l'OD */
    @Input() settings: SettingsODState;

    /** Statut du chargement */
    isLoading: boolean = true;

    /** Liste des étapes */
    listeEtapes: Etape[];

    /** Liste des propositions */
    listePropositions: Proposition[];

    /** Devise d'entreprise */
    @Input() devise: string;

    /** Liste de tous les SBT dispo pour l'utilisateur */
    listeSBTOriginale: SynchroSBTConfigUser[] = null;

    /** Mode de participant pour l'OD */
    @Input() modeParticipant: ModeParticipants;

    /** Aiguillage de l'OD */
    @Input() aiguillageOd: TypeAiguillage;

    /** Évènement indiquant que la liste des étapes a été modifiée */
    @Output() etapesChanged = new EventEmitter<void>();

    /** Template de la popup d'attente */
    @ViewChild('waitTemplate') waitTemplate: TemplateRef<any>;

    /** Booléen qui indiquant si on doit empêcher la fermeture de l'onglet */
    private isBlockReload: boolean = false;

    /** Référence à l'énum des modes de participants */
    ModeParticipants = ModeParticipants;

    /** Utilisateur connecté */
    user: User;

    /** Subject de booléen qui mémorise si les SBT ont déjà été synchronisés */
    isSbtDejaSynchro$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Actions possibles pour le DV */
    dvActions: MapAction;

    /** Indique si l'OD peut contenir un voyage */
    @Input() isVoyageAutorise: boolean;

    /** Liste de toutes les natures de l'OD */
    listeNaturesOd: NatureVoyage[];

    /** Indique si le profil voyageur de l'utilisateur est valide */
    isProfilVoyageurValide: boolean;

    /** Liste des natures de la proposition */
    public listeNatures: NatureVoyage[];

    /** Indique s'il y a au moins une étape non réservée */
    etapeNonReservee: Subject<boolean> = new Subject<boolean>();

    /**
     * Constructeur
     *
     * @param odService         Service de l'OD
     * @param store             Store de données de l'application
     * @param odVoyageService   Service dédié aux voyages
     * @param odRseService      Service du RSE pour les OD
     */
    constructor(private odService: ODService,
                private store: Store<AppState>,
                private odVoyageService: ODVoyageService,
                private odRseService: ODRseService) {
    }

    ngOnInit(): void {
        //Sélection de l'utilisateur connecté
        this.store.select(state => state.session?.user).subscribe(user => this.user = user);

        //On initialise a false
        this.etapeNonReservee.next(false);

        //On charge les infos du voyage
        this.loadVoyage();
    }

    /**
     * Chargements des infos du voyage
     */
    loadVoyage(): void {
        this.isLoading = true;

        this.odService.loadVoyage(this.od.idOd).pipe(first()).subscribe((result: Result) => {
            //On stocke la liste des SBT avant filtre
            this.listeSBTOriginale = result.data.listeSBT;
            this.listeEtapes = result.data.listeEtapes;
            this.listePropositions = result.data.listePropositions;
            this.dvActions = result.data.dvActions;
            this.isProfilVoyageurValide = result.data.isProfilVoyageurValide;

            if (this.listePropositions?.length > 0) {
                this.listePropositions.forEach(p => {
                    // Si on a pas de dossier (gestion des résa faites en V9)
                    if (p.listeDossierTravel?.length <= 0) {
                        //On charge les natures (le détail) de la proposition
                        this.loadNatures(p.idProposition);
                    } else if (this.aiguillageOd === TypeAiguillage.ONLINE) {
                        //On lie toutes les natures de tous les dossiers avec leurs étapes respectives
                        p.listeDossierTravel.forEach(d => this.linkEtapeToNature(d.listeNaturesDTO));
                    }
                });
            } else {
                this.etapeNonReservee.next(false);
            }

            //On récupère la liste de toutes les natures de l'OD à plat
            this.listeNaturesOd = this.listePropositions.flatMap(p => p.listeDossierTravel.flatMap(d => d.listeNaturesDTO));

            //On va parcourir la liste des SBT pour ajouter du fun
            this.listeSBTOriginale.forEach((sbt, index) => {
                //Si on trouve un aiguillage offline et AVION_TRAIN
                if (sbt.typeAiguillage == TypeAiguillage.OFFLINE && sbt.idNature == TypeNature.AVION_TRAIN) {
                    //On crée la contrepartie en Avion
                    let newSbtAvion = Object.assign({}, sbt);
                    //On crée la contrepartie en Train
                    let newSbtTrain = Object.assign({}, sbt);

                    //On change la nature
                    newSbtAvion.idNature = TypeNature.AVION;
                    newSbtTrain.idNature = TypeNature.TRAIN;

                    //On retire l'AVION_TRAIN
                    this.listeSBTOriginale.splice(index, 1);
                    //On ajoute les contreparties Avion et Train
                    this.listeSBTOriginale.push(newSbtAvion, newSbtTrain);
                }
            });

            //On envoie la liste de tous les SBT disponibles au service de voyage
            this.odVoyageService.listeSBT = this.listeSBTOriginale;

            this.isLoading = false;
        });
    }

    /**
     * Blocage de la fermeture de l'onglet du navigateur si le booléen associé le veut bien.
     *
     * @param $event Evènement à renseigner si on doit bloquer l'onglet
     */
    @HostListener('window:beforeunload', ['$event'])
    blockReload($event) {
        if (this.isBlockReload) $event.returnValue = '';
    }

    /**
     * Charge les natures de la proposition
     */
    private loadNatures(idProposition: number) {
        this.odService.loadNatures(idProposition).subscribe({
            next: listeNatures => {
                this.listeNatures = listeNatures;
                //En Online
                if (this.aiguillageOd === TypeAiguillage.ONLINE) {
                    //On lie les natures aux étapes
                    this.linkEtapeToNature(this.listeNatures);
                }
            }
        });
    }

    /**
     * Méthode de lien des étapes à leurs natures respectives
     *
     * @param listeNatures Liste des natures à traiter
     */
    linkEtapeToNature(listeNatures: Array<NatureVoyage>) {
        //On parcourt les étapes de l'OD qui n'ont pas encore d'idNature
        this.listeEtapes.filter(e => e.idNature == null || e.idNature == 0).forEach(e => {
            //On cherche la nature associée à l'étape
            let nature = listeNatures?.find(n => n.idEtape === e.idEtape);

            //Si on la trouve, on renseigne l'id de la nature, sinon on met 0
            e.idNature = nature ? nature.idNature : 0;
        });

        //S'il y a des étapes
        if (this.listeEtapes) {
            //On indique s'il y a au moins une étape non réservée
            this.etapeNonReservee.next(this.listeEtapes.some(e => e.idPortee !== 'FA' && e.idNature === 0));
        }
    }

    /**
     * Ouvre la popup d'ajout d'étape
     */
    addVoyage($event?: SaisieEtapeDTO) {
        //Si on n'a pas déjà un onglet ouvert
        if (!this.isBlockReload) {
            this.isBlockReload = true;

            //On lance le calcul du RSE pour les natures disponibles
            this.odRseService.loadRseValueByNature(this.od,this.odVoyageService.listeSBTParNature);

            //On ouvre la popup de création/modification d'une étape
            this.odService.openPopupTravel(this.devise,
                this.od,
                this.odVoyageService.listeSBTFiltree,
                this.odVoyageService.listeSBTParNature,
                OdVoyageTravelPopupComponent,
                this.isSbtDejaSynchro$,
                this.listePropositions.length > 0,
                this.listeEtapes?.length > 0,
                this.dvActions,
                (this.canModifier || this.canCompleter) && (!this.hasPropositionChoisie() || this.od.aiguillage === TypeAiguillageLiteral.ONLINE),
                this.isProfilVoyageurValide,
                $event).afterClosed().subscribe({
                next: value => {
                    //Si la popup s'est fermée suite à un enregistrement
                    if (value?.[0]) {
                        //Si on a une liste d'id d'étapes ONLINE
                        if (value?.[1]?.length > 0) {
                            this.odService.goToSbtOnline(value[1],this.od.idOd,value[2]).pipe(first()).subscribe(() => {
                                this.isBlockReload = false;
                                //A la fin du ONLINE, on recharge l'OD
                                this.etapesChanged.emit();
                            });
                        } else {
                            this.isBlockReload = false;
                            //On indique qu'on a modifié les étapes
                            this.etapesChanged.emit();
                        }
                    } else {
                        this.isBlockReload = false;
                    }
                }
            });
        }
    }

    /**
     * Getter pour le bouton d'actions
     */
    get isActionsAutorisees(): boolean {
        return this.od?.getMapAction()?.actionsSupplementaires?.canOdCreationDossierVoyage?.possible;
    }

    /**
     * Indique si une proposition a été choisie
     */
    hasPropositionChoisie() {
        return this.listePropositions?.some(p => p.choisie);
    }
}