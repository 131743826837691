import {AfterViewInit,Component,Input} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {RseService} from "@services/share/rse/rse.service";
import {filter,take} from "rxjs/operators";

/**
 * Composant pour l'iframe de calcul du CO2e
 *
 * @author Laurent SCIMIA
 * @date 07/05/2024
 */
@Component({
	host: {'data-test-id': 'rse-iframe'},
	selector: 'rse-iframe',
	templateUrl: './rse-iframe.component.html',
	styleUrls: ['./rse-iframe.component.scss']
})
export class RseIframeComponent implements AfterViewInit {
	/** Indique si l'iframe est chargée */
	isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	/** Libellé d'origine pour préremplir l'iframe (au format 'Ville Pays') */
	@Input()
	libelleOrigine?: string;

	/** Libellé de destination pour préremplir l'iframe (au format 'Ville Pays') */
	@Input()
	libelleDestination?: string;

	/**
	 * Constructeur
	 *
	 * @param rseService Service de gestion du RSE
	 */
	constructor(private rseService: RseService) {
	}

	/** Après le chargement de la vue */
	ngAfterViewInit(): void {
		this.rseService.paramRse.pipe(filter(param => param?.isCalculActif),take(1)).subscribe(params => {
			//On lance le script de l'iframe
			setTimeout(() => this.loadExternalScript().then(() => this.isLoaded.next(true)));
		});
	}

	/**
	 * Chargement du script de l'iframe
	 */
	public loadExternalScript(): Promise<void> {
		return new Promise((resolve,reject) => {
			const scriptElement = document.createElement('script');

			//Options de la recherche
			let dataSearch = '?theme=default&language=fr&tabs=itineraire&defaultMode=list';

			scriptElement.src = 'https://impactco2.fr/iframe.js';
			scriptElement.type = 'text/javascript';
			scriptElement.async = true;
			scriptElement.setAttribute("name","impact-co2");
			scriptElement.setAttribute('data-type','transport/itineraire');

			//S'il y a un libellé d'origine renseigné
			if (this.libelleOrigine) {
				//On l'ajoute pour qu'il soit pré-renseigné
				dataSearch += '&itineraireStart=' + this.libelleOrigine;
			}

			//S'il y a un libellé de destination renseigné
			if (this.libelleDestination) {
				//On l'ajoute pour qu'il soit pré-renseigné
				dataSearch += '&itineraireEnd=' + this.libelleDestination;
			}
			scriptElement.setAttribute('data-search',dataSearch);

			scriptElement.onload = () => resolve();
			scriptElement.onerror = (error) => reject(error);
			const container = document.getElementById('script-container');
			if (container) {
				container.appendChild(scriptElement);
			}
		});
	}
}