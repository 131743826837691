import {NgModule} from '@angular/core';

import {CoreComponentsModule} from '../core-components.module';
import {ListViewComponent} from './list-view.component';
import {ListViewSearchComponent} from './list-view-search.component';
import {ListViewService} from './list-view.service';
import {ListViewItemComponent} from './list-view-item.component';
import {ListViewSelectorDirective} from './list-view-selector.directive';
import {InfiniteScrollComponent} from './infinite-scroll.component';
import {PleaseWaitModule} from '../please-wait/please-wait.module';
import {InputNumberModule} from '../../directive/number/input-number.module';
import {ListViewHeaderComponent} from './list-view-header.component';
import {ListViewFooterComponent} from './list-view-footer.component';
import {SortingComponent} from './sorting/sorting.component';
import {CardAlerteModule} from "@share/component/card-alerte/card-alerte.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {DynamicDragScrollDirective} from "@share/component/list-view/dynamic-drag-scroll.directive";
import {DynamicDragClickInterceptorDirective} from "@share/component/list-view/dynamic-drag-click-interceptor.directive";
import {DynamicDragClickInterceptorService} from "@share/component/list-view/dynamic-drag-click-interceptor.service";

@NgModule({
	imports: [CoreComponentsModule,PleaseWaitModule,InputNumberModule,CardAlerteModule,PreviewAlerteModule],
    declarations: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent,InfiniteScrollComponent,SortingComponent,DynamicDragScrollDirective,DynamicDragClickInterceptorDirective],
    exports: [ListViewComponent,ListViewSearchComponent,ListViewItemComponent,ListViewSelectorDirective,ListViewHeaderComponent,ListViewFooterComponent,DynamicDragScrollDirective,DynamicDragClickInterceptorDirective],
    providers: [ListViewService,DynamicDragClickInterceptorService]
})
export class ListViewModule {}
