import {Injectable} from '@angular/core';
import {HttpClient,HttpParams,HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Store} from '@ngrx/store';
import {first,map} from 'rxjs/operators';

import {AppState} from '@domain/appstate';
import {Session} from '@domain/security/session';
import * as sessionActions from '@reducers/session';

import {Result} from '@domain/common/http/result';
import {User} from '@domain/user/user';
import {environment} from '@environments/environment';
import {Auth} from "@domain/security/auth";
import {LOAD_AUTH} from "@reducers/auth";
import {Logo} from '@domain/settings/logo'
import {LangueService} from "@services/admin/langue/langue.service";

/**
 * Service de gestion de l'authentification
 */
@Injectable()
export class LoginService {
    /** Session utilisateur */
    private session: Session = null;

    /** Méthode d'authentification */
    private auth: Auth = null;

    /**
     * Constructeur
     */
    constructor(private http: HttpClient, private store: Store<AppState>, private langueService: LangueService) {
        //Sélection de la session
        this.store.select<Session>(s => s.session).subscribe(session => this.session = session);
    }

    /**
     * Accesseurs
     */
    public getSession(): Session { return this.session; };
    public getAuth(): Auth { return this.auth; };

    /**
     * Connexion avec authentification locale
     */
    public login(user: User,captchaToken?: string,redirectAfterLogin?: string,forceLoginLocal?: boolean): Observable<Session> {
        let params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('action','Login');
        params.append('login',user.login);
        params.append('password',user.password);

        //On indique le mode LoginLocal forcé
        if (forceLoginLocal) {
            params.append('modeLoginLocal','1');
        }

        //Connexion de l'utilisateur
        return this.http.post(`${environment.baseUrl}/servlet/NDFServlet`,params.toString(),{
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-AUTH-CAPTCHA': captchaToken || ''
            },
            responseType: 'text',
            observe: 'response'
        }).pipe(
            map(response => {
                return this.processLoginResponse(response,redirectAfterLogin,forceLoginLocal);
            })
        );
    }

    /**
     * Connexion avec authentification SAML
     */
    public loginSAML(response: HttpResponse<unknown>) {
        this.processLoginResponse(response);
    }

    /**
     * Traitement du retour suite au login et initialisation de la session de l'utilisateur
     *
     * @param response Réponse du serveur back
     * @param redirectAfterLogin URL de redirection après authentification
     * @param forceLoginLocal Mode LoginLocal forcé
     */
    private processLoginResponse(response: HttpResponse<unknown>,redirectAfterLogin?: string,forceLoginLocal?: boolean): Session {
        let authResult: any;
        let authError: any;
        let prevSession: Session = {...this.getSession() };
        let session: Session = new Session();

        //Lecture des erreurs
        authError = response.headers.get('auth_error');

        //Lecture du résultat
        authResult = JSON.parse(response.headers.get('auth_result'));

        //Vérification de la présence d'une erreur
        if (!authError) {
            //Vérification du résultat
            if (!authResult?.authFailed) {
                //Définition de la session
                session.isLogged = true;
                session.loginError = null;
                session.isConnectAs = false;
                session.isAdmin = response.headers.get('Inone-isAdmin') === 'true';
                session.isSousAdmin = response.headers.get('Inone-isSousAdmin') === 'true';
                session.loginLocal = forceLoginLocal;
                session.isPasswordExpired = response.headers.get('obsoletePass') === 'true';
                session.isUpToDate = true;

                //On ne conserve la redirection que si on n'a pas changé d'utilisateur
                if (session.isAdmin ? prevSession.isAdmin : prevSession.user?.login === response.headers.get('Inone-login')) {
                    //Récupération de la langue de l'utilisateur connecté
                    this.langueService.setUserLangue(false);

                    session.redirect = redirectAfterLogin ?? prevSession.redirect;
                } else {
                    //Récupération de la langue de l'utilisateur connecté
                    this.langueService.setUserLangue();

                    session.redirect = null;
                }

                //Stockage de la session
                this.store.dispatch({
                    type: sessionActions.SESSION_FULFILLED,
                    payload: session
                });
            }
        } else {
            //Définition de la session
            session.isLogged = false;
            session.loginError = authError;
            session.user = null;
            session.isAdmin = false;
            session.isConnectAs = false;
        }

        return session;
    }

    /**
     * Déconnexion de l'utilisateur
     */
    public logout() {
        //Création d'une session vierge
        this.store.dispatch({
            type: sessionActions.SESSION_FULFILLED,
            payload: <Session>({
                isLogged: false,
                isAdmin: false,
                isSousAdmin: false,
                isConnectAs: false,
                isPasswordExpired: false,
                user: null,
                loginLocal: this.getSession()?.loginLocal,
            })
        });
    }

    /**
     * Demande d'envoi de mail de récupération d'un mot de passe oublié
     */
    public recoverForgottenPassword(data: { email?: string,matricule?: string,identifiant?: string },captchaToken?: string): Observable<any> {
        const params: URLSearchParams = new URLSearchParams();

        //Définition des paramètres
        params.append('recuperation','true');
        params.append('matricule',data.matricule || '');
        params.append('email',data.email || '');
        params.append('identifiant',data.identifiant || '');

        //Envoi de la demande de récupération du mot de passe
        return this.http.post<Result>(`${environment.baseUrl}/controller/Auth/sendMail`,params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-AUTH-CAPTCHA': captchaToken
            }
        });
    }

    /**
     * Chargement de la politique de mot de passe
     */
    public loadPasswordParams(): Observable<Result> {
        //Chargement de la politique de mot de passe
        return this.http.post<Result>(`${environment.baseUrl}/controller/Auth/loadPasswordParams`,null);
    }

    /**
     * Demande de creation de mot de passe
     */
    public createPassword(newPassword: string,accountToken: string,captchaToken?: string): Observable<Result> {
        const params: URLSearchParams = new URLSearchParams();

        //Définition des paramètres
        params.append('newpassword',newPassword);
        params.append('accountToken',accountToken);

        //Envoi de la demande de création de mot de passe
        return this.http.post<Result>(`${environment.baseUrl}/controller/Auth/createPassword`,params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'X-AUTH-CAPTCHA': captchaToken
            }
        });
    }

    /**
     * Récupération des données de la boîte à lien
     */
    getListeBoiteLien(): Observable<Result>{
        return this.http.post<Result>(`${environment.baseUrl}/controller/BoiteLien/listeLienActif`,null);
    }

    /**
     * Vérifie si le mot de passe entré est celui de l'utilisateur connecté
     */
    checkPassword(password: string): Observable<boolean> {
        //Paramètres HTTP
        let params: HttpParams = new HttpParams().append("password",password);

        return this.http.post<boolean>(`${environment.baseUrl}/controller/Auth/checkPassword`,params);
    }

    /**
     * Changement de mot de passe d'un utilisateur connecté
     */
    changePassword(oldPassword: string,newPassword: string): Observable<Result> {
        //Paramètres HTTP
        let params: HttpParams = new HttpParams().append("newpassword",newPassword).append("oldpassword",oldPassword);

        return this.http.post<Result>(`${environment.baseUrl}/controller/Auth/changePassword`,params);
    }

    /**
     * Retour du mode d'authentification depuis le store
     */
    public loadAuth(): Observable<Auth> {
        //Envoi de l'ordre de chargement au store
        this.store.dispatch({
            type: LOAD_AUTH
        });

        //Récupération et renvoi du mode depuis le store
        return this.store.select(state => state.auth.auth)
            .pipe(
                //On continue seulement si le mode n'est pas vide
                first(v => v !== undefined),
                //Mise en cache dans le service pour pouvoir accéder à la valeur facilement par la suite
                map((auth) => this.auth = auth )
            );
    }

    /**
     * Vérifie auprès du back si l'utilisateur est bien authentifié
     */
    checkAuth() {
        return this.http.post<Result<{isAuth: boolean, isAdmin: boolean, isSousAdmin: boolean}>>(`${environment.baseUrl}/controller/Auth/check`,null, {observe: 'response',responseType: 'json'});
    }

    /**
     * Renvoie un objet logo avec le logo personnalisé et secondaire
     * @returns un objet logo
     */
    getLogo(): Observable<Logo> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/LogoEntreprise/getInfosLogosEntreprise`)
            .pipe(first(),map(result => {
                const data = result.data;

                const logo = new Logo();
                logo.logoSecondaire = data.hasLogoSecondaire ? data.logoSecondaire : null;
                logo.logoPersonnalise = data.isLicenceAvailable && data.hasLogoPersonnalise ? data.logoPersonnalise : null;
                logo.randomImage = data.randomImage;

                return logo;
            }));
    }
}
