<div class="lvi-content">
    <div class="avatar">
        <ng-container [ngSwitch]="data.idEtat">
            <i *ngSwitchCase=EnumEtat.EN_CREATION class="nio icon-modification icone"></i>
            <i *ngSwitchCase=EnumEtat.EN_VALIDATION class="material-icons-outlined">published_with_changes</i>
            <i *ngSwitchCase=EnumEtat.VALIDE class="material-icons-outlined">check</i>
            <i *ngSwitchCase=EnumEtat.COMPTABILISE_CLOTURE class="nio icon-comptabilisation"></i>
            <i *ngSwitchCase=EnumEtat.ARCHIVE class="nio icon-archive icone"></i>
            <i *ngSwitchCase=EnumEtat.ANNULE class="material-icons-outlined">cancel</i>
        </ng-container>    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.idEtat"><strong [translate]="'workflow.statuts.etat'"></strong>
                <ng-container [ngSwitch]="data.idEtat">
                    <span *ngSwitchCase=EnumEtat.EN_CREATION [translate]="'global.etats.creation'"></span>
                    <span *ngSwitchCase=EnumEtat.EN_VALIDATION [translate]="'global.etats.validation'"></span>
                    <span *ngSwitchCase=EnumEtat.VALIDE [translate]="'global.etats.valide'"></span>
                    <span *ngSwitchCase=EnumEtat.COMPTABILISE_CLOTURE [translate]="'global.etats.comptabilise_cloture'"></span>
                    <span *ngSwitchCase=EnumEtat.ARCHIVE [translate]="'global.etats.archive'"></span>
                    <span *ngSwitchCase=EnumEtat.ANNULE [translate]="'global.etats.annule'"></span>
                </ng-container>
            </li>
            <li *ngIf="data.code"><strong [translate]="'workflow.statuts.code'"></strong>{{ data.code }}</li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data"></statut>
        </div>
    </div>
</div>