import {Component,OnInit,ViewChild} from "@angular/core";
import {Segment,Statut} from "@domain/admin/workflow/segment";
import {ListeVisualisationItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-item.component";
import {Filter,FilterValue,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {ListeVisualisationStickyItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-sticky-item.component";
import {ListeVisualisationStickyHeaderItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-sticky-header-item.component";
import {ListeVisualisationHeaderItemComponent} from "@components/admin/workflow/construction/visualisation/liste-visualisation-header-item.component";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {ListViewService} from "@share/component/list-view/list-view.service";
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {BehaviorSubject} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {ListViewState} from "@reducers/listview";
import {ListViewComponent} from "@share/component/list-view/list-view.component";

/**
 * Sous-menu visualisation du Workflow
 *
 * @author Guillaume TRAVOT
 * @date 23/07/2024
 */
@Component({
    host: {'data-test-id': 'visualisation'},
    templateUrl: './visualisation.component.html',
    styleUrls: ['./visualisation.component.scss']
})
export class VisualisationComponent implements OnInit {
    /** Constantes */
    static LISTE_CLASS_NAME: string = 'VisualisationComponent';
    static SHOW_SEGMENT_NAMES_COLUMN: string = "showSegmentNamesColumn";
    static IS_FILTER_VISIBLE: string = "isFilterVisible";

    /** Liste des segments */
    liste: ListView<Segment,ListeVisualisationItemComponent>;

    /** Listes des différents filtres */
    listePortee: FilterValue[];
    listeWorkflow: FilterValue[];
    listeRole: FilterValue[];
    listeStatutDebut: FilterValue[];
    listeStatutFin: FilterValue[];
    listeConditionPrincipale: FilterValue[];
    listeConditionInduite: FilterValue[];
    listeAction: FilterValue[];
    listeProcedureTravel: FilterValue[];
    listeProcedureBudget: FilterValue[];
    listeProcedureCompta: FilterValue[];

    /** Affichage du nom des segments */
    showSegmentNamesColumn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Pliage/dépliage du menu */
    isMenuToggled: boolean = false;

    /** Composant de liste */
    @ViewChild("visualisation") visualisationComponent: ListViewComponent<Segment,ListeVisualisationItemComponent>;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private workflowAdminService: WorkflowAdminService,
                private listViewService: ListViewService<any,any>,
                private sessionStorageService: SessionStorageService,
                private store: Store<AppState>) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Abonnement au pliage/dépliage du menu (avec débrayage du cycle standard pour éviter les erreurs)
        this.store.select<ListViewState>(s => s.listview).subscribe(listview => setTimeout(() => this.isMenuToggled = listview.isToggled));

        //Liste des portées
        this.listePortee = [{
            code: 'AV',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.AV')
        },{
            code: 'DV',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.DV')
        },{
            code: 'FC',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.FC')
        },{
            code: 'NF',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.NF')
        },{
            code: 'OD',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.OD')
        },{
            code: 'OT',
            libelle: this.translateService.instant('admin.workflow.visualisation.portees.OT')
        }].sort((a, b) => a.libelle.localeCompare(b.libelle)) as FilterValue[];

        //Récupération de la liste des filtres
        this.workflowAdminService.listeFiltres().subscribe((result) => {
            //Peuplement des différentes listes de filtres
            this.listeWorkflow = result.data.listeWorkflow as FilterValue[];
            this.listeRole = result.data.listeRole as FilterValue[];
            this.listeStatutDebut = result.data.listeStatutDebut as FilterValue[];
            this.listeStatutFin = result.data.listeStatutFin as FilterValue[];
            this.listeConditionPrincipale = result.data.listeConditionPrincipale as FilterValue[];
            this.listeConditionInduite = result.data.listeConditionInduite as FilterValue[];
            this.listeAction = result.data.listeAction?.map(a => {
                return {
                    code: a.code,
                    libelle: this.translateService.instant('admin.workflow.visualisation.actions.' + a.code)
                } as FilterValue;
            }).sort((a, b) => a.libelle.localeCompare(b.libelle));
            this.listeProcedureTravel = result.data.listeProcedureTravel?.map(a => {
                return {
                    code: a.code,
                    libelle: this.translateService.instant('admin.workflow.visualisation.eltActions.' + a.code)
                } as FilterValue;
            }).sort((a, b) => a.libelle.localeCompare(b.libelle));
            this.listeProcedureBudget = result.data.listeProcedureBudget?.map(a => {
                return {
                    code: a.code,
                    libelle: this.translateService.instant('admin.workflow.visualisation.actionsBudget.' + a.code)
                } as FilterValue;
            }).sort((a, b) => a.libelle.localeCompare(b.libelle));
            this.listeProcedureCompta = result.data.listeProcedureCompta?.map(a => {
                return {
                    code: a.code,
                    libelle: this.translateService.instant('admin.workflow.visualisation.actionsSupp.' + a.code)
                } as FilterValue;
            }).sort((a, b) => a.libelle.localeCompare(b.libelle));

            //Lecture de l'état de la colonne Segment dans le SessionStorage
            const storageShowSegmentNamesColumn: string = this.sessionStorageService.fetch(VisualisationComponent.LISTE_CLASS_NAME,VisualisationComponent.SHOW_SEGMENT_NAMES_COLUMN);

            //Changement de la visibilité de la colonne du nom de segment
            this.showSegmentNamesColumn$.next(storageShowSegmentNamesColumn === 'true');

            //Lecture des filtres dans le SessionStorage
            const storageSelectedFilters: string = this.sessionStorageService.fetch(VisualisationComponent.LISTE_CLASS_NAME,'listeSelectedFilters_0');

            //Filtre par défaut
            const defaultFilter: Filter = {
                clef: 'isActif',
                title: this.translateService.instant('admin.workflow.visualisation.filtres.actif'),
                isDefault: true,
                type: TypeFilter[TypeFilter.BOOLEAN],
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                valeur: 'true'
            };

            //Construction du SearchSpec par défaut
            const searchSpec: SearchSpec = this.listViewService.getDefaultSearchSpecForList({
                listeSelectedFilters: storageSelectedFilters && JSON.parse(storageSelectedFilters) || [defaultFilter]
            } as ListView<Segment,ListeVisualisationItemComponent>);

            //Demande de récupération de la liste des statuts
            this.workflowAdminService.listeStatuts(searchSpec).subscribe((result) => {
                //Récupération de la liste des statuts
                const listeStatuts: Statut[] = result?.data?.listeStatuts?.map(s => new Statut(s));

                //Définition de la liste
                this.liste = new ListView<Segment,ListeVisualisationItemComponent>({
                    uri: '/controller/Segment/listeSegments',
                    title: this.translateService.instant('admin.workflow.visualisation.title'),
                    component: ListeVisualisationItemComponent,
                    stickyColumnComponent: ListeVisualisationStickyItemComponent,
                    headerComponent: ListeVisualisationHeaderItemComponent,
                    stickyHeaderComponent: ListeVisualisationStickyHeaderItemComponent,
                    headerData: listeStatuts,
                    isSearchBar: false,
                    mapResult: (result) => {
                        //Détermination de l'index de référence avec un offset de la taille de la liste existante
                        let index: number = this.liste?.data?.listeResultats?.length > 0 ? this.liste.data.listeResultats.length : 0;

                        //Si la page chargée est la première
                        if (result.numPage == 0) {
                            //Au prochain lifecycle Angular, on force le chargement de la page suivante de manière à charger systématiquement deux pages
                            setTimeout(() => this.visualisationComponent?.onEndReached())
                        }

                        //Boucle sur les résultats
                        result.listeResultats = result.listeResultats?.map(r => {
                            //Instanciation
                            const segment = new Segment(r);

                            //Stockage de l'index de liste en dur sur l'objet et incrément
                            segment['index'] = index++;

                            //Retour
                            return segment;
                        });

                        //Retour
                        return result;
                    },
                    onSearch: async () => {
                        //Demande de récupération de la liste des statuts
                        await new Promise<void>((resolve) => {
                            //Construction du SearchSpec par défaut
                            const searchSpec: SearchSpec = this.listViewService.getDefaultSearchSpecForList(this.liste);

                            //Appel au backend
                            this.workflowAdminService.listeStatuts(searchSpec).subscribe((result) => {
                                //Récupération de la liste des statuts
                                const listeStatuts: Statut[] = result?.data?.listeStatuts?.map(s => new Statut(s));

                                //Mise à jour de la liste des statuts
                                this.liste.headerData = listeStatuts;

                                //Résolution de la promesse
                                resolve();
                            });
                        });
                    },
                    listeActions: [{
                        icon: "visibility",
                        onPress: () => {
                            //Sauvegarde dans le SessionStorage
                            this.sessionStorageService.save(VisualisationComponent.LISTE_CLASS_NAME,VisualisationComponent.SHOW_SEGMENT_NAMES_COLUMN,!this.showSegmentNamesColumn$.value);

                            //Changement de la visibilité de la colonne du nom de segment
                            this.showSegmentNamesColumn$.next(!this.showSegmentNamesColumn$.value);
                        }
                    }],
                    extraOptions: {
                        showSegmentNamesColumn$: this.showSegmentNamesColumn$.asObservable()
                    },
                    listeFilters: [
                        {
                            clef: 'workflow.id',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.workflow'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeWorkflow,
                            multiple: true
                        },{
                            clef: 'formulaireDepart.idPortee',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.portee'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listePortee,
                            multiple: true,
                            changeMethod: (filter) => {
                                //Récupération du filtre condition
                                const conditionFilter = this.liste.listeFilters.find(f => f.clef == 'conditionFormulaireDepart.idCondition');

                                //Purge de la sélection des conditions principales
                                conditionFilter && (conditionFilter.listeObjects = []);

                                //Purge de la sélection des conditions principales
                                conditionFilter && (conditionFilter.listeValues = this.listeConditionPrincipale.filter(c => !filter.listeObjects?.length || filter.listeObjects.includes(c.extraData)));
                            }
                        },{
                            clef: 'formulaireModifie.idPortee',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.porteeInduite'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listePortee,
                            multiple: true,
                            changeMethod: (filter) => {
                                //Récupération du filtre condition
                                const conditionFilter = this.liste.listeFilters.find(f => f.clef == 'conditionFormulaireModifie.idCondition');

                                //Purge de la sélection des conditions principales
                                conditionFilter && (conditionFilter.listeObjects = []);

                                //Purge de la sélection des conditions principales
                                conditionFilter && (conditionFilter.listeValues = this.listeConditionPrincipale.filter(c => !filter.listeObjects?.length || filter.listeObjects.includes(c.extraData)));
                            }
                        },{
                            clef: 'role.idRole',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.role'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeRole,
                            multiple: true
                        },{
                            clef: 'statutFormulaireDepart.idStatut',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.statutDebut'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeStatutDebut,
                            multiple: true
                        },{
                            clef: 'statutFinFormulaireModifie.idStatut',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.statutFin'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeStatutFin,
                            multiple: true
                        },{
                            clef: 'conditionFormulaireDepart.idCondition',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.conditionDepart'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeConditionPrincipale,
                            multiple: true
                        },{
                            clef: 'conditionFormulaireModifie.idCondition',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.conditionArrivee'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeConditionInduite,
                            multiple: true
                        },{
                            clef: 'action',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.action'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeAction,
                            multiple: true
                        },{
                            clef: 'eltAction',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.procedureTravel'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeProcedureTravel,
                            multiple: true
                        },{
                            clef: 'actionBudget',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.actionBudget'),
                            isDefault: false,
                            typeComparaison: TypeComparaison[TypeComparaison.IN],
                            listeValues: this.listeProcedureBudget,
                            multiple: true
                        },{
                            clef: 'isActif',
                            title: this.translateService.instant('admin.workflow.visualisation.filtres.actif'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.BOOLEAN],
                            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                            isSelected: true,
                            valeur: 'true'
                        }
                    ]
                });

                //Masquage du bouton de tri
                this.liste.isForcedNoSorting = true;

                //Lecture de l'état de la colonne Segment dans le SessionStorage
                const storageIsFilterUnfolded: string = this.sessionStorageService.fetch(VisualisationComponent.LISTE_CLASS_NAME,VisualisationComponent.IS_FILTER_VISIBLE);

                //Affichage des filtres par défaut
                this.liste.isFilterUnfolded = !storageIsFilterUnfolded || storageIsFilterUnfolded === 'true';

                //Persistence des filtres
                this.liste.isPersistFilters = true;

                //Nom de la classe
                this.liste.className = VisualisationComponent.LISTE_CLASS_NAME;

                //Activation de la fonctionnalité de cliquer-déplacer sur le scroll
                this.liste.isDynamicDragScrollEnable = true;

                //Forçage du filtre par défaut
                this.liste.listeSelectedFilters = [defaultFilter];

                //Reset spécifique des filtres
                this.liste.resetFilters = async () => {
                    //Purge des filtres
                    this.liste.listeSelectedFilters = [];

                    //Valeur par défaut du filtre isActif sur true
                    const isActif: Filter = this.liste.listeFilters.find(f => f.clef == 'isActif');
                    isActif.valeur = 'true';

                    //Création d'une promesse pour attendre l'obtention des résultats avant de passer à la suite
                    await new Promise<void>((resolve) => {
                        //Demande de récupération de la liste des statuts
                        this.workflowAdminService.listeStatuts(searchSpec).subscribe((result) => {
                            //Récupération de la liste des statuts
                            this.liste.headerData = result?.data?.listeStatuts?.map(s => new Statut(s));

                            //Retour
                            resolve();
                        });
                    });
                };
            });
        });
    }

    /**
     * Changement de l'état de visibilité des filtres
     *
     * @param isFilterVisible true si visible, sinon false
     */
    onFilterVisibleChange(isFilterVisible: boolean): void {
        //Sauvegarde du nouvel état dans le SessionStorage
        this.sessionStorageService.save(VisualisationComponent.LISTE_CLASS_NAME,VisualisationComponent.IS_FILTER_VISIBLE,isFilterVisible);

    }
}
