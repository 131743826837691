import {Component,EventEmitter,OnInit,Output,OnDestroy} from '@angular/core';
import {AppState} from '@domain/appstate';
import {Store} from '@ngrx/store';
import {first} from "rxjs/operators";
import {Session} from "@domain/security/session";
import {IS_TOGGLED,ListViewState} from "@reducers/listview";
import {Subscription} from "rxjs";

@Component({
    selector: 'navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit, OnDestroy {
    /** Déclencheur de l'ouverture du menu */
    @Output() menuToggle: EventEmitter<any> = new EventEmitter();

    /** Indicateur d'ouverture du menu */
    public isMenuToggled: boolean = false;

    /** Indique si le menu est ouvert ou non */
    isOpen: boolean = false;

    /** Logo fallback */
    logoFallback: string = "./assets/logo/logo_white.svg";

    /** Logo personnalisé */
    logoPersonnalise: string;

    /** Indicateur de chargement du logo */
    isLoading: boolean;

    /** Session admin ou non */
    isAdmin: boolean = false;

    /** Session à jour */
    isSessionUpToDate: boolean = false;

    /** Liste de type sticky */
    isStickyList: boolean = false;

    /** Souscriptions */
    private souscriptions: Subscription[] = [];

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Début du chargement
        this.isLoading = true;

        //Récupération du logo
        this.souscriptions.push(this.store.select(state => state.settings?.['Global'])
            .pipe(first(value => !!value))
            .subscribe(settings => {
                this.logoPersonnalise = settings?.logoPersonnalise;

                //Fin du chargement
                this.isLoading = false;
            }));

        //Sélection de la session
        this.souscriptions.push(this.store.select<Session>(s => s.session).subscribe(session => {
            //Mémorisation du niveau admin ou non de la session
            this.isAdmin = session?.isAdmin;
            this.isSessionUpToDate = session?.isUpToDate;
        }));

        //Abonnement au changement de type de listview (avec débrayage du cycle standard pour éviter les erreurs)
        this.souscriptions.push(this.store.select<ListViewState>(s => s.listview).subscribe(listview => setTimeout(() => this.isStickyList = listview.isSticky)));
    }

    /**
     * Retourne le logo à utiliser
     */
    getLogo(): { isSvg: boolean, path: string } {
        //Tant que l'on n'a pas encore déterminé la présence ou non d'un logo personnalisé, on n'affiche rien
        if (this.isLoading) {
            return null;
        }

        //On renvoie le logo trouvé et l'information si c'est un SVG ou non
        return {
            isSvg: (this.logoPersonnalise ?? this.logoFallback).endsWith(".svg"),
            path: this.logoPersonnalise ?? this.logoFallback
        }
    }

    /**
     * Clic sur le menu
     */
    onMenuClick(): void {
        //Déploiement du menu
        this.isOpen = !this.isOpen;
        this.menuToggle.emit(null);
        this.isMenuToggled = !this.isMenuToggled;
        this.store.dispatch({
            type: IS_TOGGLED,
            payload: this.isMenuToggled
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation des abonnements
        this.souscriptions.forEach(s => s.unsubscribe());
    }
}
