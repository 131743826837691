<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label cursor-default">{{ data.user | user}}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.user.orga">
                <strong [translate]="'admin.entreprise.populations.liste.serviceAffectation'"></strong>
                <span>{{ data.user.orga.numService }} - {{ data.user.orga.libelle }}</span>
            </li>
            <li *ngIf="data?.typeLien !== null">
                <strong [translate]="'admin.entreprise.populations.liste.statut'"></strong>
                <span [translate]="data.typeLien === TypeLien.INCLUS ? 'admin.entreprise.populations.liste.inclus' : 'admin.entreprise.populations.liste.exclus'"></span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data?.user.actif ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button
                *ngIf="extraOptions.canModifier"
                mat-icon-button
                (click)="deleteUser()"
                [matTooltip]="'global.actions.supprimer' | translate"
                [matTooltipPosition]="'above'">
            <i class="nio icon-suppression icone"></i>
        </button>
    </div>
</div>