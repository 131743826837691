import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Workflow} from "@domain/admin/workflow/segment";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "rxjs/operators";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {WorkflowPopupComponent} from "@components/admin/workflow/construction/liste-wf/workflow-popup.component";
import {TypeCodeErreur} from "@domain/common/http/result";

/**
 * Item de liste des workflow
 *
 * @author Guillaume TRAVOT
 * @date 11/07/2024
 */
@Component({
	host: {'data-test-id': 'liste-wf-item'},
	templateUrl: './liste-wf-item.component.html'
})
export class ListeWfItemComponent extends ListViewItem<Workflow> {
	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private matDialog: MatDialog,
				private workflowAdminService: WorkflowAdminService) {
		super();
	}

	/** Ouverture du détail du workflow */
	onSelect() {
		//Ouverture de la boîte de dialogue
		this.matDialog.open(WorkflowPopupComponent, {
			width: '80%',
			data: {
				workflow: this.data
			}
		}).afterClosed().subscribe(refresh => {
			if (refresh) {
				//On met à jour la liste
				this.liste.refresh && this.liste.refresh();
			}
		});
	}

	/**
	 * Click sur un commentaire
	 */
	onCommentClick() {
		//Ouverture de la popin de détail du commentaire
		this.confirmService.showConfirm(this.data.commentaire,{
			title: this.translateService.instant('admin.workflow.liste.commentaire'),
			type: "ok"
		});
	}

	/**
	 * Suppression d'un workflow
	 */
	onDeleteClick() {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Envoi au backend de la demande de suppression
				this.workflowAdminService.deleteWorkflow(this.data).subscribe((result) => {
					//Vérification du code d'erreur
					if (result.codeErreur == TypeCodeErreur.NO_ERROR || !result.codeErreur) {
						//Message de réussite
						this.toastrService.success(this.translateService.instant('admin.workflow.liste.supprimerSucces'));

						//Rafraichissement de la liste
						this.liste.refresh && this.liste.refresh();
					} else {
						//Message d'échec
						this.toastrService.error(this.translateService.instant('admin.workflow.liste.supprimerErreur'));
					}
				})
			}
		});
	}
}
