import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {Workflow} from "@domain/admin/workflow/segment";
import {WorkflowAdminService} from "@services/admin/workflow/workflow-admin.service";
import {TranslateService} from "@ngx-translate/core";
import {first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {VisualisationComponent} from "@components/admin/workflow/construction/visualisation/visualisation.component";
import {TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant de popup pour la modification d'un workflow
 */
@Component({
    selector: 'workflow-popup',
    templateUrl: './workflow-popup.component.html'
})
export class WorkflowPopupComponent implements OnInit {
    /** Workflow concerné */
    workflow: Workflow;

    /**
     * Constructeur
     */
    constructor(
        private workflowAdminService: WorkflowAdminService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private sessionStorageService: SessionStorageService,
        private dialogRef: MatDialogRef<WorkflowPopupComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { workflow: Workflow }
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Si un workflow est fourni en paramètre
        if (this.data?.workflow) {
            //Récupération du workflow fourni
            this.workflow = new Workflow(this.data.workflow);
        } else {
            //Création d'un nouveau workflow
            this.workflow = new Workflow({
                libelle: null,
                organisme: null,
                commentaire: null,
                nombrePersonnesRattachees: 0,
                nombreSegmentsRattaches: 0
            });
        }
    }

    /**
     * Enregistrement de la notification
     */
    enregistrer(): void {
        this.workflowAdminService.saveWorkflow(this.workflow).pipe(first()).subscribe(result => {
            //Vérification du code d'erreur
            if (result.codeErreur == TypeCodeErreur.NO_ERROR || !result.codeErreur) {
                //Fermeture de la popup
                this.dialogRef.close(true );

                //Purge du SessionStorage pour forcer la mise à jour des filtres disponibles sur la page de visualisation des WF
                this.sessionStorageService.remove(VisualisationComponent.LISTE_CLASS_NAME,"listeFilters_0");
                this.sessionStorageService.remove(VisualisationComponent.LISTE_CLASS_NAME,"listeSelectedFilters_0");

                //Message de réussite
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
            }
        });
    }
}
