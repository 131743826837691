<div class="lvi-content clickable" (click)="switchIsOuvert()">
    <div class="avatar" [ngClass]="{ 'success': data.choisie }"
         [matTooltip]="'od.voyage.propositionRetenue' | translate" [matTooltipDisabled]="!data.choisie" matTooltipPosition="right">
        <!-- Si c'est la proposition choisie, on affiche l'icone -->
        <span *ngIf="data.choisie">
            <i class="material-icons-outlined align-middle">done</i>
        </span>
        <!-- Si c'est pas la proposition choisie, on affiche son index -->
        <span *ngIf="!data.choisie">
            {{index + "/" + nbPropositions}}
        </span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="">{{ data.pnr }}</a>
        </div>
        <!-- Bulles -->
        <ul class="lvi-attrs">
            <!-- Statut ONLINE -->
            <li *ngIf="aiguillageOd === TypeAiguillage.ONLINE" [ngClass]="{'back-danger' : data.statut == StatutProposition.STATUT_NON_RESERVE, 'back-warning' : data.statut == StatutProposition.STATUT_RESERVE_PARTIELLEMENT, 'back-success' : data.statut == StatutProposition.STATUT_RESERVE}">
                <strong [translate]="'od.voyage.etat'"></strong>{{ (data.statut == StatutProposition.STATUT_NON_RESERVE ? 'od.voyage.nonReservee' : data.statut == StatutProposition.STATUT_RESERVE_PARTIELLEMENT ? 'od.voyage.partiellementReservee' : 'od.voyage.reservee') | translate }}
            </li>
            <!-- Statut OFFLINE -->
            <li *ngIf="aiguillageOd !== TypeAiguillage.ONLINE" [ngClass]="{'back-danger' : !data.choisie, 'back-success' : data.choisie}">
                <strong [translate]="'od.voyage.etat'"></strong>{{ (!data.choisie ? 'od.voyage.nonReservee' : 'od.voyage.choisie') | translate }}
            </li>

            <!-- PVE -->
            <li [ngClass]="{'back-success' : data.isPVE, 'back-danger' : !data.isPVE}"><strong [translate]="'od.voyage.pve'"></strong>{{ (data.isPVE ? 'global.oui' : 'global.non') | translate }}
            </li>

            <!-- Date limite d'approbation -->
            <li [ngClass]="{'back-danger': isApprobationPerimee(), 'back-warning' : isApprobationPresquePerimee()}"><strong
                    translate>od.voyage.limiteAppro</strong>{{ data.dateLimiteEmission | date:'short' }}</li>

            <!-- Montant total de la proposition dans la devise entreprise -->
            <li (click)="showPopinMontant();$event.stopPropagation()">
                <strong translate>od.voyage.montantTotal</strong>{{data.coutTrain + data.coutAvion + data.coutHebergement + data.coutDivers + data.coutVoiture + data.fraisAgence | montant:devise}}
                <mat-icon [matTooltip]="'od.voyage.detail' | translate">comment</mat-icon>
            </li>

            <!-- Montant total de la proposition dans la devise travel -->
            <li *ngIf="data.coutTrainOrigine + data.coutAvionOrigine + data.coutHebergementOrigine + data.coutDiversOrigine + data.coutVoitureOrigine + data.fraisAgenceOrigine != 0" (click)="showPopinMontant();$event.stopPropagation()">
                <strong translate>od.voyage.montantOrigine</strong>{{data.coutTrainOrigine + data.coutAvionOrigine + data.coutHebergementOrigine + data.coutDiversOrigine + data.coutVoitureOrigine + data.fraisAgenceOrigine | montant:deviseOrigine}}
                <mat-icon [matTooltip]="'od.voyage.detail' | translate">comment</mat-icon>
            </li>

            <!-- Montant de CO2 total de la proposition -->
            <li *ngIf="data.qteCo2 && aiguillageOd !== TypeAiguillage.ONLINE" class="rse-tag">
                <span class="material-symbols-rounded" [ngClass]="{'green': data.choisie}">eco</span>
                <strong></strong>
                {{(data.qteCo2 | number:"1.2-3") + ('rse.kgCO2' | translate)}}*
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button *ngIf="aiguillageOd !== TypeAiguillage.ONLINE
            && (dvActions.canRejeter.possible || dvActions.canEmettre.possible)
            && (data.choisie || !hasPropositionChoisie)"
            [matMenuTriggerFor]="listeActions"
            (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu" class="wide-menu">
            <!-- Ceci n'est pas un bug, c'est bien la possibilité de rejet qui rend possible le choix (ceci est fait par des professionnels) -->
            <button mat-menu-item *ngIf="dvActions.canRejeter.possible && !data.choisie" (click)="choisir()" translate>od.voyage.choisir</button>
            <button mat-menu-item *ngIf="dvActions.canRejeter.possible && data.choisie" (click)="annulerChoix()" translate>od.voyage.annulerChoix</button>
            <button mat-menu-item *ngIf="dvActions.canRejeter.possible" (click)="rejeterTout()" translate>od.voyage.rejeterTout</button>
            <button mat-menu-item *ngIf="dvActions.canEmettre.possible" (click)="modifier()" translate>od.voyage.modifier</button>
        </mat-menu>
        <button mat-icon-button *ngIf="data.listeDossierTravel?.length > 0">
            <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'od.voyage.replier' | translate">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'od.voyage.deplier' | translate">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>
<!-- Cadre de détail de la prestation -->
<div *ngIf="isOuvert && data.listeDossierTravel?.length > 0">
    <div class="listview-item mt-1" *ngFor="let dossier of data.listeDossierTravel">
        <div class="mx-5">
            <dossier-item [dossier]="dossier" [idOd]="idOd" (needRefresh)="needRefresh.emit();" [aiguillage]="aiguillageOd" [odMapActions]="odMapActions"></dossier-item>
        </div>
    </div>
</div>