import {Component,OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '@domain/appstate';
import {ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {TypeProfil} from '@domain/user/user';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {Result} from '@domain/common/http/result';
import {ReleveListItemComponent} from './releve-list-item/releve-list-item.component';
import {ReleveFacture} from '@domain/facture/releve-facture';
import {Page} from '@domain/common/http/list-result';
import {FactureListComponent} from '../facture-list/facture-list.component';
import {first} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";
import {MontantPipe} from "@share/pipe/montant";
import {Sorting} from "@domain/common/list-view/sorting";

@Component({
    selector: 'releve-list',
    templateUrl: './releve-list.component.html'
})
export class ReleveListComponent implements OnInit {
    /** Données */
    liste: ListView<ReleveFacture,ReleveListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Profil de l'utilisateur connecté */
    fonction: TypeProfil;

    /** Visibilité de la liste d'actions */
    get isListeActionsVisible(): boolean {
        return this.listeActions?.getValue()?.some(l => l.isVisible()) || false;
    }

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private store: Store<AppState>,
        private router: Router,
        private montantPipe: MontantPipe) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => {
            //Définition du type de profil
            this.fonction = fonction;

            //Définition de la liste
            this.liste = new ListView<ReleveFacture,ReleveListItemComponent>({
                uri: `/controller/Facture/listeReleve`,
                title: this.translateService.instant('facture.releve.liste.title'),
                component: ReleveListItemComponent,
                listeFilters: [{
                    clef: 'numero',
                    title: this.translateService.instant('facture.releve.liste.filtres.numero'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'fournisseur.raisonSociale',
                    title: this.translateService.instant('facture.releve.liste.filtres.fournisseur'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'dateReleve',
                    title: this.translateService.instant('facture.releve.liste.filtres.date'),
                    type: TypeFilter[TypeFilter.DATE]
                }],
                defaultOrder:"-dateReleve,numero",
                mapResult: (result: Result) => {
                    //Extraction de l'objet concerné dans le résultat
                    const page: any = result.data?.listeReleveDto;

                    //Typage des éléments de la liste
                    if (page?.listeResultats) {
                        page.listeResultats = page.listeResultats.map(r => {
                            const releve: ReleveFacture = new ReleveFacture(r);
                            releve.montantPipeForAlerte = this.montantPipe;
                            return releve;
                        });
                    }

                    //Retour
                    return page as Page<ReleveFacture>
                }
            });

            //Définition des colonnes de tri
            this.liste.columns = [
                {key: 'numero',title: 'facture.releve.liste.filtres.numero'},
                {key: 'fournisseur.raisonSociale',title: 'facture.releve.liste.filtres.fournisseur'},
                {key: 'dateReleve',title: 'facture.releve.liste.filtres.date'}
            ];

            //Ajout du tri de la liste selon l'ordre voulu
            this.liste.sorting = new Sorting(this.liste.columns,"-dateReleve,numero");

            //Activation de la persistance des filtres
            this.liste.isPersistFilters = true;

            //Nom de la class pour le SessionStorage
            this.liste.className = "ReleveListComponent";

            //Définition de la liste des actions
            this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.create(),
                isVisible: () => this.fonction == TypeProfil.COMPTABLE
            }]);
        });
    }

    /**
     * Création d'un élément
     */
    create(): void {
        //Redirection vers une page de relevé vierge
        this.router.navigate(['ReleveFacture',0]);
    }

    /**
     * Récupération du pointeur vers le sous-composant une fois son initialisation terminée
     * @param factureListComponent le sous-composant de liste de factures
     */
     onFactureListeLoaded(factureListComponent: FactureListComponent): void {
        //Définition de la liste des actions
        setTimeout(() => { this.listeActions.next(factureListComponent.listeActions.getValue()) });
    }
}
