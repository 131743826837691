/**
 * Modèle de données d'une Proposition Travel
 *
 * @author Laurent SCIMIA
 * @date 11/01/2022
 */
import {NatureVoyage} from "../voyage/nature/nature-voyage";

export class Proposition {
	/** Identifiant unique */
	idProposition: number;

	/** Numéro de la réservation */
	pnr: string;

	/** Statut de la réservation */
	statut: StatutProposition;

	/** Indique si la proposition respecte la Politique Voyage d'Entreprise */
	isPVE: boolean;

	/** Date limite d'approbation de la proposition */
	dateLimiteEmission: Date;

	/** Coût total du train dans la devise d'entreprise */
	coutTrain: number;

	/** Coût total de l'avion dans la devise d'entreprise */
	coutAvion: number;

	/** Coût total de la voiture dans la devise d'entreprise */
	coutVoiture: number;

	/** Coût total de l'hébergement dans la devise d'entreprise */
	coutHebergement: number;

	/** Coût total divers dans la devise d'entreprise */
	coutDivers: number;

	/** Coût total du train dans la devise d'origine */
	coutTrainOrigine: number;

	/** Coût total de l'avion dans la devise d'origine */
	coutAvionOrigine: number;

	/** Coût total de la voiture dans la devise d'origine */
	coutVoitureOrigine: number;

	/** Coût total de l'hébergement dans la devise d'origine */
	coutHebergementOrigine: number;

	/** Coût total divers dans la devise d'origine */
	coutDiversOrigine: number;

	/** Cout total des frais d'agence dans la devise d'origine */
	fraisAgenceOrigine: number;

	/** Indique si la proposition est celle qui a été choisie */
	choisie: boolean;

	/** Coût total des frais d'agence */
	fraisAgence: number;

	/** Meilleur prix */
	lowestFare: number;

	/** Meilleur prix dans la devise d'origine */
	lowestFareOrigine: number;

	/** Liste des dossiers travel de la proposition */
	listeDossierTravel: Array<DossierTravel>;

	/** Quantité de CO2 total de la proposition*/
	qteCo2: number;
}

/** Enumération des statuts possibles pour une proposition Travel */
export enum StatutProposition {
	STATUT_NON_RESERVE,
	STATUT_RESERVE_PARTIELLEMENT,
	STATUT_RESERVE
}

/** Type des dossiers Travel (lien entre proposition et nature) */
export type DossierTravel = {
	/** Identifiant unique du dossier travel */
	idDossier: number,

	/** Numéro du dossier */
	pnr: string,

	/** true si le dossier est à l'état réservé */
	booked: boolean,

	/** ID de la proposition liée au dossier */
	idProposition: boolean,

	/** Toutes les natures associées au dossier */
	listeNaturesDTO: Array<NatureVoyage>;

	/** Indique si le dossier respecte les potiliques voyage entreprise */
	pve: boolean;
}