import {Component,HostListener,Inject,Input,OnInit} from '@angular/core';
import {ActivatedRoute,NavigationEnd,Router} from '@angular/router';
import {AppState} from '@domain/appstate';
import {MENU_ITEMS,MenuItem,Scope} from '@domain/common/menu/menu-item';
import {Session} from '@domain/security/session';
import {SettingsGlobalState} from "@domain/settings/settings";
import {TypeProfil} from "@domain/user/user";
import {TypePortee} from '@domain/workflow/workflow';
import {Action,Store} from '@ngrx/store';
import {filter,map,mergeMap} from 'rxjs/operators';
import {LayoutService} from "./layout.service";
import {ListViewState} from "@reducers/listview";

@Component({
    host: {'data-test-id': 'app-menu'},
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    /* Déclaration pour accès dans le template */
    Scope = Scope;
    TypeProfil = TypeProfil;

    /* Données */
    session: Session = null;
    isToggled: boolean = false;

    /** Menu principal ouvert */
    menuOuvert: MenuItem = null;

    /** Sous menu sélectionné */
    sousMenuSelected: MenuItem = null;

    @Input()
    public settings: SettingsGlobalState;

    /** Portée sélectionnée */
    selectedTypePortee: TypePortee = null;

    /** Affichage du bouton vers le haut de page */
    displayScrollToTop: boolean = false;

    /** Liste de type sticky */
    isStickyList: boolean = false;

    /**
     * Constructeur
     */
    constructor(
        public router: Router,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        @Inject(MENU_ITEMS) public listeItems: Array<MenuItem>,
        public layoutService: LayoutService,
    ) { }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection de la session
        this.store.select<Session>(s => s.session).subscribe(session => {
            this.session = session;
            this.isToggled = false;
        });

        //Détection du changement de route
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => route.firstChild ? route.firstChild : route),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        ).subscribe(data => {
            //Mise à jour du module courant
            this.selectedTypePortee = data.typePortee;
        });

        //Sélection de la session
        this.store.select<ListViewState>(s => s.listview).subscribe(listview => this.isStickyList = listview.isSticky);
    }

    /**
     * Affichage/masquage du menu
     */
    toggle(): void {
        //Affichage/masquage du menu
        this.isToggled = !this.isToggled;
    }

    /**
     * Filtrage de la liste des éléments visibles
     * @param listeItems liste des objets
     * @returns la liste des objets visibles
     */
    getListeVisibleItems(listeItems: Array<MenuItem>): MenuItem[] {
        //Filtrage de la liste des éléments visibles
        return listeItems && listeItems.filter(i => !i.canSee || i.canSee(this.router,this.session,this.settings));
    }

    /**
     * Vérification de la visibilité de l'élément pour le scope
     * @param scope le scope
     * @returns une fonction de visibilité
     */
    isVisibleFor(scope: Scope): (i: MenuItem) => boolean {
        //Vérification de l'élément
        return (i: MenuItem) => {
            let isVisible: boolean = i.scope?.includes(scope);

            if (scope == Scope.PROFIL) {
                isVisible = isVisible && (!i.isSelected || !i.isSelected(this.session,null));
            }

            return isVisible;
        }
    }

    /**
     * Getter de l'élément du menu en cours (ou undefined si aucune entrée sélectionnée)
     */
    getSelectedMenuItem(): MenuItem | undefined {
        //Recherche de l'élément sélectionné
        return this.listeItems?.find(value => value.isSelected && value.isSelected(this.session,null));
    }

    /**
     *
     * @param i
     */
    isCustomIcone(i: MenuItem):boolean {
        return i.icone?.split(' ').includes('nio');
    }

    /**
     * Déclenchement de l'action lié à l'entrée de menu
     * @param menuItem l'item du menu
     */
    doAction(menuItem: MenuItem): void {
        //Déclenchement de l'action
        menuItem.doAction && menuItem.doAction((action: Action) => this.store.dispatch(action));
    }

    /**
     * Scroll en haut de page
     * @param top haut de page
     */
    scrollToTop(top: Element): void {
        top.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    /**
     * Ecoute de l'événement de scroll sur la page
     */
    @HostListener('window:scroll')
    handleScroll() {
        //Fait apparaitre le bouton de raccourci vers le haut de page
        //à partir de 700px de scroll vertical
        this.displayScrollToTop = window.pageYOffset > 700;
    }

    /**
     * Ouvre un menu
     *
     * @param item Menu à ouvrir
     */
    open(item: MenuItem) {
        //Si le menu à ouvrir est un menu de premier niveau, qu'on est en V10 et que ce n'est pas le menu déjà ouvert
        if (item.children?.length > 0 && this.menuOuvert !== item) {
            //On ouvre le menu
            this.menuOuvert = item;
        } else {
            //On s'assure que tous les menus sont fermés
            this.menuOuvert = null;
        }
    }

    /**
     * Sélectionne un sous menu
     *
     * @param item Sous meni à sélectionner
     */
    select(item: MenuItem) {
        this.sousMenuSelected = null;
        if (!item.children || item.children.length == 0) {
            this.sousMenuSelected = item;
        }
    }
}
