import {Injectable} from '@angular/core';
import {ActivatedRoute,NavigationEnd,Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter,map} from 'rxjs/operators';

import {AppState} from '@domain/appstate';
import {Session} from '@domain/security/session';
import * as layoutActions from '../../reducers/layout';
import * as sessionActions from '../../reducers/session';
import * as settingsActions from '../../reducers/settings';
import * as navigationActions from '../../reducers/navigation';
import {BehaviorSubject,Observable} from "rxjs";
import {LoginComponent} from "../login/login.component";

/**
 * Service de gestion du layout
 */
@Injectable()
export class LayoutService {
    /** **/
    private frameLocation: string = null;

    /** Subject pour la mise à jour de l'affichage du layout post-login */
    private _isLayoutPostLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Observable du statut de l'affichage du layout post-login */
    public isLayoutPostLogin$: Observable<boolean> = this._isLayoutPostLogin.asObservable();

    /**
     * Mise à jour du statut de l'affichage du layout post-login
     *
     * @param isLayoutPostLogin True pour afficher le header et le menu disponibles après s'être authentifié, False sinon
     */
    public set isLayoutPostLogin(isLayoutPostLogin: boolean) {
        this._isLayoutPostLogin.next(isLayoutPostLogin);
    }

    /**
     * Constructeur
     */
    constructor(public router: Router,private store: Store<AppState>,private activatedRoute: ActivatedRoute) {

    }

    /**
     * Initialisation
     */
    init(session: Session) {
        //On dispatch des settings dans l'application à l'ouverture du menu
        //C'est utile dans le cas ou l'utilisateur connecté refresh ou accède directement à une url
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: 'Global'
        });
        
        //Vérification de l'approbation des CGU (sauf pour l'admin)
        if (!session.isAdmin || (session.isConnectAs && session.user)) {
            this.store.dispatch({
                type: sessionActions.CHECK_CGU_VALIDATION,
                payload: session
            });
        }

        //Lecture de la location de la frame
        this.store.select(s => s.navigation?.frameLocation).subscribe({
            next: frameLocation => {
                //Récupération de la location de la frame
                this.frameLocation = frameLocation;
            }
        });

        //Détection du changement de route
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => route.firstChild ? route.firstChild : route),
            filter(route => route.outlet === 'primary'),
        ).subscribe(route => {
            //Détermine suivant le composant affiché si le layout post-login doit être affiché
            const isLayoutPostLogin = !(route.component == LoginComponent || route.component == LoginComponent.name);

            //Affichage du layout post-login le cas échéant
            if (this.isLayoutPostLogin !== isLayoutPostLogin) {
                this.isLayoutPostLogin = isLayoutPostLogin;
            }

            //Définition du titre
            this.store.dispatch({
                type: layoutActions.UPDATE_LAYOUT_DATA,
                payload: route.data
            });
        });
    }

    /**
     * Récupération de la location de la frame
     */
    public getFrameLocation(): string {
        //Récupération de la location de la frame
        return this.frameLocation;
    }

    /**
     * Définition de la location de la frame
     */
    public setFrameLocation(frameLocation: string) {
        //Définition de la location de la frame
        this.store.dispatch({
            type: navigationActions.UPDATE_FRAME_LOCATION,
            payload: frameLocation
        });
    }

    /**
     * Réinitialisation de la location de la frame
     */
    public resetFrameLocation() {
        //Réinitialisation de la location de la frame
        this.store.dispatch({
            type: navigationActions.UPDATE_FRAME_LOCATION,
            payload: null
        });
    }
}
